/**
 *
 * @Copyright 2024 UNLOCK DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { Appearance } from '@stripe/stripe-js';

export const StripeAppearance: Appearance = {
    theme: 'stripe',
    variables: {
        fontFamily: '"Aeonik Fono", sans-serif',
        fontWeightNormal: '400',
        fontWeightBold: '700',
    },
    rules: {
        '.Input': {
            fontFamily: '"Aeonik Fono", sans-serif',
            fontSize: 'inherit',
            color: 'inherit',
        },
    },
};

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import React, { FunctionComponent } from 'react';
import { DataOrError, ErrorResponse } from '../../types/errors';
import {
    DocumentChecklistAssignment,
    LocationEntity,
    ParticipantAssignPinValidationPayload,
    PropertyType,
    PropertyTypology,
    WorkflowCurrentStateSteps,
    WorkflowPayload,
    WorkflowPropertyPayload,
    WorkflowState,
    WorkflowStatePayload,
    WorkflowsFilterParams,
} from '../../types/workflows';
import { FormValidationError, validateForm } from '../../utils/validations';
import { KeyedObject, TOTAL_ITEMS } from '../../types/general';
import { WorkflowContext, WorkflowContextProvider } from './WorkflowContext';
import {
    documentTypeUrl,
    locationUrl,
    propertyTypesUrl,
    propertyTypologiesUrl,
    workflowAssignmentsUrl,
    workflowAvailableStatesUrl,
    workflowContractsUrl,
    workflowDocumentDownloadUrl,
    workflowDocumentListUrl,
    workflowDocumentUrl,
    workflowParticipantPermissionsUrl,
    workflowParticipantsUrl,
    workflowPropertyUrl,
    workflowResourceRequestAccessUrl,
    workflowResourceRequestUrl,
    workflowReviewDocumentUrl,
    workflowReviewPendingRequestUrl,
    workflowStateChangeUrl,
    workflowStatesUrl,
    workflowTransferOwnershipUrl,
    workflowTypesUrl,
    workflowUrl,
    workflowsUrl,
} from '../../services/workflows';
import {
    participantAssignUrl,
    participantPermissionsUrl,
    participantRoleUrl,
    participantUrl,
    participantWorkflowsUrl,
} from '../../services/participants';

import { organizationWorkflowsUrl } from '../../services/organizations';
import { useCornerstoneApi } from '../../api';
import { validations } from '../../constants/validations';

interface OwnProps {
    children: React.ReactNode;
}

const WorkflowController: FunctionComponent<OwnProps> = (props) => {
    const { children } = props;
    const CornerstoneAPI = useCornerstoneApi();

    /**
     * Get Workflow
     *
     * @remarks
     * Get specific Workflow by its Id
     */
    const getWorkflow: WorkflowContext['getWorkflow'] = async (workflowId: string) => {
        try {
            const { data } = await CornerstoneAPI.get(workflowUrl(Number(workflowId)));

            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    /**
     * Get Workflow Types
     *
     * @remarks
     * Get the list of Workflow Types to render on 1st step of workflow creation flow
     */
    const getWorkflowTypes: WorkflowContext['getWorkflowTypes'] = async () => {
        try {
            const { data } = await CornerstoneAPI.get(workflowTypesUrl());
            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    /**
     * Get Workflow Property
     */
    const getProperty: WorkflowContext['getProperty'] = async (workflowId: string) => {
        try {
            const { data } = await CornerstoneAPI.get(workflowPropertyUrl(Number(workflowId)));

            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };
    /**
     * Create Workflow
     *
     * @remarks
     * This is a POST API request to create a new Workflow.
     * This is the submit of the 2nd step of the creation workflow flow
     */
    const createWorkflow: WorkflowContext['createWorkflow'] = async (payload: WorkflowPayload) => {
        try {
            const { data } = await CornerstoneAPI.post(workflowsUrl(), payload);
            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    /**
     * Remove Workflow
     *
     * @remarks
     * This is a DELETE API request to remove a Workflow.
     */
    const deleteWorkflow: WorkflowContext['deleteWorkflow'] = async (workflowId: string) => {
        try {
            await CornerstoneAPI.delete(workflowUrl(Number(workflowId)));
        } catch (e) {
            return e as ErrorResponse;
        }
    };
    /**
     * Validate Workflow fields
     *
     * @remarks
     * This function validates the form fields for a Workflow
     * before submitting a POST request.
     */
    const validateWorkflow = (fields: WorkflowPayload): FormValidationError | null => {
        const errors = validateForm(fields, validations.workflowCreate);

        if (!errors || Object.keys(errors).length === 0) return null;
        return { fields: errors };
    };
    /**
     * Edit Workflow
     *
     * @remarks
     * This is a POST API request to edit a Workflow.
     * This is the submit of the 1st step of the edit workflow flow
     */
    const editWorkflow: WorkflowContext['editWorkflow'] = async (workflowId: string, payload: WorkflowPayload) => {
        try {
            const { data } = await CornerstoneAPI.patch(workflowUrl(Number(workflowId)), payload);
            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    /**
     * Get Workflow Participants
     *
     * @remarks
     * Get the list of Workflow Participants to render on 4th step of workflow creation flow
     */
    const getWorkflowParticipants: WorkflowContext['getWorkflowParticipants'] = async (workflowId: string, cursor = '', limit = '') => {
        try {
            const params = {
                _cursor: encodeURIComponent(cursor),
                _limit: limit,
            };
            const { data, headers } = await CornerstoneAPI.get(workflowParticipantsUrl(workflowId, params));
            return [{
                cursor: data.cursor,
                results: data.results,
                totalAmount: parseInt(headers[TOTAL_ITEMS] ?? '0'),
            }, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    /**
     * Create Workflow Property
     *
     * @remarks
     * This is a POST API request to create Workflow Property.
     */
    const createWorkflowProperty: WorkflowContext['createWorkflowProperty'] = async (workflowId: string, payload: WorkflowPropertyPayload) => {
        try {
            const { data } = await CornerstoneAPI.post(workflowPropertyUrl(Number(workflowId)), payload);
            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    /**
     * Edit Workflow Property
     *
     * @remarks
     * This is a POST API request to edit Workflow Property.
     */
    const editWorkflowProperty: WorkflowContext['editWorkflowProperty'] = async (workflowId: string, payload: WorkflowPropertyPayload) => {
        try {
            const { data } = await CornerstoneAPI.patch(workflowPropertyUrl(Number(workflowId)), payload);
            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    /**
     * Validate Workflow Property fields
     *
     * @remarks
     * This function validates the form fields for a Workflow Property
     * before submitting a POST request.
     */
    const validateCreateWorkflowProperty = (fields: WorkflowPropertyPayload): FormValidationError | null => {
        const errors = validateForm(fields, validations.workflowCreateProperty);

        if (!errors || Object.keys(errors).length === 0) return null;
        return { fields: errors };
    };

    /**
    * Invite Participant to Workflow
    */
    const addNewParticipant: WorkflowContext['addNewParticipant'] = async (workflowId, payload) => {
        try {
            const { data } = await CornerstoneAPI.post(participantUrl(workflowId), payload);

            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    /**
    * Assign current user to Participant
    */
    const assignUserToParticipant: WorkflowContext['assignUserToParticipant'] = async (participantToken) => {
        try {
            const { data } = await CornerstoneAPI.put(participantAssignUrl(participantToken));

            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    /**
    * Validate participant pin code sent to his email
    *
    * The pin code is send only in case of the invite email !== user email
    */
    const validateParticipantAssignPinCode: WorkflowContext['validateParticipantAssignPinCode'] = async (participantToken: string, payload: ParticipantAssignPinValidationPayload) => {
        try {
            const { data } = await CornerstoneAPI.post(participantAssignUrl(participantToken), payload);

            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    /**
    * Delete Participant from Workflow
    */
    const deleteParticipant: WorkflowContext['deleteParticipant'] = async (workflowId, participantId) => {
        try {
            await CornerstoneAPI.delete(participantUrl(workflowId, participantId));
        } catch (e) {
            return e as ErrorResponse;
        }
    };

    const updateParticipantRole: WorkflowContext['updateParticipantRole'] = async (workflowId, participantId, role) => {
        try {
            await CornerstoneAPI.patch(participantRoleUrl(workflowId, participantId), { participantRole: role });
        } catch (e) {
            return e as ErrorResponse;
        }
    };

    const transferOwnership: WorkflowContext['transferOwnership'] = async (workflowId, newOwnerId) => {
        try {
            await CornerstoneAPI.post(workflowTransferOwnershipUrl(workflowId), { userId: newOwnerId });
        } catch (e) {
            return e as ErrorResponse;
        }
    };

    /**
     * Get Participant Workflows
     *
     * @remarks
     * Get the list of Participant Workflows
     */
    const getParticipantWorkflows: WorkflowContext['getParticipantWorkflows'] = async (filters: WorkflowsFilterParams) => {
        try {
            const params = {
                ...filters,
                _cursor: encodeURIComponent(filters._cursor ?? ''),
            };
            const { data } = await CornerstoneAPI.get(participantWorkflowsUrl(params));

            return [{
                cursor: data.cursor,
                results: data.results,
            }, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    /**
    * Create Workflow Property
    *
    * @remarks
    * This is a GET API request to create Workflow Property.
    */
    const getLocation = async (params: KeyedObject<number>): Promise<DataOrError<LocationEntity[], ErrorResponse>> => {
        try {
            const { data } = await CornerstoneAPI.get(locationUrl(params));
            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    /**
     * Get Workflow Property Types
     *
     * @remarks
     * This list is used for the selectbox options
     */
    const getPropertyTypes = async (): Promise<DataOrError<PropertyType[], ErrorResponse>> => {
        try {
            const { data } = await CornerstoneAPI.get(propertyTypesUrl());
            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    /**
     * Get Workflow Property Typologies
     *
     * @remarks
     * This list is used for the selectbox options
     */
    const getPropertyTypologies = async (): Promise<DataOrError<PropertyTypology[], ErrorResponse>> => {
        try {
            const { data } = await CornerstoneAPI.get(propertyTypologiesUrl());
            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    /**
     * Get Workflow States
     *
     * @remarks
     * Get the list of available states to be used for render progress stepper
     */
    const getWorkflowStates = async (workflowType: string): Promise<DataOrError<WorkflowState[], ErrorResponse>> => {
        try {
            const params = {
                transactionType: workflowType,
            };
            const { data } = await CornerstoneAPI.get(workflowStatesUrl(params));
            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    /**
     * Get Workflow Next and Previous States
     *
     * @remarks
     * Get the list of available states to be used for render dynamic buttons
     */
    const getWorkflowAvailableStates = async (workflowId: string): Promise<DataOrError<WorkflowCurrentStateSteps, ErrorResponse>> => {
        try {
            const { data } = await CornerstoneAPI.get(workflowAvailableStatesUrl(Number(workflowId)));
            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    /**
     * Change Workflow State
     *
     * @remarks
     * Send request to change workflow state with the state in body
     */
    const changeWorkflowState: WorkflowContext['changeWorkflowState'] = async (workflowId: string, payload: WorkflowStatePayload) => {
        try {
            await CornerstoneAPI.post(workflowStateChangeUrl(Number(workflowId)), payload);
        } catch (e) {
            return e as ErrorResponse;
        }
    };

    /**
     * Get Workflow Contracts
     *
     * @remarks
     * This is a GET API request to fetch Workflows Contracts.
     *
     * This request response does not come paginated.
     * It return all the contracts for the workflow
     */
    const getWorkflowContracts: WorkflowContext['getWorkflowContracts'] = async (workflowId: string) => {
        try {
            const { data } = await CornerstoneAPI.get(workflowContractsUrl(Number(workflowId)));
            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    const uploadWorkflowDocument: WorkflowContext['uploadWorkflowDocument'] = async (workflowId, payload) => {
        try {
            const formData = new FormData();

            formData.append('documentType', String(payload.documentType));
            formData.append('name', payload.name);

            if (payload.userId) formData.append('userId', payload.userId ? String(payload.userId) : '');
            if (payload.notes) formData.append('notes', payload.notes ? String(payload.notes) : '');

            formData.append('file', payload.file as Blob, payload.file.name);

            await CornerstoneAPI.post(workflowDocumentUrl(workflowId), formData);
        } catch (e) {
            return e as ErrorResponse;
        }
    };

    const getWorkflowAssignments: WorkflowContext['getWorkflowAssignments'] = async (workflowId: string, assignmentType: DocumentChecklistAssignment, missingAction?: boolean) => {
        try {
            const { data } = await CornerstoneAPI.get(workflowAssignmentsUrl(workflowId, assignmentType, missingAction));

            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    const getDocuments: WorkflowContext['getDocuments'] = async (workflowId, cursor = '', limit = '') => {
        try {
            const params = {
                _cursor: encodeURIComponent(cursor),
                _limit: limit,
            };
            const { data } = await CornerstoneAPI.get(workflowDocumentListUrl(workflowId, params));

            return [{
                cursor: data.cursor,
                results: data.results,
            }, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    const getDocumentTypes: WorkflowContext['getDocumentTypes'] = async () => {
        try {
            const { data } = await CornerstoneAPI.get(documentTypeUrl());

            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    const getDocument: WorkflowContext['getDocument'] = async (workflowId, documentId) => {
        try {
            const { data } = await CornerstoneAPI.get(workflowDocumentUrl(workflowId, documentId));

            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    const getDocumentPdf: WorkflowContext['getDocumentPdf'] = async (workflowId, documentId) => {
        try {
            const { data } = await CornerstoneAPI.get(workflowDocumentDownloadUrl(workflowId, documentId), { responseType: 'blob' });

            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    const deleteDocument: WorkflowContext['deleteDocument'] = async (workflowId, documentId) => {
        try {
            await CornerstoneAPI.delete(workflowDocumentUrl(workflowId, documentId));
        } catch (e) {
            return e as ErrorResponse;
        }
    };

    /**
     * Review Document
     *
     * @remarks
     * This is a post API request to review a given document
     */
    const reviewDocument: WorkflowContext['reviewDocument'] = async (workflowId, documentId, documentStatus, notes) => {
        try {
            await CornerstoneAPI.post(workflowReviewDocumentUrl(workflowId, documentId), { documentStatus, notes });
        } catch (e) {
            return e as ErrorResponse;
        }
    };

    const getOrganizationWorkflows: WorkflowContext['getOrganizationWorkflows'] = async (organizationId, filters: WorkflowsFilterParams) => {
        try {
            const params = {
                ...filters,
                _cursor: encodeURIComponent(filters._cursor ?? ''),
            };
            const { data } = await CornerstoneAPI.get(organizationWorkflowsUrl(organizationId, params));
            return [{
                cursor: data.cursor,
                results: data.results,
            }, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    /**
     * Get Resource accesses requests
     *
     * @remarks
     * Fetches all requests to access a given resource
     */
    const getResourceRequests: WorkflowContext['getResourceRequests'] = async (workflowId, resourceId, resourceType, status) => {
        try {
            const { data } = await CornerstoneAPI.get(workflowResourceRequestUrl(workflowId, resourceId, resourceType, status));

            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    /**
     * Update Resource accesses request
     *
     * @remarks
     * Update access request permission
     */
    const updateResourceRequestPermission: WorkflowContext['updateResourceRequestPermission'] = async (workflowId, resourceId, resourceType, participantId, permissionType) => {
        try {
            await CornerstoneAPI.put(workflowResourceRequestUrl(workflowId, resourceId, resourceType), {
                participantId,
                permissionType,
            });
        } catch (e) {
            return e as ErrorResponse;
        }
    };

    /**
     * Review Resource accesses request
     *
     * @remarks
     * Review access request request
     */
    const reviewResourceRequest: WorkflowContext['reviewResourceRequest'] = async (workflowId, requestedAccessUuid, status) => {
        try {
            await CornerstoneAPI.post(workflowReviewPendingRequestUrl(workflowId, requestedAccessUuid), {
                status,
            });
        } catch (e) {
            return e as ErrorResponse;
        }
    };

    const getWorkflowParticipantPermissions: WorkflowContext['getWorkflowParticipantPermissions'] = async (workflowId) => {
        try {
            const { data } = await CornerstoneAPI.get(workflowParticipantPermissionsUrl(workflowId));

            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    const getParticipantPermissions: WorkflowContext['getParticipantPermissions'] = async (workflowId, participantId) => {
        try {
            const { data } = await CornerstoneAPI.get(participantPermissionsUrl(workflowId, participantId));
            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    const updateParticipantPermissions: WorkflowContext['updateParticipantPermissions'] = async (workflowId, participantId, payload) => {
        try {
            await CornerstoneAPI.patch(participantPermissionsUrl(workflowId, participantId), payload);
        } catch (e) {
            return e as ErrorResponse;
        }
    };

    /**
     * Requests access to given resource
     *
     */
    const requestAccessToWorkflowResource: WorkflowContext['requestAccessToWorkflowResource'] = async (workflowId, resourceId, resourceType) => {
        try {
            const { data } = await CornerstoneAPI.post(workflowResourceRequestAccessUrl(workflowId, resourceId, resourceType));

            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    return (
        <WorkflowContextProvider
            value={{
                getWorkflow,
                getWorkflowTypes,
                getProperty,
                createWorkflow,
                editWorkflow,
                deleteWorkflow,
                validateWorkflow,
                createWorkflowProperty,
                editWorkflowProperty,
                validateCreateWorkflowProperty,
                getWorkflowParticipants,
                addNewParticipant,
                assignUserToParticipant,
                validateParticipantAssignPinCode,
                deleteParticipant,
                updateParticipantRole,
                transferOwnership,
                getParticipantWorkflows,
                getLocation,
                getPropertyTypes,
                getPropertyTypologies,
                getWorkflowStates,
                getWorkflowAvailableStates,
                changeWorkflowState,
                getWorkflowContracts,
                uploadWorkflowDocument,
                getWorkflowAssignments,
                getDocuments,
                getDocumentTypes,
                getDocument,
                getDocumentPdf,
                deleteDocument,
                reviewDocument,
                getOrganizationWorkflows,
                getResourceRequests,
                getWorkflowParticipantPermissions,
                requestAccessToWorkflowResource,
                getParticipantPermissions,
                updateParticipantPermissions,
                reviewResourceRequest,
                updateResourceRequestPermission,
            }}
        >
            {children}
        </WorkflowContextProvider>
    );
};

export { WorkflowController };

/*
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    ChangeEvent,
    FunctionComponent,
    KeyboardEvent,
    useState,
} from 'react';

import classnames from 'classnames';

/**
 * Smart Code props
 */
interface OwnProps {
    /**
     * Element ID
     */
    id: string;
    /**
     * Form name attribute. Will be used to store the full value in the DOM
     */
    name: string;
    /**
     * Number of inputs to render
     */
    size: number;
    isPassword?: boolean;
    /**
     * Boolean that shows inputs as errors
     */
    hasError?: boolean;
    required?: boolean;
    /**
     * React key used for render refresh.
     * Set the key with the a prop to render correctly when changing props
     */
    key?: string;
    /**
     * Callback function that fires every time the user changes a value
     */
    onValuesChange?: (value: InputDefinition[]) => void;
}

/**
 * HTML input attribute definition
 */
export interface InputDefinition {
    id: string;
    index: number;
    value: string;
}

/**
 * Initiates the input array definition that will be used for rendering
 */
function loadInputsFromSize(size: number) {
    const inputs: InputDefinition[] = [];
    for (let i = 0; i < size; i++) {
        inputs.push({
            value: '',
            index: i,
            id: inputId(i),
        });
    }
    return inputs;
}

/**
 * Generates input ID attribute by index
 *
 * @param index input index
 * @returns input ID
 */
function inputId(index: number) {
    return `smart-code-${index}`;
}

/**
 * Smart Code form field generates a form field for a code attribute like OTP or PIN as one input per digit.
 * A hidden input field is also present with the full value
 *
 * {@link https://www.figma.com/file/nvWLkKo3SS0kIDZ58NWkFe/Web-App?type=design&node-id=1710-22199&mode=design&t=ki0RYkNZY836rc3C-0 Mockups}
 * @param props
 * @returns
 */
export const SmartCodeFormField: FunctionComponent<OwnProps> = (props) => {
    const {
        key,
        id,
        name,
        size,
        isPassword,
        hasError = false,
        required,
        onValuesChange,
    } = props;

    /**
     * Component state
     */
    const [inputValues, setInputValues] = useState<InputDefinition[]>(loadInputsFromSize(size));

    /**
     * Handles individual input value change and triggers
     *
     * @param e event
     * @returns
     */
    function handleOnChange(e: ChangeEvent<HTMLInputElement>) {
        const inputIndex = e.target.dataset?.index ?? '0';
        const inputValue = e.target.value;
        if (inputValue.length) {
            handleNewValue(parseInt(inputIndex), inputValue);
            return;
        }
        handleClearValue(parseInt(inputIndex));
    }

    /**
     * Applies logic when the input has a new value.
     * Updates the input value state and moves focus to the next input.
     *
     * @param index input index on focus
     * @param value new value
     */
    function handleNewValue(index: number, value: string) {
        const newArray = inputValues.map((el) => {
            const newInput = el;
            if (el.index === index) {
                newInput.value = value;
            }
            return newInput;
        });
        setInputValues(newArray);
        focusElement(index + 1);

        if (onValuesChange) onValuesChange(newArray);
    }

    /**
     * Handles logic when user deletes the input value.
     * Updates the input value state and moves focus to the previous input.
     *
     * @param index input index on focus
     */
    function handleClearValue(index: number) {
        const newArray = inputValues.map((el) => {
            const newInput = el;
            if (el.index === index) {
                newInput.value = '';
            }
            return newInput;
        });
        setInputValues(newArray);
        focusElement(index - 1);

        if (onValuesChange) onValuesChange(newArray);
    }

    /**
     * Finds and focus on input by index
     *
     * @param index input index
     */
    function focusElement(index: number) {
        if (index >= 0 && index < size) {
            document.getElementById(inputId(index))?.focus();
        }
    }

    /**
     * Handles keyboard backspace event forcing
     *
     * @param e event
     */
    function handleOnKeyDown(e: KeyboardEvent<HTMLInputElement>) {
        const inputIndex = e.currentTarget.dataset?.index ?? '0';
        if (e.key === 'Backspace') {
            handleClearValue(parseInt(inputIndex));
        }
    }

    /**
     * Calculates the full code value from all inputs
     *
     * @returns full code
     */
    function calculateFullCodeValue() {
        return inputValues.map((el) => el.value).reduce((prev, curr) => prev.concat(curr));
    }

    return (
        <div
            id={id}
            className={classnames(
                'smart-code',
                { 'smart-code--error': hasError },
            )}
            data-testid="smart-code"
            key={key}
        >
            {inputValues.map((el, i) => (
                <input
                    key={el.id}
                    id={el.id}
                    onChange={handleOnChange}
                    onKeyDown={handleOnKeyDown}
                    type={isPassword ? 'password' : 'text'}
                    value={el.value}
                    size={1}
                    maxLength={1}
                    data-testid={i}
                    data-index={el.index}
                    placeholder="0"
                    data-type="smart-code"
                    // Complicance with Chave Móvel Digital signature experience
                    aria-autocomplete="none"
                    required={required}
                />
            ))}
            <input name={name} type={isPassword ? 'password' : 'text'} hidden data-testid="full-code" value={calculateFullCodeValue()} readOnly />
        </div>
    );
};

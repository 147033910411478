/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { Alert } from '@mui/material';
import { FunctionComponent, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { AppRoute } from '../../../constants/routes';
import { PaymentMethod, PaymentMethodsList } from '../../../types/billing';
import { DataOrError, ErrorResponse } from '../../../types/errors';
import { buildUrl } from '../../../utils/navigation';
import { BillingContext, withBillingContext } from '../../controllers/BillingContext';
import { PaymentMethodContext, withPaymentMethodContext } from '../../controllers/PaymentMethodContext';
import { LoadingCircles } from '../../elements/LoadingCircles';

type OwnProps = BillingContext & PaymentMethodContext;

export const PaymentMethodsScreenBase: FunctionComponent<OwnProps> = (props) => {
    const {
        listPaymentMethods,
        billingInfo,
    } = props;

    const [apiResult, setApiResult] = useState<DataOrError<PaymentMethodsList, ErrorResponse>>([[], null]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const { wallet } = billingInfo;
        if (!wallet) {
            setIsLoading(false);
            return;
        }

        setIsLoading(true);
        listPaymentMethods(wallet.id).then((data) => {
            setApiResult(data);
            setIsLoading(false);
        });
    };

    function renderPaymentMethod(method: PaymentMethod) {
        // Default credit card mapping
        return (
            <NavLink
                to={buildUrl(AppRoute.PaymentMethod, {
                    methodId: method.id,
                })}
                data-testid={method.id}
                key={method.id}
            >
                <li>
                    <p>Brand: {method.card.brand} |||| Card Number: **** **** **** {method.card.last4} |||| Expiration: {method.card.expMonth}/{method.card.expYear} |||| Used by default: {String(method.usedByDefault)}</p>
                </li>
            </NavLink>
        );
    }

    return (
        <section id="payment-method-screen" data-testid="payment-method-screen">

            <h1>Payment methods</h1>

            <ul>
                {apiResult[0]?.map((p) => renderPaymentMethod(p))}
            </ul>

            {isLoading && <LoadingCircles size="xl" variant="primary" />}

            <div style={{
                margin: '3rem',
            }}
            >

                <NavLink to={AppRoute.NewPaymentMethodSelector}>
                    Novo Método de pagamento
                </NavLink>
            </div>

            {apiResult[1] && (
                <Alert severity="error">{JSON.stringify(apiResult[1])}</Alert>
            )}

        </section>
    );
};

export const PaymentMethodsScreen = withPaymentMethodContext(withBillingContext(PaymentMethodsScreenBase));

/*
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */
import JSEncrypt from 'jsencrypt';

/**
 * Encrypts a value with a public key. If successful returns the encrypted string, otherwise returns null.
 *
 * @param value value to be cyphered
 * @param pubKey the public key
 * @returns value encrypted or null if encryption fails
 */
export function encryptWithPublicKey(value: string, pubKey: string): string |null {
    const cmdCrypt = new JSEncrypt();
    cmdCrypt.setKey(pubKey);
    const result = cmdCrypt.encrypt(value); // returns the cyphered value in base 64
    if (result) {
        return result;
    }
    return null;
}

/**
 * Decrypts a value with a private key. If successful returns the decrypted string, otherwise returns null.
 *
 * @param value value to be decrypted
 * @param privateKey private key to decode
 * @returns value decoded or null if fails
 */
export function decryptWithPrivateKey(value: string, privateKey: string): string |null {
    const cmdCrypt = new JSEncrypt();
    cmdCrypt.setKey(privateKey);
    const result = cmdCrypt.decrypt(value); // returns the cyphered value in base 64;
    if (result) {
        return result;
    }
    return null;
}

/*
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { AppRouteType } from '../constants/routes';
import { KeyedObject } from '../types/general';

/**
 * Builds the url string from a route and object params
 *
 * @param route the route with placeholder params
 * @param params the params to replace and enrich the route
 * @returns the route replaced
 */
export function buildUrl(route: AppRouteType | string, params: KeyedObject<string> | undefined = {}): string {
    return Object.keys(params).reduce((prev, curr) => {
        return prev.replace(`:${curr}`, params[curr]);
    }, route);
}

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { KeyedObject } from '../types/general';
import { objectToParams } from '../utils/misc';

/* Contracts */
export const userContractsUrl = (userId: string, params?: KeyedObject<string>) => {
    const queryStr = objectToParams(params);
    return `/users/${userId}/contracts${queryStr}`;
};

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import { AppRoute } from '../../../constants/routes';
import Button from '../../elements/Button';
import { ButtonVariant } from '../../../types/general';

interface OwnProps extends TranslationContext { }

type RouterState = {
    referrer?: string;
    returnTo?: string;
}

const SupportTicketSubmittedScreenBase: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const { t } = props;
    const location = useLocation();
    const navigate = useNavigate();
    const returnTo = (location.state as RouterState)?.returnTo ?? AppRoute.Index;

    const onContinue = () => {
        navigate(returnTo);
    };

    return (
        <div className="support-ticket-submitted-screen">
            <div className="support-ticket-submitted-screen__wrapper">
                <h1>{t('ticketSubmittedScreen.title')}</h1>
                <p>{t('ticketSubmittedScreen.description')}</p>

                <Button onClick={onContinue} variant={ButtonVariant.Curved} extraClasses="primary" title={t('ticketSubmittedScreen.continue')}>{t('ticketSubmittedScreen.continue')}</Button>
            </div>
        </div>
    );
};

export const SupportTicketSubmittedScreen = withTranslationContext(SupportTicketSubmittedScreenBase);

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent, useState } from 'react';
import { Participant, WorkflowParticipantRole } from '../../../types/workflows';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import { ReactComponent as VerticalDotsIcon } from '../../../assets/images/vertical-dots.svg';
import { ReactComponent as ProfileIcon } from '../../../assets/images/profile.svg';
import Button from '../Button';
import { ManageParticipantModal } from './ManageParticipantModal';
import { useAuthContext } from '../../controllers/AuthenticationContext';

interface OwnProps extends TranslationContext {
    participant: Participant;
    canTransferOwner: boolean;
    canManagePermissions: boolean;
    onRemoveParticipant: (participantId: number) => void;
    onUpdateParticipant: (updatedParticipant: Participant) => void;
    onTransferOwnership: (newOwnerId: string) => void;
}

const ParticipantCardComponent: FunctionComponent<OwnProps> = (props) => {
    const {
        participant, t, onRemoveParticipant, onUpdateParticipant, onTransferOwnership, canTransferOwner, canManagePermissions,
    } = props;

    const { user } = useAuthContext();

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const handleRemovedParticipant = () => {
        setIsModalOpen(false);

        onRemoveParticipant(participant.id);
    };

    const handleUpdateParticipantRole = (role: WorkflowParticipantRole) => {
        onUpdateParticipant({ ...participant, participantRole: role });
    };

    const renderManageParticipantButton = () => {
        if (canManagePermissions || participant.userId === user?.id) {
            return (
                <Button onClick={() => setIsModalOpen(true)} testId={`manage-participant-${participant.id}`}>
                    <VerticalDotsIcon />
                </Button>
            );
        }

        return null;
    };

    return (
        <>
            <div className="participant-card" data-testid={`participant-card-${participant.id}`}>
                <div className="participant-card__info">
                    <span className="participant-card__info__icon-wrap">
                        <ProfileIcon />
                    </span>
                    <div className="participant-card__info__details">
                        <p title={participant.name}>{participant.name}</p>
                        <p className="participant-card__info__details__role">{t(`participantRole.${participant.participantRole}`)}</p>
                    </div>
                </div>
                {renderManageParticipantButton()}
            </div>
            <ManageParticipantModal
                open={isModalOpen}
                onModalClose={() => setIsModalOpen(false)}
                canTransferOwner={canTransferOwner}
                canManagePermissions={canManagePermissions}
                participant={participant}
                onRemoveParticipant={handleRemovedParticipant}
                onUpdateParticipantRole={handleUpdateParticipantRole}
                onTransferOwnership={onTransferOwnership}
            />
        </>
    );
};

export const ParticipantCard = withTranslationContext(ParticipantCardComponent);

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { API_URL, WEBSOCKET_URL } from '../settings';

export const websocketTokenUrl = (): string => {
    return `${API_URL}/websocket/token`;
};

export const websocketSignatureUrl = (token: string, signerId: string): string => {
    return `${WEBSOCKET_URL}/websocket/${token}/signature/${signerId}`;
};

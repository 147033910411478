/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent, useEffect, useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import classNames from 'classnames';
import { OrganizationsContext, withOrganizationsContext } from '../controllers/OrganizationsContext';
import { MenuBarItem } from '../../types/menu';
import { ReactComponent as WalletIcon } from '../../assets/images/wallet.svg';
import { ReactComponent as UserProfileIcon } from '../../assets/images/user-profile.svg';
import { ReactComponent as SecurityIcon } from '../../assets/images/security.svg';
import { ReactComponent as Logo } from '../../assets/images/logo-horizontal.svg';
import { ReactComponent as IconLeftArrow } from '../../assets/images/arrow-left.svg';
import { ReactComponent as NewsletterIcon } from '../../assets/images/newsletter.svg';
import { ReactComponent as NotificationsIcon } from '../../assets/images/notifications.svg';
import { ReactComponent as SignatureIcon } from '../../assets/images/sign.svg';
import { ReactComponent as MenuIcon } from '../../assets/images/menu.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/closeBtn.svg';
import { AppRoute } from '../../constants/routes';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import { Tooltip } from './Tooltip';

interface OwnProps extends OrganizationsContext, TranslationContext { }

const AccountMenubarComponent: FunctionComponent<OwnProps> = (props) => {
    const { t } = props;

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        const closeMenu = () => setIsMenuOpen(false);

        window.addEventListener('resize', closeMenu);
        return () => window.removeEventListener('resize', closeMenu);
    }, []);

    const accountCenterItems: MenuBarItem[] = [
        {
            title: 'personalInformation',
            icon: <UserProfileIcon />,
            link: AppRoute.Index,
            disabled: true,
        },
        {
            title: 'loginSecurity',
            icon: <SecurityIcon />,
            link: AppRoute.Index,
            disabled: true,
        },
    ];
    const billingItems: MenuBarItem[] = [
        {
            title: 'wallet',
            icon: <WalletIcon />,
            link: AppRoute.AccountWallet,
        },
    ];

    const otherSettingsItems: MenuBarItem[] = [
        {
            title: 'newsletter',
            icon: <NewsletterIcon />,
            link: AppRoute.Index,
            disabled: true,
        },
        {
            title: 'notifications',
            icon: <NotificationsIcon />,
            link: AppRoute.Index,
            disabled: true,
        },
        {
            title: 'signature',
            icon: <SignatureIcon />,
            link: AppRoute.Index,
            disabled: true,
        },
    ];

    return (
        <div className={classNames('menu-bar menu-bar--scrollable', { open: isMenuOpen })} data-testid="menu-bar">
            <button type="button" onClick={() => setIsMenuOpen((prev) => !prev)}>
                {isMenuOpen
                    ? <Tooltip title={t('general.menu')} placement="bottom"><CloseIcon /></Tooltip>
                    : <Tooltip title={t('general.close')} placement="bottom"><MenuIcon /></Tooltip>
                }
            </button>
            <div className="menu-bar__logo-wrap_desktop">
                <Logo />
            </div>
            <div className="menu-bar__wrap-list menu-bar__wrap-list--scrollable-list dark-custom-scrollbar">
                <NavLink className="menu-bar__wrap-list__go-to-link" to={AppRoute.Index} end>
                    <IconLeftArrow />
                    {t('menuBar.goBackHome')}
                </NavLink>
                <div className="menu-bar__wrap-list__account-center">
                    <h3>{t('menuBar.accountCenterTitle')}</h3>
                    <p>{t('menuBar.accountCenterDescription')}</p>
                    <ul className="menu-bar__wrap-list__account-center__list">
                        {accountCenterItems.map((item) => (
                            <Link to={item.link} key={item.title} className={item.disabled ? 'disabled' : ''} onClick={() => setIsMenuOpen((prev) => !prev)}>
                                <li className="menu-bar__list__item">
                                    <div className="menu-bar__list__item__icon-wrap">
                                        {item.icon}
                                    </div>
                                    <span className="menu-bar__list__item__title">
                                        {t(`menuBar.${item.title}`)}
                                    </span>
                                </li>
                            </Link>
                        ))}
                    </ul>
                    <span className="manage-accounts-link disabled">
                        {t('menuBar.accountCenterBtn')}
                    </span>
                    <span className="coming-soon disabled">
                        {t('menuBar.comingSoon')}
                    </span>
                </div>
                <h3>{t('menuBar.billing')}</h3>
                <ul className="menu-bar__wrap-list__list">
                    {billingItems.map((item) => (
                        <NavLink to={item.link} key={item.title} end className={item.disabled ? 'disabled' : ''} onClick={() => setIsMenuOpen((prev) => !prev)}>
                            <li className="menu-bar__list__item">
                                <div className="menu-bar__list__item__icon-wrap">
                                    {item.icon}
                                </div>
                                <span className="menu-bar__list__item__title">
                                    {t(`menuBar.${item.title}`)}
                                </span>
                            </li>
                        </NavLink>
                    ))}
                </ul>
                <h3>{t('menuBar.otherSettings')}</h3>
                <ul className="menu-bar__wrap-list__list">
                    {otherSettingsItems.map((item) => (
                        <NavLink to={item.link} key={item.title} end className={item.disabled ? 'disabled' : ''} onClick={() => setIsMenuOpen((prev) => !prev)}>
                            <li className="menu-bar__list__item">
                                <div className="menu-bar__list__item__icon-wrap">
                                    {item.icon}
                                </div>
                                <span className="menu-bar__list__item__title">
                                    {t(`menuBar.${item.title}`)}
                                </span>
                            </li>
                        </NavLink>
                    ))}
                </ul>
            </div>
            {/* Bottom for now is just for styling purpose */}
            <div className="menu-bar__bottom" />
        </div>
    );
};

export const AccountMenubar = withTranslationContext(withOrganizationsContext(AccountMenubarComponent));

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FunctionComponent, useCallback, useEffect, useMemo, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { debounce } from 'lodash';
import { toast } from 'react-toastify';
import { Elements } from '@stripe/react-stripe-js';
import { DefaultLayout } from '../../elements/layouts/DefaultLayout';
import { CartSummary } from '../../elements/billing/CartSummary';
import { PaymentMethodContext, withPaymentMethodContext } from '../../controllers/PaymentMethodContext';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import { BillingContext, withBillingContext } from '../../controllers/BillingContext';
import { PaymentMethodsList } from '../../../types/billing';
import { AppRoute } from '../../../constants/routes';
import { RadioSelect } from '../../elements/RadioSelect';
import { LoadingCircles } from '../../elements/LoadingCircles';
import Button from '../../elements/Button';
import { ButtonVariant } from '../../../types/general';
import { CheckoutForm } from './backup/CheckoutForm';
import { StripeAppearance } from '../../../constants/StripeAppearance';

interface OwnProps extends PaymentMethodContext, TranslationContext, BillingContext { }

const PurchaseCreditsCheckoutComponent: FunctionComponent<OwnProps> = (props) => {
    const {
        t,
        getStripeConfig,
        createPaymentIntent,
        listPaymentMethods,
        billingInfo,
        updateBasket,
    } = props;

    const navigate = useNavigate();

    const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>>();
    const [clientSecret, setClientSecret] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [paymentMethodsList, setPaymentMethodsList] = useState<PaymentMethodsList>([]);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string | null>(null);

    const paymentMethodsOptions = useMemo(() => {
        return paymentMethodsList.map((method) => ({
            value: method.id,
            label: `${method.card.brand} **** **** **** ${method.card.last4}`,
        }));
    }, [paymentMethodsList]);

    useEffect(() => {
        if (selectedPaymentMethod && billingInfo.basket?.wallet) {
            debounceUpdatePaymentMethod(billingInfo.basket?.wallet.id, selectedPaymentMethod);
        }
    }, [selectedPaymentMethod]);

    const debounceUpdatePaymentMethod = useCallback(debounce((walletId: number, paymentMethodId: string) => {
        updateBasket(String(walletId), { paymentMethodId }).then(async () => {
            await requestCreatePaymentIntent();

            setIsLoading(false);
        });
    }, 500), []);

    useEffect(() => {
        if (!billingInfo.basket) {
            navigate(AppRoute.OrganizationBillingDashboard);
            return;
        }

        init();
    }, []);

    const init = async () => {
        if (!billingInfo.basket) {
            setIsLoading(false);
            return;
        }

        const [stripeConfig, stripeConfigError] = await getStripeConfig();

        if (stripeConfig?.publishableKey) {
            setStripePromise(loadStripe(stripeConfig.publishableKey));
        }

        if (stripeConfigError) {
            toast.error(stripeConfigError.errors[0]?.getMessageTranslated(t));
        }

        await Promise.all([requestListPaymentMethods(billingInfo.basket.wallet.id)]);

        setIsLoading(false);
    };

    const requestCreatePaymentIntent = async () => {
        if (!billingInfo.wallet || !billingInfo.basket) {
            return;
        }

        const [paymentIntent, paymentIntentError] = await createPaymentIntent(String(billingInfo.wallet?.id), { basketId: billingInfo.basket.id });
        if (paymentIntent) {
            setClientSecret(paymentIntent.clientSecret);
        }

        if (paymentIntentError) {
            toast.error(paymentIntentError.errors[0]?.getMessageTranslated(t));
        }
    };

    const requestListPaymentMethods = async (walletId: number) => {
        const [paymentMethods, paymentMethodsError] = await listPaymentMethods(walletId);
        if (paymentMethods) {
            setPaymentMethodsList(paymentMethods);

            const defaultPaymentMethod = paymentMethods.find((method) => method.usedByDefault);
            if (defaultPaymentMethod) {
                setSelectedPaymentMethod(defaultPaymentMethod.id);
            }
        }

        if (paymentMethodsError) {
            toast.error(paymentMethodsError.errors[0]?.getMessageTranslated(t));
        }
    };

    const renderPaymentMethods = () => {
        return (
            <RadioSelect
                name="payment-methods"
                options={paymentMethodsOptions}
                value={selectedPaymentMethod}
                onChange={(_name, value) => setSelectedPaymentMethod(value as string)}
            />
        );
    };
    const goBackBtn = (
        <Button
            id="actionBack"
            variant={ButtonVariant.Curved}
            extraClasses="secondary slim"
            onClick={() => navigate(AppRoute.OrganizationBillingDashboard)}
            testId="actionBack"
        >
            <span className="btn-content">
                {t('general.goBack')}
            </span>
        </Button>
    );

    return (
        <DefaultLayout>
            <div className="purchase-credits-screen">
                <div className="purchase-credits-screen__container">
                    <h2>{t('subscribeFlow.checkout.title')}</h2>
                    {isLoading && (
                        <LoadingCircles size="s" variant="primary" />
                    )}
                    {renderPaymentMethods()}
                    <button type="button" onClick={() => navigate(AppRoute.NewCreditCard)}>
                        {t('subscribeFlow.checkout.addNewPaymentMethod')}
                    </button>
                    <div>
                        {goBackBtn}
                        {stripePromise && clientSecret && (
                            <Elements stripe={stripePromise} options={{ clientSecret, appearance: StripeAppearance }}>
                                <CheckoutForm checkoutFlow="purchaseCredits" clientSecret={clientSecret} />
                            </Elements>
                        )}
                    </div>
                </div>
                <CartSummary />
            </div>
        </DefaultLayout>
    );
};

export const PurchaseCreditsCheckout = withPaymentMethodContext(withBillingContext(withTranslationContext(PurchaseCreditsCheckoutComponent)));

/*
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent } from 'react';
import BulletOngoing from '../../assets/images/bullet-ongoing.svg';
import BulletTodo from '../../assets/images/bullet-todo.svg';
import Checkmark from '../../assets/images/checkmark-rounded.svg';

interface OwnProps {
    /**
     * List of steps
     */
    list: string[];
    /**
     * The current step index. Starts at 0
     */
    currrent: number;
    /**
     * Test id
     */
    testId?: string;
}

/**
 * Available step states
 */
type StepState = 'completed'|'ongoing'|'todo'

/**
 * Unlockit rectangle stepper designed at {@link https://www.figma.com/file/nvWLkKo3SS0kIDZ58NWkFe/Web-App?type=design&node-id=7-3&mode=design&t=yQfA16BFKsG6OZkL-0}
 * @param props
 * @returns
 */
export const RectangleStepper: FunctionComponent<OwnProps> = (props) => {
    const {
        currrent,
        list,
        testId,
    } = props;

    function renderItemIcon(step: number) {
        if (step === currrent) return BulletOngoing;

        if (step < currrent) return Checkmark;

        return BulletTodo;
    }

    function addStepClass(step: number): StepState {
        if (step === currrent) return 'ongoing';

        if (step < currrent) return 'completed';

        return 'todo';
    }
    
    return (
        <ol id="stepper" className="rectangle-stepper" data-size={2} data-currentstep={currrent} data-testid={testId}>
            {list.map((step, i) => (
                <li key={step} id="step" className={`rectangle-stepper__item ${addStepClass(i)}`} data-step={i}>
                    <div className="rectangle-stepper__item__container">
                        <img className="rectangle-stepper__icon" height="16px" width="16px" src={renderItemIcon(i)} alt="stepper_icon" data-testid="rectangle-stepper-icon" />
                        <p className="rectangle-stepper__item__name" data-testid="rectangle-stepper-name">{step}</p>
                    </div>
                </li>
            ))}
        </ol>
    );
};

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { Result } from '../videoId';

export enum QesVideoDocumentType {
    TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS',
    PRIVACY_POLICY = 'PRIVACY_POLICY'
}

export interface QesVideoAuth {
    videoId: string;
    videoAuthorization: string;
}

export interface QesVideoWorkflow {
    workflowId: string;
    workflowAuthorization: string;
}

export type QesVideoRequirementsError = keyof Result;

/**
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import axios, { AxiosError, AxiosResponse } from 'axios';

import { useAuthContext } from './components/controllers/AuthenticationContext';
import { UnlockitHttpHeaders } from './constants/AppHeaders';
import { API_URL } from './settings';
import { httpErrorHandler } from './utils/error';
import { useAppSelector } from './utils/storeHooks';

export function useCornerstoneApi() {
    const {
        requestLogout,
        getAccessToken,
    } = useAuthContext();

    const organizationSelected = useAppSelector((state) => state.organization.organizationSelected);
    
    const CornerstoneApiInstance = axios.create({
        baseURL: API_URL,
        withCredentials: false,
    });
    
    CornerstoneApiInstance.interceptors.request.use(
        (config) => {
            if (organizationSelected && config.headers[UnlockitHttpHeaders.Organization] === undefined) {
                Object.assign(config.headers, {
                    [UnlockitHttpHeaders.Organization]: organizationSelected?.organization?.id?.toString() ?? null,
                });
            }

            const accessToken = getAccessToken();
            if (accessToken) {
                Object.assign(config.headers, {
                    Authorization: `Bearer ${accessToken}`,
                });
            }

            return config;
        }, (error: unknown) => {
            return Promise.reject(error);
        },
    );

    CornerstoneApiInstance.interceptors.response.use(
        (response: AxiosResponse) => {
            return response;
        },
        (error: AxiosError) => {
            if (error.response?.status === 401) {
                requestLogout();
            }
    
            return Promise.reject(httpErrorHandler(error));
        },
    );
    
    return CornerstoneApiInstance;
}

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FieldValue,
    FormValidator,
    FormValidatorError,
    FormValidatorErrorType,
} from '../validations';

import { ValidatorStrategy } from './Validator';

export class SizeExactValidator implements ValidatorStrategy {
    validate(fieldValue: FieldValue, validator: FormValidator): FormValidatorError | null {
        const {
            size,
        } = validator;
        // No error shown if user did not type anything yet
        if (fieldValue.toString().length === 0) return null;

        if (fieldValue && String(fieldValue).length !== size) {
            return {
                typeOfViolation: FormValidatorErrorType.SizeExact,
                size,
            };
        }
        return null;
    }
}

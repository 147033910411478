/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { ComponentType, FC, createContext } from 'react';
import {
    CmdRequestResponse,
    CmdResendOtpResponse,
    CmdSignatureContextFormActionResult,
    CmdValidateResponse,
    ValidateOtpPayload,
} from '../../../types/esignatures/cmd';
import { DataOrError, HttpDataOrError } from '../../../types/errors';

import { CmdSignatureController } from './CmdSignatureController';
import { EsignatureCommitment } from '../../../types/esignature';

/**
 * Context data and APIs for managing the signature experience with CMD
 */
export interface CmdSignatureContext {
    commitments: DataOrError<Array<EsignatureCommitment>>;
    contextFormData: CmdSignatureContextFormActionResult | unknown;
    cypherWithAMA: (data: string) => string|null;
    navigateToSigningPage: () => void;
    navigateToContextPage: () => void;
    navigateToCredentialsPage: () => void;
    navigateToOtpPage: (fileName: string, phone: string) => void;
    navigateToContractSignedPage: () => void;
    requestSignature(payload: {
        phone: string;
        pin: string;
        commitments: string[];
        location: string;
        reason: string;
    }): Promise<HttpDataOrError<CmdRequestResponse>>;
    loadCommitments(): Promise<HttpDataOrError<Array<EsignatureCommitment>>>;
    validateOTP(payload: ValidateOtpPayload): Promise<HttpDataOrError<CmdValidateResponse>>;
    resendOTP(payload: {clientId: string}): Promise<HttpDataOrError<CmdResendOtpResponse>>;
    submitContext(payload: CmdSignatureContextFormActionResult): void;
}

export const cmdSignatureContextDefaultValue: CmdSignatureContext = {
    navigateToSigningPage: () => { },
    navigateToContextPage: () => { },
    navigateToCredentialsPage: () => { },
    navigateToOtpPage: () => { },
    navigateToContractSignedPage: () => { },
    requestSignature: async () => [
        {
            id: 0,
            createdDate: '',
            lastModifiedDate: '',
            contractName: '',
        }, null,
    ],
    validateOTP: async () => [
        {
            id: 0,
            createdDate: '',
            lastModifiedDate: '',
            contractName: '',
        }, null,
    ],
    resendOTP: async () => [
        { clientId: '' }, null,
    ],
    commitments: [[], null],
    contextFormData: undefined,
    submitContext: () => { },
    loadCommitments: () => Promise.resolve([[], null]),
    cypherWithAMA: () => '',
};

const cmdSignatureContextInstance = createContext<CmdSignatureContext | null>(cmdSignatureContextDefaultValue);

export const CmdSignatureContextProvider = cmdSignatureContextInstance.Provider;
export const CmdSignatureContextConsumer = cmdSignatureContextInstance.Consumer;

export const withCmdSignatureContext = <P extends object>(Component: ComponentType<P>): FC<Omit<P, keyof CmdSignatureContext>> => (props) => (
    <CmdSignatureController>
        <CmdSignatureContextConsumer>
            {(ctx) => <Component {...props as P} {...ctx} />}
        </CmdSignatureContextConsumer>
    </CmdSignatureController>
);

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FieldValue,
    FormValidator,
    FormValidatorError,
    FormValidatorErrorType,
} from '../validations';

import { ValidatorStrategy } from './Validator';

export class RegexValidator implements ValidatorStrategy {
    validate(fieldValue: FieldValue, validator: FormValidator): FormValidatorError | null {
        const {
            regex,
        } = validator;
        // No error shown if user did not type anything yet
        if (typeof fieldValue === 'undefined') return null;
        if (fieldValue === null) return null;
        if (!fieldValue) return null;
        if (!fieldValue.toString()) return null;

        if (regex && !regex.test(String(fieldValue))) {
            return { typeOfViolation: FormValidatorErrorType.Pattern };
        }
        return null;
    }
}

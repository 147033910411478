/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

export enum CodeType {
    PHONE = 'SMS',
    EMAIL = 'EMAIL',
}

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { KeyedObject } from '../../types/general';
import { objectToParams } from '../../utils/misc';

/**
 * Start a QES workflow
 *
 * @returns
 */
export const startQesWorkflowUrl = (signerId: string) => {
    return `/signature/qes-video/${signerId}/start-workflow`;
};

/**
 * QES documentation URL
 *
 * @returns
 */
export const documentationUrl = (params?: KeyedObject<string>) => {
    const queryStr = objectToParams(params);
    return `/signature/qes-video/documentation${queryStr}`;
};

/**
 * Accept QES documentation URL
 *
 * @returns
 */
export const acceptDocumentationUrl = (signerId: string, params?: KeyedObject<string>) => {
    const queryStr = objectToParams(params);
    return `/signature/qes-video/${signerId}/accept-documentation${queryStr}`;
};

/**
 * Request QES video flow
 *
 * @returns
 */
export const qesVideoFlowUrl = (signerId: string) => {
    return `/signature/qes-video/${signerId}/request-video`;
};

export const qesVideoVerificationUrl = (signerId: string) => {
    return `/signature/qes-video/${signerId}/request-video-verification`;
};

export const qesCompleteSignature = (signerId: string, params?: KeyedObject<string>) => {
    const queryStr = objectToParams(params);
    return `/signature/qes-video/${signerId}/complete-signature${queryStr}`;
};

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FunctionComponent,
    Suspense,
    lazy,
    useEffect,
    useRef,
    useState,
} from 'react';
import { VariableSizeList } from 'react-window';
import { useParams } from 'react-router-dom';
import { SignerInvitationContext, withSignerInvitationContext } from '../../controllers/SignerInvitationContext';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';

import { AppRoute } from '../../../constants/routes';
import Button from '../../elements/Button';
import { ButtonVariant } from '../../../types/general';
import { LoadingScreen } from '../LoadingScreen';
import { SignerInvitationLayout } from '../../elements/contracts/layouts/SignerInvitationLayout';
import { WEBAPP_URL } from '../../../settings';
import { buildUrl } from '../../../utils/navigation';
import { useAuthContext } from '../../controllers/AuthenticationContext';
import TextBadge from '../../elements/TextBadge';
import { BadgeTextSize, TextBadgeColor } from '../../../types/textBadge';
import { Contract } from '../../../types/contracts';
import { DataOrError } from '../../../types/errors';

const PdfViewerWrapper = lazy(() => import('../../elements/pdfViewer/PdfViewerWrapper'));

interface OwnProps extends TranslationContext, SignerInvitationContext {}

/**
 * Renders the invitation screen for a signer invited to sign by email
 *
 * @returns
 */
const SignerInvitationBase: FunctionComponent<OwnProps> = (props) => {
    const {
        t,
        loadContractInformation,
    } = props;
    const { redirectToLogin, redirectToSignup } = useAuthContext();
    const { signerToken } = useParams();
    const [contractInfo, setContractInfo] = useState<DataOrError<Contract>>();
    const pdfPagesListRef = useRef<VariableSizeList>();

    useEffect(() => {
        if (signerToken) {
            loadContractInformation(signerToken).then((res) => {
                setContractInfo(res);
            });
        }
    }, [signerToken]);
    
    /**
     * Redirects the user to signup page
     */
    function handleSignup() {
        const callbackURI = new URL(buildUrl(AppRoute.SignerInvitationClaimCallback, {
            signerToken: signerToken ?? '',
        }), location.origin);
        redirectToSignup({
            redirectUri: callbackURI.toString(),
        });
    }

    /**
     * Redirects the user to login page
     */
    function handleLogin() {
        const callbackURI = new URL(buildUrl(AppRoute.SignerInvitationClaimCallback, {
            signerToken: signerToken ?? '',
        }), location.origin);
        redirectToLogin({
            redirectUri: callbackURI.toString(),
        });
    }

    return (
        <Suspense fallback={<LoadingScreen />}>
            <SignerInvitationLayout>
                <div className="signer-invitation-screen light-gradient-bg information-page">
                    <div className="signer-invitation-screen__contract-data">
                        {contractInfo?.[0]?.id && (
                            <TextBadge
                                text={String(contractInfo?.[0]?.id)}
                                color={TextBadgeColor.Purple}
                                fontSize={BadgeTextSize.Small}
                                mediumPadding
                            />
                        )}
                        <h1 className="signer-invitation-screen__contract-data__name">{contractInfo?.[0]?.name}</h1>
                        <span className="signer-invitation-screen__contract-data__type">{contractInfo?.[0]?.contractType?.name}</span>
                    </div>
                    <div className="signer-invitation-screen__pdf-viewer pdf-viewer-wrap canvas-blur" data-testid="signer-invitation-viewer">
                        <div className="signer-invitation-screen__blocking-overlay">
                            <h2>{t('signerInvitation.title')}</h2>
                            <p>{t('signerInvitation.description')}</p>
                            <Button variant={ButtonVariant.Curved} extraClasses="primary" name="invitation-signup" id="invitation-signup" onClick={() => handleSignup()}>{t('signerInvitation.signup')}</Button>
                            <Button variant={ButtonVariant.Curved} extraClasses="secondary" name="invitation-signup" id="invitation-signup" onClick={() => handleLogin()}>{t('signerInvitation.login')}</Button>

                        </div>
                        <div className="pdf-viewer-max-width-content">
                            <PdfViewerWrapper
                                fileUrl={`${WEBAPP_URL}/docs/unauthenticated-contract.pdf`}
                                pdfPagesListRef={pdfPagesListRef}
                                placeholderList={[]}
                                updatePlaceholderList={() => {}}
                            />
                        </div>
                    </div>
                </div>
            </SignerInvitationLayout>

        </Suspense>
    );
};

export const SignerInvitationScreen = withSignerInvitationContext(withTranslationContext(SignerInvitationBase));

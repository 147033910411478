/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    Outlet,
    Route,
    RouterProvider,
    createBrowserRouter,
    createRoutesFromElements,
} from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import { AddCreditsToPurchaseScreen } from './screens/billing/AddCreditsToPurchaseScreen';
import { AesSignatureCodeScreen } from './screens/esignatures/aes/AesSignatureCodeScreen';
import { AesSignatureContextScreen } from './screens/esignatures/aes/AesSignatureContextScreen';
import { AppRoute } from '../constants/routes';
import { BillingCompleteScreen } from './screens/billing/BillingCompleteScreen';
import { BillingInformationScreen } from './screens/billing/BillingInformationScreen';
import { ChangeOrganizationScreen } from './screens/organizations/ChangeOrganizationScreen';
import { CheckoutScreen } from './screens/billing/backup/CheckoutScreen';
import { CmdSignatureContextScreen } from './screens/esignatures/cmd/CmdSignatureContextScreen';
import { CmdSignatureCredentialsScreen } from './screens/esignatures/cmd/CmdSignatureCredentialsScreen';
import { CmdSignatureOtpScreen } from './screens/esignatures/cmd/CmdSignatureOtpScreen';
import { CodeType } from '../types/esignatures/aes';
import { ContractCheckoutScreen } from './screens/contracts/ContractCheckoutScreen';
import { ContractContextProvider } from './elements/contracts/ContractContextProvider';
import { ContractsConfigurationScreen } from './screens/contracts/ContractsConfigurationScreen';
import { ContractsScreen } from './screens/contracts/ContractsScreen';
import { CreateContractScreen } from './screens/contracts/CreateContractScreen';
import { CreateOrganizationScreen } from './screens/billing/CreateOrganizationScreen';
import { CreateWorkflowContractScreen } from './screens/contracts/CreateWorkflowContractScreen';
import { CreateWorkflowInfoScreen } from './screens/workflows/CreateWorkflowInfoScreen';
import { CreateWorkflowSelectTypeScreen } from './screens/workflows/CreateWorkflowSelectTypeScreen';
import { DashboardScreen } from './screens/billing/DashboardScreen';
import { DefaultLayout } from './elements/layouts/DefaultLayout';
import { DraftContractScreen } from './screens/contracts/DraftContractScreen';
import { EditWorkflowSellInfoScreen } from './screens/workflows/EditWorkflowSellInfoScreen';
import { ErrorScreen } from './screens/ErrorScreen';
import { OrganizationMemberInvitationScreen } from './screens/OrganizationMemberInvitationScreen';
import { MembersScreen } from './screens/organizations/MembersScreen';
import { NewPaymentMethodScreen } from './screens/billing/NewPaymentMethodScreen';
import { NotFoundPage } from './screens/NotFoundPage';
import { OrganizationContractsScreen } from './screens/contracts/OrganizationContractsScreen';
import { OrganizationMenubar } from './elements/OrganizationMenubar';
import { OrganizationWorkflowsScreen } from './screens/workflows/OrganizationWorkflowsScreen';
import { ParticipantAssignPinValidationScreen } from './screens/workflows/ParticipantAssignPinValidationScreen';
import { ParticipantAssignScreen } from './screens/workflows/ParticipantAssignScreen';
import { PaymentMethodDetailsScreen } from './screens/billing/PaymentMethodDetails';
import { PaymentMethodsScreen } from './screens/billing/PaymentMethodsScreen';
import { PaymentStatusScreen } from './screens/billing/backup/PaymentStatus';
import { PlansScreen } from './screens/billing/PlansScreen';
import { PrivacyPolicyScreen } from './screens/PrivacyPolicyScreen';
import { PurchaseCreditsBillingInformationScreen } from './screens/billing/PurchaseCreditsBillingInformationScreen';
import { PurchaseCreditsCheckout } from './screens/billing/PurchaseCreditsCheckout';
import { PurchaseCreditsScreen } from './screens/billing/PurchaseCreditsScreen';
import { QesSignatureOtpScreen } from './screens/esignatures/qes/QesSignatureOtpScreen';
import { QesSignaturePrivacyPolicyScreen } from './screens/esignatures/qes/QesSignaturePrivacyPolicyScreen';
import { QesSignatureStartVideoValidationScreen } from './screens/esignatures/qes/QesSignatureStartVideoValidationScreen';
import { QesSignatureTermsAndConditionsScreen } from './screens/esignatures/qes/QesSignatureTermsAndConditionsScreen';
import { QesSignatureVideoValidationCheckingFailureScreen } from './screens/esignatures/qes/QesSignatureVideoValidationCheckingFailureScreen';
import { QesSignatureVideoValidationCheckingScreen } from './screens/esignatures/qes/QesSignatureVideoValidationCheckingScreen';
import { QesSignatureVideoValidationFailedScreen } from './screens/esignatures/qes/QesSignatureVideoValidationFailedScreen';
import { RequireAuth } from './containers/RequireAuth';
import { RequireOrganization } from './containers/RequireOrganization';
import { ReviewPlanScreen } from './screens/billing/ReviewPlanScreen';
import { RolesScreen } from './screens/organizations/RolesScreen';
import { SelectOrganizationScreen } from './screens/billing/SelectOrganizationScreen';
import { ShowContractScreen } from './screens/contracts/ShowContractScreen';
import { ShowRoleScreen } from './screens/organizations/ShowRoleScreen';
import { SignatureAvailabilityScreen } from './screens/SignatureAvailabilityScreen';
import { SignerInvitationClaimCallbackScreen } from './screens/contracts/SignerInvitationClaimCallbackScreen';
import { SignerInvitationScreen } from './screens/contracts/SignerInvitationScreen';
import { TermsAndConditionsScreen } from './screens/TermsAndConditionsScreen';
import { ViewWorkflowDocumentScreen } from './screens/workflows/ViewWorkflowDocumentScreen';
import { ViewWorkflowScreen } from './screens/workflows/ViewWorkflowScreen';
import { WebsocketContextProvider } from './elements/websocket/WebsocketContextProvider';
import { WorkflowContractsScreen } from './screens/workflows/WorkflowContractsScreen';
import { WorkflowDocumentsScreen } from './screens/workflows/WorkflowDocumentsScreen';
import { WorkflowParticipantsScreen } from './screens/workflows/WorkflowParticipantsScreen';
import { WorkflowParticipantsStepScreen } from './screens/workflows/WorkflowParticipantsStepScreen';
import { WorkflowPropertyScreen } from './screens/workflows/WorkflowPropertyScreen';
import { WorkflowsScreen } from './screens/workflows/WorkflowsScreen';
import { AccountMenubar } from './elements/AccountMenubar';
import { WalletScreen } from './screens/account/WalletScreen';
import { NewSupportTicketScreen } from './screens/help/NewSupportTicketScreen';
import { HomeScreen } from './screens/HomeScreen';
import { SupportTicketSubmittedScreen } from './screens/help/TicketSubmittedScreen';
import { OrganizationPurchaseCreditsScreen } from './screens/billing/OrganizationPurchaseCreditsScreen';
import { PurchaseCreditsCheckoutCompleteScreen } from './screens/billing/PurchaseCreditsCheckoutCompleteScreen';
import { OptionalAuth } from './containers/OptionalAuth';

export const mainRoutes = (
    <Route
        path="/"
        element={(
            <div className="app-router">
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />
                <Outlet />
            </div>
        )}
        ErrorBoundary={ErrorScreen}
    >
        <Route
            index
            element={(
                <RequireAuth>
                    <RequireOrganization>
                        <DefaultLayout>
                            <HomeScreen />
                        </DefaultLayout>
                    </RequireOrganization>
                </RequireAuth>
            )}
        />
        {/* ===================== INVITATIONS LANDING PAGES ===================== */}
        <Route
            path={AppRoute.OrganizationMemberInvitation}
            element={(
                <OptionalAuth>
                    <OrganizationMemberInvitationScreen />
                </OptionalAuth>
            )}
        />
        <Route
            path={AppRoute.SignerInvitation}
            element={(
                <SignerInvitationScreen />
            )}
        />
        <Route
            path={AppRoute.SignerInvitationClaimCallback}
            element={(
                <RequireAuth>
                    <SignerInvitationClaimCallbackScreen />
                </RequireAuth>
            )}
        />
        {/* ===================== GENERAL LINKS ===================== */}
        <Route
            path={AppRoute.TermsAndConditions}
            element={(
                <DefaultLayout>
                    <RequireAuth>
                        <TermsAndConditionsScreen />
                    </RequireAuth>
                </DefaultLayout>
            )}
        />
        <Route
            path={AppRoute.PrivacyPolicy}
            element={(
                <DefaultLayout>
                    <RequireAuth>
                        <PrivacyPolicyScreen />
                    </RequireAuth>
                </DefaultLayout>
            )}
        />
        <Route
            path={AppRoute.NewSupportTicket}
            element={(
                <RequireAuth>
                    <DefaultLayout>
                        <NewSupportTicketScreen />
                    </DefaultLayout>
                </RequireAuth>
            )}
        />
        <Route
            path={AppRoute.SupportTicketSubmitted}
            element={(
                <RequireAuth>
                    <DefaultLayout>
                        <SupportTicketSubmittedScreen />
                    </DefaultLayout>
                </RequireAuth>
            )}
        />
        {/* ===================== ORGANIZATIONS MANAGEMENT ===================== */}
        <Route
            path={AppRoute.OrganizationBillingDashboard}
            element={(
                <RequireAuth>
                    <RequireOrganization>
                        <DefaultLayout customMenu={<OrganizationMenubar />}>
                            <Outlet />
                        </DefaultLayout>
                    </RequireOrganization>
                </RequireAuth>
            )}
        >
            <Route index element={<DashboardScreen />} />
            <Route
                path={AppRoute.BillingPlans}
                element={(
                    <RequireAuth>
                        <RequireOrganization>
                            <PlansScreen />
                        </RequireOrganization>
                    </RequireAuth>
                )}
            />
            <Route
                path={AppRoute.OrganizationRoles}
                element={<RolesScreen />}
            />
            <Route
                path={AppRoute.OrganizationRole}
                element={<ShowRoleScreen />}
            />
            <Route
                path={AppRoute.OrganizationMembers}
                element={<MembersScreen />}
            />
            <Route
                path={AppRoute.OrganizationChange}
                element={<ChangeOrganizationScreen />}
            />
            <Route
                path={AppRoute.ContractsConfiguration}
                element={(
                    <RequireAuth>
                        <RequireOrganization>
                            <ContractsConfigurationScreen />
                        </RequireOrganization>
                    </RequireAuth>
                )}
            />
        </Route>
        {/* ===================== ACCOUNT MANAGEMENT ===================== */}
        <Route
            path={AppRoute.AccountWallet}
            element={(
                <RequireAuth>
                    <RequireOrganization>
                        <DefaultLayout customMenu={<AccountMenubar />}>
                            <Outlet />
                        </DefaultLayout>
                    </RequireOrganization>
                </RequireAuth>
            )}
        >
            <Route index element={<WalletScreen />} />
            <Route
                path={AppRoute.AccountWallet}
                element={(
                    <RequireAuth>
                        <RequireOrganization>
                            <WalletScreen />
                        </RequireOrganization>
                    </RequireAuth>
                )}
            />
        </Route>
        {/* ===================== CONTRACTS ===================== */}
        <Route
            path={AppRoute.Contracts}
            element={(
                <RequireAuth>
                    <RequireOrganization>
                        <ContractsScreen />
                    </RequireOrganization>
                </RequireAuth>
            )}
        />
        <Route
            path={AppRoute.OrganizationContracts}
            element={(
                <RequireAuth>
                    <RequireOrganization>
                        <OrganizationContractsScreen />
                    </RequireOrganization>
                </RequireAuth>
            )}
        />
        <Route
            path=""
            element={(
                <WebsocketContextProvider>
                    <Outlet />
                </WebsocketContextProvider>
            )}
        >
            <Route
                path={AppRoute.CreateContract}
                element={(
                    <RequireAuth>
                        <RequireOrganization>
                            <ContractContextProvider>
                                <CreateContractScreen />
                            </ContractContextProvider>
                        </RequireOrganization>
                    </RequireAuth>
                )}
            />
            <Route
                path={AppRoute.CreateWorkflowContract}
                element={(
                    <RequireAuth>
                        <RequireOrganization>
                            <ContractContextProvider>
                                <CreateWorkflowContractScreen />
                            </ContractContextProvider>
                        </RequireOrganization>
                    </RequireAuth>
                )}
            />
            <Route
                path={AppRoute.ContractCheckout}
                element={(
                    <RequireAuth>
                        <RequireOrganization>
                            <ContractContextProvider>
                                <DefaultLayout>
                                    <ContractCheckoutScreen />
                                </DefaultLayout>
                            </ContractContextProvider>
                        </RequireOrganization>
                    </RequireAuth>
                )}
            />
            <Route
                path={AppRoute.DraftContract}
                element={(
                    <RequireAuth>
                        <RequireOrganization>
                            <ContractContextProvider>
                                <DraftContractScreen />
                            </ContractContextProvider>
                        </RequireOrganization>
                    </RequireAuth>
                )}
            />
            <Route
                path={AppRoute.ShowContract}
                element={(
                    <RequireAuth>
                        <RequireOrganization>
                            <ContractContextProvider>
                                <ShowContractScreen />
                            </ContractContextProvider>
                        </RequireOrganization>
                    </RequireAuth>
                )}
            />
            {/* ============================ AES SIGNATURE ============================ */}
            <Route
                path={AppRoute.AesSignatureContext}
                element={(
                    <RequireAuth>
                        <RequireOrganization>
                            <AesSignatureContextScreen />
                        </RequireOrganization>
                    </RequireAuth>
                )}
            />
            <Route
                path={AppRoute.AesSignaturePhone}
                element={(
                    <RequireAuth>
                        <RequireOrganization>
                            <AesSignatureCodeScreen codeType={CodeType.PHONE} />
                        </RequireOrganization>
                    </RequireAuth>
                )}
            />
            <Route
                path={AppRoute.AesSignatureEmail}
                element={(
                    <RequireAuth>
                        <RequireOrganization>
                            <AesSignatureCodeScreen codeType={CodeType.EMAIL} />
                        </RequireOrganization>
                    </RequireAuth>
                )}
            />
            {/* ============================ CMD SIGNATURE ============================ */}
            <Route
                path={AppRoute.CmdSignatureContext}
                element={(
                    <RequireAuth>
                        <RequireOrganization>
                            <CmdSignatureContextScreen />
                        </RequireOrganization>
                    </RequireAuth>
                )}
            />
            <Route
                path={AppRoute.CmdSignatureCredentials}
                element={(
                    <RequireAuth>
                        <RequireOrganization>
                            <CmdSignatureCredentialsScreen />
                        </RequireOrganization>
                    </RequireAuth>
                )}
            />
            <Route
                path={AppRoute.CmdSignatureOtp}
                element={(
                    <RequireAuth>
                        <RequireOrganization>
                            <CmdSignatureOtpScreen otpValidationMinutes={5} />
                        </RequireOrganization>
                    </RequireAuth>
                )}
            />
            {/* ============================ QES VIDEO SIGNATURE ============================ */}
            <Route
                path={AppRoute.QesSignatureTermsAndCondition}
                element={(
                    <RequireAuth>
                        <RequireOrganization>
                            <QesSignatureTermsAndConditionsScreen />
                        </RequireOrganization>
                    </RequireAuth>
                )}
            />
            <Route
                path={AppRoute.QesSignaturePrivacyPolicy}
                element={(
                    <RequireAuth>
                        <RequireOrganization>
                            <QesSignaturePrivacyPolicyScreen />
                        </RequireOrganization>
                    </RequireAuth>
                )}
            />
            <Route
                path={AppRoute.QesSignatureStartVideoValidation}
                element={(
                    <RequireAuth>
                        <RequireOrganization>
                            <QesSignatureStartVideoValidationScreen />
                        </RequireOrganization>
                    </RequireAuth>
                )}
            />
            <Route
                path={AppRoute.QesSignatureVideoValidationFailed}
                element={(
                    <RequireAuth>
                        <RequireOrganization>
                            <QesSignatureVideoValidationFailedScreen />
                        </RequireOrganization>
                    </RequireAuth>
                )}
            />
            <Route
                path={AppRoute.QesSignatureVideoValidationChecking}
                element={(
                    <RequireAuth>
                        <RequireOrganization>
                            <QesSignatureVideoValidationCheckingScreen />
                        </RequireOrganization>
                    </RequireAuth>
                )}
            />
            <Route
                path={AppRoute.QesSignatureVideoValidationCheckingFailed}
                element={(
                    <RequireAuth>
                        <RequireOrganization>
                            <QesSignatureVideoValidationCheckingFailureScreen />
                        </RequireOrganization>
                    </RequireAuth>
                )}
            />
            <Route
                path={AppRoute.QesSignatureOtp}
                element={(
                    <RequireAuth>
                        <RequireOrganization>
                            <QesSignatureOtpScreen otpValidationMinutes={5} />
                        </RequireOrganization>
                    </RequireAuth>
                )}
            />
            <Route
                path={AppRoute.SignatureAvailability}
                element={(
                    <RequireAuth>
                        <RequireOrganization>
                            <SignatureAvailabilityScreen />
                        </RequireOrganization>
                    </RequireAuth>
                )}
            />
            {/* ===================== WORKFLOWS ===================== */}
            <Route
                path={AppRoute.Workflows}
                element={(
                    <RequireAuth>
                        <RequireOrganization>
                            <WorkflowsScreen />
                        </RequireOrganization>
                    </RequireAuth>
                )}
            />
            <Route
                path={AppRoute.WorkflowParticipantAssign}
                element={(
                    <RequireAuth>
                        <RequireOrganization>
                            <ParticipantAssignScreen />
                        </RequireOrganization>
                    </RequireAuth>
                )}
            />
            <Route
                path={AppRoute.WorkflowParticipantAssignPinValidation}
                element={(
                    <RequireAuth>
                        <RequireOrganization>
                            <ParticipantAssignPinValidationScreen />
                        </RequireOrganization>
                    </RequireAuth>
                )}
            />
            <Route
                path={AppRoute.CreateWorkflowSelectType}
                element={(
                    <RequireAuth>
                        <RequireOrganization>
                            <CreateWorkflowSelectTypeScreen />
                        </RequireOrganization>
                    </RequireAuth>
                )}
            />
            <Route
                path={AppRoute.CreateWorkflowInfo}
                element={(
                    <RequireAuth>
                        <RequireOrganization>
                            <CreateWorkflowInfoScreen />
                        </RequireOrganization>
                    </RequireAuth>
                )}
            />
            <Route
                path={AppRoute.WorkflowProperty}
                element={(
                    <RequireAuth>
                        <RequireOrganization>
                            <WorkflowPropertyScreen />
                        </RequireOrganization>
                    </RequireAuth>
                )}
            />
            <Route
                path={AppRoute.WorkflowParticipantsStep}
                element={(
                    <RequireAuth>
                        <RequireOrganization>
                            <WorkflowParticipantsStepScreen />
                        </RequireOrganization>
                    </RequireAuth>
                )}
            />
            <Route
                path={AppRoute.ViewWorkflow}
                element={(
                    <RequireAuth>
                        <RequireOrganization>
                            <ViewWorkflowScreen />
                        </RequireOrganization>
                    </RequireAuth>
                )}
            />
            <Route
                path={AppRoute.WorkflowContracts}
                element={(
                    <RequireAuth>
                        <RequireOrganization>
                            <WorkflowContractsScreen />
                        </RequireOrganization>
                    </RequireAuth>
                )}
            />
            <Route
                path={AppRoute.WorkflowParticipants}
                element={(
                    <RequireAuth>
                        <RequireOrganization>
                            <WorkflowParticipantsScreen />
                        </RequireOrganization>
                    </RequireAuth>
                )}
            />
            <Route
                path={AppRoute.WorkflowInfo}
                element={(
                    <RequireAuth>
                        <RequireOrganization>
                            <EditWorkflowSellInfoScreen />
                        </RequireOrganization>
                    </RequireAuth>
                )}
            />
            <Route
                path={AppRoute.WorkflowDocuments}
                element={(
                    <RequireAuth>
                        <RequireOrganization>
                            <WorkflowDocumentsScreen />
                        </RequireOrganization>
                    </RequireAuth>
                )}
            />
            <Route
                path={AppRoute.ViewWorkflowDocument}
                element={(
                    <RequireAuth>
                        <RequireOrganization>
                            <ViewWorkflowDocumentScreen />
                        </RequireOrganization>
                    </RequireAuth>
                )}
            />
            <Route
                path={AppRoute.OrganizationWorkflows}
                element={(
                    <RequireAuth>
                        <RequireOrganization>
                            <OrganizationWorkflowsScreen />
                        </RequireOrganization>
                    </RequireAuth>
                )}
            />

            {/* ===================== BILLING ===================== */}
            <Route
                path={AppRoute.OrganizationBillingDashboard}
                element={(
                    <RequireAuth>
                        <RequireOrganization>
                            <DashboardScreen />
                        </RequireOrganization>
                    </RequireAuth>
                )}
            />
            <Route
                path={AppRoute.PaymentMethod}
                element={(
                    <RequireAuth>
                        <RequireOrganization>
                            <PaymentMethodDetailsScreen />
                        </RequireOrganization>
                    </RequireAuth>
                )}
            />
            <Route
                path={AppRoute.PaymentMethods}
                element={(
                    <RequireAuth>
                        <RequireOrganization>
                            <PaymentMethodsScreen />
                        </RequireOrganization>
                    </RequireAuth>
                )}
            />
            <Route
                path={AppRoute.PurchaseCredits}
                element={(
                    <RequireAuth>
                        <PurchaseCreditsScreen />
                    </RequireAuth>
                )}
            />
            <Route
                path={AppRoute.OrganizationPurchaseCredits}
                element={(
                    <RequireAuth>
                        <OrganizationPurchaseCreditsScreen />
                    </RequireAuth>
                )}
            />
            <Route
                path={AppRoute.PurchaseCreditsBillingInformation}
                element={(
                    <RequireAuth>
                        <PurchaseCreditsBillingInformationScreen />
                    </RequireAuth>
                )}
            />
            <Route
                path={AppRoute.PurchaseCreditsCheckout}
                element={(
                    <RequireAuth>
                        <PurchaseCreditsCheckout />
                    </RequireAuth>
                )}
            />
            <Route
                path={AppRoute.PurchaseCreditsCheckoutComplete}
                element={(
                    <RequireAuth>
                        <RequireOrganization>
                            <PurchaseCreditsCheckoutCompleteScreen />
                        </RequireOrganization>
                    </RequireAuth>
                )}
            />
            <Route
                path={AppRoute.NewCreditCard}
                element={(
                    <RequireAuth>
                        <RequireOrganization>
                            <NewPaymentMethodScreen />
                        </RequireOrganization>
                    </RequireAuth>
                )}
            />
            <Route
                path={AppRoute.NewCreditCardCallback}
                element={(
                    <RequireAuth>
                        <RequireOrganization>
                            <PaymentStatusScreen />
                        </RequireOrganization>
                    </RequireAuth>
                )}
            />
            <Route
                path={AppRoute.PaymentCheckout}
                element={(
                    <RequireAuth>
                        <RequireOrganization>
                            <CheckoutScreen />
                        </RequireOrganization>
                    </RequireAuth>
                )}
            />
            <Route
                path={AppRoute.PaymentCheckoutComplete}
                element={(
                    <RequireAuth>
                        <RequireOrganization>
                            <BillingCompleteScreen />
                        </RequireOrganization>
                    </RequireAuth>
                )}
            />

            <Route
                path={AppRoute.BillingSelectOrganization}
                element={(
                    <RequireAuth>
                        <RequireOrganization>
                            <SelectOrganizationScreen />
                        </RequireOrganization>
                    </RequireAuth>
                )}
            />
            <Route
                path={AppRoute.BillingCreateOrganization}
                element={(
                    <RequireAuth>
                        <RequireOrganization>
                            <CreateOrganizationScreen />
                        </RequireOrganization>
                    </RequireAuth>
                )}
            />
            <Route
                path={AppRoute.BillingReviewPlan}
                element={(
                    <RequireAuth>
                        <RequireOrganization>
                            <ReviewPlanScreen />
                        </RequireOrganization>
                    </RequireAuth>
                )}
            />
            <Route
                path={AppRoute.BillingAddCredits}
                element={(
                    <RequireAuth>
                        <RequireOrganization>
                            <AddCreditsToPurchaseScreen />
                        </RequireOrganization>
                    </RequireAuth>
                )}
            />
            <Route
                path={AppRoute.BillingInformation}
                element={(
                    <RequireAuth>
                        <RequireOrganization>
                            <BillingInformationScreen />
                        </RequireOrganization>
                    </RequireAuth>
                )}
            />
        </Route>

        {/* ===================== FALLBACKS ===================== */}
        <Route
            path="*"
            element={<NotFoundPage />}
        />
    </Route>
);

export const mainBrowserRouter = createBrowserRouter(
    createRoutesFromElements(
        mainRoutes,
    ),
);

export const MainRouterProvider = () => {
    return (<RouterProvider router={mainBrowserRouter} />);
};

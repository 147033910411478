/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    ComponentType,
    FC,
    createContext,
    useContext,
} from 'react';
import { Contract, Signer, SignerEmailPinPayload } from '../../types/contracts';
import { DataOrError, FunctionalError } from '../../types/errors';

import { SignerInvitationController } from './SignerInvitationController';

export interface SignerInvitationContext {
    loadContractInformation: (signerToken: string) => Promise<DataOrError<Contract>>;
    tryAssigningUserToSigner: (signerToken: string) => Promise<DataOrError<Signer>>;
    validatePIN: (signerToken: string, payload: SignerEmailPinPayload) => Promise<DataOrError<Contract>>;
}

export const signerInvitationContextDefaultValue: SignerInvitationContext = {
    loadContractInformation: () => Promise.resolve([null, new FunctionalError('Not loaded', '0')]),
    tryAssigningUserToSigner: () => Promise.resolve([null, new FunctionalError('Not loaded', '0')]),
    validatePIN: () => Promise.resolve([null, new FunctionalError('Not loaded', '0')]),
};

const signerInvitationContextInstance = createContext<SignerInvitationContext>(signerInvitationContextDefaultValue);

export const useContextSignerInvitation = (): SignerInvitationContext => {
    return useContext(signerInvitationContextInstance);
};

export const SignerInvitationContextProvider = signerInvitationContextInstance.Provider;
export const SignerInvitationContextConsumer = signerInvitationContextInstance.Consumer;

export const withSignerInvitationContext = <P extends object>(Component: ComponentType<P>): FC<Omit<P, keyof SignerInvitationContext>> => (props) => (
    <SignerInvitationController>
        <SignerInvitationContextConsumer>
            {(ctx) => <Component {...props as P} {...ctx} />}
        </SignerInvitationContextConsumer>
    </SignerInvitationController>
);

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FunctionComponent, useEffect, useMemo, useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import { Contract } from '../../../types/contracts';
import { LoadingCircles } from '../LoadingCircles';
import ContractCard from '../contracts/ContractCard';
import Button from '../Button';
import { ButtonVariant } from '../../../types/general';
import { ReactComponent as PlusIcon } from '../../../assets/images/plus-icon.svg';
import { WorkflowContext, withWorkflowContext } from '../../controllers/WorkflowContext';
import { AppRoute } from '../../../constants/routes';
import { buildUrl } from '../../../utils/navigation';
import { useUserHasPermission } from '../../../hooks/useUserHasPermission';
import { ParticipantPermissionsList, ResourcePermissionsType } from '../../../types/workflows';
import { Permissions } from '../../../types/permissions';
import { Tooltip } from '../Tooltip';

type OwnProps = TranslationContext & WorkflowContext;

const ContractListBase: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t,
        getWorkflowContracts,
        getWorkflowParticipantPermissions,
    } = props;
    const { workflowId = '' } = useParams();
    const navigate = useNavigate();

    const [contracts, setContracts] = useState<Contract[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [contractsCount, setContractsCount] = useState(0);
    const [participantPermissions, setParticipantPermissions] = useState<ParticipantPermissionsList | null>(null);

    const canManage = useUserHasPermission([Permissions.MANAGE_ORGANIZATION_TRANSACTIONS, Permissions.MANAGE_ALL_ORGANIZATION_TRANSACTIONS]);

    const participantCanManage = useMemo(() => {
        if (canManage) return true;

        if (participantPermissions?.transactionPermissions.some((e) => e === ResourcePermissionsType.MANAGE)) {
            return true;
        }

        return false;
    }, [canManage, participantPermissions]);

    useEffect(() => {
        fetchContractsList();
    }, []);

    /**
     * Fetch Contracts
     *
     * @remarks
     * Fetch contracts to render on contracts list
     * This request response does not come paginated.
     * It return all the contracts for the workflow.
     * For this component we will slice the first 5 elements to render
     */
    const fetchContractsList = async () => {
        const [contractsData] = await getWorkflowContracts(workflowId);
        if (contractsData) {
            setContractsCount(contractsData.length);
            setContracts(contractsData.slice(0, 5));
        }
        getWorkflowParticipantPermissions(workflowId).then((permissionsResponse) => {
            const [permissions] = permissionsResponse;

            if (!permissions) {
                return;
            }

            setParticipantPermissions(permissions);
        });

        setIsLoading(false);
    };

    return (
        <div className="workflow-screen--view__section-elements__contracts">
            <div className="workflow-screen--view__section-elements__contracts__header">
                <div className="workflow-screen--view__section-elements__contracts__header__title">
                    {t('workflows.view.contracts')}
                    <span className="count" data-testid="contracts-count">
                        ({contractsCount})
                    </span>
                </div>
                {participantCanManage && (
                    <Button
                        variant={ButtonVariant.Circle}
                        extraClasses="primary"
                        testId="add-contract-btn"
                        onClick={() => navigate(buildUrl(AppRoute.CreateWorkflowContract, { workflowId }))}
                    >
                        <Tooltip title={t('workflows.view.createContractTooltip')} placement="bottom">
                            <PlusIcon />
                        </Tooltip>
                    </Button>
                )}
            </div>
            {isLoading && <LoadingCircles size="s" variant="primary" />}
            {!isLoading && contracts.length === 0 && (<p>{t('workflows.view.noContractsFound')}</p>)}
            {!isLoading && contracts.length > 0 && (
                <>
                    <div className="document-card-list" data-testid="contracts-list">
                        {contracts.map((contract) => (
                            <ContractCard key={contract.id} contract={contract} />
                        ))}
                    </div>
                    <Button
                        variant={ButtonVariant.RectangularStroked}
                        extraClasses="full-width"
                        onClick={() => navigate(buildUrl(AppRoute.WorkflowContracts, { workflowId }))}
                    >
                        {t('workflows.view.viewAll')}
                    </Button>
                </>
            )}
        </div>
    );
};

export const ContractList = withTranslationContext(withWorkflowContext(ContractListBase));

/*
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

export enum AppLanguage {
    EN = 'en',
    PT = 'pt'
}

export const languageNames: { [key in AppLanguage]: string } = {
    [AppLanguage.EN]: 'English',
    [AppLanguage.PT]: 'Português',
};

export const languageTypes = {
    [AppLanguage.EN]: 'ENGLISH',
    [AppLanguage.PT]: 'PORTUGUESE',
};

/**
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

export const VERSION = process.env.REACT_APP_COMMIT;
export const WEBAPP_URL = process.env.REACT_APP_WEBAPP_URL;
export const API_URL = process.env.REACT_APP_API_URL;
export const WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL;
export const KEYCLOAK_URL = process.env.REACT_APP_KEYCLOAK_URL;
export const KEYCLOAK_REALM = process.env.REACT_APP_KEYCLOAK_REALM;
export const KEYCLOAK_CLIENT_ID = process.env.REACT_APP_KEYCLOAK_CLIENT_ID;
export const PUBLIC_EID_ENDPOINT = process.env.REACT_APP_PUBLIC_EID_ENDPOINT;

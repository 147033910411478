/*
*
* @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
* Development by VOID Software, SA
*
*/

/* eslint-disable @typescript-eslint/no-explicit-any */

export default (props: any) => {
    const {
        name, value, onChange, children, disabled,
    } = props;

    return (
        <select data-testid={`select-${name}`} value={value} name={name} onChange={onChange} disabled={disabled}>
            {children.map((c: any) => <option value={c.key} key={c.key}>{c.props.children}</option>)}
        </select>
    );
};

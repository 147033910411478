/*
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

/**
 * Returns the country code from phone string.
 * It supposes that country follows the format `<country_code><space><phone_number>`.
 *
 * @param phone {string}
 * @return the country code or empty string if failed to parse
 */
export function parseCountryCodeFromPhone(phone: string): string {
    return phone.split(' ')[0] ?? '';
}

/**
 * Returns the phone number from phone string.
 * It supposes that country follows the format `<country_code><space><phone_number>`.
 *
 * @param phone {string}
 * @return the phone number or empty string if failed to parse
 */
export function parsePhoneNumberFromPhone(phone: string): string {
    return phone.split(' ')[1] ?? '';
}

/**
 * Formats a phone from country code and phone number as `<country_code><space><phone_number>`
 *
 * @param countryCode {string} The country code. Ex: +351 (Portugal)
 * @param phoneNumber {string} The phone number. Ex: 929999999
 * @returns the phone formatted as `<country_code><space><phone_number>`
 */
export function formatPhone(countryCode: string, phoneNumber: string): string {
    return `${countryCode} ${phoneNumber}`;
}

/**
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import React, { ComponentType, FC, createContext } from 'react';

import { AppLanguage } from '../../types/preferences';
import { DefaultLanguage } from '../../services/language';
import TranslationController from './TranslationController';

export interface TranslationContext {
    language: AppLanguage;
    languages: AppLanguage[];
    t(literal: string, params?: object): string;
    tWithJSX(key: string, params?: object): React.ReactNode;
    changeLanguageSelected(language: AppLanguage): void;
}

export const translationContextDefaultValue = {
    language: DefaultLanguage,
    languages: [AppLanguage.EN, AppLanguage.PT],
    t: (): string => '',
    tWithJSX: (): React.ReactNode => null,
    changeLanguageSelected: () => {},
};

const translationContextInstance = createContext<TranslationContext | null>(translationContextDefaultValue);

export const TranslationContextProvider = translationContextInstance.Provider;
export const TranslationContextConsumer = translationContextInstance.Consumer;

export const withTranslationContext = <P extends object>(Component: ComponentType<P>): FC<Omit<P, keyof TranslationContext>> => (props) => (
    <TranslationController>
        <TranslationContextConsumer>
            {(ctx) => <Component {...props as P} {...ctx} />}
        </TranslationContextConsumer>
    </TranslationController>
);

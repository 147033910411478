/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent } from 'react';
import { TranslationContext, withTranslationContext } from '../../../controllers/TranslationContext';
import ElectronicIDLogo from '../../../../assets/images/logo-electronic-id.png';
import ElectronicIDIcon from '../../../../assets/images/electronic-logo-short.png';
import { EsignaturesLayout } from '../../../elements/esignatures/layouts/EsignaturesLayout';
import { EsignatureErrorModal } from '../../../elements/esignatures/common/EsignatureErrorModal';
import Button from '../../../elements/Button';
import { ButtonVariant } from '../../../../types/general';
import { QesSignatureContext, withQesSignatureContext } from '../../../controllers/esignatures/QesSignatureContext';

type OwnProps = TranslationContext & QesSignatureContext;

const QesSignatureVideoValidationCheckingFailureScreenComponent: FunctionComponent<OwnProps> = (props) => {
    const { t, navigateToContract, navigateToStartVideoValidation } = props;

    const retryButton = (
        <Button
            id="actionNext"
            variant={ButtonVariant.Curved}
            extraClasses="primary"
            testId="actionNext"
            onClick={navigateToStartVideoValidation}
        >
            {t('qesSignatureVideoValidationCheckingFailureScreen.retryAction')}
        </Button>
    );

    const goBackSpan = (
        <span onClick={navigateToContract}>
            {t('qesSignatureVideoValidationCheckingFailureScreen.goBackAction')}
        </span>
    );
    
    return (
        <div className="qes-signature-documents">
            <EsignaturesLayout>
                <div className="qes-signature-layout">
                    <div className="qes-signature-banner">
                        <img src={ElectronicIDLogo} alt="Electronic IDentification logo" height={25} width="auto" />
                    </div>
                    <EsignatureErrorModal
                        open
                        providerIconSrc={ElectronicIDIcon}
                        errorTitle={t('qesSignatureVideoValidationCheckingFailureScreen.title')}
                        errorDescription={[]}
                        extraClasses="qes-signature-modal"
                        retryButton={retryButton}
                        tryAgainLaterBackButton={goBackSpan}
                    />
                </div>
            </EsignaturesLayout>
        </div>
    );
};

export const QesSignatureVideoValidationCheckingFailureScreen = withQesSignatureContext(withTranslationContext(QesSignatureVideoValidationCheckingFailureScreenComponent));

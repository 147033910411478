/**
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import React, {
    FunctionComponent,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { PDFPageProxy } from 'pdfjs-dist';
import { VariableSizeList } from 'react-window';
import { ElementPosition, isOfType } from '../../../types/general';
import { PendingPlaceholder, Placeholder } from '../../../types/contracts';

import PdfNavigationBar from './PdfNavigationBar';
import PdfPage from './PdfPage';
import { PdfPageCanvasDimensions } from '../../../types/pdf';

interface OwnProps {
    totalPages: number;
    placeholderList?: Placeholder[];
    pendingPlaceholder?: PendingPlaceholder;
    pdfPagesListRef: React.MutableRefObject<VariableSizeList | undefined>;
    gap?: number;
    currentPage: number;
    isPlaceholderDraggable?: boolean;
    updateCurrentPage: (newPage: number) => void;
    updatePlaceholderList: (newPlaceholder: Placeholder) => void;
    updatePendingPlaceholder: (newPendingPlaceholder: PendingPlaceholder) => void;
    getPdfPage: (index: number) => Promise<PDFPageProxy> | undefined;
}

/**
 * Component that renders a PDF file
 */
const PdfViewer: FunctionComponent<OwnProps> = (props) => {
    const {
        getPdfPage,
        totalPages,
        placeholderList = [],
        updatePlaceholderList,
        updatePendingPlaceholder,
        pdfPagesListRef,
        gap = 40,
        currentPage,
        updateCurrentPage,
        pendingPlaceholder,
        isPlaceholderDraggable,
    } = props;

    const [pages, setPages] = useState<PDFPageProxy[]>([]);
    const [dimensions, setDimensions] = useState<PdfPageCanvasDimensions>({ width: 0, height: 0 });
    const [scale, setScale] = useState(1);
    const [minScale, setMinScale] = useState(1);

    const listRef = useRef<VariableSizeList>();
    const pdfViewerContainerRef = useRef<HTMLDivElement>();

    useEffect(() => {
        const handleResize = () => {
            if (pdfViewerContainerRef.current) {
                const { width, height } = pdfViewerContainerRef.current.getBoundingClientRect();

                setDimensions({ width, height });
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            document.documentElement.style.removeProperty('--scale-factor');
        };
    }, []);

    useEffect(() => {
        const page = pages[0];

        if (!page) return;
        const unscaledViewport = page.getViewport({ scale: 1 });

        let viewportScale: number = 0;

        if (dimensions.height < dimensions.width) {
            viewportScale = Math.max((dimensions.height / unscaledViewport.height), (dimensions.width / unscaledViewport.width));
        } else {
            viewportScale = Math.min((dimensions.height / unscaledViewport.height), (dimensions.width / unscaledViewport.width));
        }

        setScale(viewportScale);
        setMinScale(viewportScale);
    }, [dimensions, pages]);

    useEffect(() => {
        setPages([]);
    }, [getPdfPage]);

    useEffect(() => {
        listRef.current?.resetAfterIndex(0);
        document.documentElement.style.setProperty('--scale-factor', `${scale}`);
    }, [scale]);

    useEffect(() => {
        updateCurrentPage(currentPage);
    }, [currentPage]);

    /**
     * Fetch new pdf pages and adds them to pages array
     *
     * @param { number } index
     */
    const fetchPage = useCallback((index: number) => {
        if (!pages[index]) {
            getPdfPage(index)?.then((pdfPage) => {
                setPages((prev) => {
                    const next = [...prev];
                    next[index] = pdfPage;
                    return next;
                });
            });
        }
    }, [getPdfPage, pages]);

    const handleListRef = useCallback((elem: VariableSizeList) => {
        listRef.current = elem;
        if (pdfPagesListRef) {
            pdfPagesListRef.current = elem;
        }
    }, [pdfPagesListRef]);

    const handleItemSize = useCallback((index: number) => {
        const page = pages[index];
        if (page) {
            const viewport = page.getViewport({ scale });
            const pdfViewerHeight = pdfViewerContainerRef.current?.clientHeight ?? 0;

            if (index + 1 === totalPages && viewport.height < pdfViewerHeight) {
                // add some bottom padding to the last page
                return viewport.height + (pdfViewerHeight - viewport.height) + gap;
            }

            return viewport.height + gap;
        }
        return 50;
    }, [pages, scale, gap]);

    const handlePageChange = (pageNum: number) => {
        if (pageNum <= 0 || pageNum > totalPages || !listRef.current) return;

        listRef.current.scrollToItem(pageNum - 1, 'center');
    };

    /**
     * Calculates to which page the placeholder was dragged into
     *
     * @param offsetAxisYOfParentElement offset of the placeholder from the top of the parent element
     * @param placeholder
     * @param newPosition
     */
    const handleDragPlaceholderIntoNewPage = (offsetAxisYOfParentElement: number, placeholder: Placeholder | PendingPlaceholder, newPosition: Partial<ElementPosition>) => {
        if ((offsetAxisYOfParentElement < 0 && placeholder.page === 1) || (offsetAxisYOfParentElement > 0 && placeholder.page === totalPages)) {
            // early return for dragging placeholder before first page OR after last page
            return;
        }

        let totalOffset = Math.abs(offsetAxisYOfParentElement);
        let { page } = placeholder;
        let sizeOfPageAtIndex = 0;

        // check if the placeholder is being dragged to the next or previous page
        const increment = offsetAxisYOfParentElement > 0 ? 1 : -1;

        const startIndex = (placeholder.page - 1) + increment;

        for (let index = startIndex; index >= 0 && index < totalPages; index += increment) {
            // calculate size of the page at index
            sizeOfPageAtIndex = handleItemSize(index);

            // check if the total offset is less than the size of the page at index
            if (totalOffset < sizeOfPageAtIndex) {
                page = index + 1;
                break;
            }

            // subtract the size of the page at index from the total offset
            totalOffset -= sizeOfPageAtIndex;
        }

        // update placeholder coordinates
        const placeholderCoordinates = {
            left: newPosition.left ? newPosition.left / scale : placeholder.left,
            top: offsetAxisYOfParentElement > 0 ? (totalOffset - gap) / scale : (sizeOfPageAtIndex - totalOffset + gap - (placeholder.height ?? 0)) / scale,
        };

        const newPlaceholder = {
            ...placeholder,
            ...placeholderCoordinates,
            page,
        };

        if (isOfType<Placeholder>(newPlaceholder, 'id')) {
            updatePlaceholderList(newPlaceholder);
            return;
        }

        updatePendingPlaceholder(newPlaceholder);
    };

    const handleDragPlaceholder = (newPosition: ElementPosition, placeholder: Placeholder | PendingPlaceholder) => {
        const updatedPlaceholder = {
            ...placeholder,
            ...newPosition,
        };
        if (isOfType<Placeholder>(updatedPlaceholder, 'id')) {
            updatePlaceholderList(updatedPlaceholder);
            return;
        }

        updatePendingPlaceholder(updatedPlaceholder);
    };

    return (
        <div className="pdf-viewer" ref={pdfViewerContainerRef as React.RefObject<HTMLDivElement>} data-testid="pdf-viewer">
            <PdfNavigationBar
                currentPage={currentPage}
                totalPages={totalPages}
                scale={scale}
                minScale={minScale}
                onPageChange={handlePageChange}
                onScaleChange={setScale}
            />
            <div className="pdf-wrapper__page-list-wrapper">
                <VariableSizeList
                    itemCount={totalPages}
                    ref={handleListRef}
                    width={dimensions.width}
                    height={dimensions.height}
                    itemSize={handleItemSize}
                    onItemsRendered={({ visibleStartIndex }) => {
                        updateCurrentPage(visibleStartIndex + 1);
                    }}
                    estimatedItemSize={handleItemSize(0)}
                >
                    {({ index, style }) => {
                        fetchPage(index);
                        return (
                            <PdfPage
                                page={pages[index]}
                                scale={scale}
                                style={style}
                                placeholderList={placeholderList.filter((p) => p.page === index + 1)}
                                pendingPlaceholder={pendingPlaceholder?.page === index + 1 ? pendingPlaceholder : undefined}
                                onDragPlaceholder={handleDragPlaceholder}
                                dragPlaceholderIntoNewPage={handleDragPlaceholderIntoNewPage}
                                isPlaceholderDraggable={isPlaceholderDraggable}
                            />
                        );
                    }}
                </VariableSizeList>
            </div>
        </div>
    );
};

export default PdfViewer;

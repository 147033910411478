/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import React, { FunctionComponent, useEffect } from 'react';
import { BillingContext, withBillingContext } from '../controllers/BillingContext';

import { LoadingScreen } from '../screens/LoadingScreen';
import { useAuthContext } from '../controllers/AuthenticationContext';

type OwnProps = Pick<BillingContext, 'loadUserWallet'> & {
    children: React.ReactNode;
}

/**
 * Allows a component to render anonymously.
 * Helpful in situations where we want to provide different behavior for logged in users and anonymous users.
 *
 * @param props
 * @returns
 */
export const OptionalAuthBase: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        children,
        loadUserWallet,
    } = props;

    const {
        user,
        isKeycloakReady,
        isAuthenticated,
    } = useAuthContext();

    useEffect(() => {
        if (isKeycloakReady && isAuthenticated) {
            loadUserWallet();
        }
    }, [user]);
    
    if (!isKeycloakReady) {
        return <LoadingScreen />;
    }
    
    return <>{children}</>;
};

export const OptionalAuth = withBillingContext(OptionalAuthBase);

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FunctionComponent,
    useEffect,
    useState,
} from 'react';

import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import { preparePageTitle } from '../../../utils/route';
import { BillingContext, withBillingContext } from '../../controllers/BillingContext';
import { LoadingCircles } from '../../elements/LoadingCircles';
import { PlansList } from '../../elements/billing/PlansList';
import { SubscriptionPlan } from '../../../types/billing';

interface BaseProps extends BillingContext, TranslationContext {
}

export const PlansScreenBase: FunctionComponent<BaseProps> = (props) => {
    const {
        t,
        getAvailablePlans,
    } = props;
    const [isLoading, setIsLoading] = useState(true);
    const [plans, setPlans] = useState<SubscriptionPlan[]>([]);

    useEffect(() => {
        document.title = preparePageTitle(t('plans.title'));
        getPlansList();
    }, []);

    const getPlansList = async () => {
        const [plansData] = await getAvailablePlans();

        setIsLoading(false);

        if (plansData) {
            setPlans([...plansData]);
        }
    };

    return (
        <div className="plans-screen">
            <div className="plans-screen__header">
                <h1>{t('plans.title')}</h1>
            </div>
            {isLoading && (<LoadingCircles size="xs" variant="primary" />)}
            {plans && (
                <>
                    <PlansList availablePlans={plans} />
                    <div className="plans-screen__footer">
                        <p>{t('plans.VATInfo')}</p>
                        <p>{t('plans.extraSubscriptionActions')}</p>
                    </div>
                </>
            )}
        </div>
    );
};
export const PlansScreen = withTranslationContext(withBillingContext(PlansScreenBase));

/**
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

export enum SlicerName {
    Preferences = 'preferences',
    Organization = 'organization',
    Billing = 'billing',
    User = 'user',
}

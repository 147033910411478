/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FieldValue,
    FormValidator,
    FormValidatorError,
    FormValidatorErrorType,
} from '../validations';

import { ValidatorStrategy } from './Validator';

export class LengthValidator implements ValidatorStrategy {
    validate(fieldValue: FieldValue, validator: FormValidator): FormValidatorError | null {
        const {
            length,
        } = validator;
        // No error shown if user did not type anything yet
        if (!length || !fieldValue || fieldValue.toString().length === 0) return null;

        const { lowerLimit, upperLimit } = length;
        const parsedValue = String(fieldValue);
        if (lowerLimit === upperLimit) {
            const exactLimit = lowerLimit;
            if (parsedValue.length !== exactLimit) {
                return {
                    typeOfViolation: FormValidatorErrorType.SizeExact,
                    size: exactLimit,
                };
            }
        } else {
            if (parsedValue.length < lowerLimit || parsedValue.length > upperLimit) {
                return {
                    typeOfViolation: FormValidatorErrorType.Size,
                    min: lowerLimit,
                    max: upperLimit,
                };
            }
        }
        return null;
    }
}

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FunctionComponent,
    useEffect,
    useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ReactComponent as PlusIcon } from '../../../assets/images/plus-icon.svg';
import { AppRoute } from '../../../constants/routes';
import { ButtonVariant } from '../../../types/general';
import { WorkflowDocumentType } from '../../../types/workflows';
import { buildUrl } from '../../../utils/navigation';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import { WorkflowContext, withWorkflowContext } from '../../controllers/WorkflowContext';
import Button from '../Button';
import { LoadingCircles } from '../LoadingCircles';
import { useWorkflowContext } from '../workflows/WorkflowContextProvider';
import { AddDocumentModal } from './AddDocumentModal';
import { DocumentCard } from './DocumentCard';
import { Tooltip } from '../Tooltip';

type OwnProps = TranslationContext & WorkflowContext;

const DocumentListComponent: FunctionComponent<OwnProps> = (props) => {
    const {
        t, uploadWorkflowDocument, getDocumentTypes,
    } = props;

    const { fetchDocuments, documents } = useWorkflowContext();

    const { workflowId = '' } = useParams();
    const navigate = useNavigate();

    const [documentTypes, setDocumentTypes] = useState<WorkflowDocumentType[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isModalAddingNewDocumentOpen, setIsModalAddingNewDocumentOpen] = useState(false);

    useEffect(() => {
        setIsLoading(true);

        Promise.allSettled([fetchDocuments(), fetchDocumentTypes()]).then(() => {
            setIsLoading(false);
        });
    }, []);

    const fetchDocumentTypes = async () => {
        const [documentTypeList] = await getDocumentTypes(workflowId);

        if (documentTypeList) {
            setDocumentTypes(documentTypeList);
        }
    };

    const handleSubmitNewDocument = (file: File, notes?: string) => {
        const documentType = documentTypes.find((d) => d.name === 'Other');

        if (!documentType) {
            toast.error(t('errors.cantUploadDocument'));
            return;
        }

        uploadWorkflowDocument(workflowId, {
            documentType: documentType.id,
            file,
            notes,
            name: file.name,
        }).then((functionalErr) => {
            if (functionalErr) {
                toast.error(functionalErr.errors[0].getMessageTranslated(t));
            } else {
                setIsModalAddingNewDocumentOpen(false);
                fetchDocuments(true);
            }
        });
    };

    return (
        <div className="workflow-screen--view__section-elements__uploaded-documents">
            <div className="workflow-screen--view__section-elements__uploaded-documents__header">
                <div className="workflow-screen--view__section-elements__uploaded-documents__header__title">
                    {t('workflows.view.uploadedDocuments')}
                    <span className="count">({documents.length})</span>
                </div>
                <Button
                    variant={ButtonVariant.Circle}
                    extraClasses="primary"
                    testId="add-document-btn"
                    onClick={() => setIsModalAddingNewDocumentOpen(true)}
                >
                    <Tooltip title={t('workflows.documents.uploadAdhocDocumentTooltip')} placement="bottom">
                        <PlusIcon />
                    </Tooltip>
                </Button>
            </div>
            {isLoading && <LoadingCircles size="m" variant="primary" />}
            {!isLoading && documents.length === 0 && (<p>{t('workflows.view.noDocumentsFound')}</p>)}
            {!isLoading && documents.length > 0 && (
                <>
                    <div className="document-card-list">
                        {documents.map((d) => (
                            <DocumentCard
                                key={d.id}
                                document={d}
                            />
                        ))}
                    </div>
                    <Button
                        variant={ButtonVariant.RectangularStroked}
                        extraClasses="full-width"
                        onClick={() => navigate(buildUrl(AppRoute.WorkflowDocuments, { workflowId }))}
                    >
                        {t('workflows.view.viewAll')}
                    </Button>
                </>
            )}
            <AddDocumentModal
                open={isModalAddingNewDocumentOpen}
                onModalClose={() => setIsModalAddingNewDocumentOpen(false)}
                onSubmit={handleSubmitNewDocument}
            />
        </div>
    );
};

export const DocumentList = withWorkflowContext(withTranslationContext(DocumentListComponent));

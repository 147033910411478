/*
*
* @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
* Development by VOID Software, SA
*
*/

import React, { FunctionComponent } from 'react';
import { withTranslationContext, TranslationContext } from '../controllers/TranslationContext';

interface OwnProps {
    name: string;
    value: boolean;
    onChange?: () => void;
}

type Props = OwnProps & TranslationContext;

const Switch: FunctionComponent<Props> = (props: Props) => {
    const {
        onChange, name, value,
    } = props;

    return (
        <div className="switch">
            <div className="switch__toggle">
                <input id={name} name={name} checked={Boolean(value)} onChange={onChange} type="checkbox" hidden data-testid="switch-btn" />
                <span className="switch__toggle__slider" onClick={onChange} />
            </div>
        </div>
    );
};

export default withTranslationContext(Switch);

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent, ReactNode } from 'react';

import Logo from '../../../../assets/images/brand_logo_unlockitV2_fundo_branco.png';

interface OwnProps {
    children: ReactNode;
}

export const SignerInvitationLayout: FunctionComponent<OwnProps> = (props) => {
    const { children } = props;
    return (
        <div className="signer-invitation-layout">
            <header
                className="signer-invitation-layout__header"
                data-testid="header-signing"
            >
                <img src={Logo} height={35} width="auto" alt="Unlockit Logo" />
            </header>
            <div className="signer-invitation-layout__children">
                {children}
            </div>
        </div>
    );
};

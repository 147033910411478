/**
*
* @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
* Development by VOID Software, SA
*
*/

import React, { FunctionComponent } from 'react';

import { Drawer as DrawerMUI } from '@mui/material';
import { ReactComponent as CloseIcon } from '../../assets/images/closeBtn.svg';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import { Tooltip } from './Tooltip';

interface OwnProps extends TranslationContext {
    open: boolean;
    title?: string;
    handleClose: () => void;
    children: React.ReactNode;
    extraClasses?: string;
    testId?: string;
}

const DrawerComponent: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t,
        open,
        title,
        handleClose,
        children,
        extraClasses,
        testId,
    } = props;

    return (
        <DrawerMUI
            className={`drawer ${extraClasses}`}
            anchor="bottom"
            open={open}
            onClose={handleClose}
            data-testid={testId}
        >
            <div className="drawer__wrapper">
                <div className="drawer__wrapper__header">
                    <h3>{title}</h3>
                    <Tooltip title={t('general.close')} placement="bottom">
                        <button type="button" className="drawer__wrapper__header__close-btn" data-testid="modal-close-btn" onClick={handleClose}>
                            <CloseIcon />
                        </button>
                    </Tooltip>
                </div>
                <div className="drawer__wrapper__content">
                    {children}
                </div>
            </div>
        </DrawerMUI>
    );
};

export const Drawer = withTranslationContext(DrawerComponent);

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { QesVideoRequirementsError } from '../types/esignatures/qes';
import { Result, VideoIDValidationChecks } from '../types/videoId';

export const validationChecksToRequirementsError = (validationChecks: VideoIDValidationChecks | null): Array<QesVideoRequirementsError> => {
    const requirementsError: Array<QesVideoRequirementsError> = [];

    if (!validationChecks || validationChecks.passed) return requirementsError;

    Object.keys(validationChecks.result).forEach((k) => {
        const resultObj = validationChecks.result[k as keyof Result];
        if (resultObj.passed) return;

        requirementsError.push(k as keyof Result);
    });

    return requirementsError;
};

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FunctionComponent,
    memo,
    useMemo,
} from 'react';
import classNames from 'classnames';
import { ElementPosition, isOfType } from '../../../types/general';
import { PendingPlaceholder, Placeholder } from '../../../types/contracts';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';

import { ReactComponent as Logo } from '../../../assets/images/logo_without_text.svg';
import { useDrag } from '../../../hooks/useDrag';

interface OwnProps extends TranslationContext {
    placeholder: Placeholder | PendingPlaceholder;
    scale: number;
    isDraggable?: boolean;
    disabled?: boolean;
    onDragPlaceholder?: (position: ElementPosition, placeholder: Placeholder | PendingPlaceholder) => void;
    onDragIntoNewPage?: (offsetAxisYOfParentElement: number, placeholder: Placeholder | PendingPlaceholder, newPosition: Partial<ElementPosition>) => void;
}

const PdfPlaceholderAnnotationComponent: FunctionComponent<OwnProps> = (props) => {
    const {
        t,
        placeholder,
        scale,
        isDraggable = false,
        disabled = false,
        onDragIntoNewPage,
        onDragPlaceholder,
    } = props;

    const handleDragOver = (newPosition: ElementPosition) => {
        if (!onDragPlaceholder) return;

        onDragPlaceholder({
            left: newPosition.left / scale,
            top: newPosition.top / scale,
        }, placeholder);
    };

    function handleDragOutsideOfYAxisOfParentElement(offsetAxisYOfParentElement: number, newPosition: Partial<ElementPosition>) {
        if (onDragIntoNewPage) {
            onDragIntoNewPage(offsetAxisYOfParentElement, placeholder, newPosition);
        }
    }

    const { ref, isDragging } = useDrag(
        {
            dragOver: handleDragOver,
            dragOutsideOfYAxisOfParentElement: handleDragOutsideOfYAxisOfParentElement,
        },
    );
    
    const positionAndSize = useMemo(() => {
        return {
            width: `${scale * placeholder.width}px`,
            height: `${scale * placeholder.height}px`,
            top: `${scale * placeholder.top}px`,
            left: `${scale * placeholder.left}px`,
        };
    }, [placeholder]);

    // Don't render this placeholder if it is already signed
    if (placeholder.signer.signedAt) return (<></>);
    
    return (
        <div
            className={classNames('pdf-placeholder-annotation',
                { 'pdf-placeholder-annotation--dragging': isDragging },
                { 'pdf-placeholder-annotation--disabled': disabled })}
            data-testid="pdf-placeholder-annotation"
            style={{ ...positionAndSize }}
            ref={isDraggable ? ref : null}
            aria-disabled={disabled}
        >
            <div className="pdf-placeholder-annotation__signer-name">
                <Logo />
                <h1>{placeholder.signer.name}</h1>
            </div>
            <h1 className="pdf-placeholder-annotation__signed-date pdf-placeholder-annotation__signed-date--awaits-signature">
                {(!isOfType<Placeholder>(placeholder, 'id') || !placeholder.signer.signedAt) ? t('signerStates.notSigned') : t('signerStates.signed')}
            </h1>
        </div>
    );
};

export const PdfPlaceholderAnnotation = memo(withTranslationContext(PdfPlaceholderAnnotationComponent));

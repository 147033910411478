/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent } from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { DocumentStatus, WorkflowDocument } from '../../../types/workflows';
import { ReactComponent as ClockIcon } from '../../../assets/images/clock.svg';
import { ReactComponent as CheckmarkIcon } from '../../../assets/images/checkmark-green.svg';
import { ReactComponent as CrossIcon } from '../../../assets/images/cross-red.svg';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import { buildUrl } from '../../../utils/navigation';
import { AppRoute } from '../../../constants/routes';

interface OwnProps extends TranslationContext {
    document: WorkflowDocument;
}

const DocumentCardComponent: FunctionComponent<OwnProps> = (props) => {
    const { document, t } = props;

    const navigate = useNavigate();

    const renderStateIcon = () => {
        switch (document.documentStatus) {
            case DocumentStatus.PENDING:
                return <ClockIcon />;
            case DocumentStatus.APPROVED:
                return <CheckmarkIcon />;
            case DocumentStatus.REJECTED:
                return <CrossIcon />;
            default:
                return <></>;
        }
    };

    const renderTextStatus = () => {
        const translateParams = document.documentStatus !== DocumentStatus.PENDING ? { name: document.lastUserInteractionName } : undefined;

        return t(`workflows.status.${document.documentStatus}`, translateParams);
    };

    return (
        <div
            className="document-card"
            data-testid={`document-card-${document.id}`}
            onClick={() => navigate(buildUrl(AppRoute.ViewWorkflowDocument, { workflowId: String(document.transactionId), documentId: String(document.id) }))}
        >
            <div className="document-card__info">
                <span className="document-card__info__icon-wrap">
                    {renderStateIcon()}
                </span>
                <div className="document-card__info__details">
                    <h1 title={document.documentType}>{document.documentType}</h1>
                    <p className={
                        classNames('document-card__info__details__status',
                            { 'document-card__info__details__status--PENDING': document.documentStatus === DocumentStatus.PENDING })
                        }
                    >
                        {renderTextStatus()}
                    </p>
                </div>
            </div>
        </div>
    );
};

export const DocumentCard = withTranslationContext(DocumentCardComponent);

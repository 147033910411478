/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent, ReactNode } from 'react';
import { LoadingScreen } from '../../screens/LoadingScreen';
import { MenuBar } from '../MenuBar';
import { NavBar } from '../NavBar';

interface OwnProps {
    children: ReactNode;
    primaryBtn?: ReactNode;
    secondaryBtn?: ReactNode;
    isLoading?: boolean;
    customMenu?: ReactNode;
}
const DefaultLayout: FunctionComponent<OwnProps> = (props) => {
    const {
        children,
        primaryBtn,
        secondaryBtn,
        isLoading = false,
        customMenu,
    } = props;

    return (
        <div className="default-layout">
            <NavBar />
            {customMenu || <MenuBar />}
            {isLoading && <LoadingScreen />}
            {!isLoading && (
                <>
                    <div className="default-layout__content" data-testid="default-layout">
                        {children}
                    </div>
                    {(secondaryBtn || primaryBtn) && (
                        <footer className="default-layout__footer" data-testid="footer">
                            <div className="default-layout__footer__btns-wrap">
                                {secondaryBtn}
                                {primaryBtn}
                            </div>
                        </footer>
                    )}
                </>
            )}
        </div>
    );
};

export { DefaultLayout };

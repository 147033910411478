/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent, useMemo } from 'react';
import { useRouteError, useNavigate } from 'react-router-dom';
import { isArray } from 'lodash';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import { DefaultLayout } from '../elements/layouts/DefaultLayout';
import { ReactComponent as WarningIcon } from '../../assets/images/warning.svg';
import { ReactComponent as NetworkFailedIcon } from '../../assets/images/network_failed.svg';
import { ReactComponent as DocumentNotFoundIcon } from '../../assets/images/document_not_found.svg';
import { ReactComponent as PdfErrorIcon } from '../../assets/images/pdf_error.svg';
import Button from '../elements/Button';
import { ButtonVariant, isOfType } from '../../types/general';
import { ErrorCode, ErrorResponse, FunctionalError } from '../../types/errors';
import { AppRoute } from '../../constants/routes';

type OwnProps = TranslationContext;

const ErrorScreenComponent: FunctionComponent<OwnProps> = (props) => {
    const { t } = props;
    const routeErrors = useRouteError();
    const navigate = useNavigate();

    const error: FunctionalError = useMemo(() => {
        if (isOfType<ErrorResponse>(routeErrors, 'errors')
        && isArray(routeErrors.errors)
        && routeErrors.errors.length > 0
        && isOfType<FunctionalError>(routeErrors.errors[0], 'errorCode')) return routeErrors.errors[0];

        return new FunctionalError('General', ErrorCode.GENERAL);
    }, [routeErrors]);

    const renderIcon = () => {
        switch (String(error.errorCode)) {
            case ErrorCode.ERR_NETWORK:
            case ErrorCode.ERR_CANCELED:
                return <NetworkFailedIcon />;
            case ErrorCode.ENTITY_NOT_FOUND:
                return <DocumentNotFoundIcon />;
            case ErrorCode.CANNOT_CONVERT_TO_PDFA:
                return <PdfErrorIcon />;
            default:
                return <WarningIcon />;
        }
    };

    const renderErrorTitle = () => {
        if (!isOfType<FunctionalError>(error, 'errorCode')) {
            return t('errors.general');
        }

        return error.getMessageTranslated(t);
    };

    return (
        <DefaultLayout>
            <div className="error-screen light-gradient-bg">
                <div className="error-screen__icon-container">
                    {renderIcon()}
                </div>
                <div className="error-screen__body">
                    <h1>{renderErrorTitle()}</h1>
                    <p>{t('errors.genericDescription')}</p>
                </div>
                <div className="error-screen__actions">
                    <Button
                        variant={ButtonVariant.Curved}
                        onClick={() => navigate(0)}
                    >
                        {t('general.retry')}
                    </Button>
                    <Button
                        onClick={() => navigate(AppRoute.Index)}
                    >
                        {t('general.goToHomePage')}
                    </Button>
                </div>
            </div>
        </DefaultLayout>
    );
};

export const ErrorScreen = withTranslationContext(ErrorScreenComponent);

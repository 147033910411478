/*
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import { useAuthContext } from '../controllers/AuthenticationContext';
import { AppRoute } from '../../constants/routes';
import { ReactComponent as PlanSvg } from '../../assets/images/rocket.svg';
import { ReactComponent as ReferralSvg } from '../../assets/images/megaphone.svg';
import { ReactComponent as ListWorkflowsSvg } from '../../assets/images/search-list.svg';
import { ReactComponent as CreateWorkflowSvg } from '../../assets/images/work-bag.svg';
import { ReactComponent as AddMemberSvg } from '../../assets/images/add-user.svg';
import { ReactComponent as ListContractsSvg } from '../../assets/images/businesses.svg';
import { LinkActionItem } from '../../types/menu';
import { OrganizationsContext, withOrganizationsContext } from '../controllers/OrganizationsContext';
import { preparePageTitle } from '../../utils/route';

type OwnProps = TranslationContext & OrganizationsContext;

const HomeScreenBase: FunctionComponent<OwnProps> = (props) => {
    const { organizationSelected, t } = props;
    const { user } = useAuthContext();

    useEffect(() => {
        document.title = preparePageTitle(t('menuBar.home'));
    }, []);

    const quickActionsMenu: LinkActionItem[] = organizationSelected?.organization
        ? [
            {
                title: 'subscribePlan',
                icon: <PlanSvg />,
                link: AppRoute.BillingSelectOrganization,
            },
            {
                title: 'inviteInnovators',
                icon: <ReferralSvg />,
                link: 'https://academy.unlockit.io/programa-de-referral',
                newTab: true,
            },
            {
                title: 'viewWorkflows',
                icon: <ListWorkflowsSvg />,
                link: AppRoute.OrganizationWorkflows,
            },
            {
                title: 'createWorkflow',
                icon: <CreateWorkflowSvg />,
                link: AppRoute.CreateWorkflowSelectType,
            },
            {
                title: 'inviteMembers',
                icon: <AddMemberSvg />,
                link: `${AppRoute.OrganizationMembers}?invite=true`,
            },
            {
                title: 'viewContracts',
                icon: <ListContractsSvg />,
                link: AppRoute.OrganizationContracts,
            },
            {
                title: 'createContract',
                icon: <ListContractsSvg />,
                link: AppRoute.CreateContract,
            },
        ] : [
            {
                title: 'subscribePlan',
                icon: <PlanSvg />,
                link: AppRoute.BillingSelectOrganization,
            },
            {
                title: 'inviteInnovators',
                icon: <ReferralSvg />,
                link: 'https://academy.unlockit.io/programa-de-referral',
                newTab: true,
            },
            {
                title: 'viewWorkflows',
                icon: <ListWorkflowsSvg />,
                link: AppRoute.Workflows,
            },
            {
                title: 'inviteMembers',
                icon: <AddMemberSvg />,
                link: `${AppRoute.OrganizationMembers}?invite=true`,
            },
            {
                title: 'viewContracts',
                icon: <ListContractsSvg />,
                link: AppRoute.Contracts,
            },
        ];
    const renderActionItem = (item: LinkActionItem) => {
        return (
            <div className="home-screen__quick-actions__card card">
                <div className="home-screen__quick-actions__card__icon-wrap">
                    {item.icon}
                </div>
                <span className="home-screen__quick-actions__card__title">
                    {t(`homeScreen.${item.title}`)}
                </span>
            </div>
        );
    };
    return (
        <div className="home-screen">
            <h1>{`${t('homeScreen.welcome')}, ${user?.fullName}`}</h1>
            <section className="home-screen__quick-actions">
                <h3>{t('homeScreen.quickActionsTitle')}</h3>
                <Grid container spacing={2} data-testid="quick-actions-list">
                    {quickActionsMenu.map((item) => (
                        <Grid item xs={6} md={4} lg={3} key={item.title}>
                            <Link
                                to={item.link}
                                target={item.newTab ? '_blank' : '_self'}
                                data-testid={`link-${item.title}`}
                            >
                                {renderActionItem(item)}
                            </Link>
                        </Grid>
                    ))}
                </Grid>
            </section>
        </div>
    );
};

export const HomeScreen = withOrganizationsContext(withTranslationContext(HomeScreenBase));

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent, useState } from 'react';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import SignatureAvailabilities from '../../assets/data/signature-availability.json';
import {
    AvailabilitySignatureType, SignatureAvailabilityType, SignatureCountry,
} from '../../types/contracts';
import { FormSelectField } from './FormSelectField';

type BaseProps = TranslationContext;

const SignatureAvailabilityListBase: FunctionComponent<BaseProps> = ({ t }) => {
    const typedSignatureAvailabilities = SignatureAvailabilities as SignatureAvailabilityType;
    const [selectedSignatureType, setSelectedSignatureType] = useState<AvailabilitySignatureType>(AvailabilitySignatureType.QESCMD);
    const [filteredList, setFilteredList] = useState<SignatureCountry[]>(typedSignatureAvailabilities[AvailabilitySignatureType.QESCMD]);

    /**
     * Map Signature Types
     *
     * @remarks
     * Map Signature Types for label/value needed for the selectbox
     */
    const getMappedSignatureKeys = () => {
        return Object.entries(typedSignatureAvailabilities)
            .map(([signatureKey]) => ({
                value: signatureKey,
                label: t(`signatureAvailability.${signatureKey}`),
            }));
    };

    /**
    * Filter data (countries list)
    *
    * @remarks
    * Filter data based on selected signature type
    */
    const filterList = (_: string, selectedValue: string) => {
        setSelectedSignatureType(selectedValue as AvailabilitySignatureType);
        setFilteredList(typedSignatureAvailabilities[selectedValue]);
    };

    return (
        <div className="signature-availability__content">
            <FormSelectField
                name="signature-type"
                onChange={filterList}
                options={getMappedSignatureKeys()}
            />

            <div className="signature-availability__content__item" key={selectedSignatureType}>
                <ul data-testid="countries-list">
                    {filteredList.map((country) => (
                        <li key={`${selectedSignatureType} ${country.alpha3}`}>
                            <div className="signature-availability__content__item__country">
                                <div className="signature-availability__content__item__country__flag"> {country.flag}</div>
                                <h4>{country.name}</h4>
                            </div>
                            <ul>
                                {country.documents.map((document) => (
                                    <li key={`${country.name} ${document.i18n} ${document.year ?? ''}`}>{t(`signatureAvailability.${document.i18n}`)} {document.year}</li>
                                ))}
                            </ul>
                        </li>
                    ))}
                </ul>
            </div>

        </div>
    );
};

export const SignatureAvailabilityList = withTranslationContext(SignatureAvailabilityListBase);

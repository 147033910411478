/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FunctionComponent, useEffect, useMemo, useState,
} from 'react';
import { Chip } from '@mui/material';
import { isEqual } from 'lodash';
import { ReactComponent as FiltersIcon } from '../../../assets/images/filters.svg';
import { Workflow, WorkflowsFilterParams } from '../../../types/workflows';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import { WorkflowContext, withWorkflowContext } from '../../controllers/WorkflowContext';
import Button from '../../elements/Button';
import { Drawer } from '../../elements/Drawer';
import InfiniteScrollWrapper from '../../elements/InfiniteScrollWrapper';
import { LoadingCircles } from '../../elements/LoadingCircles';
import { DefaultLayout } from '../../elements/layouts/DefaultLayout';
import { FiltersForm } from '../../elements/workflows/FiltersForm';
import { WorkflowCard } from '../../elements/workflows/WorkflowCard';
import { ReactComponent as RemoveIcon } from '../../../assets/images/closeBtn.svg';
import { formatToLocalDate } from '../../../utils/date';
import { OrderOptions } from '../../../types/general';
import { preparePageTitle } from '../../../utils/route';

type OwnProps = TranslationContext & WorkflowContext;

const defaultFilters: WorkflowsFilterParams = {
    _cursor: '',
    _limit: '',
    _sort: OrderOptions.ASCENDING,
    email: '',
    externalId: '',
    from: '',
    to: '',
};

const WorkflowsScreenBase: FunctionComponent<OwnProps> = (props) => {
    const {
        t,
        getParticipantWorkflows,
    } = props;

    const [workflows, setWorkflows] = useState<Workflow[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isOpenFilters, setIsOpenFilters] = useState(false);
    const [filters, setFilters] = useState<WorkflowsFilterParams>(defaultFilters);

    useEffect(() => {
        document.title = preparePageTitle(t('workflows.list.title'));
        getWorkflowsList(filters);
    }, []);

    const appliedFilters: string[] = useMemo(() => {
        return Object.keys(filters)
            .filter((key) => !key.startsWith('_')
                && filters[key as keyof WorkflowsFilterParams] !== ''
                && filters[key as keyof WorkflowsFilterParams] !== undefined);
    }, [filters]);
    
    const getWorkflowsList = async (submittedFilters: WorkflowsFilterParams) => {
        const appliedFiltersWithOrder = submittedFilters._sort !== filters._sort
            ? { ...submittedFilters, _cursor: '' }
            : submittedFilters;
        const [workflowsData] = await getParticipantWorkflows(appliedFiltersWithOrder);
        
        setIsLoading(false);
        
        if (workflowsData) {
            setWorkflows(isEqual(submittedFilters, filters) ? [...workflows, ...workflowsData.results] : [...workflowsData.results]);
            setFilters({
                ...appliedFiltersWithOrder,
                _cursor: workflowsData.cursor,
            });
        }
    };
    
    const applyFilters = (filtersToApply: WorkflowsFilterParams) => {
        setIsLoading(true);
        setIsOpenFilters(false);
        getWorkflowsList({ ...filtersToApply, _cursor: '' });
    };
    
    const getAppliedFilterDisplayValue = (key: keyof WorkflowsFilterParams) => {
        switch (key) {
            case 'transactionType':
            case 'transactionStatus':
                return t(`workflows.list.filters.${key}Options.${filters[key]}`);
            case 'from':
            case 'to':
                return formatToLocalDate(filters[key]);
            default:
                return filters[key];
        }
    };
                        
    const removeFilter = (keyToRemove: keyof WorkflowsFilterParams) => {
        setFilters({
            ...filters,
            [keyToRemove]: defaultFilters[keyToRemove],
        });
    };
    const cleanAllFilters = () => {
        setIsOpenFilters(false);
        getWorkflowsList(defaultFilters);
    };

    return (
        <DefaultLayout>
            <div className="workflows-screen" data-testid="workflows-screen">
                <div className="workflows-screen__header">
                    <h1>{t('workflows.list.title')}</h1>
                    <Button
                        extraClasses="circle-btn filters-trigger-btn"
                        onClick={() => setIsOpenFilters(true)}
                        testId="mobile-filters-trigger-btn"
                    >
                        <FiltersIcon />
                    </Button>
                </div>
                {appliedFilters.length > 0 && (
                    <div className="workflows-screen__applied-filters" data-testid="applied-filters">
                        <div className="workflows-screen__applied-filters__header">
                            {`${appliedFilters.length} ${t(`workflows.list.filters.${appliedFilters.length === 1 ? 'filterApplied' : 'filtersApplied'}`)}:`}
                            <Button
                                onClick={cleanAllFilters}
                                testId="large-clean-btn"
                            >
                                {t('workflows.list.filters.cleanAll')}
                            </Button>
                        </div>
                        <div className="workflows-screen__applied-filters__list" data-testid="applied-filters-list">
                            {appliedFilters.map((appliedFilter) => {
                                return (
                                    <Chip
                                        key={appliedFilter}
                                        className="workflows-screen__applied-filters__list__item"
                                        label={`${t(`workflows.list.filters.${appliedFilter}`)}: ${getAppliedFilterDisplayValue(appliedFilter as keyof WorkflowsFilterParams)}`}
                                        onDelete={() => removeFilter(appliedFilter as keyof WorkflowsFilterParams)}
                                        deleteIcon={<RemoveIcon />}
                                    />
                                );
                            })}
                        </div>
                    </div>
                )}

                <div className="workflows-screen__content">
                    <div className="workflows-screen__content__large-filters" data-testid="filters-sidebar">
                        <FiltersForm
                            currentFilters={filters}
                            onSubmit={applyFilters}
                            onClean={cleanAllFilters}
                        />
                    </div>
                    <div>
                        <div className="workflows-screen__content__list">

                            <InfiniteScrollWrapper
                                hasMore={!!filters._cursor}
                                requestMore={() => getWorkflowsList(filters)}
                            >
                                {workflows.map((workflow) => (
                                    <WorkflowCard key={workflow.id} workflow={workflow} />
                                ))}
                            </InfiniteScrollWrapper>
                            {isLoading && <LoadingCircles size="m" variant="primary" />}
                        </div>
                    </div>
                </div>
                <Drawer
                    open={isOpenFilters}
                    handleClose={() => setIsOpenFilters(false)}
                    title={t('general.filter')}
                    extraClasses="filters-drawer"
                    testId="filters-drawer"
                >
                    <FiltersForm
                        currentFilters={filters}
                        onSubmit={applyFilters}
                        onClean={cleanAllFilters}
                    />
                </Drawer>
            </div>
        </DefaultLayout>
    );
};

export const WorkflowsScreen = withTranslationContext(withWorkflowContext(WorkflowsScreenBase));

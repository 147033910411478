/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent } from 'react';
import { TranslationContext, withTranslationContext } from '../../../controllers/TranslationContext';
import { EsignaturesLayout } from '../../../elements/esignatures/layouts/EsignaturesLayout';
import ElectronicIDLogo from '../../../../assets/images/logo-electronic-id.png';
import { ReactComponent as PhoneIcon } from '../../../../assets/images/phone.svg';
import { ReactComponent as EmailIcon } from '../../../../assets/images/email.svg';
import { AesSignatureContext, withAesSignatureContext } from '../../../controllers/esignatures/AesSignatureContext';

type OwnProps = TranslationContext & AesSignatureContext;

const AesSignatureContextScreenComponent: FunctionComponent<OwnProps> = (props) => {
    const { t, navigateToAesSignaturePhoneScreen, navigateToAesSignatureEmailScreen } = props;
    
    return (
        <div>
            <EsignaturesLayout primaryBtn={<></>} secondaryBtn={<></>}>
                <div className="aes-signature-banner">
                    <img src={ElectronicIDLogo} alt="Electronic IDentification logo" height={25} width="auto" />
                </div>
                <section className="aes-context">
                    <div className="aes-context__header">
                        <h3 className="aes-heading">{t('aesSignatureContextScreen.contextTitle')}</h3>
                        <p>{t('aesSignatureContextScreen.contextDescription')}</p>
                    </div>
                    <button
                        type="button"
                        className="aes-signing-method"
                        onClick={navigateToAesSignaturePhoneScreen}
                    >
                        <PhoneIcon />
                        <div>
                            <h4>{t('aesSignatureContextScreen.phoneTitle')}</h4>
                            <p>{t('aesSignatureContextScreen.phoneDescription')}</p>
                        </div>
                    </button>
                    <button
                        type="button"
                        className="aes-signing-method"
                        onClick={navigateToAesSignatureEmailScreen}
                    >
                        <EmailIcon />
                        <div>
                            <h4>{t('aesSignatureContextScreen.emailTitle')}</h4>
                            <p>{t('aesSignatureContextScreen.emailDescription')}</p>
                        </div>
                    </button>
                </section>
            </EsignaturesLayout>
        </div>
    );
};

export const AesSignatureContextScreen = withAesSignatureContext(withTranslationContext(AesSignatureContextScreenComponent));

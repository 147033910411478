/*
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { KeyedObject } from '../types/general';

export const stringToDecimal = (stringNumber: string): number => {
    return String(stringNumber).includes(',') ? Number(String(stringNumber).replace(',', '.')) : Number(stringNumber);
};

export const objectToParams = (obj?: KeyedObject<unknown>, useQ = true): string => {
    if (obj === undefined) return '';

    const params = new URLSearchParams('');
    Object.entries(obj).filter((entries) => entries[1]).forEach((objEntries) => {
        const key = objEntries[0];
        const value = objEntries[1];
        // Array specific logic
        if (Array.isArray(objEntries[1])) {
            // This may need to be refactored in order to represent array parameters as param[0]=1&param[1]=2, etc.
            objEntries[1].forEach((arrEl) => {
                params.append(key, String(arrEl));
            });
            return;
        }
        // Non array logic
        params.append(key, String(value));
    });

    return `${useQ ? '?' : ''}${params.toString()}`;
};

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FieldValue,
    FormValidatorError,
    FormValidatorErrorType,
} from '../validations';

import { ValidatorStrategy } from './Validator';

export class NumberValidator implements ValidatorStrategy {
    validate(fieldValue: FieldValue): FormValidatorError | null {
        // No error shown if user did not type anything yet
        if (fieldValue?.toString().length === 0) return null;

        if (isNaN(Number(fieldValue))) {
            return { typeOfViolation: FormValidatorErrorType.NotNumber };
        }
        return null;
    }
}

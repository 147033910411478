/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Basket, SubscriptionPlan, Wallet } from '../types/billing';

import { SlicerName } from './types';

export interface BillingState {
    planDetails: SubscriptionPlan | null;
    credits: number;
    wallet: Wallet | null;
    basket: Basket | null;
}

const billingInitialState: BillingState = {
    planDetails: null,
    credits: 0,
    wallet: null,
    basket: null,
};

const billingSlice = createSlice({
    name: SlicerName.Billing,
    initialState: billingInitialState,
    reducers: {
        selectPlan: (state, action: PayloadAction<SubscriptionPlan>) => {
            state.planDetails = { ...action.payload };
        },
        updateCredits: (state, action: PayloadAction<number>) => {
            state.credits = action.payload;
        },
        clearCheckoutCart: (state) => {
            state.planDetails = null;
            state.credits = 0;
        },
        setWallet: (state, action: PayloadAction<Wallet | null>) => {
            state.wallet = action.payload;
        },
        setBasket: (state, action: PayloadAction<Basket | null>) => {
            state.basket = action.payload;
        },
    },
});

export const {
    selectPlan, updateCredits, clearCheckoutCart, setWallet, setBasket,
} = billingSlice.actions;

export const billingReducer = billingSlice.reducer;

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import React, { FunctionComponent } from 'react';
import { FormValidatorError, validateForm } from '../../utils/validations';
import {
    GroupedRolePermissions,
    RoleMappingPayload,
    RolePayload,
} from '../../types/roles';
import { RolesContext, RolesContextProvider } from './RolesContext';
import {
    groupedPermissionsUrl,
    permissionsUrl,
    roleUrl,
    rolesListUrl,
    rolesUrl,
} from '../../services/organizations';

import { ErrorResponse } from '../../types/errors';
import { KeyedObject } from '../../types/general';
import { useCornerstoneApi } from '../../api';
import { validations } from '../../constants/validations';

interface OwnProps {
    children: React.ReactNode;
}

type Props = OwnProps;

export const RolesController: FunctionComponent<Props> = (props: Props) => {
    const { children } = props;

    const CornerstoneApiInstance = useCornerstoneApi();

    const submitNewOrganizationRole: RolesContext['submitNewOrganizationRole'] = async (payload: RolePayload) => {
        try {
            await CornerstoneApiInstance.post(rolesUrl(), payload);
        } catch (e) {
            return e as ErrorResponse;
        }
    };

    const assignRoleMapping: RolesContext['assignRoleMapping'] = async (payload: RoleMappingPayload) => {
        try {
            await CornerstoneApiInstance.put(permissionsUrl(), payload);
        } catch (e) {
            return e as ErrorResponse;
        }
    };

    const getRole: RolesContext['getRole'] = async (roleId) => {
        try {
            const { data } = await CornerstoneApiInstance.get(roleUrl(roleId));
            return data;
        } catch {
            return null;
        }
    };

    const getRoles: RolesContext['getRoles'] = async (organizationId) => {
        try {
            const { data } = await CornerstoneApiInstance.get(rolesListUrl(organizationId));
            return data;
        } catch {
            return null;
        }
    };

    const deleteRole: RolesContext['deleteRole'] = async (roleId: number) => {
        try {
            await CornerstoneApiInstance.delete(roleUrl(roleId));
        } catch (e) {
            return e as ErrorResponse;
        }
    };

    const updateRole: RolesContext['updateRole'] = async (id, payload) => {
        try {
            const { data } = await CornerstoneApiInstance.put(roleUrl(id), payload);
            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    const getGroupedPermissions: RolesContext['getGroupedPermissions'] = async () => {
        try {
            const { data } = await CornerstoneApiInstance.get<GroupedRolePermissions>(groupedPermissionsUrl());
            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    const validateCreateOrUpdateRoleForm: RolesContext['validateCreateOrUpdateRoleForm'] = (fields) => {
        const errors: KeyedObject<FormValidatorError[]> | null = validateForm(fields, validations.roleCreate);

        if (!errors || Object.keys(errors).length === 0) return null;
        return { fields: errors };
    };

    return (
        <RolesContextProvider
            value={{
                submitNewOrganizationRole,
                assignRoleMapping,
                getRole,
                getRoles,
                deleteRole,
                updateRole,
                validateCreateOrUpdateRoleForm,
                getGroupedPermissions,
            }}
        >
            {children}
        </RolesContextProvider>
    );
};

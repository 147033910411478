/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { ComponentType, FC, createContext } from 'react';
import { WebsocketController } from './WebsocketController';
import { DataOrError, ErrorResponse } from '../../types/errors';
import { WebsocketToken } from '../../types/websocket';

export interface WebsocketContext {
    getWebsocketToken(): Promise<DataOrError<WebsocketToken, ErrorResponse>>;
}

export const websocketContextDefaultValue: WebsocketContext = {
    getWebsocketToken: async () => [{ webSocketToken: 'token' }, null],
};

const websocketContextInstance = createContext<WebsocketContext | null>(websocketContextDefaultValue);

export const WebsocketContextProvider = websocketContextInstance.Provider;
export const WebsocketContextConsumer = websocketContextInstance.Consumer;

export const withWebsocketContext = <P extends object>(Component: ComponentType<P>): FC<Omit<P, keyof WebsocketContext>> => (props) => (
    <WebsocketController>
        <WebsocketContextConsumer>
            {(ctx) => <Component {...props as P} {...ctx} />}
        </WebsocketContextConsumer>
    </WebsocketController>
);

/*
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent, ReactElement } from 'react';

import { Checkbox } from './Checkbox';
import { EsignatureCommitment } from '../../types/esignature';

interface OwnProps {
    /**
     * List of commitments available
     */
    list: EsignatureCommitment[];
    /**
     * A warning message when no list is rendered
     */
    emptyMessage: string;
}

/**
 * Renders a list of electronic signature commitments.
 *
 * Mockups @see https://www.figma.com/file/nvWLkKo3SS0kIDZ58NWkFe/Web-App?type=design&node-id=1667-20614&mode=design&t=HCEgrscm5cLowDXh-0
 *
 * @param props {OwnProps}
 * @returns
 */
export const EsignatureCommitments: FunctionComponent<OwnProps> = (props: OwnProps): ReactElement => {
    const { list, emptyMessage } = props;
    
    return (
        <ol id="esignature-commitments" className="esignature-commitments" data-testid="esignature-commitments">
            {list.map((c) => (
                <li key={c.oid} id={c.oid} className="esignature-commitments__item">
                    <div className="esignature-commitments__item__header-container">
                        <Checkbox id={c.oid} label={c.commitmentName} name={c.commitmentType} testId={c.commitmentType} />
                        <p className="esignature-commitments__oid">OID {c.oid}</p>
                    </div>
                    <p className="esignature-commitments__description">{c.commitmentDescription}</p>
                </li>
            ))}
            {list.length === 0 && (
                <p className="esignature-commitments__empty">{emptyMessage}</p>
            )}
        </ol>

    );
};

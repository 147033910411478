/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import { WorkflowContext, withWorkflowContext } from '../../controllers/WorkflowContext';
import { WorkflowStateEnum, WorkflowStatePayload } from '../../../types/workflows';
import Button from '../Button';
import { ButtonVariant, MenuListOptions } from '../../../types/general';
import Modal from '../Modal';
import { FormField } from '../FormField';
import { ButtonWithMenuList } from '../ButtonWithMenuList';
import { AppRoute } from '../../../constants/routes';

type OwnProps = TranslationContext & WorkflowContext;

const ExtraActionsBase: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t,
        changeWorkflowState,
        deleteWorkflow,
    } = props;

    const { workflowId = '' } = useParams();
    const navigate = useNavigate();

    const [openCancelConfirmModal, setOpenCancelConfirmModal] = useState(false);
    const [cancelPayload, setCancelPayload] = useState<WorkflowStatePayload>({ state: WorkflowStateEnum.SELL_CANCELED, notes: '' });
    const [openRemoceConfirmModal, setOpenRemoveConfirmModal] = useState(false);

    const extraOptions = (): MenuListOptions[] => {
        const options: MenuListOptions[] = [
            {
                value: t('workflows.view.extraOptions.CANCEL'),
                action: () => { setOpenCancelConfirmModal(true); },
                testId: 'cancel-btn',
            },
            {
                value: t('workflows.view.extraOptions.REMOVE'),
                action: () => { setOpenRemoveConfirmModal(true); },
                testId: 'remove-btn',
            },
        ];

        return options;
    };

    /**
     * Start request to change this workflow state to canceled
     *
     * @returns
     */
    const handleCancelWorkflow = async () => {
        const requestError = await changeWorkflowState(workflowId, cancelPayload);

        if (requestError) {
            toast.error(requestError.errors[0].getMessageTranslated(t));
            return;
        }
        
        // Refresh screen
        navigate(0);
    };

    /**
     * Start request to remove this workflow
     *
     * @returns
     */
    const handleRemoveWorkflow = () => {
        deleteWorkflow(workflowId)
            .then((responseError) => {
                // If no error detected succeed
                if (responseError === undefined) {
                    toast.success(t('workflows.view.removeWorkflowSuccessMsg'));
                    navigate(AppRoute.Workflows);
                }
                toast.error(responseError?.errors[0].getMessageTranslated(t));
            });
    };

    return (
        <div className="workflow-screen--view__general-info__header__actions__extra-actions" data-testid="extra-actions-menu">
            <ButtonWithMenuList
                options={extraOptions()}
                data-testid="extra-options-btn"
            />
            <Modal
                title={t('workflows.view.cancelWorkflow')}
                open={openCancelConfirmModal}
                handleClose={() => setOpenCancelConfirmModal(false)}
                handleConfirm={handleCancelWorkflow}
            >
                <div className="workflow-screen--view__modal-text">{t('workflows.view.cancelWorkflowText')}</div>
                <FormField
                    name="note"
                    label={t('workflows.view.note')}
                    placeholder={t('workflows.view.notePlaceholder')}
                    onChange={(_, value) => setCancelPayload({ ...cancelPayload, notes: value })}
                />
            </Modal>
            <Modal
                title={t('workflows.view.removeWorkflow')}
                open={openRemoceConfirmModal}
                handleClose={() => setOpenRemoveConfirmModal(false)}
            >
                <p>{t('workflows.view.removeWorkflowText')}</p>
                <div className="workflow-screen--view__delete-btn-wrapper">
                    <Button
                        onClick={handleRemoveWorkflow}
                        variant={ButtonVariant.Curved}
                        testId="modal-confirm-btn"
                        extraClasses="danger"
                    >
                        {t('modalConfirm.confirm')}
                    </Button>
                </div>
            </Modal>
        </div>
    );
};

export const ExtraActions = withTranslationContext(withWorkflowContext(ExtraActionsBase));

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent, useState } from 'react';
import { ReactComponent as GlobeIcon } from '../../assets/images/globe.svg';
import { ButtonVariant } from '../../types/general';
import Button from './Button';
import { LanguageSelector } from './LanguageSelector';
import Modal from './Modal';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import { Tooltip } from './Tooltip';

type OwnProps = TranslationContext;

const LanguageAndCurrencyPreferencesComponent: FunctionComponent<OwnProps> = (props) => {
    const { t } = props;

    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <Button variant={ButtonVariant.IconBtn} onClick={() => setShowModal(true)}>
                <Tooltip title={t('languageAndCurrencyPreferences.title')} placement="bottom">
                    <GlobeIcon />
                </Tooltip>
            </Button>
            <Modal
                open={showModal}
                handleClose={() => setShowModal(false)}
                title={t('languageAndCurrencyPreferences.title')}
            >
                <LanguageSelector />
            </Modal>
        </>
    );
};

export const LanguageAndCurrencyPreferences = withTranslationContext(LanguageAndCurrencyPreferencesComponent);

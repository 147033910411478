/*
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { get } from 'lodash';
import { AppLanguage } from '../types/preferences';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const getString = (data: Record<string, any>, key: string): string => {
    const keyParts = key.split('.');
    let currentPosition = data;
    keyParts.forEach((k, i) => {
        if (currentPosition) {
            currentPosition = currentPosition[keyParts[i]];
        }
    });

    if (currentPosition) return String(currentPosition);
    return key;
};

const getModifier = (text: string) => {
    const reversedText = text.split('').reverse().join('');
    const indexOfModified = reversedText.split('}')[0].indexOf('%');
    return indexOfModified > 0 ? reversedText.substring(0, indexOfModified + 1).split('').reverse().join('') : '';
};

export const interpolateString = (text: string, params?: Record<string, unknown>): string => {
    if (!params) {
        return text;
    }

    let parsedText = text;
    const keys = Array.from(text.matchAll(/{([^{}]+)}/g));

    keys.forEach((key) => {
        const [keyWithMarkers, innerText] = key;

        const modifier = getModifier(text.substring(0, key.index));
        const value = get(params, innerText.trim(), innerText);

        parsedText = parsedText.replace(keyWithMarkers, value + modifier);
    });

    return parsedText;
};

export const getTranslation = (translations: Record<string, object>, language: AppLanguage, key: string, params?: Record<string, unknown>): string => {
    let out = key;

    const languageStrings = translations[language];

    if (languageStrings) {
        const keyString = getString(languageStrings, key);

        if (keyString) {
            out = interpolateString(keyString, params);
        }
    }

    return out;
};

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { ContractsContext, withContractsContext } from '../../controllers/ContractsContext';
import { OrganizationsContext, withOrganizationsContext } from '../../controllers/OrganizationsContext';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';

import { AppRoute } from '../../../constants/routes';
import { ReactComponent as ArrowRightIcon } from '../../../assets/images/arrow-right.svg';
import Button from '../Button';
import { ButtonVariant } from '../../../types/general';
import { ReactComponent as DraftIcon } from '../../../assets/images/draft.svg';
import { buildUrl } from '../../../utils/navigation';
import { useContractContext } from './ContractContextProvider';

type OwnProps = TranslationContext & ContractsContext & OrganizationsContext;

const DraftContractActionBarComponent: FunctionComponent<OwnProps> = (props) => {
    const {
        t,
        organizationSelected,
    } = props;

    const {
        contract,
    } = useContractContext();

    const navigate = useNavigate();

    return (
        <>
            <div className="contract-screen__bottom-actions" data-testid="draft-contract-action-bar">
                <Button
                    title={t('contractCreate.saveDraft')}
                    variant={ButtonVariant.Curved}
                    extraClasses="secondary"
                    startIcon={<DraftIcon className="no-style" />}
                    testId="draft-btn"
                    onClick={() => (organizationSelected
                        ? navigate(AppRoute.OrganizationContracts)
                        : navigate(AppRoute.Contracts))}
                >
                    {t('contractCreate.saveDraft')}
                </Button>
                <Button
                    title={t('contractCreate.submit')}
                    variant={ButtonVariant.Curved}
                    extraClasses="primary"
                    onClick={() => navigate(buildUrl(AppRoute.ContractCheckout, { contractId: String(contract?.id) }))}
                    endIcon={<ArrowRightIcon />}
                    testId="submit-btn"
                >
                    {t('contractCreate.submit')}
                </Button>
            </div>
        </>
    );
};

export const DraftContractActionBar = withOrganizationsContext(withTranslationContext(withContractsContext(DraftContractActionBarComponent)));

/*
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { ThemeOptions } from '@mui/material/styles';

export const materialThemeOptions: ThemeOptions = {
    typography: {
        fontFamily: 'Aeonik Fono',
        fontSize: 14,
    },
    palette: {
        mode: 'light',
        primary: {
            main: '#6570E7',
        },
        secondary: {
            main: '#3A3B45',
            light: '#D9D9D9',
        },
        text: {
            primary: '#000000',
        },
        error: {
            main: '#f44336',
        },
        success: {
            main: '#008000',
        },
        warning: {
            main: '#ffc440',
        },
        info: {
            main: '#6570E7',
        },
        divider: '#6570E7',
    },
    components: {
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgba(255, 255, 255, 0.75)',
                    zIndex: 50,
                },
            },
        },
        MuiModal: {
            styleOverrides: {
                backdrop: {
                    backgroundColor: 'rgba(0, 0, 0, 0.25)',
                },
            },
        },
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    backgroundColor: '#e7e7e7',
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: '12px',
                    boxShadow: '0px 3px 6px 2px rgba(0, 0, 0, 0.1)',
                    padding: '0 5px',
                    color: '#3A3B45',
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    fontSize: '14px',
                    borderRadius: '8px',
                    boxShadow: 'none',
                    color: '#3a3b45',
                    marginBottom: '15px',
                },
                standardWarning: {
                    backgroundColor: '#f4d550',
                },
                standardInfo: {
                    backgroundColor: '#f6f6f6',
                },
                icon: {
                    color: '#3a3b45',
                },
            },
        },
        MuiDrawer: {
            styleOverrides: {
                modal: {
                    backdrop: {
                        root: {
                            backgroundColor: 'rgba(0, 0, 0, 0.25)',
                        },
                    },
                },
                paper: {
                    borderRadius: '12px 12px 0 0',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    fontSize: 14,
                },
                startIcon: {
                    margin: 0,
                },
                endIcon: {
                    margin: 0,
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    borderColor: '#E7E7E7',
                },
                root: {
                    color: '#3a3b45',
                },
            },
        },
    },
};

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { Link, useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import { FunctionComponent } from 'react';
import classNames from 'classnames';
import { BillingContext, withBillingContext } from '../controllers/BillingContext';
import { OrganizationsContext, withOrganizationsContext } from '../controllers/OrganizationsContext';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';

import { AppRoute } from '../../constants/routes';
import { ReactComponent as BookIcon } from '../../assets/images/book.svg';
import Button from './Button';
import { ButtonVariant } from '../../types/general';
import { ReactComponent as CogIcon } from '../../assets/images/cog.svg';
import { ReactComponent as LogoutIcon } from '../../assets/images/exit.svg';
import { ReactComponent as PlusIcon } from '../../assets/images/plus-icon.svg';
import { ReactComponent as WalletIcon } from '../../assets/images/wallet.svg';
import { ReactComponent as QuestionMarkIcon } from '../../assets/images/question-mark.svg';
import { ReactComponent as UserIcon } from '../../assets/images/user.svg';
import organizationPlaceholder from '../../assets/images/organization_avatar_placeholder.png';
import { useAuthContext } from '../controllers/AuthenticationContext';
import { Tooltip } from './Tooltip';

interface OwnProps extends TranslationContext, OrganizationsContext, BillingContext {
    isOpen: boolean;
    onClose: () => void;
}

const UserProfileSideBarComponent: FunctionComponent<OwnProps> = (props) => {
    const {
        t,
        isOpen,
        onClose,
        organizationSelected,
        userWallet,
        organizationWallet,
    } = props;

    const { user, requestLogout } = useAuthContext();
    const navigate = useNavigate();

    return (
        <div
            className={classNames('user-profile-side-bar',
                { visible: isOpen === true })}
            data-testid="user-profile-side-bar"
        >
            <div className="user-info">
                <div className="icon-title-description">
                    <div className="icon-title-description__icons">
                        <Avatar alt="user avatar">
                            <UserIcon />
                        </Avatar>
                        <Avatar
                            alt={`${organizationSelected?.organization.name} avatar`}
                            src={organizationSelected?.organization?.logoUrl ?? organizationPlaceholder}
                            className="organization-avatar"
                        />
                    </div>
                    <div className="icon-title-description__info">
                        <h2>{user?.fullName}</h2>
                        <p>{user?.email}</p>
                    </div>
                </div>
                {userWallet && (
                    <div className="wallet-info">
                        <div>
                            <p>{t('userProfile.personalWallet')}</p>
                            <span className="amount">
                                {userWallet.creditsBalance.amount} {t('userProfile.credits')}
                            </span>
                        </div>
                        <div className="wallet-info__buttons">
                            <Button
                                testId="purchase-credits-organization"
                                variant={ButtonVariant.IconBtn}
                                extraClasses="secondary"
                                onClick={() => {
                                    navigate(AppRoute.AccountWallet);
                                    onClose();
                                }}
                            >
                                <Tooltip
                                    title={t('userProfile.userWalletTooltip')}
                                    placement="bottom"
                                >
                                    <WalletIcon />
                                </Tooltip>
                            </Button>
                            <Button
                                testId="purchase-credits-organization"
                                variant={ButtonVariant.IconBtn}
                                extraClasses="primary"
                                onClick={() => {
                                    navigate(AppRoute.PurchaseCredits);
                                    onClose();
                                }}
                            >
                                <Tooltip
                                    title={t('userProfile.userWalletTopUpTooltip')}
                                    placement="bottom"
                                >
                                    <PlusIcon />
                                </Tooltip>
                            </Button>
                        </div>
                    </div>
                )}
            </div>
            {organizationSelected && (
                <div className="organization-info">
                    <div className="icon-title-description organization-card">
                        <div className="icon-title-description__icons">
                            <Avatar
                                alt={`${organizationSelected?.organization.name} avatar`}
                                src={organizationSelected?.organization?.logoUrl ?? organizationPlaceholder}
                            />
                        </div>
                        <div className="icon-title-description__info">
                            <h2>{organizationSelected.organization.name}</h2>
                            <p>{organizationSelected.jobTitle}</p>
                        </div>
                    </div>
                    <Button onClick={onClose}>
                        <Link to={AppRoute.OrganizationChange}>{t('userProfile.selectOrganization')}</Link>
                    </Button>
                    {organizationWallet && (
                        <div className="wallet-info">
                            <div>
                                <p>{t('userProfile.organizationWallet')}</p>
                                <span className="amount">
                                    {organizationWallet.creditsBalance.amount} {t('userProfile.credits')}
                                </span>
                            </div>
                            <div className="wallet-info__buttons">
                                <Button
                                    testId="purchase-credits-organization"
                                    variant={ButtonVariant.IconBtn}
                                    extraClasses="secondary"
                                    onClick={() => {
                                        navigate(AppRoute.OrganizationBillingDashboard);
                                        onClose();
                                    }}
                                >
                                    <Tooltip
                                        title={t('userProfile.organizationWalletTooltip', {
                                            name: organizationSelected.organization.name,
                                        })}
                                        placement="bottom"
                                    >
                                        <WalletIcon />
                                    </Tooltip>
                                </Button>
                                <Button
                                    testId="purchase-credits-organization"
                                    variant={ButtonVariant.IconBtn}
                                    extraClasses="primary"
                                    onClick={() => navigate(AppRoute.OrganizationPurchaseCredits)}
                                >
                                    <Tooltip
                                        title={t('userProfile.organizationWalletTopUpTooltip', {
                                            name: organizationSelected.organization.name,
                                        })}
                                        placement="bottom"
                                    >
                                        <PlusIcon />
                                    </Tooltip>
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            )}
            <div className="useful-links">
                <Tooltip title={t('userProfile.accountSettings')} placement="bottom">
                    <Link to={AppRoute.AccountWallet} onClick={onClose}>
                        <CogIcon />{t('userProfile.accountSettings')}
                    </Link>
                </Tooltip>
                {organizationSelected && (
                    <Tooltip title={t('userProfile.organizationSettings')} placement="bottom">
                        <Link to={AppRoute.OrganizationBillingDashboard} onClick={onClose}>
                            <CogIcon />{t('userProfile.organizationSettings')}
                        </Link>
                    </Tooltip>
                )}
                <Tooltip title={t('userProfile.helpAndSupport')} placement="bottom">
                    <Link to={AppRoute.NewSupportTicket} onClick={onClose}>
                        <QuestionMarkIcon />{t('userProfile.helpAndSupport')}
                    </Link>
                </Tooltip>
                <Tooltip title={t('userProfile.termsAndConditions')} placement="bottom">
                    <Link to={AppRoute.TermsAndConditions} onClick={onClose}>
                        <BookIcon />{t('userProfile.termsAndConditions')}
                    </Link>
                </Tooltip>
                <Tooltip title={t('userProfile.privacyPolicy')} placement="bottom">
                    <Link to={AppRoute.PrivacyPolicy} onClick={onClose}>
                        <BookIcon />{t('userProfile.privacyPolicy')}
                    </Link>
                </Tooltip>
            </div>
            <footer>
                <Tooltip title={t('userProfile.logout')} placement="bottom">
                    <button
                        type="button"
                        onClick={requestLogout}
                    >
                        <LogoutIcon />
                        {t('userProfile.logout')}
                    </button>
                </Tooltip>
            </footer>
        </div>
    );
};

export const UserProfileSideBar = withBillingContext(withTranslationContext(withOrganizationsContext(UserProfileSideBarComponent)));

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import React, { FunctionComponent } from 'react';
import axios from 'axios';
import { Contract, Signer } from '../../types/contracts';
import { SignerInvitationContext, SignerInvitationContextProvider } from './SignerInvitationContext';
import { TranslationContext, withTranslationContext } from './TranslationContext';
import { assignUserToSignerUrl, signerContractUrl } from '../../services/contracts';

import { API_URL } from '../../settings';
import { DataOrError } from '../../types/errors';
import { buildUrl } from '../../utils/navigation';
import { httpErrorHandler } from '../../utils/error';
import { useCornerstoneApi } from '../../api';

interface OwnProps {
    children: React.ReactNode;
}

type Props = OwnProps & TranslationContext;

const SignerInvitationControllerBase: FunctionComponent<Props> = (props: Props) => {
    const { children } = props;
    const CornerstoneAPI = useCornerstoneApi();

    /**
     * Loads contract information for the signer based on the signer token
     */
    const loadContractInformation: SignerInvitationContext['loadContractInformation'] = (signerToken) => {
        return new Promise<DataOrError<Contract>>((resolve) => {
            axios.get<Contract>(buildUrl(`${API_URL}${signerContractUrl(signerToken)}`, {})).then(({ data }) => {
                resolve([data, null]);
            }).catch((e) => {
                const functionalErrors = httpErrorHandler(e);
                resolve([null, functionalErrors.errors?.[0]]);
            });
        });
    };

    /**
     * Calls API to attempt assigning the current user to the signer.
     * Returns error if user cannot be assigned.
     *
     * @param signerToken
     * @returns
     */
    const tryAssigningUserToSigner: SignerInvitationContext['tryAssigningUserToSigner'] = (signerToken: string) => {
        return new Promise<DataOrError<Signer>>((resolve) => {
            CornerstoneAPI.put<Signer>(assignUserToSignerUrl(signerToken)).then(({ data }) => {
                resolve([data, null]);
            }).catch((e) => {
                const errors = httpErrorHandler(e);
                resolve([null, errors?.errors?.[0]]);
            });
        });
    };

    /**
     * Validates a PIN sent by email to confirm the email ownership of the signer invited.
     * Returns error if PIN is incorrect.
     */
    const validatePIN: SignerInvitationContext['validatePIN'] = (signerToken, payload) => {
        return new Promise<DataOrError<Contract>>((resolve) => {
            CornerstoneAPI.post<Contract>(assignUserToSignerUrl(signerToken), payload).then(({ data }) => {
                resolve([data, null]);
            }).catch((e) => {
                const errors = httpErrorHandler(e);
                resolve([null, errors?.errors?.[0]]);
            });
        });
    };
    return (
        <SignerInvitationContextProvider
            value={{
                loadContractInformation,
                tryAssigningUserToSigner,
                validatePIN,
            }}
        >
            {children}
        </SignerInvitationContextProvider>
    );
};

export const SignerInvitationController = withTranslationContext(SignerInvitationControllerBase);

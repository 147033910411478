/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

export const preparePageTitle = (pageTitle: string): string => {
    return `${pageTitle} | Unlockit`;
};

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent } from 'react';
import Button from '../../Button';
import { ReactComponent as DownloadIcon } from '../../../../assets/images/download.svg';
import { ButtonVariant } from '../../../../types/general';

interface OwnProps {
    fileUrl: string;
    fileName?: string;
    testId?: string;
}

export const DownloadOption: FunctionComponent<OwnProps> = (props) => {
    const { fileUrl, fileName, testId } = props;

    const handleClick = () => {
        const link = document.createElement('a');
        
        link.href = fileUrl;

        const name = fileName ?? 'contract-file';

        link.download = name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <Button
            variant={ButtonVariant.IconBtn}
            extraClasses="primary"
            onClick={handleClick}
            testId={testId}
        >
            <DownloadIcon />
        </Button>
    );
};

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FunctionComponent,
    Suspense,
    useEffect,
    useState,
} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { BillingContext, withBillingContext } from '../../controllers/BillingContext';
import { ContractsContext, withContractsContext } from '../../controllers/ContractsContext';
import {
    Order,
    PlanFeatureType,
    PurchaseItem,
    PurchaseItemTranslated,
} from '../../../types/billing';
import { OrganizationsContext, withOrganizationsContext } from '../../controllers/OrganizationsContext';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';

import { Alert } from '../../elements/Alert';
import { AppRoute } from '../../../constants/routes';
import { ReactComponent as BackIcon } from '../../../assets/images/arrow-left.svg';
import { Button } from '../../elements/Button';
import { ButtonVariant } from '../../../types/general';
import { ErrorResponse } from '../../../types/errors';
import { FormSelectField } from '../../elements/FormSelectField';
import HasPermission from '../../elements/HasPermission';
import { LoadingScreen } from '../LoadingScreen';
import { Permissions } from '../../../types/permissions';
import { ResourceType } from '../../../types/workflows';
import { buildUrl } from '../../../utils/navigation';

type OwnProps = ContractsContext & TranslationContext & BillingContext & OrganizationsContext;

const ContractCheckout: FunctionComponent<OwnProps> = (props) => {
    const {
        t,
        userWallet,
        organizationWallet,
        organizationSelected,
        purchaseContract,
        simulateOrder,
    } = props;

    const { contractId = '' } = useParams();

    const navigate = useNavigate();
    const location = useLocation();

    const [isLoading, setLoading] = useState<boolean>(false);
    const [orderError, setOrderError] = useState<null | ErrorResponse>(null);
    const [submitContractCost, setSubmitContractCost] = useState(0);
    const [items, setItems] = useState<PurchaseItemTranslated[]>([]);
    const [selectedWalletId, setSelectedWalletId] = useState(organizationWallet?.id ?? userWallet?.id);
    const [orderData, setOrderData] = useState<Order | null>(null);
    const selectedWallet = userWallet?.id === selectedWalletId ? userWallet : organizationWallet;
    const walletHasCredits = (selectedWallet?.creditsBalance.amount ?? 0) > (orderData?.totalCost.amount ?? 0);
    const [topUpWalletUrl, setTopUpWalletUrl] = useState<string>(userWallet?.id === selectedWalletId ? AppRoute.PurchaseCredits : AppRoute.OrganizationPurchaseCredits);

    const walletOptions = [
        {
            value: organizationWallet?.id,
            label: t('workflowQuotaLimitExceeded.organizationWallet', { organizationName: organizationSelected?.organization?.name }),
        },
        {
            value: userWallet?.id,
            label: t('workflowQuotaLimitExceeded.personalWallet'),
        },
    ];
    
    useEffect(() => {
        if (organizationWallet) {
            simulateContractOrder(organizationWallet.id);
        }
    }, [organizationWallet]);

    useEffect(() => {
        if (orderError) throw orderError;
    }, [orderError]);

    /**
     * Verify if subscribed plan was enough quota
     *
     * @remarks
     * Simulate order to verify if there is enough quota to open new workflow
     * if so, call prepareScreen() if not, ask for the payment
     */
    const simulateContractOrder = async (walletId: number) => {
        setLoading(true);
        const [_orderData, requestError]: [Order | null, ErrorResponse | null] = await simulateOrder({
            walletContextId: walletId,
            resourceType: ResourceType.CONTRACT,
            assetId: Number(contractId),
        });
        setLoading(false);
        setOrderData(_orderData);

        if (requestError) {
            setOrderError(requestError);
        }
        if (_orderData) {
            translatePurchaseItems(_orderData.items);
            if (_orderData.totalCost.amount > 0) {
                setSubmitContractCost(_orderData.totalCost.amount);
            }
        }
    };

    /**
     * Set Contract as ready to sign
     *
     * @remarks
     * Is all the fields is validated, and user clicks on "Submit" btn
     * the contract can be set as ready to sign
     */
    const submitContract = async () => {
        const [, error] = await purchaseContract(contractId, {
            walletForPaymentId: selectedWalletId,
        });
        
        if (error) {
            onFailure(error.errors?.[0]?.getMessageTranslated(t));
            return;
        }
        onSubmitSuccess();
    };
    
    /**
     * onSubmitSuccess callback function
     *
     * @remarks
     * Navigates the user to the contract visualization and edit page
     * after successful form submission.
     */
    const onSubmitSuccess = () => {
        navigate(buildUrl(AppRoute.ShowContract, {
            contractId,
        }), { state: { from: location.state?.from } });
    };
    /**
     * onFailure calback function
     *
     * @remarks
     * Show toast with generic failure message
     */
    const onFailure = (errorMessage: string = t('errors.general')) => {
        toast.error(errorMessage);
    };

    const translatePurchaseItems = (purchaseItems: PurchaseItem[]) => {
        const translatedItems: { [key: string]: PurchaseItemTranslated } = {};
    
        purchaseItems.forEach((item) => {
            const key = `${item.featureType}-${item.settlementType}`;
    
            if (translatedItems[key]) {
                translatedItems[key].quantity += 1;
                translatedItems[key].cost.amount += item.cost.amount;
            } else {
                translatedItems[key] = {
                    quantity: 1,
                    featureType: item.featureType,
                    settlementType: item.settlementType,
                    cost: item.cost,
                };
            }
        });
    
        setItems(Object.values(translatedItems));
    };
    
    const printItemCost = (item: PurchaseItemTranslated) => {
        if (item.cost.amount > 0) {
            return `${item.cost.amount} ${t('contractCheckout.credits')}`;
        }
        
        return t('contractCheckout.included');
    };
    
    const printItem = (item: PurchaseItemTranslated) => {
        switch (item.featureType) {
            case PlanFeatureType.CMD_SIGNATURE:
            case PlanFeatureType.QES_SIGNATURE:
            case PlanFeatureType.AES_SIGNATURE:
                return `${item.quantity} ${t('contractCheckout.signatures')} ${t(`contractCheckout.${item.featureType}`)}`;
            default:
                return t(`contractCheckout.${item.featureType}`);
        }
    };
    const selectWallet = (walletId: number | undefined) => {
        setSelectedWalletId(walletId);
        setTopUpWalletUrl(userWallet?.id === walletId ? AppRoute.PurchaseCredits : AppRoute.OrganizationPurchaseCredits);
    };
    return (
        <Suspense fallback={<LoadingScreen />}>
            <HasPermission permissions={[Permissions.MANAGE_CONTRACT, Permissions.MANAGE_ALL_ORGANIZATION_CONTRACTS]}>
                <div className="submit-contract-summary-screen">
                    {isLoading && (<LoadingScreen />)}
                    {!isLoading && (
                        <>
                            <div className="quota-limit-exceeded-message__header">
                                <Button
                                    onClick={() => navigate(buildUrl(AppRoute.DraftContract, { contractId }))}
                                    testId="back-btn"
                                >
                                    <BackIcon /> {t('general.goBack')}
                                </Button>
                                <h1>{t('contractCheckout.confirmSubmitTitle')}</h1>
                            </div>
                            {!walletHasCredits && (
                                <FormSelectField
                                    name="walletId"
                                    label={t('contractCheckout.selectWallet')}
                                    value={selectedWalletId}
                                    options={walletOptions}
                                    onChange={(_, value) => { selectWallet(Number(value)); }}
                                />
                            )}
                            <ol className="submit-contract-summary-screen__billing">
                                <li className="submit-contract-summary-screen__billing__header">
                                    {t('contractCheckout.willUse')}
                                </li>
                                {items && items.map((item) => {
                                    return (
                                        <li key={`${item.featureType}-${item.settlementType}`}>
                                            <span>{printItem(item)}</span>
                                            <span>{printItemCost(item)}</span>
                                        </li>
                                    );
                                })}
                                <li className="submit-contract-summary-screen__billing__total">
                                    <span>{t('contractCheckout.total')}</span>
                                    <span>{`${submitContractCost} ${t('contractCheckout.credits')}`}</span>
                                </li>
                            </ol>
                            {!walletHasCredits && (
                                <>
                                    <Alert
                                        message={t('contractCheckout.noCredits')}
                                        button={(
                                            <Button
                                                onClick={() => navigate(topUpWalletUrl)}
                                                disabled={!topUpWalletUrl}
                                                testId="purchase-credits"
                                            >{t('contractCheckout.addCreditsBtn')}
                                            </Button>
                                        )}
                                    />
                                </>
                            )}
                            {walletHasCredits && (
                                <>
                                    <Alert
                                        variant="info"
                                        message={t('contractCheckout.information')}
                                    />
                                </>
                            )}
                            <div className="btn-wrapper">
                                <Button
                                    onClick={submitContract}
                                    variant={ButtonVariant.Curved}
                                    extraClasses="primary"
                                    testId="confirm-btn"
                                >
                                    {t('contractCheckout.confirmBtn')}
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            </HasPermission>
        </Suspense>
    );
};

export const ContractCheckoutScreen = withTranslationContext(withContractsContext(withBillingContext(withOrganizationsContext(ContractCheckout))));

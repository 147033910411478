/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent, useState } from 'react';
import { toast } from 'react-toastify';
import { useContractContext } from './ContractContextProvider';
import { ReactComponent as CloseIcon } from '../../../assets/images/closeBtn.svg';
import { ReactComponent as CheckIcon } from '../../../assets/images/checkmark.svg';
import { ReactComponent as PersonIcon } from '../../../assets/images/profile.svg';
import Modal from '../Modal';
import Button from '../Button';
import { ButtonVariant } from '../../../types/general';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import { Tooltip } from '../Tooltip';

type OwnProps = TranslationContext;

const SignerActionBarComponent: FunctionComponent<OwnProps> = (props) => {
    const { t } = props;

    const { pendingPlaceholder, addNewPlaceholder, prepareToRemoveSigner } = useContractContext();

    const [isModalOpen, setIsModalOpen] = useState(false);

    if (!pendingPlaceholder) return null;

    const removePlaceholder = () => {
        prepareToRemoveSigner(pendingPlaceholder.signer.id, () => { }, () => { });
    };

    const failToSubmitPlaceholder = (message: string) => {
        toast.error(message);
    };

    return (
        <>
            <div className="signer-action-bar">
                <button className="signer-action-bar__cancel" type="button" data-testid="modal-close-btn" onClick={() => setIsModalOpen(true)}>
                    <Tooltip title={t('signerActions.tooltips.cancelPlaceholder')} placement="top">
                        <CloseIcon />
                    </Tooltip>
                </button>
                <div className="signer-action-bar__name-container">
                    <PersonIcon />
                    <h2>{pendingPlaceholder.signer.name}</h2>
                </div>
                <button className="signer-action-bar__success" type="button" data-testid="modal-close-btn" onClick={() => addNewPlaceholder(pendingPlaceholder, failToSubmitPlaceholder)}>
                    <Tooltip title={t('signerActions.tooltips.confirmPlaceholder')} placement="top">
                        <CheckIcon />
                    </Tooltip>
                </button>
            </div>
            <Modal
                open={isModalOpen}
                title={t('signerActions.warningTitle')}
                handleClose={() => setIsModalOpen(false)}
            >
                <p className="primary font-xl">{t('signerActions.confirmText')}</p>
                <p>{t('signerActions.warningDescription')}</p>
                <div className="buttons">
                    <Button
                        onClick={() => setIsModalOpen(false)}
                        variant={ButtonVariant.Curved}
                        extraClasses="secondary"
                        testId="cancel-btn"
                    >
                        {t('modalConfirm.cancel')}
                    </Button>
                    <Button
                        onClick={removePlaceholder}
                        variant={ButtonVariant.Curved}
                        extraClasses="primary"
                        testId="confirm-btn"
                    >
                        {t('signerActions.confirmText')}
                    </Button>
                </div>
            </Modal>
        </>
    );
};

export const SignerActionBar = withTranslationContext(SignerActionBarComponent);

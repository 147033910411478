/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import React, { FunctionComponent } from 'react';
import { ErrorResponse } from '../../types/errors';
import { FormValidatorError, validateForm } from '../../utils/validations';
import { KeyedObject } from '../../types/general';
import { MembersContext, MembersContextProvider } from './MembersContext';
import { Role, RolesMemberPayload } from '../../types/roles';
import { TranslationContext, withTranslationContext } from './TranslationContext';
import {
    inviteAcceptUrl,
    inviteRejectUrl,
    inviteUrl,
    invitesUrl,
    memberRolesUrl,
    membersUrl,
    organizationAccessMemberUrl,
    organizationInvitesUrl,
} from '../../services/members';

import {
    InvitationPayload,
} from '../../types/members';
import { useCornerstoneApi } from '../../api';
import { validations } from '../../constants/validations';

interface OwnProps {
    children: React.ReactNode;
}

type Props = OwnProps & TranslationContext;

const MembersController: FunctionComponent<Props> = (props: Props) => {
    const { children } = props;
    const CornerstoneApiInstance = useCornerstoneApi();

    const getMembers: MembersContext['getMembers'] = async (organizationId, cursor = '') => {
        try {
            const params = {
                _cursor: cursor,
            };

            const { data } = await CornerstoneApiInstance.get(membersUrl(organizationId, params));

            return {
                cursor: data.cursor,
                results: data.results,
            };
        } catch {
            return {
                cursor: '',
                results: [],
            };
        }
    };

    const getInvites: MembersContext['getInvites'] = async (organizationId, params) => {
        try {
            const { data } = await CornerstoneApiInstance.get(organizationInvitesUrl(organizationId, params));

            return {
                cursor: data.cursor,
                results: data.results,
            };
        } catch {
            return {
                cursor: '',
                results: [],
            };
        }
    };

    const getInvite: MembersContext['getInvite'] = async (inviteToken) => {
        try {
            const { data } = await CornerstoneApiInstance.get(inviteUrl(inviteToken));
            return data;
        } catch {
            return null;
        }
    };

    const deleteMember: MembersContext['deleteMember'] = async (memberId: number) => {
        try {
            await CornerstoneApiInstance.delete(organizationAccessMemberUrl(memberId));
        } catch (e) {
            return e as ErrorResponse;
        }
    };

    const sendMemberInvite: MembersContext['sendMemberInvite'] = async (payload: InvitationPayload) => {
        try {
            await CornerstoneApiInstance.post(invitesUrl(), payload);
        } catch (e) {
            return e as ErrorResponse;
        }
    };

    const validateInvitationForm: MembersContext['validateInvitationForm'] = (fields) => {
        const errors: KeyedObject<FormValidatorError[]> | null = validateForm(fields, validations.invitationCreate);

        if (!errors || Object.keys(errors).length === 0) return null;
        return { fields: errors };
    };

    const assignRoleToMember: MembersContext['assignRoleToMember'] = async (memberId: number, payload: RolesMemberPayload) => {
        try {
            await CornerstoneApiInstance.put(memberRolesUrl(memberId), payload);
        } catch (e) {
            return e as ErrorResponse;
        }
    };

    const acceptInvite: MembersContext['acceptInvite'] = async (inviteToken: string) => {
        try {
            await CornerstoneApiInstance.put(inviteAcceptUrl(inviteToken));
        } catch (e) {
            return e as ErrorResponse;
        }
    };

    const rejectInvite: MembersContext['rejectInvite'] = async (inviteToken: string) => {
        try {
            await CornerstoneApiInstance.put(inviteRejectUrl(inviteToken));
        } catch (e) {
            return e as ErrorResponse;
        }
    };

    const loadMemberRoles: MembersContext['loadMemberRoles'] = async (memberId) => {
        return new Promise((resolve) => {
            CornerstoneApiInstance.get<Array<Role>>(memberRolesUrl(memberId)).then((res) => {
                resolve([res.data, null]);
            }).catch((e: ErrorResponse) => {
                resolve([null, e]);
            });
        });
    };

    return (
        <MembersContextProvider
            value={{
                getMembers,
                getInvites,
                getInvite,
                deleteMember,
                sendMemberInvite,
                validateInvitationForm,
                assignRoleToMember,
                acceptInvite,
                rejectInvite,
                loadMemberRoles,
            }}
        >
            {children}
        </MembersContextProvider>
    );
};

export default withTranslationContext(MembersController);

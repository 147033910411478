/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FunctionComponent,
    useRef,
} from 'react';

import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { ReactComponent as InnovatorsLogo } from '../../assets/images/innovators-club-logo.svg';
import { referralLink } from '../../constants/billing';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import Button from './Button';
import { ButtonVariant } from '../../types/general';

interface BaseProps extends TranslationContext {
}

export const InnovatorsClubSectionBase: FunctionComponent<BaseProps> = (props) => {
    const {
        t,
    } = props;

    const anchorRef = useRef<HTMLAnchorElement | null>(null);

    const handleButtonClick = () => {
        anchorRef.current?.click();
    };

    return (
        <div className="innovators-club-section" data-testid="innovators-club-section">
            <Grid container spacing={2}>
                <Grid item md={4} className="image-wrapper">
                    <InnovatorsLogo />
                </Grid>
                <Grid item md={8}>
                    <h2>{t('highlightSection.referral.title')}</h2>
                    <p>{t('highlightSection.referral.description')}</p>
                    <div>
                        <Button variant={ButtonVariant.Curved} testId="innovators-club-button" onClick={handleButtonClick}>
                            <Link to={referralLink} ref={anchorRef} target="_blank">
                                {t('highlightSection.referral.primaryBtnText')}
                            </Link>
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export const InnovatorsClubSection = withTranslationContext(InnovatorsClubSectionBase);

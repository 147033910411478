/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FormEvent, FunctionComponent, useEffect, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import { DefaultLayout } from '../../elements/layouts/DefaultLayout';
import { BillingContext, withBillingContext } from '../../controllers/BillingContext';
import { AppRoute } from '../../../constants/routes';
import { CartSummary } from '../../elements/billing/CartSummary';
import Button from '../../elements/Button';
import { ButtonVariant, KeyedObject } from '../../../types/general';
import { BillingInformationForm } from '../../elements/billing/BillingInformationForm';
import { FormValidationError, FormValidatorError, validateForm } from '../../../utils/validations';
import { validations } from '../../../constants/validations';
import { WalletUpdatePayload } from '../../../types/billing';

interface OwnProps extends TranslationContext, BillingContext { }

const PurchaseCreditsBillingInformationScreenComponent: FunctionComponent<OwnProps> = (props) => {
    const {
        t, billingInfo, updateBillingInformation, setWalletInfo,
    } = props;

    const navigate = useNavigate();

    const [personErrorsForm, setPersonErrorsForm] = useState<FormValidationError | null>(null);
    const [fiscalErrorsForm, setFiscalErrorsForm] = useState<FormValidationError | null>(null);
    const [addressErrorsForm, setAddressErrorsForm] = useState<FormValidationError | null>(null);

    useEffect(() => {
        if (!billingInfo.wallet || billingInfo.credits === 0 || !billingInfo.basket || billingInfo.basket.credits?.amount === 0) {
            navigate(AppRoute.OrganizationPurchaseCredits);
        }
    }, []);

    const renderGoBackButton = (
        <Button
            variant={ButtonVariant.Curved}
            extraClasses="secondary slim"
            onClick={() => navigate(-1)}
        >
            {t('purchaseCredits.goBack')}
        </Button>
    );

    const renderNextStepButton = (
        <Button
            variant={ButtonVariant.Curved}
            extraClasses="primary slim"
            isSubmit
        >
            {t('purchaseCredits.nextStep')}
        </Button>
    );

    const onFormSubmit = async (e: FormEvent) => {
        e.preventDefault();

        if (billingInfo.wallet === null) return;

        const formData = new FormData(e.target as HTMLFormElement);
        const contactablePersonPayload = {
            firstName: String(formData.get('firstName')),
            lastName: String(formData.get('lastName')),
            email: String(formData.get('email')),
            phoneNumber: String(formData.get('phoneNumber')),
        };
        const fiscalInfoPayload = {
            fiscalNumber: String(formData.get('fiscalNumber')),
            fiscalName: String(formData.get('fiscalName')),
        };
        const addressPayload = {
            addressLine1: String(formData.get('addressLine1')),
            addressLine2: String(formData.get('addressLine2')),
            postalCode: String(formData.get('postalCode')),
            city: String(formData.get('city')),
            state: String(formData.get('state')),
            country: String(formData.get('country')),
        };

        const errorsPerson: KeyedObject<FormValidatorError[]> | null = validateForm(contactablePersonPayload, validations.contactablePersonValidationsUpdate);
        const errorsFiscal: KeyedObject<FormValidatorError[]> | null = validateForm(fiscalInfoPayload, validations.fiscalInfoValidationsUpdate);
        const errorsAddress: KeyedObject<FormValidatorError[]> | null = validateForm(addressPayload, validations.addressValidationsUpdate);
        let hasErrors = false;

        if (errorsPerson && Object.keys(errorsPerson).length > 0) {
            setPersonErrorsForm({ fields: errorsPerson });
            hasErrors = true;
        }

        if (errorsFiscal && Object.keys(errorsFiscal).length > 0) {
            setFiscalErrorsForm({ fields: errorsFiscal });
            hasErrors = true;
        }

        if (errorsAddress && Object.keys(errorsAddress).length > 0) {
            setAddressErrorsForm({ fields: errorsAddress });
            hasErrors = true;
        }

        if (hasErrors) return;

        const payload: WalletUpdatePayload = {
            contactablePerson: contactablePersonPayload,
            fiscalInfo: fiscalInfoPayload,
            address: addressPayload,
        };

        const [newWalletInfo, submittingError] = await updateBillingInformation(String(billingInfo.wallet?.id), payload);
        if (submittingError) {
            toast.error(submittingError.errors[0].getMessageTranslated(t));
            return;
        }

        setWalletInfo(newWalletInfo);
        navigate(AppRoute.PurchaseCreditsCheckout);
    };

    return (
        <DefaultLayout>
            <form
                className="purchase-credits-screen form"
                data-testid="billing-information-form"
                onSubmit={onFormSubmit}
            >
                <div className="purchase-credits-screen__container">
                    {billingInfo.wallet && (
                        <BillingInformationForm
                            title={t('subscribeFlow.billingStep.title')}
                            personErrorsForm={personErrorsForm}
                            fiscalErrorsForm={fiscalErrorsForm}
                            addressErrorsForm={addressErrorsForm}
                            wallet={billingInfo.wallet}
                        />
                    )}
                </div>
                <CartSummary />
                <footer>
                    {renderGoBackButton}
                    {renderNextStepButton}
                </footer>
            </form>
        </DefaultLayout>
    );
};

export const PurchaseCreditsBillingInformationScreen = withBillingContext(withTranslationContext(PurchaseCreditsBillingInformationScreenComponent));

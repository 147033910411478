/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { Step, StepLabel, Stepper as StepperMUI } from '@mui/material';
import classNames from 'classnames';
import { FunctionComponent } from 'react';

interface OwnProps {
    steps: string[];
    activeStep: number;
    extraClasses?: string;
}

const HorizontalStepper: FunctionComponent<OwnProps> = (props) => {
    const { steps, activeStep, extraClasses } = props;

    return (
        <StepperMUI
            activeStep={activeStep}
            alternativeLabel
            className={classNames(
                'horizontal-stepper',
                {
                    'big-stepper': steps.length > 3,
                },
                extraClasses,
            )}
            data-testid="horizontal-stepper"
        >
            {steps.map((label) => (
                <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                </Step>
            ))}
        </StepperMUI>
    );
};

export { HorizontalStepper };

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FieldValue,
    FormValidatorError,
    FormValidatorErrorType,
} from '../validations';

import { ValidatorStrategy } from './Validator';

export class NotDecimalValidator implements ValidatorStrategy {
    validate(fieldValue: FieldValue): FormValidatorError | null {
        if (String(fieldValue).indexOf('.') !== -1) {
            return { typeOfViolation: FormValidatorErrorType.NotDecimal };
        }
        return null;
    }
}

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { ManagementProcess, PlanSubtype, ProcessType } from '../types/billing';

export const managementProcesses: Record<PlanSubtype, ManagementProcess> = {
    [PlanSubtype.STARTER]: {
        alreadyIncluded: false,
        processes: [
            ProcessType.strongAuth,
            ProcessType.documentManagement,
            ProcessType.custody,
        ],
    },
    [PlanSubtype.INDIVIDUAL]: {
        alreadyIncluded: true,
        processes: [
            ProcessType.training,
            ProcessType.custody,
        ],
    },
    [PlanSubtype.TEAM]: {
        alreadyIncluded: true,
        processes: [
            ProcessType.training,
            ProcessType.custody,
        ],
    },
    [PlanSubtype.PROFESSIONAL]: {
        alreadyIncluded: true,
        processes: [
            ProcessType.accountManager,
        ],
    },
    [PlanSubtype.ENTERPRISE]: {
        alreadyIncluded: true,
        processes: [
            ProcessType.cloudIntegrations,
        ],
    },
};

export const demoLink = 'https://academy.unlockit.io/pedido-de-demonstracao-app';

export const enterpriseSolutionLink = 'https://academy.unlockit.io/solucoes-enterprise-app';

export const referralLink = 'https://academy.unlockit.io/programa-de-referral';

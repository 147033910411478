/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FieldValue,
    FormValidatorError,
    FormValidatorErrorType,
} from '../validations';

import { ValidatorStrategy } from './Validator';

export class CheckedValidator implements ValidatorStrategy {
    validate(fieldValue: FieldValue): FormValidatorError | null {
        return fieldValue === false ? { typeOfViolation: FormValidatorErrorType.Checked } : null;
    }
}

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FormEventHandler, FunctionComponent, Suspense, lazy, useEffect, useRef, useState,
} from 'react';
import { VariableSizeList } from 'react-window';
import { TranslationContext, withTranslationContext } from '../../../controllers/TranslationContext';
import { EsignaturesLayout } from '../../../elements/esignatures/layouts/EsignaturesLayout';
import Button from '../../../elements/Button';
import { ButtonVariant } from '../../../../types/general';
import { ReactComponent as ArrowRightIcon } from '../../../../assets/images/arrow-right.svg';
import { QesSignatureContext, withQesSignatureContext } from '../../../controllers/esignatures/QesSignatureContext';
import ElectronicIDLogo from '../../../../assets/images/logo-electronic-id.png';
import { Checkbox } from '../../../elements/Checkbox';
import { ErrorResponse } from '../../../../types/errors';
import { LoadingScreen } from '../../LoadingScreen';

const PdfViewerWrapper = lazy(() => import('../../../elements/pdfViewer/PdfViewerWrapper'));

type OwnProps = TranslationContext & QesSignatureContext;

const QesSignatureTermsAndConditionsScreenComponent: FunctionComponent<OwnProps> = (props) => {
    const {
        t,
        acceptQesVideoTermsAndCondition,
        navigateToPrivacyPolicy,
        navigateToContract,
        getQesVideoTermsAndCondition,
        startQesVideoWorkflow,
    } = props;
    const [hasTermsError, setHasTermsError] = useState<boolean>(false);
    const [error, setError] = useState<ErrorResponse>();
    const [termsAndConditionsPdfUrl, setTermsAndConditionsPdfUrl] = useState<string | null>(null);
    const [isPreparing, setIsPreparing] = useState<boolean>(false);

    const pdfPagesListRef = useRef<VariableSizeList>();

    useEffect(() => {
        if (error) throw error;
    }, [error]);

    useEffect(() => {
        setIsPreparing(true);
        startQesVideoWorkflow()
            .then((qesWorkflowRes) => {
                if (qesWorkflowRes[1]) {
                    setError(qesWorkflowRes[1]);
                    return;
                }

                getQesVideoTermsAndCondition().then((res) => {
                    if (res[1]) {
                        setError(res[1]);
                        return;
                    }
    
                    setTermsAndConditionsPdfUrl(URL.createObjectURL(res[0]));
                });
            })
            .finally(() => {
                setIsPreparing(false);
            });
    }, []);

    /**
    * JSX for the "Go Back" button used in the existing bottom bar of the layout.
    *
    */
    const goBackBtn = (
        <Button
            id="actionBackToViewSigningContract"
            variant={ButtonVariant.Curved}
            extraClasses="secondary"
            onClick={navigateToContract}
            testId="actionBackToViewSigningContract"
        >
            <span className="btn-content">
                {t('aesSignatureCodeScreen.actionGoBack')}
            </span>
        </Button>
    );

    /**
    * JSX for the "Next" button used in the existing bottom bar of the layout.
    *
    */
    const nextBtn = (
        <Button
            id="actionNext"
            variant={ButtonVariant.Curved}
            extraClasses="primary"
            testId="actionNext"
            isSubmit
            disabled={isPreparing}
        >
            <span className="btn-content">
                {t('qesSignatureTermsAndConditions.actionNext')}
                <ArrowRightIcon />
            </span>
        </Button>
    );

    const handleNextAction: FormEventHandler = async (e) => {
        e.preventDefault();

        const formData = new FormData(e.target as HTMLFormElement);
        const checkboxValue = formData.get('accept-terms-n-condition');

        if (checkboxValue === null) {
            setHasTermsError(true);

            return;
        }

        const res = await acceptQesVideoTermsAndCondition();
        if (res[1]) {
            setError(res[1]);
            return;
        }

        navigateToPrivacyPolicy();
    };

    return (
        <form onSubmit={handleNextAction} className="qes-signature-documents u-bg bg-secondary">
            <EsignaturesLayout primaryBtn={nextBtn} secondaryBtn={goBackBtn}>
                <div className="qes-signature-layout">
                    <div className="qes-signature-banner">
                        <img src={ElectronicIDLogo} alt="Electronic IDentification logo" height={25} width="auto" />
                    </div>
                    <section className="qes-signature-body">
                        <h2>{t('qesSignatureTermsAndConditions.readCarefully.title')}</h2>
                        <h3>{t('qesSignatureTermsAndConditions.readCarefully.description')}</h3>
                        <span className="line" />
                        <h1>{t('qesSignatureTermsAndConditions.title')}</h1>
                        {termsAndConditionsPdfUrl && (
                            <Suspense fallback={<LoadingScreen />}>
                                <PdfViewerWrapper
                                    fileUrl={termsAndConditionsPdfUrl}
                                    pdfPagesListRef={pdfPagesListRef}
                                />
                            </Suspense>
                        )}
                        {isPreparing && (
                            <LoadingScreen />
                        )}
                    </section>
                    <footer className="qes-signature-footer">
                        <Checkbox
                            id="accept-terms-n-condition"
                            label={t('qesSignatureTermsAndConditions.checkboxLabel')}
                            name="accept-terms-n-condition"
                            hasError={hasTermsError}
                            onChange={() => setHasTermsError(false)}
                        />
                    </footer>
                </div>
            </EsignaturesLayout>
        </form>
    );
};

export const QesSignatureTermsAndConditionsScreen = withQesSignatureContext(withTranslationContext(QesSignatureTermsAndConditionsScreenComponent));

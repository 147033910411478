/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { CMD_PREPROD_PUBLIC_KEY, CMD_PROD_PUBLIC_KEY } from '../../constants/cmd';

/**
 * CMD Commitments URL
 *
 * @returns
 */
export const commitmentTypesUrl = () => {
    return '/signature/cmd/commitment-types';
};

/**
 * CMD Signature Force OTP URL
 *
 * @returns
 */
export const forceOtpUrl = (signerId: string) => {
    return `/signature/cmd/${signerId}/force-otp`;
};

/**
 * CMD Signature Request Signature URL
 *
 * @returns
 */
export const requestSignatureUrl = (signerId: string) => {
    return `/signature/cmd/${signerId}/request`;
};

/**
 * CMD Signature Validate OTP URL
 *
 * @returns
 */
export const validateOtpUrl = (signerId: string) => {
    return `/signature/cmd/${signerId}/validate`;
};

/**
 * Returns public key to encrypt every request to CMD signature
 */
export const getEncryptionPublicKey = (): string => {
    if (process.env.NODE_ENV === 'production') return CMD_PREPROD_PUBLIC_KEY;

    return CMD_PROD_PUBLIC_KEY;
};

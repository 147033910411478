/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent, useRef } from 'react';
import { VariableSizeList } from 'react-window';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';

import PdfViewerWrapper from '../elements/pdfViewer/PdfViewerWrapper';

type BaseProps = TranslationContext;

const PrivacyPolicyScreenBase: FunctionComponent<BaseProps> = () => {
    const pdfPagesListRef = useRef<VariableSizeList>();

    return (
        <section id="privacy-policy-screen" className="privacy-policy-screen">
            <PdfViewerWrapper
                fileUrl="/docs/terms-and-conditions.pdf"
                pdfPagesListRef={pdfPagesListRef}
            />
        </section>
    );
};

export const PrivacyPolicyScreen = withTranslationContext(PrivacyPolicyScreenBase);

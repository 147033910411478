/**
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FLUSH,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
    REHYDRATE,
    persistReducer,
    persistStore,
} from 'redux-persist';
import { combineReducers, configureStore } from '@reduxjs/toolkit';

import storage from 'redux-persist/lib/storage';
import { billingReducer } from './slicers/billingSlice';
import { organizationReducer } from './slicers/organizationSlice';
import { preferencesReducer } from './slicers/preferencesSlice';
import { userReducer } from './slicers/userSlice';

const rootReducer = combineReducers({
    preferences: preferencesReducer,
    organization: organizationReducer,
    billing: billingReducer,
    user: userReducer,
    
});

const persistedReducer = persistReducer({
    key: 'root',
    storage,
}, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
    }),
    devTools: process.env.NODE_ENV === 'development',
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>

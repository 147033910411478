/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FormEventHandler,
    FunctionComponent, useEffect, useState,
} from 'react';
import { Backdrop } from '@mui/material';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { TranslationContext, withTranslationContext } from '../../../controllers/TranslationContext';
import ElectronicIDIcon from '../../../../assets/images/electronic-logo-short-black.png';
import ElectronicIDLogo from '../../../../assets/images/logo-electronic-id.png';
import { EsignatureLoadingModal } from '../../../elements/esignatures/common/EsignatureLoadingModal';
import { EsignaturesLayout } from '../../../elements/esignatures/layouts/EsignaturesLayout';
import { LoadingCircles } from '../../../elements/LoadingCircles';
import { ProgressBar } from '../../../elements/ProgressBar';
import { SmartCodeFormField } from '../../../elements/SmartCodeFormField';
import { QesSignatureContext, withQesSignatureContext } from '../../../controllers/esignatures/QesSignatureContext';
import Button from '../../../elements/Button';
import { ButtonVariant } from '../../../../types/general';
import { ContractsContext, withContractsContext } from '../../../controllers/ContractsContext';
import { ReactComponent as ArrowRightIcon } from '../../../../assets/images/arrow-right.svg';
import UnlockitLogo from '../../../../assets/images/logo_without_text.svg';

type OwnProps = TranslationContext & QesSignatureContext & ContractsContext & {
    otpValidationMinutes: number;
};

const QesSignatureOtpScreenComponent: FunctionComponent<OwnProps> = (props) => {
    const {
        t, tWithJSX, otpValidationMinutes, completeSignature, navigateToContractSignedPage, navigateToContract, navigateToStartVideoValidation, getContract,
    } = props;

    const { contractId = '' } = useParams();

    const [secondsLeft, setSecondsLeft] = useState(otpValidationMinutes * 60);
    const [isLoading, setIsLoading] = useState(false);
    const [codeError, setCodeError] = useState(false);
    const [contractName, setContractName] = useState('');

    useEffect(() => {
        const timer = setInterval(() => {
            setSecondsLeft(
                (prevSecondsLeft) => (prevSecondsLeft > 0 ? prevSecondsLeft - 1 : 0),
            );
        }, 1000);

        prepare();

        return () => {
            clearInterval(timer);
        };
    }, []);

    const prepare = async () => {
        setIsLoading(true);
        const [contract] = await getContract(contractId);

        if (contract) {
            setContractName(contract.name);
        }

        setIsLoading(false);
    };

    const renderTimeLeft = () => {
        return dayjs().startOf('day').add(secondsLeft, 'second').format('mm:ss');
    };

    const calculateProgress = () => {
        return (secondsLeft / (otpValidationMinutes * 60)) * 100;
    };

    const handleValidateCodeSubmit: FormEventHandler = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const formData = new FormData(e.target as HTMLFormElement);

        const err = await completeSignature(String(formData.get('pin')));

        if (err) {
            setCodeError(true);
            
            setIsLoading(false);
        } else {
            navigateToContractSignedPage();
        }
    };

    /**
    * JSX for the "Go Back" button used in the existing bottom bar of the layout.
    *
    */
    const goBackBtn = (
        <Button
            id="actionBackToViewSigningContract"
            variant={ButtonVariant.Curved}
            extraClasses="secondary"
            onClick={navigateToContract}
            testId="actionBackToViewSigningContract"
        >
            <span className="btn-content">
                {t('qesSignatureVideoOtpScreen.actionGoBack')}
            </span>
        </Button>
    );

    /**
    * JSX for the "Next" button used in the existing bottom bar of the layout.
    *
    */
    const submitBtn = (
        <Button
            id="actionNext"
            variant={ButtonVariant.Curved}
            extraClasses="primary"
            testId="actionNext"
            isSubmit
            disabled={isLoading}
        >
            <span className="btn-content">
                {t('qesSignatureVideoOtpScreen.actionSubmit')}
                <ArrowRightIcon />
            </span>
        </Button>
    );

    return (
        <>
            {isLoading && (
                <Backdrop open>
                    <EsignatureLoadingModal
                        open
                        providerIconSrc={ElectronicIDIcon}
                        message={t('aesSignatureCodeScreen.loadingModal.title')}
                        disclaimer={t('aesSignatureCodeScreen.loadingModal.disclaimer')}
                    />
                </Backdrop>
            )}
            <form className="qes-signature-documents" onSubmit={handleValidateCodeSubmit}>
                <EsignaturesLayout primaryBtn={submitBtn} secondaryBtn={goBackBtn}>
                    <div className="qes-signature-layout">
                        <div className="qes-signature-banner">
                            <img src={ElectronicIDLogo} alt="Electronic IDentification logo" height={25} width="auto" />
                        </div>
                        <div className="qes-signature-body qes-signature-otp">
                            <div className="qes-signature-otp__icons">
                                <img src={UnlockitLogo} height="25px" width="auto" alt="unlockit-logo" data-testid="unlockit-icon" />
                                <LoadingCircles size="xs" variant="primary" />
                                <img src={ElectronicIDIcon} height="25px" width="auto" alt="eid-icon" data-testid="eid-icon" />
                            </div>
                            <h1 className="qes-signature-otp__title">{t('qesSignatureVideoOtpScreen.title')}</h1>
                            <h2 className="qes-signature-otp__description">{tWithJSX('qesSignatureVideoOtpScreen.description', { documentName: contractName })}</h2>
                            <div className="qes-signature-otp__otp-code">
                                <h2>{t('qesSignatureVideoOtpScreen.securityCode')}</h2>
                                <SmartCodeFormField id="pin" name="pin" size={6} hasError={!!codeError} onValuesChange={() => {}} />
                                <div className="qes-signature-otp__otp-code__progress-bar" data-testid="progress-bar">
                                    <ProgressBar progress={calculateProgress()} size="xs" variant="primary" testId="time-left-progress-bar" />
                                    <p data-testid="time-disclaimer">
                                        {tWithJSX('qesSignatureVideoOtpScreen.timeDisclaimer', {
                                            time: renderTimeLeft(),
                                        })}
                                    </p>
                                </div>
                            </div>
                            <div className="qes-signature-otp__try-again">
                                <p>{t('qesSignatureVideoOtpScreen.failedSignatureText')}</p>
                                <span onClick={navigateToStartVideoValidation}>{t('qesSignatureVideoOtpScreen.failedSignatureLink')}</span>
                            </div>
                            <div className="qes-signature-otp__info-card">
                                <div>
                                    <img src={ElectronicIDIcon} height="25px" width="auto" alt="eid-icon" data-testid="eid-icon-info-card" />
                                    <h2>{t('qesSignatureVideoOtpScreen.disclaimerTitle')}</h2>
                                </div>
                                <h3>{t('qesSignatureVideoOtpScreen.disclaimerDescription')}</h3>
                            </div>
                        </div>
                    </div>
                </EsignaturesLayout>
            </form>
        </>
    );
};

export const QesSignatureOtpScreen = withContractsContext(withQesSignatureContext(withTranslationContext(QesSignatureOtpScreenComponent)));

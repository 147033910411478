/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent, ReactNode } from 'react';
import { Avatar } from '@mui/material';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../../../assets/images/logo_without_text.svg';
import { ReactComponent as LogoDesktop } from '../../../assets/images/logo-unlockit-purple-black.svg';
import { useAuthContext } from '../../controllers/AuthenticationContext';
import { ReactComponent as UserIcon } from '../../../assets/images/user.svg';
import { AppRoute } from '../../../constants/routes';

interface OwnProps {
    title?: string;
    children: ReactNode;
}

const IsolateFlowLayout: FunctionComponent<OwnProps> = (props) => {
    const {
        title,
        children,
    } = props;
    
    const { user } = useAuthContext();
    
    return (
        <div className="isolate-flow-layout" data-testid="isolate-flow-layout">
            <header className="isolate-flow-layout__header" data-testid="header">
                <Link to={AppRoute.Index} className="isolate-flow-layout__header__logo-wrap">
                    <Logo className="logo-mobile" />
                    <LogoDesktop className="logo-desktop" />
                </Link>
                {title && (<h1 className="desktop-title">{title}</h1>)}
                <div className="isolate-flow-layout__header__user-wrap">
                    <div className="isolate-flow-layout__header__user-wrap__name">
                        {user?.fullName}
                    </div>
                    <Avatar>
                        <UserIcon />
                    </Avatar>
                </div>
            </header>
            {title && (<h1 className="mobile-title">{title}</h1>)}
            <div className="isolate-flow-layout__content">
                {children}
            </div>
        </div>
    );
};

export { IsolateFlowLayout };

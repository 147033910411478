/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    ChangeEvent, FormEvent, FunctionComponent, useEffect, useState,
} from 'react';
import { ButtonVariant } from '../../../types/general';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import Button from '../Button';
import { FormField } from '../FormField';
import Modal from '../Modal';

interface OwnProps extends TranslationContext {
    open: boolean;
    onModalClose: () => void;
    onSubmit: (file: File, notes?: string) => void;
}

const AddDocumentModalComponent: FunctionComponent<OwnProps> = (props) => {
    const {
        t, open, onModalClose, onSubmit,
    } = props;

    const [notes, setNotes] = useState<string>('');
    const [documentFile, setDocumentFile] = useState<File | null>(null);

    useEffect(() => {
        if (!open) {
            setDocumentFile(null);
            setNotes('');
        }
    }, [open]);

    const handleFileSelected = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const uploadedFile = e.target.files[0];

            setDocumentFile(uploadedFile);
        }
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (!documentFile) return;

        onSubmit(documentFile, notes);
    };

    return (
        <Modal
            open={open}
            handleClose={onModalClose}
            title={t('workflows.documents.documentUpload')}
        >
            <form className="add-document-modal" onSubmit={handleSubmit}>
                <input type="file" onChange={handleFileSelected} data-testid="file-input" />
                <FormField
                    name="notes"
                    label={t('workflows.documents.notes')}
                    onChange={(_, notesValue) => setNotes(notesValue)}
                    value={notes}
                />
                <div className="form__bottom">
                    <Button
                        variant={ButtonVariant.Curved}
                        extraClasses="secondary"
                        onClick={onModalClose}
                    >
                        {t('workflows.documents.goBackAction')}
                    </Button>
                    <Button
                        variant={ButtonVariant.Curved}
                        extraClasses="primary"
                        isSubmit
                        disabled={!documentFile}
                    >
                        {t('workflows.documents.uploadAction')}
                    </Button>
                </div>
            </form>
        </Modal>
    );
};

export const AddDocumentModal = withTranslationContext(AddDocumentModalComponent);

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import classNames from 'classnames';
import {
    FunctionComponent,
} from 'react';

interface BaseProps {
    total: number;
    available: number;
    captive: number;
}
const UsageChart: FunctionComponent<BaseProps> = (props) => {
    const {
        total,
        available,
        captive,
    } = props;

    const availablePercentage = available / total * 100;
    const captivePercentage = captive / total * 100;

    return (
        <div className={classNames('usage-chart', { red: availablePercentage < 5 })} data-testid="usage-chart">
            <div className="usage-chart__available" style={{ width: `${availablePercentage}%` }} />
            {captivePercentage > 0
            && <div className="usage-chart__captive" style={{ width: `${captivePercentage}%` }} />
            }
        </div>
    );
};

export { UsageChart };

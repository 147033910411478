/*
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { ChangeEventHandler, FunctionComponent } from 'react';

import classNames from 'classnames';
import { ElementSize } from '../../types/design-system';

type OwnProps = {
    /**
     * DOM Input ID
     */
    id: string;
    /**
     * DOM Inpute Name
     */
    name: string;
    /**
     * Checkbox label shown near the checkbox input
     */
    label: string;
    /**
     * Is checkbox disabled
     */
    disabled?: boolean;
    checked?: boolean;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    /**
     * Test id
     */
    testId?: string;
    /**
     * Boolean that shows inputs as errors
     */
    hasError?: boolean;
    size?: ElementSize;
}

export const Checkbox: FunctionComponent<OwnProps> = (props) => {
    const {
        id,
        label,
        name,
        disabled,
        testId,
        checked,
        onChange,
        hasError,
        size = 'md',
    } = props;

    return (
        <div className={classNames('checkbox', { 'checkbox--error': hasError }, size)}>
            <input className={classNames('checkbox__input', size)} type="checkbox" id={id} aria-label={label} name={name} checked={checked} aria-checked={checked} onChange={onChange} disabled={disabled} data-testid={testId} />
            <label htmlFor={id}>
                {label}
            </label>
        </div>
    );
};

/*
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { ComponentType, FC, createContext } from 'react';
import { DataOrError, ErrorResponse } from '../../types/errors';
import {
    Organization,
    OrganizationAccess,
    OrganizationPayload,
} from '../../types/organizations';

import { FormValidationError } from '../../utils/validations';
import { ListResponse } from '../../types/general';
import OrganizationsController from './OrganizationsController';
import { Role } from '../../types/roles';

export interface OrganizationsContext {
    organizationAccesses: ListResponse<OrganizationAccess>;
    organizationSelected: OrganizationAccess | null;
    selectOrganization(payload: OrganizationAccess): void;
    validateCreateOrganizationForm(fields: OrganizationPayload): FormValidationError | null;
    submitNewOrganization: (payload: OrganizationPayload) => Promise<DataOrError<Organization, ErrorResponse>>;
    submitOrganizationLogo: (organizationId: number, payload: FormData) => Promise<undefined | ErrorResponse>;
    getUserOrganizations(cursor?: string): Promise<ListResponse<OrganizationAccess>>;
    loadMoreUserOrganizations(cursor?: string): Promise<void>;
    getOrganization(organizationId: number): Promise<Organization | null>;
    getOrganizationAccessRoles(organizationAccess: OrganizationAccess): Promise<DataOrError<Role[]|null, ErrorResponse>>;
}

export const organizationsContextDefaultValue: OrganizationsContext = {
    organizationAccesses: { cursor: '', results: [] },
    organizationSelected: null,
    validateCreateOrganizationForm: () => null,
    submitNewOrganization: async () => [{ id: 0, name: '' }, null],
    submitOrganizationLogo: async () => undefined,
    selectOrganization: () => null,
    getUserOrganizations: async () => ({ cursor: '', results: [] }),
    loadMoreUserOrganizations: async () => undefined,
    getOrganization: async () => null,
    getOrganizationAccessRoles: async () => [[], null],
};

const organizationsContextInstance = createContext<OrganizationsContext>(organizationsContextDefaultValue);

export const OrganizationsContextProvider = organizationsContextInstance.Provider;
export const OrganizationsContextConsumer = organizationsContextInstance.Consumer;

export const withOrganizationsContext = <P extends object>(Component: ComponentType<P>): FC<Omit<P, keyof OrganizationsContext>> => (props) => (
    <OrganizationsController>
        <OrganizationsContextConsumer>
            {(ctx) => <Component {...props as P} {...ctx} />}
        </OrganizationsContextConsumer>
    </OrganizationsController>
);

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    ComponentType,
    FC,
    createContext,
    useContext,
} from 'react';
import {
    BasketPaymentRequest,
    NewPaymentMethodResponse,
    PaymentMethod,
    PaymentMethodsList,
    StripeConfig,
    StripePaymentIntent,
} from '../../types/billing';
import { DataOrError, ErrorResponse } from '../../types/errors';

import { PaymentMethodController } from './PaymentMethodController';

export interface PaymentMethodContext {
    listPaymentMethods(walletId: number): Promise<DataOrError<PaymentMethodsList, ErrorResponse>>;
    getPaymentMethod(methodId: string): Promise<DataOrError<PaymentMethod, ErrorResponse>>;
    deletePaymentMethod(methodId: string): Promise<DataOrError<null, ErrorResponse>>;
    markPaymentMethodAsDefault(methodId: string): Promise<DataOrError<null, ErrorResponse>>;
    createCreditCard(walletId: number): Promise<DataOrError<NewPaymentMethodResponse, ErrorResponse>>;
    getStripeConfig(): Promise<DataOrError<StripeConfig, ErrorResponse>>;
    createPaymentIntent(walletId: string, basketPaymentRequest: BasketPaymentRequest): Promise<DataOrError<StripePaymentIntent, ErrorResponse>>;
}

export const paymentMethodContextDefaultValue: PaymentMethodContext = {
    markPaymentMethodAsDefault: () => Promise.resolve([null, null]),
    getPaymentMethod: () => Promise.resolve(Object.create({})),
    deletePaymentMethod: () => Promise.resolve([null, null]),
    listPaymentMethods: () => Promise.resolve([[], null]),
    createCreditCard: () => Promise.resolve([Object.create({}), null]),
    getStripeConfig: () => Promise.resolve([Object.create({}), null]),
    createPaymentIntent: () => Promise.resolve([Object.create({}), null]),
};

const paymentMethodContextInstance = createContext<PaymentMethodContext>(paymentMethodContextDefaultValue);

export const PaymentMethodContextProvider = paymentMethodContextInstance.Provider;
export const PaymentMethodContextConsumer = paymentMethodContextInstance.Consumer;

export const usePaymentMethodContext = () => useContext(paymentMethodContextInstance);

export const withPaymentMethodContext = <P extends object>(Component: ComponentType<P>): FC<Omit<P, keyof PaymentMethodContext>> => (props) => (
    <PaymentMethodController>
        <PaymentMethodContextConsumer>
            {(ctx) => <Component {...props as P} {...ctx} />}
        </PaymentMethodContextConsumer>
    </PaymentMethodController>
);

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    PaymentElement,
    useElements, useStripe,
} from '@stripe/react-stripe-js';
import { FormEvent, FunctionComponent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonVariant } from '../../../types/general';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import Button from '../../elements/Button';
import { AppRoute } from '../../../constants/routes';

interface OwnProps extends TranslationContext {
    successRedirectTo: string;
}

const NewPaymentMethodFormComponent: FunctionComponent<OwnProps> = (props) => {
    const { successRedirectTo: returnTo, t } = props;
    const stripe = useStripe();
    const elements = useElements();

    const navigate = useNavigate();

    const [errorMessage, setErrorMessage] = useState<string>();

    const handleSubmit = async (event: FormEvent) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return null;
        }

        const { error } = await stripe.confirmSetup({
            // `Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: returnTo,
            },
        });

        if (error) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (for example, payment
            // details incomplete)
            setErrorMessage(error.message);
        }

        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
    };

    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement />
            <Button
                id="actionBack"
                variant={ButtonVariant.Curved}
                extraClasses="secondary slim"
                onClick={() => navigate(AppRoute.BillingAddCredits)}
                testId="actionBack"
            >
                <span className="btn-content">
                    {t('general.goBack')}
                </span>
            </Button>
            <Button
                isSubmit
                disabled={!stripe}
                variant={ButtonVariant.Curved}
                extraClasses="primary slim"
            >
                {t('subscribeFlow.newCard.saveCard')}
            </Button>
            {errorMessage && <div>{errorMessage}</div>}
        </form>
    );
};

export const NewPaymentMethodForm = withTranslationContext(NewPaymentMethodFormComponent);

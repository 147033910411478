/**
*
* @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
* Development by VOID Software, SA
*
*/

import { FunctionComponent } from 'react';

import classNames from 'classnames';
import { withTranslationContext } from '../controllers/TranslationContext';
import { ProgressStep } from '../../types/general';
import { WorkflowStateEnumType } from '../../types/workflows';

interface OwnProps {
    steps: ProgressStep[];
    currentStep: WorkflowStateEnumType;
}

const ProgressStepperBase: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        steps,
        currentStep,
    } = props;

    const title = steps.find((step) => step.value === currentStep)?.label;
    const stepInSteps = !!steps.find((step) => step.value === currentStep);
    const currentStepIndex = steps.findIndex((step) => step.value === currentStep);
    return (
        <>
            {stepInSteps && (
                <div className="progress-stepper__wrapper">
                    <div className="progress-stepper" data-testid="progress-stepper">
                        {steps.map((step, index) => {
                            return (
                                <span
                                    key={String(step.value)}
                                    className={classNames('progress-stepper__step-item',
                                        {
                                            completed: index <= currentStepIndex,
                                        })}
                                />
                            );
                        })}
                    </div>
                    <h3>{title}</h3>
                </div>
            )}
        </>
    );
};
export const ProgressStepper = withTranslationContext(ProgressStepperBase);

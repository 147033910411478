/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { PlanSubtype, SubscriptionPlan } from '../types/billing';

export const getSubtypeOfSubscriptionPlan = (subscriptionPlan: SubscriptionPlan | null): PlanSubtype | null => {
    if (!subscriptionPlan) return null;
    
    // formats are as following SUBSCRIPTION_<PLAN>_<YEAR | CODE>
    const subType = subscriptionPlan.subscriptionSku.split('_')[1]; // We want to get the plan of subscription

    if (!subType) return null;

    if (Object.values(PlanSubtype).includes(subType as PlanSubtype)) {
        return subType as PlanSubtype;
    }

    return null;
};

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FieldValue,
    FormValidator,
    FormValidatorError,
    FormValidatorErrorType,
} from '../validations';

import { ValidatorStrategy } from './Validator';

const decimalRegex = (decimalPoints: number) => new RegExp(`^-?\\d+[.|,]?\\d{0,${decimalPoints}}$`);

export class DecimalValidator implements ValidatorStrategy {
    validate(fieldValue: FieldValue, validator: FormValidator): FormValidatorError | null {
        // No error shown if user did not type anything yet
        if (fieldValue.toString().length === 0) return null;

        const {
            decimalPoints,
        } = validator;

        if (decimalPoints !== undefined) {
            if (!decimalRegex(decimalPoints).test(String(fieldValue))) {
                return { typeOfViolation: FormValidatorErrorType.DecimalPoints, decimalPoints };
            }
        }

        return null;
    }
}

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent, ReactNode } from 'react';
import { isArray } from 'lodash';
import UnlockitLogo from '../../../../assets/images/unlockit-logo-short-white.svg';
import { ReactComponent as IconX } from '../../../../assets/images/closeBtn.svg';

interface OwnProps {
    /**
     * The source for icon representing the provider Unlockit interacts with
     */
    providerIconSrc: string;
    /**
     * Handles modal open state
     */
    open: boolean;
    /**
     * The main error message
     */
    errorTitle: string;
    /**
     * A detailed error description or descriptions
     */
    errorDescription: string | string[];
    /**
     * Retry button
     */
    retryButton?: ReactNode;
    /**
     * Try again later button
     */
    tryAgainLaterBackButton?: ReactNode;
    extraClasses?: string;
}

const EsignatureErrorModal: FunctionComponent<OwnProps> = (props) => {
    const {
        open,
        providerIconSrc,
        errorTitle,
        errorDescription,
        retryButton = null,
        tryAgainLaterBackButton = null,
        extraClasses = '',
    } = props;

    return (
        <dialog open={open} id="esignature-error" className={`esignature-error ${extraClasses}`} data-testid="esignature-error">
            <div className="esignature-error__container">
                <div className="esignature-error__icons">
                    <img src={UnlockitLogo} height="25px" width="auto" alt="unlockit-logo" data-testid="unlockit-icon" />
                    <IconX />
                    <img src={providerIconSrc} height="25px" width="auto" alt="provider-icon" data-testid="provider-icon" />
                </div>
                <h2 data-testid="message">{errorTitle}</h2>
                {isArray(errorDescription) ? (
                    <ul>
                        {errorDescription.map((e) => {
                            return <li key={e}>{e}</li>;
                        })}
                    </ul>
                ) : (
                    <p>
                        {errorDescription}
                    </p>
                )}
                {retryButton}
                {tryAgainLaterBackButton}
            </div>
        </dialog>
    );
};

export { EsignatureErrorModal };

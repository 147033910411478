/*
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { useEffect, useState } from 'react';
import { useAppSelector } from '../utils/storeHooks';
import { PermissionList } from '../types/permissions';

export const useUserHasPermission = (permissions: PermissionList) => {
    const currentPermissions = useAppSelector((state) => state.organization.currentPermissions);
    const [hasPermission, setHasPermission] = useState<boolean>(false);

    useEffect(() => {
        const somePermissionFound = permissions.some((permission) => currentPermissions?.includes(permission));
        setHasPermission(somePermissionFound);
    }, [permissions, currentPermissions]);

    return hasPermission;
};

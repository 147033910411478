/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { ReactNode } from 'react';
import { useCornerstoneApi } from '../../api';
import {
    createPaymentIntentUrl,
    paymentMethodDefaultUrl,
    paymentMethodUrl,
    paymentMethodsUrl,
    stripeConfigUrl,
} from '../../services/billing';
import {
    NewPaymentMethodResponse,
    PaymentMethod,
    PaymentMethodsList,
    StripeConfig,
} from '../../types/billing';
import { ErrorResponse } from '../../types/errors';
import { PaymentMethodContext, PaymentMethodContextProvider } from './PaymentMethodContext';

interface Props {
    children: ReactNode;
}

export const PaymentMethodController = ({ children }: Props) => {
    const CornerstoneApi = useCornerstoneApi();

    const listPaymentMethods: PaymentMethodContext['listPaymentMethods'] = (walletId: number) => {
        return new Promise((resolve) => {
            CornerstoneApi.get<PaymentMethodsList>(paymentMethodsUrl(String(walletId)))
                .then((res) => resolve([res.data, null]))
                .catch((e: ErrorResponse) => resolve([null, e]));
        });
    };

    const getPaymentMethod: PaymentMethodContext['getPaymentMethod'] = (methodId) => {
        return new Promise((resolve) => {
            CornerstoneApi.get<PaymentMethod>(paymentMethodUrl(methodId))
                .then((res) => resolve([res.data, null]))
                .catch((e: ErrorResponse) => resolve([null, e]));
        });
    };

    const deletePaymentMethod: PaymentMethodContext['deletePaymentMethod'] = (methodId) => {
        return new Promise((resolve) => {
            CornerstoneApi.delete(paymentMethodUrl(methodId)).then((res) => {
                resolve([res.data, null]);
            }).catch((e: ErrorResponse) => {
                resolve([null, e]);
            });
        });
    };

    const markPaymentMethodAsDefault: PaymentMethodContext['markPaymentMethodAsDefault'] = (methodId) => {
        return new Promise((resolve) => {
            CornerstoneApi.post(paymentMethodDefaultUrl(methodId)).then((res) => {
                resolve([res.data, null]);
            }).catch((e: ErrorResponse) => {
                resolve([null, e]);
            });
        });
    };

    const createCreditCard: PaymentMethodContext['createCreditCard'] = (methodId) => {
        return new Promise((resolve) => {
            CornerstoneApi.post<NewPaymentMethodResponse>(paymentMethodsUrl(String(methodId)))
                .then((res) => {
                    resolve([res.data, null]);
                }).catch((e: ErrorResponse) => {
                    resolve([null, e]);
                });
        });
    };

    const getStripeConfig: PaymentMethodContext['getStripeConfig'] = () => {
        return new Promise((resolve) => {
            CornerstoneApi.get<StripeConfig>(stripeConfigUrl())
                .then((res) => {
                    resolve([res.data, null]);
                }).catch((e: ErrorResponse) => {
                    resolve([null, e]);
                });
        });
    };

    /**
     * Create Stripe payment intent
     *
     * @returns
     */
    const createPaymentIntent: PaymentMethodContext['createPaymentIntent'] = async (walletId, basketPaymentRequest) => {
        try {
            const { data } = await CornerstoneApi.post(createPaymentIntentUrl(walletId), basketPaymentRequest);

            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    return (
        <PaymentMethodContextProvider
            value={{
                getPaymentMethod,
                deletePaymentMethod,
                markPaymentMethodAsDefault,
                listPaymentMethods,
                createCreditCard,
                getStripeConfig,
                createPaymentIntent,
            }}
        >
            {children}
        </PaymentMethodContextProvider>
    );
};

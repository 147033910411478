/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Alert } from '@mui/material';
import { PaymentMethodContext, withPaymentMethodContext } from '../../controllers/PaymentMethodContext';
import { AppRoute } from '../../../constants/routes';
import { ErrorResponse } from '../../../types/errors';
import { LoadingCircles } from '../../elements/LoadingCircles';
import { PaymentMethod } from '../../../types/billing';

interface Props extends PaymentMethodContext {}

export const PaymentMethodDetailsScreenBase: FunctionComponent<Props> = (props) => {
    const {
        getPaymentMethod,
        deletePaymentMethod,
        markPaymentMethodAsDefault,
    } = props;
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>();
    const [error, setError] = useState<ErrorResponse>();
    const [loading, setLoading] = useState(true);
    const { methodId = '' } = useParams();
    const navigate = useNavigate();
    
    function loadPaymentMethod() {
        setLoading(true);
        getPaymentMethod(methodId).then((res) => {
            setLoading(false);
            const [data, _error] = res;
            if (_error) {
                setError(_error);
                return;
            }
            setPaymentMethod(data);
        });
    }

    function handleDelete() {
        deletePaymentMethod(methodId).then((res) => {
            const [, _error] = res;
            if (_error) {
                setError(_error);
                return;
            }
            navigate(AppRoute.PaymentMethods);
        });
    }
        
    function handleMakeDefault() {
        markPaymentMethodAsDefault(methodId).then((res) => {
            const [data, _error] = res;
            if (data) {
                return loadPaymentMethod();
            }
            
            if (_error) {
                setError(_error);
            }
        });
    }

    useEffect(() => {
        loadPaymentMethod();
    }, []);
    
    return (
        <section id="payment-method-details" data-testid="payment-method-details">
            <h1>Payment Method Details {methodId}</h1>

            {loading && <LoadingCircles size="xl" variant="primary" />}

            <h2>All Details</h2>

            {/* CREDIT CARD IMPLEMENTATION */}
            <ul>
                <li>ID: {paymentMethod?.id}</li>
                <li>Brand: {paymentMethod?.card?.brand}</li>
                <li>Card Number: **** **** **** {paymentMethod?.card?.last4}</li>
                <li>Expiration Date: {paymentMethod?.card?.expMonth}/{paymentMethod?.card?.expYear}</li>
                <li>Used by default: {String(paymentMethod?.usedByDefault)}</li>
            </ul>

            <h2>Actions</h2>

            <div style={{ display: 'flex', gap: '2rem' }}>
                <button type="button" data-testid="delete-payment-method" onClick={handleDelete}>DELETE</button>
                <button type="button" data-testid="make-default-payment-method" onClick={handleMakeDefault}>MAKE DEFAULT</button>
            </div>

            {error && (
                <Alert severity="error">{JSON.stringify(error)}</Alert>

            )}
            
        </section>
    );
};

export const PaymentMethodDetailsScreen = withPaymentMethodContext(PaymentMethodDetailsScreenBase);

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    ChangeEventHandler,
    FormEvent,
    FunctionComponent,
    useEffect,
    useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { GroupedRolePermissions, OrganizationRoleFieldsName, Role } from '../../../types/roles';
import { RolesContext, withRolesContext } from '../../controllers/RolesContext';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';

import { AppRoute } from '../../../constants/routes';
import Button from '../../elements/Button';
import { ButtonVariant } from '../../../types/general';
import { Checkbox } from '../../elements/Checkbox';
import { FormField } from '../../elements/FormField';
import { FormValidationError } from '../../../utils/validations';
import Modal from '../../elements/Modal';
import { PermissionList } from '../../../types/permissions';

interface OwnProps extends TranslationContext, RolesContext { }

const ShowRoleScreenComponent: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t,
        getRole,
        updateRole,
        getGroupedPermissions,
        assignRoleMapping,
        deleteRole,
    } = props;

    const {
        roleId,
    } = useParams<{
        roleId: string;
    }>();

    const navigate = useNavigate();
    const [roleData, setRoleData] = useState<Role | null>();
    const [roleNameUpdate, setRoleNameUpdate] = useState<string>(roleData?.name ?? '');
    const [roleDescriptionUpdate, setDescriptionUpdate] = useState<string>(roleData?.description ?? '');
    const [groupedPermissions, setGroupedPermissions] = useState<GroupedRolePermissions>([]);
    const [selectedPermissions, setSelectedPermissions] = useState<Set<string>>(new Set([]));
    const [formErrors, setFormErrors] = useState<FormValidationError>();
    const [openDeleteModalConfirm, setOpenDeleteModalConfirm] = useState(false);

    useEffect(() => {
        loadRole();
        loadGroupedPermissions();
    }, []);

    const loadRole = () => {
        getRole(parseInt(roleId ?? '0')).then((res) => {
            setRoleData(res);
            setRoleNameUpdate(res?.name ?? '');
            setDescriptionUpdate(res?.description ?? '');

            // Build permission set
            const currentPermissionsSet = selectedPermissions;
            res?.permissions.forEach((p) => currentPermissionsSet.add(p));
            setSelectedPermissions(currentPermissionsSet);
        });
    };

    const loadGroupedPermissions = () => {
        getGroupedPermissions().then(([data, error]) => {
            if (error) {
                toast.error(error.errors?.[0]?.getMessageTranslated(t));
                setFormErrors(error.fields as unknown as FormValidationError);
                return;
            }

            setGroupedPermissions(data);
        });
    };

    const handleSubmitRoleUpdate = (event: FormEvent) => {
        event.preventDefault();

        updateRole(parseInt(roleId ?? '0'), {
            name: roleNameUpdate,
            description: roleDescriptionUpdate,
        }).then(([, error]) => {
            if (error) {
                toast.error(error.errors?.[0].getMessageTranslated(t));
            }
            toast.success(t('showRoleScreen.editSuccess'));
            loadRole();
        });
    };

    const togglePermission: ChangeEventHandler<HTMLInputElement> = (event) => {
        if (event.target.checked) {
            setSelectedPermissions((prevState) => new Set(prevState).add(event.target.name));
        } else {
            const newSet = new Set(selectedPermissions);
            newSet.delete(event.target.name);
            setSelectedPermissions(newSet);
        }

        assignRoleMapping({
            roleId: roleData?.id ?? 0,
            permissions: Array.from(selectedPermissions) as PermissionList,
        });
    };

    const handleOpenDeleteModal = () => {
        setOpenDeleteModalConfirm(true);
    };

    const handleCloseModalConfirm = () => {
        setOpenDeleteModalConfirm(false);
    };

    const handleConfirm = async () => {
        const deleteRoleError = await deleteRole(Number(roleId));

        if (deleteRoleError) {
            onDeleteFailure(deleteRoleError.errors[0].getMessageTranslated(t));
        } else {
            onDeleteSuccess();
        }
        handleCloseModalConfirm();
    };

    const onDeleteSuccess = () => {
        toast.success(t('rolesScreen.deleteSuccess'));

        navigate(AppRoute.OrganizationRoles);
    };

    const onDeleteFailure = (errorMessage: string) => {
        toast.error(errorMessage);
    };

    return (
        <div className="show-role-screen">
            <h1 data-testid="screen-title">{roleData?.name}</h1>
            <div className="card card-default-inner">
                <form
                    onSubmit={(e) => handleSubmitRoleUpdate(e)}
                    autoComplete="off"
                >
                    <h2>{t('showRoleScreen.editTitle')}</h2>
                    <div className="form__fields">
                        <FormField
                            name={OrganizationRoleFieldsName.Name}
                            value={roleNameUpdate}
                            onChange={(_, val) => setRoleNameUpdate(val)}
                            maxLength={250}
                            placeholder={t('rolesScreen.roleName')}
                            label={t('rolesScreen.roleName')}
                            errors={formErrors}
                        />
                        <FormField
                            name={OrganizationRoleFieldsName.Description}
                            value={roleDescriptionUpdate}
                            onChange={(_, val) => setDescriptionUpdate(val)}
                            maxLength={250}
                            placeholder={t('rolesScreen.roleDescription')}
                            label={t('rolesScreen.roleDescription')}
                            errors={formErrors}
                        />

                    </div>
                    <Button
                        isSubmit
                        variant={ButtonVariant.Curved}
                        extraClasses="primary slim"
                        testId="save-btn"
                    >
                        {t('general.save')}
                    </Button>
                </form>
            </div>
            <br />
            <div className="card card-default-inner" key={`permissions-${selectedPermissions.size}`}>
                <h2>{t('showRoleScreen.permissionsTitle')}</h2>
                {groupedPermissions.map((group) => (
                    <div key={group.groupedPermission}>
                        <h3>{t(`permissionGroups.${group.groupedPermission}`)}</h3>
                        <ul data-testid="permission-list">
                            {group.permissions.map((perm) => (
                                <li key={perm}>
                                    <Checkbox
                                        id={perm}
                                        label={t(`permissions.${perm}`)}
                                        name={perm}
                                        testId={perm}
                                        size="lg"
                                        checked={selectedPermissions.has(perm)}
                                        onChange={togglePermission}
                                    />
                                </li>

                            ))}
                        </ul>
                    </div>
                ))}
            </div>
            <br />
            <Button extraClasses="danger" onClick={handleOpenDeleteModal} data-testid="delete-btn">
                {t('showRoleScreen.deleteBtn')}
            </Button>
            <Modal
                open={openDeleteModalConfirm}
                title={t('rolesScreen.deleteConfirmTitle')}
                handleClose={handleCloseModalConfirm}
                handleConfirm={handleConfirm}
            >
                <p>{t('rolesScreen.deleteConfirmText', {
                    name: roleData?.name,
                })}
                </p>
            </Modal>
        </div>
    );
};

export const ShowRoleScreen = withTranslationContext(withRolesContext(ShowRoleScreenComponent));

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
    TranslationContext,
    withTranslationContext,
} from '../../controllers/TranslationContext';

import Button from '../Button';
import { ButtonVariant } from '../../../types/general';
import Modal from '../Modal';
import { ProgressBar } from '../ProgressBar';
import { ReactComponent as SignedIcon } from '../../../assets/images/signature-icon.svg';

type OwnProps = TranslationContext & {
    /**
     * Time in Seconds to auto close the modal
     */
    timeToClose: number;
};

const SignedContractModalBase: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t,
        timeToClose,
    } = props;

    const [searchParams] = useSearchParams();
    const signSuccessScreen = searchParams.get('signSuccess') ?? '';

    const [isOpenSignedModal, setIsOpenSignedModal] = useState(true);
    const [secondsLeft, setSecondsLeft] = useState(timeToClose);

    useEffect(() => {
        const timer = setInterval(() => {
            setSecondsLeft(
                (prevSecondsLeft) => (prevSecondsLeft > 0 ? prevSecondsLeft - 1 : 0),
            );
            if (secondsLeft < 1) closeModal();
        }, 1000);
        return () => {
            clearInterval(timer);
        };
    }, []);

    /**
     * Calculate progress number for progress bar
     *
     * @returns
     */
    function calculateProgress() {
        return secondsLeft / timeToClose * 100;
    }

    /**
     * Manage what happens when modal is closed
     *
     */
    const closeModal = () => {
        setIsOpenSignedModal(false);
        setSecondsLeft(0);
    };

    return (
        <Modal
            open={!!signSuccessScreen && isOpenSignedModal && secondsLeft > 0}
            handleClose={closeModal}
            extraClasses="signed-modal"
        >
            <div className="content-wrap">
                <SignedIcon />
                <h3>{t('contractView.signedModal.title')}</h3>
                <p>{t('contractView.signedModal.text')}</p>
                <Button
                    onClick={closeModal}
                    variant={ButtonVariant.Curved}
                    extraClasses="secondary"
                    testId="btn-view-contract"
                >
                    {t('contractView.signedModal.btnText')}
                </Button>
                <ProgressBar progress={calculateProgress()} size="xs" variant="secondary" testId="time-left-progress-bar" />
            </div>
        </Modal>
    );
};

export const SignedContractModal = withTranslationContext(SignedContractModalBase);

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { OrganizationsContext, withOrganizationsContext } from '../controllers/OrganizationsContext';
import { MenuBarItem } from '../../types/menu';
import { ReactComponent as ClockIcon } from '../../assets/images/historic-clock.svg';
import { ReactComponent as RocketIcon } from '../../assets/images/rocket.svg';
import { ReactComponent as DashboardIcon } from '../../assets/images/dashboard.svg';
import { ReactComponent as Logo } from '../../assets/images/logo-horizontal.svg';
import { ReactComponent as CardIcon } from '../../assets/images/credit-card.svg';
import { ReactComponent as GraphIcon } from '../../assets/images/graph.svg';
import { ReactComponent as PeopleIcon } from '../../assets/images/people.svg';
import { ReactComponent as UserSettingIcon } from '../../assets/images/user-setting.svg';
import { ReactComponent as IconLeftArrow } from '../../assets/images/arrow-left.svg';
import { ReactComponent as CogIcon } from '../../assets/images/settings.svg';
import { ReactComponent as DocumentIcon } from '../../assets/images/document-icon.svg';
import { ReactComponent as WrenchIcon } from '../../assets/images/wrench.svg';
import { ReactComponent as MenuIcon } from '../../assets/images/menu.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/closeBtn.svg';
import { AppRoute } from '../../constants/routes';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import { Tooltip } from './Tooltip';

interface OwnProps extends OrganizationsContext, TranslationContext { }

const OrganizationMenubarComponent: FunctionComponent<OwnProps> = (props) => {
    const { t } = props;

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        const closeMenu = () => setIsMenuOpen(false);

        window.addEventListener('resize', closeMenu);
        return () => window.removeEventListener('resize', closeMenu);
    }, []);

    const billingItems: MenuBarItem[] = [
        {
            title: 'billingDashboard',
            icon: <DashboardIcon />,
            link: AppRoute.OrganizationBillingDashboard,
        },
        {
            title: 'myPlan',
            icon: <RocketIcon />,
            link: AppRoute.BillingPlans,
        },
        {
            title: 'billing',
            icon: <CardIcon />,
            link: AppRoute.Index,
            disabled: true,
        },
        {
            title: 'bills',
            icon: <ClockIcon />,
            link: AppRoute.Index,
            disabled: true,
        },
        {
            title: 'billingHistory',
            icon: <GraphIcon />,
            link: AppRoute.Index,
            disabled: true,
        },
    ];

    const generalSettingsItems: MenuBarItem[] = [
        {
            title: 'members',
            icon: <PeopleIcon />,
            link: AppRoute.OrganizationMembers,
        },
        {
            title: 'rolesAndPermissions',
            icon: <UserSettingIcon />,
            link: AppRoute.OrganizationRoles,
        },
        {
            title: 'settings',
            icon: <CogIcon />,
            link: AppRoute.Index,
            disabled: true,
        },
    ];

    const otherSettingsItems: MenuBarItem[] = [
        {
            title: 'contractConfiguration',
            icon: <WrenchIcon />,
            link: AppRoute.ContractsConfiguration,
        },
        {
            title: 'checklistDocs',
            icon: <DocumentIcon />,
            link: AppRoute.Index,
            disabled: true,
        },
    ];

    return (
        <div className={classNames('menu-bar menu-bar--scrollable', { open: isMenuOpen })} data-testid="menu-bar">
            <button type="button" onClick={() => setIsMenuOpen((prev) => !prev)}>
                {isMenuOpen
                    ? <Tooltip title={t('general.menu')} placement="bottom"><CloseIcon /></Tooltip>
                    : <Tooltip title={t('general.close')} placement="bottom"><MenuIcon /></Tooltip>
                }
            </button>
            <div className="menu-bar__logo-wrap_desktop">
                <Logo />
            </div>
            <div className="menu-bar__wrap-list menu-bar__wrap-list--scrollable-list dark-custom-scrollbar">
                <Tooltip title={t('menuBar.goBackHome')} placement="bottom">
                    <NavLink className="menu-bar__wrap-list__go-to-link" to={AppRoute.Index} end>
                        <IconLeftArrow />
                        {t('menuBar.goBackHome')}
                    </NavLink>
                </Tooltip>
                <h3>{t('menuBar.billing')}</h3>
                <ul className="menu-bar__wrap-list__list">
                    {billingItems.map((item) => (
                        <Tooltip key={item.title} title={t(`menuBar.${item.title}`)} placement="bottom">
                            <NavLink to={item.link} end className={item.disabled ? 'disabled' : ''} onClick={() => setIsMenuOpen((prev) => !prev)}>
                                <li className="menu-bar__list__item">
                                    <div className="menu-bar__list__item__icon-wrap">
                                        {item.icon}
                                    </div>
                                    <span className="menu-bar__list__item__title">
                                        {t(`menuBar.${item.title}`)}
                                    </span>
                                </li>
                            </NavLink>
                        </Tooltip>
                    ))}
                </ul>
                <h3>{t('menuBar.generalSettings')}</h3>
                <ul className="menu-bar__wrap-list__list">
                    {generalSettingsItems.map((item) => (
                        <Tooltip key={item.title} title={t(`menuBar.${item.title}`)} placement="bottom">
                            <NavLink to={item.link} end className={item.disabled ? 'disabled' : ''} onClick={() => setIsMenuOpen((prev) => !prev)}>
                                <li className="menu-bar__list__item">
                                    <div className="menu-bar__list__item__icon-wrap">
                                        {item.icon}
                                    </div>
                                    <span className="menu-bar__list__item__title">
                                        {t(`menuBar.${item.title}`)}
                                    </span>
                                </li>
                            </NavLink>
                        </Tooltip>
                    ))}
                </ul>
                <h3>{t('menuBar.otherSettings')}</h3>
                <ul className="menu-bar__wrap-list__list">
                    {otherSettingsItems.map((item) => (
                        <Tooltip key={item.title} title={t(`menuBar.${item.title}`)} placement="bottom">
                            <NavLink to={item.link} end className={item.disabled ? 'disabled' : ''} onClick={() => setIsMenuOpen((prev) => !prev)}>
                                <li className="menu-bar__list__item">
                                    <div className="menu-bar__list__item__icon-wrap">
                                        {item.icon}
                                    </div>
                                    <span className="menu-bar__list__item__title">
                                        {t(`menuBar.${item.title}`)}
                                    </span>
                                </li>
                            </NavLink>
                        </Tooltip>
                    ))}
                </ul>
            </div>
            {/* Bottom for now is just for styling purpose */}
            <div className="menu-bar__bottom" />
        </div>
    );
};

export const OrganizationMenubar = withTranslationContext(withOrganizationsContext(OrganizationMenubarComponent));

/**
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { detectBrowserLanguage, matchAvailableLanguagesOrDefault } from '../services/language';

import { AppLanguage } from '../types/preferences';
import { SlicerName } from './types';

export interface PreferencesState {
    language: AppLanguage;
    languages: AppLanguage[];
}

const preferencesInitialState: PreferencesState = {
    language: matchAvailableLanguagesOrDefault(detectBrowserLanguage()),
    languages: [AppLanguage.EN, AppLanguage.PT],
};

export const preferencesSlice = createSlice({
    name: SlicerName.Preferences,
    initialState: preferencesInitialState,
    reducers: {
        setLanguage: (state, action: PayloadAction<AppLanguage>) => {
            state.language = action.payload;
        },
    },
});

export const { setLanguage } = preferencesSlice.actions;

export const preferencesReducer = preferencesSlice.reducer;

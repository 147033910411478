/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { useStripe } from '@stripe/react-stripe-js';
import {
    FormEvent, FunctionComponent, memo, useState,
} from 'react';
import { toast } from 'react-toastify';
import { AppRoute } from '../../../../constants/routes';
import { ButtonVariant } from '../../../../types/general';
import { TranslationContext, withTranslationContext } from '../../../controllers/TranslationContext';
import Button from '../../../elements/Button';

type OwnProps = TranslationContext & {
    checkoutFlow?: 'purchaseSubscription' | 'purchaseCredits';
    clientSecret: string;
};
const CheckoutFormComponent: FunctionComponent<OwnProps> = (props) => {
    const {
        t,
        checkoutFlow = 'purchaseSubscription',
        clientSecret,
    } = props;
    
    const stripe = useStripe();

    const [isProcessing, setIsProcessing] = useState(false);

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        if (!stripe) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsProcessing(true);

        const { error } = await stripe.confirmPayment({
            clientSecret,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: checkoutFlow === 'purchaseCredits' ? `${window.location.origin}${AppRoute.PurchaseCreditsCheckoutComplete}` : `${window.location.origin}${AppRoute.PaymentCheckoutComplete}`,
            },
        });

        if (error) {
            toast.error(error.message);
        }

        setIsProcessing(false);
    };

    /**
    * JSX for the "Next" button used in the existing bottom bar of the layout.
    *
    */
    const nextBtn = (
        <Button
            id="actionNext"
            variant={ButtonVariant.Curved}
            extraClasses="primary slim"
            testId="actionNext"
            isSubmit
        >
            <span className="btn-content">
                {isProcessing ? t('subscribeFlow.checkout.processing') : t('subscribeFlow.checkout.payNow')}
            </span>
        </Button>
    );

    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            {nextBtn}
        </form>
    );
};

export const CheckoutForm = memo(withTranslationContext(CheckoutFormComponent));

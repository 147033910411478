/**
import withTranslationContext from '../../../controllers/OrganizationsController';
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { TranslationContext, withTranslationContext } from '../../../controllers/TranslationContext';
import ElectronicIDLogo from '../../../../assets/images/logo-electronic-id.png';
import { EsignaturesLayout } from '../../../elements/esignatures/layouts/EsignaturesLayout';
import { useVideoIdRequirements } from '../../../../hooks/useVideoIdRequirements';
import { QesSignatureContext, withQesSignatureContext } from '../../../controllers/esignatures/QesSignatureContext';
import { AppLanguage } from '../../../../types/preferences';
import { LoadingScreen } from '../../LoadingScreen';
import { validationChecksToRequirementsError } from '../../../../utils/qes';
import { AppRoute } from '../../../../constants/routes';

type OwnProps = TranslationContext & QesSignatureContext;

const QesSignatureStartVideoValidationScreenComponent: FunctionComponent<OwnProps> = (props) => {
    const { requestQesVideoFlow, language, navigateToCheckingVideoSignature } = props;
    const navigate = useNavigate();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const videoId = useRef<any>(undefined);

    const handleOnComplete = () => {
        navigateToCheckingVideoSignature();
    };

    const handleStartVideoIdAfterVerifications = () => {
        requestQesVideoFlow()
            .then((res) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                videoId.current = window.EID.videoId('#unlockit-qes-video-render', {
                    lang: language ?? AppLanguage.EN,
                });
                videoId.current?.start({
                    authorization: res[0]?.videoAuthorization,
                });
                videoId.current?.on('completed', handleOnComplete);
                videoId.current?.on('phaseStarting', (phase: {continue: () => void}) => {
                    // Add your functionality here
                    phase.continue();
                });
            })
            .catch(() => {});
    };

    const { isVideoIdLoading, videoIdValidationResult } = useVideoIdRequirements(handleStartVideoIdAfterVerifications);

    useEffect(() => {
        const requirementsErrors = validationChecksToRequirementsError(videoIdValidationResult);

        if (requirementsErrors.length > 0) {
            navigate(AppRoute.QesSignatureVideoValidationFailed, { state: { requirementsErrors } });
        }
    }, [videoIdValidationResult]);

    return (
        <div className="qes-signature-documents">
            <EsignaturesLayout>
                <div className="qes-signature-layout">
                    <div className="qes-signature-banner">
                        <img src={ElectronicIDLogo} alt="Electronic IDentification logo" height={25} width="auto" />
                    </div>
                    <section className="qes-signature-start-video-validation" id="unlockit-qes-video-render">
                        {isVideoIdLoading && <LoadingScreen />}
                    </section>
                </div>
            </EsignaturesLayout>
        </div>
    );
};

export const QesSignatureStartVideoValidationScreen = withQesSignatureContext(withTranslationContext(QesSignatureStartVideoValidationScreenComponent));

/**
 *
 * @Copyright 2024 UNLOCK DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

export enum UnlockitHttpHeaders {
    Organization='organization'
}

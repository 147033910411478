/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { ComponentType, FC, createContext } from 'react';

import { AesSignatureController } from './AesSignatureController';
import { CodeType } from '../../../types/esignatures/aes';
import { ErrorResponse } from '../../../types/errors';

export interface AesSignatureContext {
    requestSignature(payload: {
        otpType: CodeType;
    }): Promise<undefined | ErrorResponse>;
    validateSignature(payload: {
        otpType: CodeType;
        otpCode: string;
    }): Promise<undefined | ErrorResponse>;
    navigateToContract: () => void;
    navigateToContractSignedPage: () => void;
    navigateToAesSignaturePhoneScreen: () => void;
    navigateToAesSignatureEmailScreen: () => void;
    navigateToAesContextScreen: () => void;
}

export const aesSignatureContextDefaultValue: AesSignatureContext = {
    requestSignature: async () => undefined,
    validateSignature: async () => undefined,
    navigateToContract: () => {},
    navigateToContractSignedPage: () => {},
    navigateToAesSignaturePhoneScreen: () => {},
    navigateToAesSignatureEmailScreen: () => {},
    navigateToAesContextScreen: () => {},
};

const aesSignatureContextInstance = createContext<AesSignatureContext | null>(aesSignatureContextDefaultValue);

export const AesSignatureContextProvider = aesSignatureContextInstance.Provider;
export const AesSignatureContextConsumer = aesSignatureContextInstance.Consumer;

export const withAesSignatureContext = <P extends object>(Component: ComponentType<P>): FC<Omit<P, keyof AesSignatureContext>> => (props) => (
    <AesSignatureController>
        <AesSignatureContextConsumer>
            {(ctx) => <Component {...props as P} {...ctx} />}
        </AesSignatureContextConsumer>
    </AesSignatureController>
);

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent, useState } from 'react';
import classNames from 'classnames';
import { ContractsContext, withContractsContext } from '../../../controllers/ContractsContext';
import {
    Signer,
} from '../../../../types/contracts';
import {
    TranslationContext,
    withTranslationContext,
} from '../../../controllers/TranslationContext';

import { ReactComponent as AddUserIcon } from '../../../../assets/images/add-user.svg';
import Button from '../../Button';
import { ButtonVariant } from '../../../../types/general';

import { Drawer } from '../../Drawer';
import { ReactComponent as PeopleIcon } from '../../../../assets/images/people.svg';
import { SignerListItem } from '../SignerListItem';
import { UserAsSignerListItem } from '../UserAsSignerListItem';
import { useAuthContext } from '../../../controllers/AuthenticationContext';
import { useContractContext } from '../ContractContextProvider';

import { AddSignerDrawer } from '../AddSignerDrawer';
import { Tooltip } from '../../Tooltip';

interface OwnProps extends TranslationContext, ContractsContext {
}

const SignersOptionComponent: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t,
    } = props;

    const {
        contract,
    } = useContractContext();

    const [isOpenSigners, setIsOpenSigners] = useState(true);
    const [isOpenAddSigner, setIsOpenAddSigner] = useState(false);

    const { user } = useAuthContext();
    const currentUserInSigners = contract?.signers?.find((signer) => signer.inviteEmail === user?.email);

    return (
        <>
            <Button
                variant={ButtonVariant.IconBtn}
                extraClasses={classNames('primary',
                    { active: isOpenSigners })
                }
                onClick={() => setIsOpenSigners(true)}
                testId="signers-btn"
            >
                <Tooltip title={t('contractCreate.signers')} placement="left">
                    <PeopleIcon />
                </Tooltip>
            </Button>
            <Drawer
                title={t('contractCreate.signers')}
                open={isOpenSigners}
                handleClose={() => setIsOpenSigners(false)}
            >
                <form className="form">
                    <div className="form__fields signers">
                        {!currentUserInSigners && (
                            <UserAsSignerListItem
                                user={user!}
                                signer={currentUserInSigners}
                            />
                        )}
                        {contract?.signers?.length === 0 && (<p>{t('contractCreate.noResultsForSigners')}</p>)}
                        {contract?.signers?.map((signer: Signer) => {
                            return (
                                <SignerListItem
                                    key={signer.id}
                                    signer={signer}
                                />
                            );
                        })}
                    </div>
                    <div className="add-signer-btn-wrapper">
                        <Button
                            extraClasses="form__fields__signer-line-new full-width"
                            key="new signer"
                            variant={ButtonVariant.RectangularFilled}
                            onClick={() => setIsOpenAddSigner(true)}
                            testId="add-btn"
                            startIcon={<AddUserIcon />}
                        >
                            {t('contractCreate.addSigner')}
                        </Button>
                    </div>
                </form>
            </Drawer>
            <AddSignerDrawer
                isOpen={isOpenAddSigner}
                onClose={() => setIsOpenAddSigner(false)}
            />
        </>

    );
};

const SignersOption = withTranslationContext(withContractsContext(SignersOptionComponent));
export { SignersOption };

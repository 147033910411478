/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent } from 'react';
import { AppLanguage, languageNames } from '../../types/preferences';
import {
    TranslationContext,
    withTranslationContext,
} from '../controllers/TranslationContext';
import { ReactComponent as GlobeIcon } from '../../assets/images/globe.svg';
import { ReactComponent as UkFlagIcon } from '../../assets/images/uk-flag.svg';
import { ReactComponent as PtFlagIcon } from '../../assets/images/pt-flag.svg';
import { RadioSelect } from './RadioSelect';

interface OwnProps extends TranslationContext { }

const LanguageSelectorComponent: FunctionComponent<OwnProps> = (props) => {
    const {
        t,
        languages,
        language: languageSelected,
        changeLanguageSelected,
    } = props;

    const renderCountryFlag = (l: AppLanguage) => {
        switch (l) {
            case AppLanguage.EN:
                return <UkFlagIcon />;
            case AppLanguage.PT:
                return <PtFlagIcon />;
            default:
                return <GlobeIcon />;
        }
    };

    return (
        <div className="language-selector">
            <RadioSelect
                name="select-language"
                label={t('languageAndCurrencyPreferences.selectLanguage')}
                options={languages.map((l) => ({ value: l, label: languageNames[l], icon: renderCountryFlag(l) }))}
                value={languageSelected}
                onChange={(_name, value) => changeLanguageSelected(value as AppLanguage)}
            />
        </div>
    );
};

export const LanguageSelector = withTranslationContext(LanguageSelectorComponent);

/*
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FormEventHandler, FunctionComponent, useEffect } from 'react';
import { CmdSignatureContext, withCmdSignatureContext } from '../../../controllers/esignatures/CmdSignatureContext';
import { TranslationContext, withTranslationContext } from '../../../controllers/TranslationContext';

import AutenticacaoGovLogo from '../../../../assets/images/logo-autenticacao.gov-branco.png';
import Button from '../../../elements/Button';
import { EsignatureCommitments } from '../../../elements/EsignatureCommitments';
import { FormField } from '../../../elements/FormField';
import { RectangleStepper } from '../../../elements/RectangleStepper';
import { withOrganizationsContext } from '../../../controllers/OrganizationsContext';
import { EsignaturesLayout } from '../../../elements/esignatures/layouts/EsignaturesLayout';
import { ButtonVariant } from '../../../../types/general';
import { ReactComponent as ArrowRightIcon } from '../../../../assets/images/arrow-right.svg';
import { ReactComponent as ArrowLeftIcon } from '../../../../assets/images/arrow-left.svg';
import { CmdSignatureContextFormActionResult } from '../../../../types/esignatures/cmd';

type BaseProps = TranslationContext & Pick<CmdSignatureContext, 'commitments' | 'submitContext' | 'navigateToSigningPage' | 'loadCommitments'>

/**
 * Contextualizes the electronic signature for Chave Móvel Digital
*
* @param props
* @returns
*/
export const CmdSignatureContextScreenBase: FunctionComponent<BaseProps> = (props) => {
    const {
        t,
        commitments,
        navigateToSigningPage,
        submitContext,
        loadCommitments,
    } = props;

    useEffect(() => {
        loadCommitments();
    }, []);

    /**
    * JSX for the "Next" button used in the existing bottom bar of the layout.
    *
    */
    const nextBtn = (
        <Button
            id="actionNext"
            variant={ButtonVariant.Curved}
            extraClasses="primary"
            isSubmit
            testId="actionNext"
        >
            <span className="btn-content">
                {t('cmdSignatureContextScreen.actionNext')}
                <ArrowRightIcon />
            </span>
        </Button>

    );

    /**
    * JSX for the "Go Back" button used in the existing bottom bar of the layout.
    *
    */
    const goBackBtn = (
        <Button
            id="actionBackToViewSigningContract"
            variant={ButtonVariant.Curved}
            extraClasses="secondary"
            onClick={() => navigateToSigningPage()}
            testId="actionBackToViewSigningContract"
        >
            <span className="btn-content">
                <ArrowLeftIcon />
                {t('cmdSignatureContextScreen.actionBackToViewSigningContract')}
            </span>
        </Button>
    );

    const handleFormSubmit: FormEventHandler = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target as HTMLFormElement);
        const formObject = Object.fromEntries(formData);
        const returnObj: CmdSignatureContextFormActionResult = {
            reason: '',
            location: '',
            commitments: [],
            contact: '',
            identification: '',
            professionalAttributes: '',
        };

        Object.keys(formObject).forEach((k) => {
            if ([
                'contact',
                'identification',
                'location',
                'professionalAttributes',
                'reason',
            ].indexOf(k) >= 0) {
                Object.assign(returnObj, {
                    [k]: typeof formObject[k] === 'string' ? formObject[k] : '',
                });
                return;
            }
            returnObj.commitments.push(k);
        });

        submitContext(returnObj);
    };

    return (
        <form name="cmdSignatureContextForm" onSubmit={handleFormSubmit} id="cmd-signature-context-page" data-testid="cmd-signature-context-page">
            <EsignaturesLayout primaryBtn={nextBtn} secondaryBtn={goBackBtn}>
                <div id="cmd-signature-banner" className="cmd-signature-banner">
                    <img src={AutenticacaoGovLogo} alt="Autenticação.Gov logo" height={25} width="auto" data-testid="cmd-signature-banner" />
                </div>
                <section id="cmd-context-screen" className="cmd-context" data-testid="cmd-context-screen">
                    <div id="cmd-stepper" className="cmd-stepper">
                        <RectangleStepper currrent={0} list={[t('cmdSignatureStepper.context'), t('cmdSignatureStepper.sign')]} testId="cmd-stepper" />
                    </div>
                    <div id="cmd-context-zone" data-testid="cmd-context-zone">
                        <h3 className="cmd-heading">{t('cmdSignatureContextScreen.contextTitle')}</h3>
                        <p>{t('cmdSignatureContextScreen.contextDescription')}</p>
                        <FormField
                            name="reason"
                            label={t('cmdSignatureContextScreen.reasonLabel')}
                            placeholder={t('cmdSignatureContextScreen.reasonPlaceholder')}
                            maxLength={100}
                        />
                        <FormField
                            name="location"
                            label={t('cmdSignatureContextScreen.locationLabel')}
                            placeholder={t('cmdSignatureContextScreen.locationPlaceholder')}
                            maxLength={100}
                        />
                    </div>
                    <div id="cmd-commitments-zone" data-testid="cmd-commitments-zone">
                        <h3 className="cmd-heading">{t('cmdSignatureContextScreen.commitmentsTitle')}</h3>
                        <p>{t('cmdSignatureContextScreen.commitmentsDescription')}</p>
                        <ol data-testid="cmd-commitments-list">
                            {commitments[0] !== null && (
                                <EsignatureCommitments emptyMessage={t('cmdSignatureContextScreen.emptyCommitments')} list={commitments[0]} />
                            )}
                            {/* TO BE REFACTORED WITH ERROR BOUNDARY */}
                            {commitments[1] !== null && (
                                <p>{commitments[1].message}</p>
                            )}
                        </ol>
                    </div>
                </section>
            </EsignaturesLayout>
        </form>
    );
};

/**
 * Electronic signature context screen for Chave Móvel Digital
 */
export const CmdSignatureContextScreen = withTranslationContext(withOrganizationsContext(withCmdSignatureContext(CmdSignatureContextScreenBase)));

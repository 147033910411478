/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    ComponentType,
    FC,
    createContext,
    useContext,
} from 'react';
import { KeycloakLoginOptions, KeycloakRegisterOptions } from 'keycloak-js';

import { AuthenticationController } from './AuthenticationController';
import { User } from '../../types/user';

export interface AuthenticationContext {
    isAuthenticated: boolean;
    isKeycloakReady: boolean;
    user: User | null;
    accessToken: string | undefined;
    authError: unknown;
    redirectToLogin(options?: KeycloakLoginOptions | undefined): void;
    redirectToSignup(options?: KeycloakRegisterOptions | undefined): void;
    requestLogout(): void;
    getAccessToken(): string | undefined;
}

export const authenticationContextDefaultValue: AuthenticationContext = {
    isAuthenticated: false,
    isKeycloakReady: false,
    user: null,
    redirectToLogin(): void { },
    redirectToSignup(): void { },
    requestLogout(): void { },
    getAccessToken(): string | undefined { return undefined; },
    accessToken: undefined,
    authError: undefined,
};

const authenticationContextInstance = createContext<AuthenticationContext>(authenticationContextDefaultValue);

export const AuthenticationContextProvider = authenticationContextInstance.Provider;
export const AuthContextConsumer = authenticationContextInstance.Consumer;

export const useAuthContext = () => useContext(authenticationContextInstance);

export const withAuthContext = <P extends object>(Component: ComponentType<P>): FC<Omit<P, keyof AuthenticationContext>> => (props) => (
    <AuthenticationController>
        <AuthContextConsumer>
            {(ctx) => <Component {...props as P} {...ctx} />}
        </AuthContextConsumer>
    </AuthenticationController>
);

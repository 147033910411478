/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { ContractSignatureUpdate } from '../types/websocket';

interface WebSocketManagerFacade<Receive, Send, Discriminator extends keyof Receive> {
    initConnection(key: Discriminator, initOptions?: { websocketURL: string }): void;
    sendMessage(data: Send): void;
    addHandler<Key extends Receive[Discriminator]>(key: Key | StandardWebsocketEvents, fn: (args: ReceiveMessageDetails<Receive, Discriminator, Key>) => void): void;
    removeHandler<Key extends Receive[Discriminator]>(key: Key | StandardWebsocketEvents, fn: (args: ReceiveMessageDetails<Receive, Discriminator, Key>) => void): void;
    close(): void;
}

const getPingIntervalMS = () => {
    const hasNewValueTimeout = process.env.REACT_APP_WEBSOCKET_TIMEOUT && Number(process.env.REACT_APP_WEBSOCKET_TIMEOUT);

    return (hasNewValueTimeout ? Number(process.env.REACT_APP_WEBSOCKET_TIMEOUT) : 15) * 1000;
};

type StandardWebsocketEvents = 'open' | 'close' | 'error';

export const WSClient = <Receive, Send, Discriminator extends keyof Receive>(options?: { websocketURL: string }): WebSocketManagerFacade<Receive, Send, Discriminator> => {
    abstract class Websocket {
        private static ws: WebSocket;

        private static indexProperty: Discriminator;

        private static handlers: Map<string, ((...args: unknown[]) => void)[]> = new Map();

        private static setIntervalId: ReturnType<typeof setInterval>;

        static initConnection = (indexProperty: Discriminator, initOptions?: { websocketURL: string }) => {
            const url = initOptions?.websocketURL || options?.websocketURL || process.env.REACT_APP_WEBSOCKET_URL;
            if (!url) throw new Error('No WEBSOCKET URL FOUND');

            Websocket.indexProperty = indexProperty;

            Websocket.ws = new WebSocket(url);
            Websocket.ws.onopen = () => Websocket.notifyHandlers('open');
            Websocket.ws.onclose = () => Websocket.notifyHandlers('close');
            Websocket.ws.onerror = () => Websocket.notifyHandlers('error');
            Websocket.ws.onmessage = (a: MessageEvent) => Websocket.onMessage(JSON.parse(a.data));
            Websocket.setIntervalId = setInterval(Websocket.ping, getPingIntervalMS());

            Websocket.addHandler('error', Websocket.close);
            Websocket.addHandler('close', Websocket.close);
        };

        private static notifyHandlers = (type: string, data?: unknown) => {
            const fns = Websocket.handlers.get(type);
            (fns || []).forEach((fn) => (data ? fn(data) : fn()));
        };

        private static ping = () => {
            Websocket.sendMessage({ [Websocket.indexProperty]: 'PING' } as Send);
        };

        private static onMessage = (msg: Record<Discriminator, string> & { data: unknown}) => {
            const { [Websocket.indexProperty]: type, ...data } = msg;
            Websocket.notifyHandlers(type, data);
        };
     
        static addHandler = <Key extends Receive[Discriminator]>(key: Key | StandardWebsocketEvents, fn: (args: ReceiveMessageDetails<Receive, Discriminator, Key>) => void) => {
            const type = String(key);
            const fnUnTyped = fn as (...args: unknown[]) => void; // we inside this class don't care about typing thats Why we have WebSocketManagerI Facade

            if (Websocket.handlers.has(type)) {
                (Websocket.handlers.get(type) as ((...args: unknown[]) => void)[]).push(fnUnTyped);
                return;
            }
            Websocket.handlers.set(type, [fnUnTyped]);
        };

        static removeHandler = <Key extends Receive[Discriminator]>(key: Key | StandardWebsocketEvents, fn: (args: ReceiveMessageDetails<Receive, Discriminator, Key>) => void) => {
            const type = String(key);
            const functionsToNotify = Websocket.handlers.get(type);
            const index = functionsToNotify?.findIndex((b) => b === fn);

            if (functionsToNotify === undefined || index === undefined || index === -1) {
                if (process.env.NODE_ENV === 'development') {
                    // eslint-disable-next-line no-console
                    console.warn('Passed an invalid function pointer, please on React.FC use React.useCallback');
                }
                return;
            }

            functionsToNotify.splice(index, 1);
        };

        static sendMessage = (data: Send) => {
            Websocket.ws.send(JSON.stringify(data));
        };

        static close = () => {
            if (Websocket.ws) {
                Websocket.ws.close();
            }

            if (Websocket.setIntervalId) {
                clearInterval(Websocket.setIntervalId);
            }
        };
    }
    return Websocket;
};

export type ReceiveMessageDetails<Receive, Discriminator extends keyof Receive, Key extends Receive[Discriminator]> = Omit<Extract<Receive, Record<Discriminator, Key>>, Discriminator>;

export type Receive = ContractSignatureUpdate;
export type Send = unknown;

export const WS = WSClient<Receive, Send, 'type'>();

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    ClickAwayListener,
    Grow,
    IconButton,
    IconButtonProps,
    MenuItem,
    MenuList as MenuListMUI,
    Paper,
    Popper,
} from '@mui/material';
import React, {
    FunctionComponent,
    ReactNode,
    useRef,
    useState,
} from 'react';

import { MenuListOptions } from '../../types/general';
import { ReactComponent as VerticalDotsIcon } from '../../assets/images/vertical-dots.svg';
import { Tooltip } from './Tooltip';

type BaseProps = Pick<IconButtonProps, 'title'>

type OwnProps = BaseProps & {
    icon?: ReactNode;
    options: MenuListOptions[];
    testId?: string;
}

const ButtonWithMenuList: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        options,
        title,
        testId = 'options-icon',
        icon = <Tooltip title={title} placement="bottom"><VerticalDotsIcon fill="primary" /></Tooltip>,
    } = props;

    const anchorRef = useRef<HTMLButtonElement>(null);
    const [isOpenOptions, setIsOpenOptions] = useState<boolean>(false);

    /**
     * Handle Keyboard Events for Composition Menu
     *
     * @param {React.KeyboardEvent} event - The keyboard event.
     *
     * @remarks
     * This function is used to handle keyboard events for the composition menu.
     * It closes the menu if the 'Tab' key is pressed to prevent tabbing out of the menu.
     * It also closes the menu if the 'Escape' key is pressed.
     */
    const handleListKeyDown = (event: React.KeyboardEvent) => {
        switch (event.key) {
            case 'Tab':
                event.preventDefault();
                break;
            case 'Escape':
            default:
                // NOOP for now
                break;
        }
        setIsOpenOptions(false);
    };

    return (
        <div className="button-with-menu-list">
            <IconButton
                ref={anchorRef}
                aria-label="more"
                id="long-button"
                aria-controls={isOpenOptions ? 'long-menu' : undefined}
                aria-expanded={isOpenOptions ? 'true' : undefined}
                aria-haspopup="true"
                onClick={(e) => {
                    e.stopPropagation();
                    setIsOpenOptions(true);
                }}
                data-testid={testId}
            >
                {icon}
            </IconButton>
            <Popper
                sx={{ zIndex: 99 }}
                open={isOpenOptions}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                        placement === 'bottom-start' ? 'left top' : 'left bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={() => setIsOpenOptions(false)}>
                                <MenuListMUI
                                    autoFocusItem={isOpenOptions}
                                    id="composition-menu"
                                    aria-labelledby="composition-button"
                                    onKeyDown={handleListKeyDown}
                                >
                                    {Object.values(options).map((option) => (
                                        <MenuItem
                                            key={option.value}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                option.action();
                                            }}
                                            data-testid={option.testId ?? ''}
                                            disabled={option.disabled}
                                            aria-disabled={option.disabled}
                                        >{option.value}
                                        </MenuItem>
                                    ))}
                                </MenuListMUI>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
};

export { ButtonWithMenuList };

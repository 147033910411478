/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Grid, Tab } from '@mui/material';
import {
    FunctionComponent, SyntheticEvent, useEffect, useState,
} from 'react';
import { PlanType, SubscriptionPlan } from '../../../types/billing';
import { BillingContext, withBillingContext } from '../../controllers/BillingContext';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import { PlanCard } from './PlanCard';

interface OwnProps extends TranslationContext, BillingContext {
    availablePlans: SubscriptionPlan[];
    onCardClick?: (plan: SubscriptionPlan) => void;
    activePlan?: SubscriptionPlan;
}

const PlansListBase: FunctionComponent<OwnProps> = (props) => {
    const {
        t,
        availablePlans,
        billingInfo,
        onCardClick,
        activePlan,
    } = props;
    const [planType, setPlanType] = useState<PlanType>(PlanType.INDIVIDUAL);

    const individualPlans = availablePlans.filter((plan) => plan.subscriptionType === PlanType.INDIVIDUAL);
    const teamPlans = availablePlans.filter((plan) => plan.subscriptionType === PlanType.TEAM);

    const handleChange = (_: SyntheticEvent, newValue: PlanType) => {
        setPlanType(newValue);
    };

    useEffect(() => {
        if (billingInfo.planDetails?.subscriptionType === PlanType.TEAM) {
            setPlanType(PlanType.TEAM);
        }
    }, []);

    return (
        <div className="plans-screen__list" data-testid="plans-screen-list">
            <Box>
                <TabContext value={planType}>
                    <Box>
                        <TabList onChange={handleChange} data-testid="tab-list">
                            <Tab label={t('plans.typeIndividual')} value={PlanType.INDIVIDUAL} />
                            <Tab label={t('plans.typeTeam')} value={PlanType.TEAM} />
                        </TabList>
                    </Box>
                    <TabPanel value={PlanType.INDIVIDUAL}>
                        <div className="plans-screen__list__individual-plans">
                            <Grid container spacing={2}>
                                {individualPlans
                                    .filter((plan) => availablePlans.some((availablePlan) => plan.id === availablePlan.id))
                                    .map((plan) => {
                                        return (
                                            <Grid item key={plan.id} xs={12} md={6} lg={4}>
                                                <PlanCard plan={plan} isActive={plan.id === activePlan?.id} onClick={onCardClick} />
                                            </Grid>
                                        );
                                    })}
                            </Grid>
                        </div>
                    </TabPanel>
                    <TabPanel value={PlanType.TEAM}>
                        <div className="plans-screen__list__team-plans">
                            <Grid container spacing={2}>
                                {teamPlans
                                    .filter((plan) => availablePlans.some((availablePlan) => plan.id === availablePlan.id))
                                    .map((plan) => {
                                        return (
                                            <Grid item key={plan.id} xs={12} md={6} lg={4}>
                                                <PlanCard plan={plan} isActive={plan.id === activePlan?.id} onClick={onCardClick} />
                                            </Grid>
                                        );
                                    })}
                            </Grid>
                        </div>
                    </TabPanel>
                </TabContext>
            </Box>
        </div>
    );
};

export const PlansList = withBillingContext(withTranslationContext(PlansListBase));

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import React, { FunctionComponent, useState } from 'react';
import {
    useNavigate,
    useParams,
} from 'react-router-dom';
import { AxiosResponse } from 'axios';
import {
    CmdRequestResponse,
    CmdResendOtpResponse,
    CmdSignatureCommitments,
    CmdSignatureContextFormActionResult,
    CmdValidateResponse,
    RequestSignaturePayload,
} from '../../../types/esignatures/cmd';
import { CmdSignatureContext, CmdSignatureContextProvider } from './CmdSignatureContext';
import { DataOrError, HttpDataOrError } from '../../../types/errors';
import { TranslationContext, withTranslationContext } from '../TranslationContext';
import {
    commitmentTypesUrl,
    forceOtpUrl,
    getEncryptionPublicKey,
    requestSignatureUrl,
    validateOtpUrl,
} from '../../../services/esignatures/cmd';

import { AppRoute } from '../../../constants/routes';
import { EsignatureCommitment } from '../../../types/esignature';
import { buildUrl } from '../../../utils/navigation';
import { encryptWithPublicKey } from '../../../utils/encryption';
import { useCornerstoneApi } from '../../../api';

interface OwnProps {
    children: React.ReactNode;
}

type Props = OwnProps & TranslationContext;

type CmdSignaturePathParams = {
    contractId: string;
    signerId: string;
}

/**
 * Controls data loading and API calls for CMD Signature Experience
 *
 * @param props Props
 * @returns
 */
export const CmdSignatureControllerBase: FunctionComponent<Props> = (props: Props) => {
    const { children, t } = props;
    const params = useParams() as CmdSignaturePathParams;
    const navigate = useNavigate();
    const [commitments, setCommitments] = useState<DataOrError<Array<EsignatureCommitment>>>([[], null]);
    const [contextFormData, setContextFormData] = useState<CmdSignatureContextFormActionResult>({
        commitments: [],
        contact: '',
        identification: '',
        location: '',
        professionalAttributes: '',
        reason: '',
    });
    const CornerstoneApiInstance = useCornerstoneApi();

    const cypherWithAMA: CmdSignatureContext['cypherWithAMA'] = (data) => {
        return encryptWithPublicKey(data, getEncryptionPublicKey());
    };

    /**
     * Translates CMD commitments to be rendered
     *
     * @param cmdCommitments {CmdSignatureCommitments}
     * @returns {Array<EsignatureCommitment>}
     */
    const translateCommitments = (cmdCommitments: CmdSignatureCommitments): Array<EsignatureCommitment> => {
        return cmdCommitments.map((c) => ({
            commitmentType: c.commitmentType,
            commitmentName: t(`esignatureCommitments.nameDefaults.${c.commitmentType}`),
            commitmentDescription: t(`esignatureCommitments.descriptionDefaults.${c.commitmentType}`),
            uri: c.uri,
            oid: c.oid,
        })).sort((curr, next) => curr.commitmentName.localeCompare(next.commitmentName));
    };

    /**
     * Calls validate CMD OTP APi
     *
     * @param payload
     * @returns {ReturnType<CmdSignatureContext['validateOTP']>}
     */
    const validateOTP: CmdSignatureContext['validateOTP'] = (payload) => {
        const cypheredOtp = cypherWithAMA(payload.otp);

        return new Promise<HttpDataOrError<CmdValidateResponse>>((resolve) => {
            CornerstoneApiInstance.post<CmdValidateResponse>(validateOtpUrl(params.signerId), {
                otp: cypheredOtp,
            }).then((res) => {
                resolve([res.data, null]);
            }).catch((e) => {
                resolve([null, e]);
            });
        });
    };

    /**
     * Calls request CMD signature API
     *
     * @param payload
     * @returns
     */
    const requestSignature: CmdSignatureContext['requestSignature'] = (payload) => {
        return new Promise<HttpDataOrError<CmdRequestResponse>>((resolve, reject) => {
            const phoneCyphered = cypherWithAMA(payload.phone);
            const pinCyphered = cypherWithAMA(payload.pin);

            if (!phoneCyphered) {
                reject('Failed to cypher phone');
                return;
            }
            if (!pinCyphered) {
                reject('Failed to cypher phone');
                return;
            }

            CornerstoneApiInstance.post<RequestSignaturePayload, AxiosResponse<CmdRequestResponse>>(requestSignatureUrl(params.signerId), {
                ...contextFormData,
                phoneCypher: phoneCyphered,
                pinChyper: pinCyphered,
                location: payload.location,
                reason: payload.reason,
                commitments: payload.commitments,
            }).then((res) => {
                resolve([res.data, null]);
            }).catch((e) => {
                resolve([null, e]);
            });
        });
    };

    /**
     * Calls resend OTP API
     *
     * @param payload
     * @returns {CmdSignatureContext['resendOTP']}
     */
    const resendOTP: CmdSignatureContext['resendOTP'] = (payload) => {
        return new Promise<HttpDataOrError<CmdResendOtpResponse>>((resolve) => {
            CornerstoneApiInstance.post(forceOtpUrl(params.signerId), {
                clientId: payload.clientId,
            }).then((res) => {
                resolve([res.data, null]);
            }).catch((e) => {
                resolve([null, e]);
            });
        });
    };

    /**
     * Loads and translates CMD electronic signature commitments from API
     *
     * @returns {ReturnType<CmdSignatureContext>}
     */
    const loadCommitments: CmdSignatureContext['loadCommitments'] = () => {
        return new Promise((resolve) => {
            CornerstoneApiInstance.get<CmdSignatureCommitments>(commitmentTypesUrl()).then(((res) => {
                const translatedCommitments = translateCommitments(res.data);
                setCommitments([translatedCommitments, null]);
                resolve([translatedCommitments, null]);
            })).catch((err) => {
                resolve([null, err]);
            });
        });
    };

    /**
     * Navigates to signing page
     * @returns {ReturnType<NavigateFunction>}
     */
    const navigateToSigningPage = () => {
        return navigate(buildUrl(AppRoute.ShowContract, params));
    };

    /**
     * Navigates to CMD context page
     * @returns {ReturnType<NavigateFunction>}
     */
    const navigateToContextPage = () => {
        return navigate(buildUrl(AppRoute.CmdSignatureContext, params));
    };

    /**
     * Navigates to CMD credentials page
     * @returns {ReturnType<NavigateFunction>}
     */
    const navigateToCredentialsPage = () => {
        return navigate(buildUrl(AppRoute.CmdSignatureCredentials, params));
    };

    /**
     * Handles navigation to CMD OTP page
     * @param fileName the file name being signed
     * @returns {ReturnType<NavigateFunction>}
     */
    const navigateToOtpPage = (fileName: string, phone: string) => {
        const searchQuery = new URLSearchParams();
        searchQuery.append('fileName', fileName);
        searchQuery.append('clientId', String(cypherWithAMA(phone)));
        return navigate({
            pathname: buildUrl(AppRoute.CmdSignatureOtp, params),
            search: searchQuery.toString(),
        });
    };

    /**
     * Navigates to Contract Signed page
     * @returns {ReturnType<NavigateFunction>}
     */
    const navigateToContractSignedPage = () => {
        const searchQuery = new URLSearchParams();
        searchQuery.append('signSuccess', 'true');
        return navigate({
            pathname: buildUrl(AppRoute.ShowContract, params),
            search: searchQuery.toString(),
        });
    };

    /**
     * Handles CMD Context Screen submission
     * @param payload electronic signature context
     * @returns {ReturnType<NavigateFunction>}
     */
    const submitContext: CmdSignatureContext['submitContext'] = (payload) => {
        setContextFormData(payload);

        const searchQuery = new URLSearchParams();
        searchQuery.append('reason', payload.reason);
        searchQuery.append('location', payload.location);

        const commitmentsString = JSON.stringify(payload.commitments);
        searchQuery.set('commitments', encodeURIComponent(commitmentsString));
          
        return navigate({
            pathname: buildUrl(AppRoute.CmdSignatureCredentials, params),
            search: searchQuery.toString(),
        });
    };
    
    return (
        <CmdSignatureContextProvider
            value={{
                submitContext,
                commitments,
                loadCommitments,
                validateOTP,
                requestSignature,
                resendOTP,
                navigateToSigningPage,
                navigateToContextPage,
                navigateToCredentialsPage,
                navigateToOtpPage,
                navigateToContractSignedPage,
                cypherWithAMA,
                contextFormData,
            }}
        >
            {children}
        </CmdSignatureContextProvider>
    );
};

export const CmdSignatureController = withTranslationContext(CmdSignatureControllerBase);

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FunctionComponent,
    useEffect,
    useState,
} from 'react';
import { useContractContext } from '../../elements/contracts/ContractContextProvider';
import { ErrorResponse } from '../../../types/errors';
import { UploadContractScreen } from './UploadContractScreen';
import { preparePageTitle } from '../../../utils/route';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';

interface OwnProps extends TranslationContext { }

const CreateContractScreenBase: FunctionComponent<OwnProps> = ({ t }) => {
    const [uploadError, setUploadError] = useState<null | ErrorResponse>(null);
    useEffect(() => {
        document.title = preparePageTitle(t('contractCreate.title'));
    }, []);
    useEffect(() => {
        if (uploadError) throw uploadError;
    }, [uploadError]);

    const { uploadContract, navigateToDraftContractPage } = useContractContext();
    const [isSubmitting, setIsSubmitting] = useState(false);
    
    /**
     * Callback for File Selection
     *
     * @remarks
     * Update file and contractFields name states
     */
    const handleFileSelected = async (documentFile: File | undefined, selectedContractTypeId: number) => {
        if (!documentFile) return;
        
        setIsSubmitting(true);

        const [contract, contractError] = await uploadContract(documentFile, selectedContractTypeId);
        if (contract) {
            setIsSubmitting(false);
            navigateToDraftContractPage(String(contract.id));
            return;
        }

        if (contractError) {
            setUploadError(contractError);
        }
    };
    
    return (
        <UploadContractScreen
            isSubmitting={isSubmitting}
            onFileSelected={handleFileSelected}
        />
    );
};

export const CreateContractScreen = withTranslationContext(CreateContractScreenBase);

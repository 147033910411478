/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { PermissionList } from './permissions';

export enum WorkflowType {
    SELL = 'SELL',
    RENTAL = 'RENTAL',
    EXCHANGE = 'EXCHANGE',
}

export enum WorkflowFrequencyType {
    NONE = 'NONE',
    MONTHLY = 'MONTHLY',
    YEARLY = 'YEARLY',
}

export enum ComissionType {
    FIXED = 'FIXED',
    PERCENTAGE = 'PERCENTAGE',
    RENT = 'RENT',
    NONE = 'NONE',
}

export interface WorkflowResponseType {
    transactionType: WorkflowType;
    description: string;
    comissionTypesAllowed: ComissionType[];
    frequencyTypes: WorkflowFrequencyType[];
}

export interface WorkflowPayload {
    title: string;
    description: string;
    transactionType: WorkflowType;
    comissionType: ComissionType;
    comissionValue: number | null;
    comissionCurrency?: string;
    transactionValue: number | null;
    transactionCurrency: string;
    frequencyType: WorkflowFrequencyType;
    cmi?: string;
    expiresAt: string;
    externalId: string;
    walletForPaymentId?: number;
}

export interface Workflow {
    id: number;
    createdDate: string;
    lastModifiedDate: string | null;
    title: string;
    description: string;
    transactionType: WorkflowType;
    transactionValue: number;
    transactionCurrency: string;
    comissionType: ComissionType;
    comissionValue: number;
    comissionCurrency: string;
    frequencyType: WorkflowFrequencyType;
    status: WorkflowStateEnumType;
    expiresAt: string;
    externalId: string;
    userOwnerId: string;
    organizationId: number;
    propertyId: number | null;
    cmi: string | null;
    property: WorkflowProperty | null;
}

export enum SellWorkflowStep {
    GENERAL_INFO = 'GENERAL_INFO',
    PROPERTY = 'PROPERTY',
    PARTICIPANTS = 'PARTICIPANTS',
}

export interface CurrencyInfo {
    symbol: string;
    name: string;
    symbol_native: string;
    decimal_digits: number;
    rounding: number;
    code: string;
    name_plural: string;
}

export enum PropertyTypeEnum {
    APARTMENT = 'APARTMENT',
    WAREHOUSE = 'WAREHOUSE',
    OFFICE = 'OFFICE',
    GARAGE = 'GARAGE',
    STORE = 'STORE',
    DWELLING = 'DWELLING',
    BUILDING = 'BUILDING',
    VILLA = 'VILLA',
    LAND = 'LAND',
}
export enum PropertyTypologyEnum {
    T0 = 'T0',
    T1 = 'T1',
    T2 = 'T2',
    T3 = 'T3',
    T4 = 'T4',
    T5 = 'T5',
    T6 = 'T6',
    T7 = 'T7',
    T8PLUS = 'T8PLUS',
}
export interface PropertyType {
    propertyType: PropertyTypeEnum;
    description: string;
}

export interface PropertyTypology {
    typology: PropertyTypologyEnum;
    description: string;
}

export enum MatrixArticleType {
    URBAN = 'U',
    RUSTIC = 'R',
}
export interface WorkflowPropertyPayload {
    propertyType: PropertyTypeEnum;
    typology: PropertyTypologyEnum;
    address: string;
    district: number | null;
    county: number | null;
    parish: number | null;
    zone: string;
    matrixType: MatrixArticleType;
    matrixArticle: string;
    matrixFraction: string;
}
export interface WorkflowProperty {
    id: number;
    createdDate: string;
    lastModifiedDate: string;
    propertyType: PropertyTypeEnum;
    typology: PropertyTypologyEnum;
    address: string;
    district: LocationEntity;
    county: LocationEntity;
    parish: LocationEntity;
    zone: string;
    matrixType: MatrixArticleType;
    matrixArticle: string;
    matrixFraction: string;
    uniqueId: string;
}

export interface LocationEntity {
    id: number;
    createdDate?: string;
    lastModifiedDate?: string;
    name: string;
    code?: string;
}
export interface LocationPayload {
    l1Id?: number;
    l2Id?: number;
}
export interface Participant {
    id: number;
    userId?: string;
    createdDate: string;
    lastModifiedDate: string;
    transactionId: number;
    inviteCreatorId: string;
    inviteEmail: string;
    inviteExpiresAt: string;
    name: string;
    participantRole: WorkflowParticipantRole;
}

export interface ParticipantPermissionsList {
    participant: Participant;
    organizationPermissions: PermissionList;
    transactionPermissions: ResourcePermissionsType[];
    documents: ResourcePermission[];
    contracts: ResourcePermission[];
}
export interface ParticipantPermissions {
    role: WorkflowParticipantRole;
    transaction: PermissionList;
    documents: PermissionList;
    contracts: PermissionList;
}
export interface ParticipantPermissionsPayload {
    transaction?: PermissionList;
    documents?: PermissionList;
    contracts?: PermissionList;
}

export interface ParticipantPayload {
    email: string;
    name: string;
    participantRole: WorkflowParticipantRole;
}

export enum WorkflowParticipantRole {
    TRANSACTION_OWNER = 'TRANSACTION_OWNER',
    ADMIN = 'ADMIN',
    PROPERTY_OWNER = 'PROPERTY_OWNER',
    BUYER = 'BUYER',
    TENANT = 'TENANT',
    GUARANTOR = 'GUARANTOR',
    LAWYER = 'LAWYER',
}
export enum SellWorkflowStateEnum {
    SELL_RAISING = 'SELL_RAISING',
    SELL_PENDING_APPROVAL = 'SELL_PENDING_APPROVAL',
    SELL_NEGOTIATION = 'SELL_NEGOTIATION',
    SELL_BUYING_PROMISSORY_AGREEMENT = 'SELL_BUYING_PROMISSORY_AGREEMENT',
    SELL_DEED = 'SELL_DEED',
    SELL_CANCELED = 'SELL_CANCELED',
    SELL_CLOSED = 'SELL_CLOSED',
}
export enum RentalWorkflowStateEnum {
    RENTAL_RAISING = 'RENTAL_RAISING',
    RENTAL_PENDING_APPROVAL = 'RENTAL_PENDING_APPROVAL',
    RENTAL_LISTED = 'RENTAL_LISTED',
    RENTAL_NEGOTIATION = 'RENTAL_NEGOTIATION',
    RENTAL_PROPOSAL_APPROVED = 'RENTAL_PROPOSAL_APPROVED',
    RENTAL_RENTED = 'RENTAL_RENTED',
    RENTAL_CANCELED = 'RENTAL_CANCELED',
}
export const WorkflowStateEnum = {
    ...SellWorkflowStateEnum,
    ...RentalWorkflowStateEnum,
} as const;

export type WorkflowStateEnumType = string;
export interface WorkflowState {
    transactionState: WorkflowStateEnumType;
    description: string;
}
export interface WorkflowCurrentStateSteps {
    currentState: WorkflowStateEnumType;
    nextState: WorkflowStateEnumType;
    previousState: WorkflowStateEnumType;
}
export interface WorkflowStatePayload {
    state: WorkflowStateEnumType;
    notes?: string;
}
export interface WorkflowAssignmentResponse {
    missingDocs: number;
    transactionAssignments: WorkflowAssignment[];
}

export interface WorkflowAssignment {
    documentTypeId: number;
    documentTypeCode: string;
    documentTypeName: string;
    assignment: DocumentChecklistAssignment;
    participantRole: WorkflowParticipantRole;
    participantId: string;
    participantName: string;
    documentId: number;
    documentName: string;
    status: DocumentStatus;
}

export interface ParticipantAssignPinValidationPayload {
    authenticationPIN: string;
}

export enum DocumentChecklistAssignment {
    UPLOAD = 'UPLOAD',
    REVIEW = 'REVIEW',
}

export interface WorkflowDocument {
    id: number;
    createdDate: string;
    lastModifiedDate: string;
    ownerId: string;
    organizationId: number;
    name: string;
    documentType: string;
    externalId: string;
    documentStatus: DocumentStatus;
    documentNotes: string;
    transactionId: number;
    lastUserInteractionName: string;
    lastUserInteractionId: string;
    downloadUrl: string;
}

export enum DocumentStatus {
    APPROVED = 'APPROVED',
    PENDING = 'PENDING',
    REJECTED = 'REJECTED',
}

export interface WorkflowDocumentUploadPayload {
    file: File;
    documentType: number;
    name: string;
    userId?: string;
    notes?: string;
}

export interface WorkflowDocumentType {
    id: number;
    createdDate: string;
    lastModified: string;
    name: string;
    country: string;
    organizationId: number;
}

export interface WorkflowsFilterParams {
    _cursor?: string;
    _limit?: string;
    _sort?: string;
    email?: string;
    externalId?: string;
    from?: string;
    to?: string;
    transactionType?: WorkflowType;
    transactionStatus?: WorkflowStateEnumType;
}

export enum ResourceType {
    NONE = 'NONE',
    CONTRACT = 'CONTRACT',
    TRANSACTION = 'TRANSACTION',
    DOCUMENT = 'DOCUMENT',
}

export enum ResourcePermissionsType {
    NONE = 'NONE',
    VIEW = 'VIEW',
    MANAGE = 'MANAGE',
    SIGNING = 'SIGNING',
    REVIEW = 'REVIEW',
}

export interface ResourcePermission {
    id: number;
    permissions: ResourcePermissionsType[];
}

export enum WorkflowResourceRequestStatus {
    ACCEPTED = 'ACCEPTED',
    REJECTED = 'REJECTED',
    PENDING = 'PENDING',
}

export interface WorkflowResourceRequest {
    id: number;
    createDate: string;
    lastModifiedDate: string;
    transactionId: number;
    participantId: number;
    transactionTitle: string;
    participantName: string;
    resourceName: string;
    resourceId: number;
    requestToken: string;
    requestExpiresAt: string;
    resourceType: ResourceType;
    permissionType: ResourcePermissionsType;
    status: WorkflowResourceRequestStatus;
}

export interface WorkflowResourceRequestAccess {
    resourceType: ResourceType;
    permissionType: ResourcePermissionsType;
}

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FormEventHandler,
    FunctionComponent,
    useEffect,
    useState,
} from 'react';
import { Backdrop } from '@mui/material';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { AesSignatureContext, withAesSignatureContext } from '../../../controllers/esignatures/AesSignatureContext';
import { ContractsContext, withContractsContext } from '../../../controllers/ContractsContext';
import { TranslationContext, withTranslationContext } from '../../../controllers/TranslationContext';

import Button from '../../../elements/Button';
import { ButtonVariant } from '../../../../types/general';
import { CodeType } from '../../../../types/esignatures/aes';
import ElectronicIDIcon from '../../../../assets/images/electronic-logo-short.png';
import ElectronicIDLogo from '../../../../assets/images/logo-electronic-id.png';
import { ErrorResponse } from '../../../../types/errors';
import { EsignatureLoadingModal } from '../../../elements/esignatures/common/EsignatureLoadingModal';
import { EsignaturesLayout } from '../../../elements/esignatures/layouts/EsignaturesLayout';
import { Signer } from '../../../../types/contracts';
import { SmartCodeFormField } from '../../../elements/SmartCodeFormField';

interface OwnProps extends TranslationContext, AesSignatureContext, ContractsContext {
    codeType: CodeType;
}

const CODE_LENGTH = 6;

const AesSignatureCodeScreenComponent: FunctionComponent<OwnProps> = (props) => {
    const {
        t,
        tWithJSX,
        codeType,
        getSigner,
        validateSignature,
        requestSignature,
        navigateToContract,
        navigateToContractSignedPage,
        navigateToAesContextScreen,
    } = props;
    const { signerId = '', contractId = '' } = useParams();

    const [isPreparing, setIsPreparing] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [codeError, setCodeError] = useState<boolean>(false);
    const [preparingError, setPreparingError] = useState<ErrorResponse | null>(null);
    const [signer, setSigner] = useState<Signer | null>(null);

    const contactValue = codeType === CodeType.PHONE ? signer?.phoneNumber : signer?.inviteEmail;

    useEffect(() => {
        if (preparingError) {
            throw preparingError;
        }
    }, [preparingError]);

    useEffect(() => {
        prepareGetSigner();
        preparingSigning();
    }, []);

    /**
     * This function gets the signer
     *
     * @remarks
     * Signer data (phone number and email) is needed for this screen
     */
    const prepareGetSigner = async () => {
        setIsPreparing(true);
        setCodeError(false);

        // get signer
        const [requestedSigner, getSignerError] = await getSigner(Number(contractId), Number(signerId));
        if (requestedSigner) {
            setSigner(requestedSigner);
        }

        if (getSignerError) {
            toast.error(getSignerError.errors[0].getMessageTranslated(t) || t('errors.general'));
        }
    };

    /**
     * This function calls requestSignature to start AES signature flow
     *
     * @remarks
     * If it receive error it render that specific error page
     *
     * We created an exception for error 60, this error happens
     * when the signer started the signature flow before (in past 5 minutes) and quited,
     * we will allow display this component to user can insert the validating code he has already received.
     */
    const preparingSigning = async () => {
        setIsPreparing(true);
        setCodeError(false);

        // request signature
        const err = await requestSignature({ otpType: codeType });

        // Exception if user have quited and then return to signature flow
        if (err && err.errors[0].errorCode !== 60) {
            setPreparingError(err);
        }

        setIsPreparing(false);
    };

    const handleValidateCodeSubmit: FormEventHandler = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        const formData = new FormData(e.target as HTMLFormElement);

        const err = await validateSignature({ otpType: codeType, otpCode: String(formData.get('pin')) });

        if (err) {
            setCodeError(true);

            setIsSubmitting(false);
        } else {
            navigateToContractSignedPage();
        }
    };

    /**
    * JSX for the "Verify" button used in the existing bottom bar of the layout.
    *
    */
    const verifyBtn = (
        <Button
            id="actionNext"
            variant={ButtonVariant.Curved}
            extraClasses="primary"
            testId="actionNext"
            isSubmit
        >
            <span className="btn-content">
                {t('aesSignatureCodeScreen.actionVerify')}
            </span>
        </Button>
    );

    const handleOtpChange = () => {
        if (codeError) {
            setCodeError(false);
        }
    };

    /**
    * JSX for the "Go Back" button used in the existing bottom bar of the layout.
    *
    */
    const goBackBtn = (
        <Button
            id="actionBackToViewSigningContract"
            variant={ButtonVariant.Curved}
            extraClasses="secondary"
            onClick={navigateToContract}
            testId="actionBackToViewSigningContract"
        >
            <span className="btn-content">
                {t('aesSignatureCodeScreen.actionGoBack')}
            </span>
        </Button>
    );

    return (
        <>
            {isPreparing && (
                <Backdrop open>
                    <EsignatureLoadingModal
                        open
                        providerIconSrc={ElectronicIDIcon}
                        message={t('aesSignatureCodeScreen.preparingModal.title', { value: codeType })}
                        disclaimer={t('aesSignatureCodeScreen.preparingModal.disclaimer')}
                    />
                </Backdrop>
            )}
            {isSubmitting && (
                <Backdrop open>
                    <EsignatureLoadingModal
                        open
                        providerIconSrc={ElectronicIDIcon}
                        message={t('aesSignatureCodeScreen.loadingModal.title')}
                        disclaimer={t('aesSignatureCodeScreen.loadingModal.disclaimer')}
                    />
                </Backdrop>
            )}
            {!isPreparing && !isSubmitting && (
                <form onSubmit={handleValidateCodeSubmit} autoComplete="off">
                    <EsignaturesLayout primaryBtn={verifyBtn} secondaryBtn={goBackBtn}>
                        <div className="aes-signature-banner">
                            <img src={ElectronicIDLogo} alt="Electronic IDentification logo" height={25} width="auto" />
                        </div>
                        <section className="aes-context">
                            <div className="aes-context__header">
                                <h3 className="aes-heading">{t(`aesSignatureCodeScreen.${codeType.toLowerCase()}.title`)}</h3>
                                <p>{tWithJSX(`aesSignatureCodeScreen.${codeType.toLowerCase()}.description`, { value: contactValue })}</p>
                            </div>
                            <SmartCodeFormField id="pin" name="pin" size={CODE_LENGTH} hasError={!!codeError} onValuesChange={handleOtpChange} />
                            {codeError && (
                                <p className="aes-context__error">
                                    {t('aesSignatureCodeScreen.codeError')}
                                </p>
                            )}
                            <div className="aes-context__try-again">
                                <p>{t('aesSignatureCodeScreen.didNotReceiveCode')}</p>
                                <span onClick={preparingSigning}>{t('aesSignatureCodeScreen.resend')}</span>
                            </div>
                            <p
                                className="aes-context__change-method"
                                onClick={navigateToAesContextScreen}
                            >
                                {t('aesSignatureCodeScreen.changeAuthenticationMethod')}
                            </p>
                        </section>
                    </EsignaturesLayout>
                </form>
            )}
        </>
    );
};

export const AesSignatureCodeScreen = withContractsContext(withAesSignatureContext(withTranslationContext(AesSignatureCodeScreenComponent)));

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { useNavigate, useParams } from 'react-router-dom';
import {
    FormEventHandler, FunctionComponent, useState,
} from 'react';
import { WorkflowContext, withWorkflowContext } from '../../controllers/WorkflowContext';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import { LoadingScreen } from '../LoadingScreen';
import { DefaultLayout } from '../../elements/layouts/DefaultLayout';
import { AppRoute } from '../../../constants/routes';
import { buildUrl } from '../../../utils/navigation';
import { SmartCodeFormField } from '../../elements/SmartCodeFormField';
import Button from '../../elements/Button';
import { ButtonVariant } from '../../../types/general';
import { ParticipantAssignPinValidationPayload } from '../../../types/workflows';

type OwnProps = TranslationContext & WorkflowContext;

const ParticipantAssignPinValidationScreenBase: FunctionComponent<OwnProps> = (props) => {
    const {
        t,
        validateParticipantAssignPinCode,
    } = props;
    const { participantToken = '' } = useParams();
    const navigate = useNavigate();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [codeError, setCodeError] = useState(false);

    const prepareValidatePinSubmit: FormEventHandler = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        const formData = new FormData(e.target as HTMLFormElement);
        const payload: ParticipantAssignPinValidationPayload = {
            authenticationPIN: String(formData.get('pin')),
        };

        const [assignedParticipant, pinValidationError] = await validateParticipantAssignPinCode(participantToken, payload);
        if (assignedParticipant) {
            navigate(buildUrl(AppRoute.ViewWorkflow, { workflowId: String(assignedParticipant.transactionId) }));
        }

        if (pinValidationError) {
            setCodeError(true);
        }
        setIsSubmitting(false);
    };

    const handleOtpChange = () => {
        if (codeError) {
            setCodeError(false);
        }
    };

    /**
    * JSX for the "Verify" button used in the existing bottom bar of the layout.
    *
    */
    const verifyBtn = (
        <Button
            id="actionNext"
            variant={ButtonVariant.Curved}
            extraClasses="primary"
            testId="actionNext"
            isSubmit
        >
            <span className="btn-content">
                {t('workflows.participants.assignPinValidation.actionVerify')}
            </span>
        </Button>
    );
    
    /**
    * JSX for the "Go Back" button used in the existing bottom bar of the layout.
    *
    */
    const goBackBtn = (
        <Button
            id="actionBackToViewSigningContract"
            variant={ButtonVariant.Curved}
            extraClasses="secondary"
            onClick={() => navigate(AppRoute.Workflows)}
            testId="actionBack"
        >
            <span className="btn-content">
                {t('aesSignatureCodeScreen.actionGoBack')}
            </span>
        </Button>
    );

    return (
        <form onSubmit={prepareValidatePinSubmit} autoComplete="off">
            <DefaultLayout primaryBtn={!isSubmitting && verifyBtn} secondaryBtn={!isSubmitting && goBackBtn}>
                {isSubmitting && <LoadingScreen />}
                {!isSubmitting && (
                    <div className="participant-assign-pin-validation-screen">
                        <div className="participant-assign-pin-validation-screen__header">
                            <h3 className="aes-heading">{t('workflows.participants.assignPinValidation.title')}</h3>
                            <p>{t('workflows.participants.assignPinValidation.description')}</p>
                        </div>
                        <SmartCodeFormField id="pin" name="pin" size={4} hasError={!!codeError} onValuesChange={handleOtpChange} />
                        {codeError && (
                            <p className="aes-context__error">
                                {t('workflows.participants.assignPinValidation.codeError')}
                            </p>
                        )}
                    </div>
                )}
            </DefaultLayout>
        </form>
    );
};

export const ParticipantAssignPinValidationScreen = withTranslationContext(withWorkflowContext(ParticipantAssignPinValidationScreenBase));

/*
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent } from 'react';
import { LoadingCircles } from '../elements/LoadingCircles';

const LoadingScreen: FunctionComponent = () => {
    return (
        <div className="loading-screen" data-testid="loading-screen">
            <LoadingCircles size="s" variant="primary" />
        </div>
    );
};

export { LoadingScreen };

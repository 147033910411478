/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

/**
 * Current architecture for localization is based only on language.
 * In future iterations we could specialize based on culture.
 *
 * Notes about handling culture @see https://learn.microsoft.com/en-us/aspnet/core/fundamentals/localization/select-language-culture?view=aspnetcore-8.0
 */

import { AppLanguage } from '../types/preferences';
import { Regex } from '../utils/validations';

export const DefaultLanguage = AppLanguage.EN;

/**
 * Detects browser language and parses only the language code in ISO format `en` or `pt` for example.
 * If user has language specific `en-GB` it will return `en`.
 *
 * @returns {string | undefined} the language detected
 */
export const detectBrowserLanguage = (): string | undefined => {
    return navigator.language.match(Regex.Language)?.[0];
};

/**
 *  Given a language string converts to the enum of available languages.
 *  If if fails, defaults to the default language.
 */
export const matchAvailableLanguagesOrDefault = (lang: string | undefined): AppLanguage => {
    if (!lang) return DefaultLanguage;
    return Object.values(AppLanguage).includes(lang as AppLanguage) ? lang as AppLanguage : DefaultLanguage;
};

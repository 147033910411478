/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FieldValue,
    FormValidator,
    FormValidatorError,
    FormValidatorErrorType,
} from '../validations';

import { ValidatorStrategy } from './Validator';

export class FunctionValidator implements ValidatorStrategy {
    validate(fieldValue: FieldValue, validator: FormValidator): FormValidatorError | null {
        const {
            func,
        } = validator;
        // No error shown if user did not type anything yet
        if (fieldValue.toString().length === 0) return null;

        if (func && !func(fieldValue)) {
            return { typeOfViolation: FormValidatorErrorType.Pattern };
        }
        return null;
    }
}

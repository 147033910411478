
/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FunctionComponent,
    ReactNode,
    createContext,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    NavigateFunction,
    useLocation,
    useNavigate,
    useParams,
} from 'react-router-dom';
import {
    Contract,
    ContractFieldsName,
    ContractPayload,
    ContractState,
    ContractType,
    PendingPlaceholder,
    Placeholder,
    PlaceholderPayload,
    Signer,
    SignerPayload,
} from '../../../types/contracts';
import { ContractsContext, withContractsContext } from '../../controllers/ContractsContext';
import { DataOrError, ErrorResponse } from '../../../types/errors';
import { PlaceholderHeight, PlaceholderWidth } from '../../../constants/contracts';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';

import { AppRoute } from '../../../constants/routes';
import { PurchasePayload } from '../../../types/billing';
import { buildUrl } from '../../../utils/navigation';

const initialFieldsState: ContractPayload = {
    [ContractFieldsName.Name]: '',
    [ContractFieldsName.Type]: 0,
    [ContractFieldsName.Id]: 0,
    [ContractFieldsName.File]: null,
    [ContractFieldsName.Signers]: null,
    [ContractFieldsName.ExternalId]: '',
};
export interface ContractContextProviderInterface {
    file: File | null;
    contractTypes: ContractType[];
    contractFields: ContractPayload;
    contract: Contract | null;
    contractPdfFileUrl: string | null;
    placeholderList: Placeholder[];
    currentPage: number;
    pendingPlaceholder: PendingPlaceholder | null;
    uploadContract: (fileToUpload: File, contractTypeId: number) => Promise<DataOrError<Contract, ErrorResponse>>;
    uploadWorkflowContract: (workflowId: string, fileToUpload: File, contractTypeId: number) => Promise<DataOrError<Contract, ErrorResponse>>;
    getContractTypesList: () => Promise<undefined | ErrorResponse>;
    prepareContract: (contractId: string) => Promise<undefined | ErrorResponse>;
    prepareContractPdf: (contractId: string) => Promise<undefined | ErrorResponse>;
    prepareEditContract: (changedFields: Partial<ContractPayload>, onSuccess: () => void, onFailure: (errorMessage: string) => void) => Promise<void>;
    prepareReplaceContract: (fileToReplace: File, onSuccess: () => void, onFailure: (errorMessage: string) => void) => Promise<void>;
    prepareToAddSigner: (payload: SignerPayload, onSuccess: () => void, onFailure: (errorMessage: string) => void) => Promise<void>;
    prepareToRemoveSigner: (signerId: number, onSuccess: () => void, onFailure: (errorMessage: string) => void) => Promise<void>;
    prepareSigner: (signerId: number, onSuccess: (signer: Signer) => void, onFailure: (errorMessage: string) => void) => Promise<void>;
    setCurrentPage: (pageNum: number) => void;
    updatePlaceholderInList: (updatedPlaceholderProperties: Placeholder, onFailure: (errorMessage: string) => void) => void;
    addNewPlaceholder: (newPlaceholder: PendingPlaceholder, onFailure: (errorMessage: string) => void) => void;
    createPendingPlaceholder: (newSigner: Signer) => void;
    updatePendingPlaceholder: (updatedPlaceholder: PendingPlaceholder) => void;
    removePlaceholderFromList: (signerId: number) => void;
    purchaseContract(contractId: string, payload: PurchasePayload): Promise<DataOrError<undefined, ErrorResponse>>;
    navigateToDraftContractPage: (contractId: string) => ReturnType<NavigateFunction>;
}

const contractDefault: Contract = {
    id: 1,
    name: 'Contract Default 1',
    organizationId: 1,
    userOwnerId: '1',
    contractType: {
        id: 0,
        name: 'ContractType',
        organizationId: 90000,
        createdDate: new Date(),
        lastModifiedDate: new Date(),
    },
    signers: [],
    contractState: ContractState.DRAFT,
    createdDate: '',
    lastModifiedDate: '',
    externalId: '',
    downloadUrl: '',
    transactionId: 1,
};

export const contractContextDefaultValue: ContractContextProviderInterface = {
    file: null,
    contractTypes: [],
    contractFields: initialFieldsState,
    contract: null,
    contractPdfFileUrl: null,
    placeholderList: [],
    currentPage: 0,
    pendingPlaceholder: null,
    uploadContract: async () => [contractDefault, null],
    uploadWorkflowContract: async () => [contractDefault, null],
    getContractTypesList: async () => undefined,
    prepareContract: async () => undefined,
    prepareContractPdf: async () => undefined,
    prepareEditContract: async () => { },
    prepareReplaceContract: async () => { },
    prepareToAddSigner: async () => { },
    prepareToRemoveSigner: async () => { },
    prepareSigner: async () => { },
    setCurrentPage: () => { },
    updatePlaceholderInList: () => { },
    addNewPlaceholder: () => { },
    createPendingPlaceholder: () => { },
    updatePendingPlaceholder: () => { },
    removePlaceholderFromList: () => { },
    navigateToDraftContractPage: () => '',
    purchaseContract: async () => [undefined, null],
};

export const ContractContext = createContext(contractContextDefaultValue);

interface OwnProps extends ContractsContext, TranslationContext {
    children: ReactNode;
}

const ContractContextProviderComponent: FunctionComponent<OwnProps> = (props) => {
    const {
        t,
        children,
        getContractTypes,
        getContract,
        getContractPdf,
        createContract,
        createWorkflowContract,
        editContract,
        replaceContract,
        addNewSigner,
        deleteSigner,
        getSigner,
        getContractPlaceholders,
        createSignaturePlaceholder,
        updateSignaturePlaceholder,
        purchaseContract,
        deleteContract,
    } = props;

    const { contractId = '' } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const [placeholderList, setPlaceholderList] = useState<Placeholder[]>([]);
    const [currentPage, setCurrentPage] = useState(0);

    const [file, setFile] = useState<File | null>(null);
    const [contract, setContract] = useState<Contract | null>(null);
    const [contractFields, setContractFields] = useState<ContractPayload>(initialFieldsState);
    const [contractTypes, setContractTypes] = useState<ContractType[]>([]);
    const [contractPdfFileUrl, setContractPdfFileUrl] = useState<string | null>(null);

    const [pendingPlaceholder, setPendingPlaceholder] = useState<PendingPlaceholder | null>(null);

    useEffect(() => {
        if (!contractId) return;
        getContractPlaceholders(contractId).then((list) => setPlaceholderList([...list]));
    }, [contractId]);

    const updatePlaceholderInList = async (updatedPlaceholderProperties: Placeholder, onFailure: (errorMessage: string) => void) => {
        const payload: PlaceholderPayload = {
            signerId: updatedPlaceholderProperties.signer.id,
            height: updatedPlaceholderProperties.height,
            width: updatedPlaceholderProperties.width,
            page: updatedPlaceholderProperties.page,
            top: updatedPlaceholderProperties.top,
            left: updatedPlaceholderProperties.left,
        };

        const [updatedPlaceholder, updatePlaceholderError] = await updateSignaturePlaceholder(String(updatedPlaceholderProperties.contractId), updatedPlaceholderProperties.id, payload);

        if (updatedPlaceholder) {
            updatePlaceholderList(updatedPlaceholder);
        }

        if (updatePlaceholderError) {
            onFailure(updatePlaceholderError.errors[0].getMessageTranslated(t));
            setPlaceholderList((prevState) => [...prevState]); // just so all placeholders are reset to where they were before the update
        }
    };

    const updatePlaceholderList = (placeholderToUpdate: Placeholder) => {
        setPlaceholderList((prevState) => prevState.map((p) => {
            if (p.id === placeholderToUpdate.id) {
                return {
                    ...p,
                    ...placeholderToUpdate,
                };
            }

            return p;
        }));
    };

    const updatePendingPlaceholder = (updatedPlaceholder: PendingPlaceholder) => {
        setPendingPlaceholder(updatedPlaceholder);
    };

    const addNewPlaceholder = async (placeholderToCreate: PendingPlaceholder, onFailure: (message: string) => void) => {
        const { signer, ...rest } = placeholderToCreate;

        const placeholderPayload: PlaceholderPayload = {
            signerId: signer.id,
            ...rest,
        };

        const [newPlaceholder, placeholderError] = await createSignaturePlaceholder(String(signer.contractId), placeholderPayload);

        if (newPlaceholder) {
            setPlaceholderList(((prevState) => [...prevState, newPlaceholder]));
            setPendingPlaceholder(null);
        }

        if (placeholderError) {
            onFailure(placeholderError.errors[0].getMessageTranslated(t));
        }
    };

    const removePlaceholderFromList = (signerId: number) => {
        setPlaceholderList((prevState) => prevState.filter((p) => p.signer.id !== signerId));
    };

    const createPendingPlaceholder = (newSigner: Signer) => {
        setPendingPlaceholder({
            signer: newSigner,
            left: 200,
            top: 200,
            page: currentPage,
            height: PlaceholderHeight,
            width: PlaceholderWidth,
        });
    };

    /**
     * Create payload and call createContract request
     *
     * @param fileToUpload
     * @param onSuccess
     * @param onFailure
     */
    const uploadContract: ContractContextProviderInterface['uploadContract'] = async (fileToUpload: File, contractTypeId: number) => {
        const formData = new FormData();

        formData.append(ContractFieldsName.Name, fileToUpload.name);
        formData.append(ContractFieldsName.Type, String(contractTypeId));

        formData.append('file', fileToUpload as Blob, fileToUpload.name);

        const uploadResponse = await createContract(formData);

        const [newContract, uploadingError] = uploadResponse;

        if (newContract) {
            setFile(fileToUpload);
            setContractFields({ ...contractFields, ...newContract });
            setContract({ ...newContract, contractState: ContractState.DRAFT });
            return [newContract, null];
        }

        return [null, uploadingError];
    };

    /**
     * Create payload and call createWorkflowontract request
     *
     * @param fileToUpload
     * @param onSuccess
     * @param onFailure
     */
    const uploadWorkflowContract: ContractContextProviderInterface['uploadWorkflowContract'] = async (workflowId: string, fileToUpload: File, contractTypeId: number) => {
        const formData = new FormData();

        formData.append(ContractFieldsName.Name, fileToUpload.name);
        formData.append(ContractFieldsName.Type, String(contractTypeId));

        formData.append('file', fileToUpload as Blob, fileToUpload.name);

        const uploadResponse = await createWorkflowContract(workflowId, formData);

        const [newContract, uploadingError] = uploadResponse;

        if (newContract) {
            setFile(fileToUpload);
            setContractFields({ ...contractFields, ...newContract });
            setContract({ ...newContract, contractState: ContractState.DRAFT });
            return [newContract, null];
        }

        return [null, uploadingError];
    };

    /**
     * Get Contract Types
     *
     * @remarks
     * Get the first 30 contract types for the contract type select box
     * Update default Contract Type value for the first from the response
     */
    const getContractTypesList = async (): Promise<undefined | ErrorResponse> => {
        try {
            const response = await getContractTypes('', '30');
            setContractTypes(response.results);
            setContractFields({ ...contractFields, contractTypeId: response.results[0].id });
        } catch (e) {
            return e as ErrorResponse;
        }
    };

    /**
     * Get Contract
     *
     * @remarks
     * Get the contract by id
     */
    const prepareContract = async (id: string): Promise<undefined | ErrorResponse> => {
        try {
            const response = await getContract(id);
            if (response[1]) return response[1];

            setContract(response[0]);
        } catch (e) {
            return e as ErrorResponse;
        }
    };

    /**
     * Get Contract Pdf
     */
    const prepareContractPdf = async (id: string): Promise<undefined | ErrorResponse> => {
        try {
            const response = await getContractPdf(id);
            if (response[1]) return response[1];

            setContractPdfFileUrl(URL.createObjectURL(response[0]));
        } catch (e) {
            return e as ErrorResponse;
        }
    };

    const prepareReplaceContract = async (fileToUpload: File, onSuccess: () => void, onFailure: (errorMessage: string) => void) => {
        if (!contract) return;

        const formData = new FormData();
        formData.append('file', fileToUpload as Blob, fileToUpload.name);
        const [updatedContract, updatedContractError] = await replaceContract(String(contract.id), formData);

        if (updatedContract) {
            setFile(fileToUpload);
            setContractFields(
                {
                    ...contractFields,
                    [ContractFieldsName.Name]: updatedContract.name,
                    [ContractFieldsName.File]: fileToUpload,
                },
            );
            setContract(updatedContract);
            onSuccess();
            return;
        }

        if (updatedContractError) {
            onFailure(updatedContractError.errors[0].getMessageTranslated(t));
        }
    };

    const prepareEditContract = async (changedFields: Partial<ContractPayload>, onSuccess: () => void, onFailure: (errorMessage: string) => void) => {
        if (!contract) return;

        const [updatedContract, editError] = await editContract(String(contract.id), changedFields);

        if (updatedContract) {
            setContractFields({ ...contractFields, ...changedFields });
            setContract(updatedContract);
            onSuccess();
            return;
        }

        if (editError) {
            onFailure(editError.errors[0].getMessageTranslated(t));
        }
    };

    const prepareToAddSigner = async (payload: SignerPayload, onSuccess: () => void, onFailure: (errorMessage: string) => void) => {
        if (!contract) return;

        const [newSigner, newSignerError] = await addNewSigner(String(contract.id), payload);

        if (newSigner) {
            const updatedSigners = contract?.signers?.concat(newSigner);
            createPendingPlaceholder(newSigner);
            setContractFields({ ...contractFields, signers: updatedSigners });
            setContract({ ...contract, signers: updatedSigners });
            onSuccess();
            return;
        }

        if (newSignerError) {
            if (newSignerError.errors.length) {
                onFailure(newSignerError.errors[0].getMessageTranslated(t));
            }
            if (newSignerError.fields) {
                Object.entries(newSignerError.fields).forEach(([, fieldError]) => {
                    fieldError.forEach((f) => onFailure(f.message));
                });
            }
        }
    };

    const prepareToRemoveSigner = async (signerId: number, onSuccess: () => void, onFailure: (errorMessage: string) => void) => {
        if (!contract) return;

        const deleteSignerError = await deleteSigner(Number(contractId), signerId);

        if (deleteSignerError) {
            onFailure(deleteSignerError.errors[0].getMessageTranslated(t));
            return;
        }

        const updatedSigners = contract?.signers?.filter((signer) => signer.id !== signerId);
        removePlaceholderFromList(signerId);
        setContractFields({ ...contractFields, signers: updatedSigners });
        setContract({ ...contract, signers: updatedSigners });
        onSuccess();
        setPendingPlaceholder(null);
    };
    /**
     * Get Signer
     *
     * @remarks
     * Get the signer by id
     */
    const prepareSigner = async (signerId: number, onSuccess: (signer: Signer) => void, onFailure: (errorMessage: string) => void) => {
        const [signer, getSignerError] = await getSigner(Number(contractId), signerId);
        if (signer) {
            onSuccess(signer);
            return;
        }

        if (getSignerError) {
            onFailure(getSignerError.errors[0].getMessageTranslated(t));
        }
    };

    /**
    * Handles navigation to Draft Contract Screen
    *
    * @returns {ReturnType<NavigateFunction>}
    */
    const navigateToDraftContractPage = (id: string): ReturnType<NavigateFunction> => {
        return navigate(buildUrl(AppRoute.DraftContract, { contractId: id }), { state: { from: location.state?.from } });
    };

    const value = useMemo(() => ({
        file,
        contractTypes,
        contractFields,
        contract,
        contractPdfFileUrl,
        placeholderList,
        currentPage,
        pendingPlaceholder,
        deleteContract,
        uploadContract,
        uploadWorkflowContract,
        getContractTypesList,
        prepareContract,
        prepareContractPdf,
        prepareEditContract,
        prepareReplaceContract,
        prepareToAddSigner,
        prepareToRemoveSigner,
        prepareSigner,
        setCurrentPage,
        updatePlaceholderInList,
        addNewPlaceholder,
        createPendingPlaceholder,
        updatePendingPlaceholder,
        removePlaceholderFromList,
        purchaseContract,
        navigateToDraftContractPage,
    }), [file,
        contractTypes,
        contractFields,
        contract,
        contractPdfFileUrl,
        placeholderList,
        currentPage,
        pendingPlaceholder,
        uploadContract,
        uploadWorkflowContract,
        getContractTypesList,
        prepareContract,
        prepareContractPdf,
        prepareEditContract,
        prepareReplaceContract,
        prepareToAddSigner,
        prepareToRemoveSigner,
        setCurrentPage,
        updatePlaceholderInList,
        addNewPlaceholder,
        createPendingPlaceholder,
        updatePendingPlaceholder,
        removePlaceholderFromList,
        purchaseContract,
        navigateToDraftContractPage]);

    return (
        <ContractContext.Provider
            value={value}
        >
            {children}
        </ContractContext.Provider>
    );
};

export const ContractContextProvider = withTranslationContext(withContractsContext(ContractContextProviderComponent));

export const useContractContext = () => {
    return useContext(ContractContext);
};

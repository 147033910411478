/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import React, { FunctionComponent } from 'react';
import { WebsocketContext, WebsocketContextProvider } from './WebsocketContext';
import { websocketTokenUrl } from '../../services/websocket';
import { useCornerstoneApi } from '../../api';
import { ErrorResponse } from '../../types/errors';

interface OwnProps {
    children: React.ReactNode;
}

const WebsocketController: FunctionComponent<OwnProps> = (props) => {
    const { children } = props;
    const CornerstoneApiInstance = useCornerstoneApi();

    const getWebsocketToken: WebsocketContext['getWebsocketToken'] = async () => {
        try {
            const { data } = await CornerstoneApiInstance.get(websocketTokenUrl());

            return [data, null];
        } catch (e) {
            return [null, e as ErrorResponse];
        }
    };

    return (
        <WebsocketContextProvider
            value={{
                getWebsocketToken,
            }}
        >
            {children}
        </WebsocketContextProvider>
    );
};

export { WebsocketController };

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ButtonVariant, ListResponse, OptionSelectField } from '../../../types/general';
import { MembersContext, withMembersContext } from '../../controllers/MembersContext';
import { OrganizationsContext, withOrganizationsContext } from '../../controllers/OrganizationsContext';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';

import Button from '../Button';
import { FormField } from '../FormField';
import { FormSelectField } from '../FormSelectField';
import { FormValidationError } from '../../../utils/validations';
import { InvitationPayload } from '../../../types/members';
import { Role } from '../../../types/roles';

interface OwnProps extends TranslationContext, OrganizationsContext, MembersContext {
    roles: ListResponse<Role>;
    handleCloseModal: () => void;
}

const MemberInvitationFormBase: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        roles,
        t,
        organizationSelected,
        validateInvitationForm,
        sendMemberInvite,
        handleCloseModal,
    } = props;

    const [roleId, setRoleId] = useState(0);
    const [email, setEmail] = useState('');
    const [optionsRole, setOptionsRole] = useState<OptionSelectField<string>[]>([]);
    const [errorsForm, setErrorsForm] = useState<FormValidationError | null>(null);

    useEffect(() => {
        createRoleOptions();
    }, []);

    const createRoleOptions = () => {
        if (roles.results.length === 0) return;

        const optionsRoleAux = roles.results.map((role) => {
            return {
                value: String(role.id),
                label: role.name,
            };
        });

        setRoleId(roles.results[0].id);
        setOptionsRole(optionsRoleAux);
    };

    const onRoleChange = (_: string, value: string) => {
        setRoleId(Number(value));
    };

    const onEmailChange = (_: string, value: string) => {
        setEmail(value);
    };

    const handleInvite = async () => {
        if (!organizationSelected) return;

        const fieldsTransformed: InvitationPayload = {
            email: email.trim(),
            roleId,
            organizationId: organizationSelected.organization.id,
            jobTitle: '',
        };

        const errors = validateInvitationForm({ ...fieldsTransformed });
        setErrorsForm(errors);

        if (errors) return;

        const requestError = await sendMemberInvite(fieldsTransformed);

        if (requestError) {
            onRequestFailure(requestError.errors[0].getMessageTranslated(t));
        } else {
            onCreateSuccess();
        }
    };

    const onCreateSuccess = () => {
        toast.success(t('memberInvitationForm.success'));
        setEmail('');
        handleCloseModal();
    };

    const onRequestFailure = (errorMessage: string) => {
        toast.error(errorMessage);
    };

    return (
        <div className="invitation-form" data-testid="member-invitation-form">
            <FormField
                name="email"
                value={email}
                onChange={onEmailChange}
                maxLength={250}
                placeholder={t('memberInvitationForm.emailPlaceholder')}
                errors={errorsForm}
                label={t('memberInvitationForm.emailLabel')}
            />
            {optionsRole.length > 0 && <FormSelectField name="roleId" options={optionsRole} onChange={onRoleChange} label={t('memberInvitationForm.roleLabel')} />}
            <div className="invitation-form__instruction">
                <p>{t('memberInvitationForm.instruction1')}</p>
                <p>{t('memberInvitationForm.instruction2')}</p>
            </div>
            <Button
                onClick={handleInvite}
                variant={ButtonVariant.Curved}
                testId="submit-new-member"
            >
                {t('memberInvitationForm.send')}
            </Button>
        </div>
    );
};

export const MemberInvitationForm = withTranslationContext(withMembersContext(withOrganizationsContext(MemberInvitationFormBase)));

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FormEvent, FunctionComponent, useEffect, useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AppRoute } from '../../../constants/routes';
import { ErrorResponse } from '../../../types/errors';
import { Permissions } from '../../../types/permissions';
import {
    WorkflowPayload,
    WorkflowType,
} from '../../../types/workflows';
import { buildUrl } from '../../../utils/navigation';
import { FormValidationError } from '../../../utils/validations';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import { WorkflowContext, withWorkflowContext } from '../../controllers/WorkflowContext';
import HasPermission from '../../elements/HasPermission';
import { LoadingScreen } from '../LoadingScreen';
import { NotFoundPage } from '../NotFoundPage';
import { WorkflowRentalInfoScreen } from './WorkflowRentalInfoScreen';
import { WorkflowSellInfoScreen } from './WorkflowSellInfoScreen';

type OwnProps = TranslationContext & WorkflowContext;

const EditWorkflowSellInfoScreenBase: FunctionComponent<OwnProps> = (props) => {
    const {
        t,
        getWorkflow,
        editWorkflow,
        validateWorkflow,
    } = props;

    const { workflowId = '' } = useParams();
    const navigate = useNavigate();

    const [errorsForm, setErrorsForm] = useState<FormValidationError | null>(null);
    const [errorResponse, setErrorResponse] = useState<ErrorResponse | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const [fetchedWorkflow, setFetchedWorkflow] = useState<WorkflowPayload | undefined>();

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        if (errorResponse) throw errorResponse;
    }, [errorResponse]);

    const init = async () => {
        const [response, requestError] = await getWorkflow(workflowId);

        if (requestError) {
            setErrorResponse(requestError);
        }

        if (response) {
            const {
                title,
                description,
                transactionType,
                comissionType,
                comissionValue,
                comissionCurrency,
                transactionValue,
                transactionCurrency,
                cmi,
                expiresAt,
                externalId,
                frequencyType,
            } = response;
            setFetchedWorkflow({
                title,
                description,
                transactionType,
                comissionType,
                comissionValue,
                comissionCurrency,
                transactionValue,
                transactionCurrency,
                expiresAt,
                externalId,
                frequencyType,
                cmi: cmi ?? undefined,
            });
        }

        setIsLoading(false);
    };

    /**
     * Submit Create Workflow
     *
     * @remarks
     * Submit fields
     */
    const handleFormSubmit = async (e: FormEvent<HTMLFormElement>, workflow: WorkflowPayload) => {
        e.preventDefault();
        setIsLoading(true);

        const payload = {
            ...workflow,
        };

        const errors = validateWorkflow(payload);
        setErrorsForm(errors);

        if (!errors && payload) {
            const [newWorkflow, submittingError] = await editWorkflow(workflowId, payload);

            if (newWorkflow) {
                navigate(buildUrl(AppRoute.WorkflowProperty, { workflowId: String(newWorkflow.id) }));

                return;
            }

            if (submittingError) {
                toast.error(submittingError.errors[0].getMessageTranslated(t));
            }
        }

        setIsLoading(false);
    };

    const renderWorkflowInfoTypeScreen = (workflow: WorkflowPayload) => {
        switch (workflow.transactionType) {
            case WorkflowType.SELL:
                return (
                    <WorkflowSellInfoScreen
                        onFormSubmit={handleFormSubmit}
                        isLoading={isLoading}
                        errorsForm={errorsForm}
                        workflowDefaults={workflow}
                    />
                );
            case WorkflowType.RENTAL:
                return (
                    <WorkflowRentalInfoScreen
                        onFormSubmit={handleFormSubmit}
                        isLoading={isLoading}
                        errorsForm={errorsForm}
                        workflowDefaults={workflow}
                    />
                );
            default:
                return <NotFoundPage />;
        }
    };

    return (
        <HasPermission permissions={[Permissions.MANAGE_ORGANIZATION_TRANSACTIONS, Permissions.MANAGE_ALL_ORGANIZATION_TRANSACTIONS]}>
            {!fetchedWorkflow && <LoadingScreen />}
            {!!fetchedWorkflow && renderWorkflowInfoTypeScreen(fetchedWorkflow)}
        </HasPermission>
    );
};

export const EditWorkflowSellInfoScreen = withTranslationContext(withWorkflowContext(EditWorkflowSellInfoScreenBase));

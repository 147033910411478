/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FieldValue,
    FormValidator,
    FormValidatorError,
    ValidationType,
} from '../validations';

import { CheckedValidator } from './CheckedValidator';
import { DecimalValidator } from './DecimalValidator';
import { FunctionValidator } from './FunctionValidator';
import { LengthValidator } from './LengthValidator';
import { MaxValueValidator } from './MaxValueValidator';
import { MinValueValidator } from './MinValueValidator';
import { NotBlankValidator } from './NotBlankValidator';
import { NotDecimalValidator } from './NotDecimalValidator';
import { NotEmptyValidator } from './NotEmptyValidator';
import { NumberValidator } from './NumberValidator';
import { RegexValidator } from './RegexValidator';
import { SizeExactValidator } from './SizeExactValidator';

export interface ValidatorStrategy {
    validate(fieldValue: FieldValue, validator: FormValidator): FormValidatorError|null;
}

/**
 * Applies validation logic to a form field
 */
export class FieldValidator {
    static applyAllForValidations(fieldName: string, fieldValue: FieldValue, validator: FormValidator): FormValidatorError[] | null {
        if (!validator.validations) return null;

        const errorsFound = validator.validations.map((val) => {
            switch (val) {
                case ValidationType.Checked: {
                    return new CheckedValidator().validate(fieldValue);
                }
                case ValidationType.NotBlank: {
                    return new NotBlankValidator().validate(fieldValue);
                }
                case ValidationType.NotEmpty: {
                    return new NotEmptyValidator().validate(fieldValue);
                }
                case ValidationType.NotDecimal: {
                    return new NotDecimalValidator().validate(fieldValue);
                }
                case ValidationType.Length: {
                    return new LengthValidator().validate(fieldValue, validator);
                }
                case ValidationType.SizeExact: {
                    return new SizeExactValidator().validate(fieldValue, validator);
                }
                case ValidationType.Max: {
                    return new MaxValueValidator().validate(fieldValue, validator);
                }
                case ValidationType.Min: {
                    return new MinValueValidator().validate(fieldValue, validator);
                }
                case ValidationType.Regex: {
                    return new RegexValidator().validate(fieldValue, validator);
                }
                case ValidationType.Function: {
                    return new FunctionValidator().validate(fieldValue, validator);
                }
                case ValidationType.Number: {
                    return new NumberValidator().validate(fieldValue);
                }
                case ValidationType.Decimal: {
                    return new DecimalValidator().validate(fieldValue, validator);
                }
                default:
                    return null;
            }
        }).filter((val) => val !== null) as FormValidatorError[];

        return errorsFound.length ? errorsFound : null;
    }
}

/**
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { CreditAmount } from '../types/billing';
import { Regex, ValidationType } from '../utils/validations';

const organizationsValidations = {
    name: {
        validations: [ValidationType.NotBlank],
    },
};

const contractTypeValidations = {
    name: {
        validations: [ValidationType.NotBlank],
    },
};

const contractSubmitValidations = {
    name: {
        validations: [ValidationType.NotBlank],
    },
    contractTypeId: {
        validations: [ValidationType.NotBlank],
    },
    externalId: {
        validations: [ValidationType.Regex],
        regex: Regex.ExternalId,
    },
};

const signerCreateValidations = {
    contractId: {
        validations: [ValidationType.NotBlank],
    },
    name: {
        validations: [ValidationType.NotBlank],
    },
    email: {
        validations: [ValidationType.Regex],
        regex: Regex.Email,
    },
    phoneNumber: {
        validations: [ValidationType.NotBlank],
    },
    documentNumber: {
        validations: [ValidationType.NotBlank],
    },
    documentType: {
        validations: [ValidationType.NotBlank],
    },
    documentCountry: {
        validations: [ValidationType.NotBlank],
    },
    signatureType: {
        validations: [ValidationType.NotBlank],
    },
};

const workflowCreateValidations = {
    comissionType: {
        validations: [ValidationType.NotBlank],
    },
    comissionValue: {
        validations: [
            ValidationType.NotBlank,
            ValidationType.Number,
        ],
    },
    expiresAt: {
        validations: [ValidationType.NotBlank],
    },
    externalId: {
        validations: [ValidationType.Regex],
        regex: Regex.ExternalId,
    },
};
const workflowCreatePropertyValidations = {
    propertyType: {
        validations: [ValidationType.NotBlank],
    },
    typology: {
        validations: [ValidationType.NotBlank],
    },
    address: {
        validations: [
            ValidationType.NotBlank,
            ValidationType.Min,
            ValidationType.Max,
        ],
        min: 3,
        max: 255,
    },
    district: {
        validations: [ValidationType.NotBlank],
    },
    county: {
        validations: [ValidationType.NotBlank],
    },
    parish: {
        validations: [ValidationType.NotBlank],
    },
};
const contactablePersonValidations = {
    firstName: {
        validations: [ValidationType.NotBlank],
    },
    lastName: {
        validations: [ValidationType.NotBlank],
    },
    email: {
        validations: [ValidationType.NotBlank],
    },
    phoneNumber: {
        validations: [ValidationType.NotBlank],
    },
};

const fiscalInfoValidationsValidations = {
    fiscalNumber: {
        validations: [ValidationType.NotBlank],
    },
    fiscalName: {
        validations: [ValidationType.NotBlank],
    },
};

const addressValidationsValidations = {
    addressLine1: {
        validations: [ValidationType.NotBlank],
    },
    postalCode: {
        validations: [ValidationType.NotBlank],
    },
    city: {
        validations: [ValidationType.NotBlank],
    },
    state: {
        validations: [ValidationType.NotBlank],
    },
    country: {
        validations: [ValidationType.NotBlank],
    },
};

export const validations = {
    organizationCreate: {
        ...organizationsValidations,
    },
    roleCreate: {
        name: {
            validations: [ValidationType.NotBlank],
        },
        organizationId: {
            validations: [ValidationType.Decimal],
        },
    },
    contractTypeCreate: {
        ...contractTypeValidations,
    },
    contractSubmit: {
        ...contractSubmitValidations,
    },
    signerCreate: {
        ...signerCreateValidations,
    },
    invitationCreate: {
        email: {
            validations: [ValidationType.NotBlank, ValidationType.Regex],
            regex: Regex.Email,
        },
        roleId: {
            validations: [ValidationType.Number],
        },
        organizationId: {
            validations: [ValidationType.Number],
        },
    },
    workflowCreate: {
        ...workflowCreateValidations,
    },
    workflowCreateProperty: {
        ...workflowCreatePropertyValidations,
    },
    contactablePersonValidationsUpdate: {
        ...contactablePersonValidations,
    },
    fiscalInfoValidationsUpdate: {
        ...fiscalInfoValidationsValidations,
    },
    addressValidationsUpdate: {
        ...addressValidationsValidations,
    },
};

export const creditsValidations = {
    credits: {
        validations: [ValidationType.Min],
        min: Number(CreditAmount.AMOUNT_25),
    },
};

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { debounce, isNumber } from 'lodash';
import {
    FunctionComponent, useCallback, useEffect,
    useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as AddOnIcon } from '../../../assets/images/addon-icon.svg';
import { ReactComponent as InfoIcon } from '../../../assets/images/info-icon.svg';
import { ReactComponent as PhoneLockIcon } from '../../../assets/images/phone-lock-icon.svg';
import { ReactComponent as VideoIcon } from '../../../assets/images/video-icon.svg';
import { AppRoute } from '../../../constants/routes';
import { CreditAmount, OnboardingFlowStep } from '../../../types/billing';
import { ButtonVariant, FormFieldType } from '../../../types/general';
import {
    FormValidationError,
} from '../../../utils/validations';
import { BillingContext, withBillingContext } from '../../controllers/BillingContext';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import { CartSummary } from '../../elements/billing/CartSummary';
import Button from '../../elements/Button';
import { FormField } from '../../elements/FormField';
import { HorizontalStepper } from '../../elements/HorizontalStepper';
import { IsolateFlowLayout } from '../../elements/layouts/IsolateFlowLayout';
import { preparePageTitle } from '../../../utils/route';

type OwnProps = TranslationContext & BillingContext;

const AddCreditsToPurchaseScreenComponent: FunctionComponent<OwnProps> = (props) => {
    const {
        t,
        billingInfo,
        changeCreditsToPurchase,
        updateBasket,
        validateCreditsInput,
    } = props;

    const navigate = useNavigate();
    const [creditInputError, setCreditInputError] = useState<FormValidationError | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        document.title = preparePageTitle(t('subscribeFlow.title'));
        if (!billingInfo.wallet || !billingInfo.planDetails || !billingInfo.basket) {
            navigate(AppRoute.BillingPlans);
        }
    }, []);

    useEffect(() => {
        if (isNumber(billingInfo.credits) && !creditInputError && billingInfo.basket?.credits?.amount !== billingInfo.credits) {
            setIsLoading(true);
            debounceUpdateCredits(billingInfo.credits);
        }
    }, [billingInfo.credits]);

    const debounceUpdateCredits = useCallback(debounce((value: number) => {
        if (!billingInfo.wallet) {
            return;
        }

        updateBasket(String(billingInfo.wallet.id), { creditAmount: Number(value) }).then(() => { setIsLoading(false); });
    }, 500), []);

    const OnboardingSteps = [
        t(`subscribeFlow.stepper.${OnboardingFlowStep.ORGANIZATION}`),
        t(`subscribeFlow.stepper.${OnboardingFlowStep.PLAN_REVIEW}`),
        t(`subscribeFlow.stepper.${OnboardingFlowStep.ADD_CREDITS}`),
        t(`subscribeFlow.stepper.${OnboardingFlowStep.BILLING}`),
        t(`subscribeFlow.stepper.${OnboardingFlowStep.PAYMENT}`),
    ];

    const handleCreditInput = (value: string) => {
        const numberValue = Math.round(Number(value));

        if (value === '' || numberValue === 0) {
            setCreditInputError(null);
        } else {
            setCreditInputError(validateCreditsInput(numberValue));
        }
        changeCreditsToPurchase(numberValue);
    };

    /**
    * JSX for the "Next" button used in the existing bottom bar of the layout.
    *
    */
    const nextBtn = (
        <Button
            id="actionNext"
            variant={ButtonVariant.Curved}
            extraClasses="primary slim"
            testId="actionNext"
            onClick={() => navigate(AppRoute.BillingInformation)}
            disabled={!!creditInputError || isLoading}
        >
            <span className="btn-content">
                {t('general.nextStep')}
            </span>
        </Button>
    );

    /**
    * JSX for the "Go Back" button used in the existing bottom bar of the layout.
    *
    */
    const goBackBtn = (
        <Button
            id="actionBack"
            variant={ButtonVariant.Curved}
            extraClasses="secondary slim"
            onClick={() => navigate(AppRoute.BillingPlans)}
            testId="actionBack"
        >
            <span className="btn-content">
                {t('general.goBack')}
            </span>
        </Button>
    );

    return (
        <IsolateFlowLayout title={t('subscribeFlow.title')}>
            <HorizontalStepper steps={OnboardingSteps} activeStep={2} />
            <div className="add-credits-screen step-content" data-testid="add-credits-screen">
                <div className="add-credits-screen__container">
                    <h2>{t('subscribeFlow.addCreditsStep.title')}</h2>
                    <p>{t('subscribeFlow.addCreditsStep.description')}</p>
                    <FormField
                        name="credits"
                        value={billingInfo.credits}
                        placeholder={t('subscribeFlow.addCreditsStep.creditsPlaceholder')}
                        onChange={(_, v) => handleCreditInput(v)}
                        type={FormFieldType.Number}
                        errors={creditInputError}
                    />
                    <div className="buttons-container">
                        {Object.values(CreditAmount).map((c) => (
                            <Button key={c} onClick={() => handleCreditInput(c)}>{c}</Button>
                        ))}
                    </div>
                    <div className="add-credits-screen__container__info">
                        <InfoIcon />
                        <p>{t('subscribeFlow.addCreditsStep.usageForCredits.creditsToEuro')}</p>
                        <p>{t('subscribeFlow.addCreditsStep.usageForCredits.description')}</p>
                        <p><VideoIcon />{t('subscribeFlow.addCreditsStep.usageForCredits.qesVideo')}</p>
                        <p><PhoneLockIcon />{t('subscribeFlow.addCreditsStep.usageForCredits.aes')}</p>
                        <p><AddOnIcon />{t('subscribeFlow.addCreditsStep.usageForCredits.addon')}</p>
                    </div>
                </div>
                <CartSummary isLoading={isLoading} canChangeCheckoutCart />
            </div>
            <footer className="footer" data-testid="footer">
                <div className="footer__btns-wrap">
                    {nextBtn}
                    {goBackBtn}
                </div>
            </footer>
        </IsolateFlowLayout>
    );
};

export const AddCreditsToPurchaseScreen = withBillingContext(withTranslationContext(AddCreditsToPurchaseScreenComponent));

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FunctionComponent, useEffect, useMemo, useState,
} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Avatar } from '@mui/material';
import { IsolateFlowLayout } from '../../elements/layouts/IsolateFlowLayout';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import { ButtonVariant } from '../../../types/general';
import Button from '../../elements/Button';
import { AppRoute } from '../../../constants/routes';
import { HorizontalStepper } from '../../elements/HorizontalStepper';
import { OnboardingFlowStep } from '../../../types/billing';
import { RadioSelect } from '../../elements/RadioSelect';
import { OrganizationAccess } from '../../../types/organizations';
import { OrganizationsContext, withOrganizationsContext } from '../../controllers/OrganizationsContext';
import { LoadingCircles } from '../../elements/LoadingCircles';
import { BillingContext, withBillingContext } from '../../controllers/BillingContext';
import { preparePageTitle } from '../../../utils/route';

type OwnProps = TranslationContext & OrganizationsContext & BillingContext;

export const SelectOrganizationScreenBase: FunctionComponent<OwnProps> = (props) => {
    const {
        t,
        organizationSelected,
        getUserOrganizations,
        getOrganizationWallet,
        setWalletInfo,
        billingInfo,
        selectOrganization,
    } = props;
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);
    const [organizationAccesses, setOrganizationAccesses] = useState<OrganizationAccess[]>([]);
    const [selectedOrganizationId, setSelectedOrganizationId] = useState<number | undefined>(organizationSelected?.organization.id);

    const OnboardingSteps = [
        t(`subscribeFlow.stepper.${OnboardingFlowStep.ORGANIZATION}`),
        t(`subscribeFlow.stepper.${OnboardingFlowStep.PLAN_REVIEW}`),
        t(`subscribeFlow.stepper.${OnboardingFlowStep.ADD_CREDITS}`),
        t(`subscribeFlow.stepper.${OnboardingFlowStep.BILLING}`),
        t(`subscribeFlow.stepper.${OnboardingFlowStep.PAYMENT}`),
    ];

    const organizationOptions = useMemo(() => {
        return organizationAccesses.map((access) => {
            return {
                value: access.organization.id,
                label: access.organization.name,
                icon: (
                    <Avatar alt={`${access.organization.name} avatar`} src={access.organization.logoUrl ? access.organization.logoUrl : undefined}>
                        {access.organization.name.split(' ')[0][0]}{access.organization.name.split(' ')[1] ? access.organization.name.split(' ')[1][0] : ''}
                    </Avatar>),
            };
        });
    }, [organizationAccesses]);

    useEffect(() => {
        document.title = preparePageTitle(t('subscribeFlow.title'));
        if (!billingInfo.planDetails) {
            navigate(AppRoute.BillingPlans);
            return;
        }
        getOrganizationAccessesList();
    }, []);

    const getOrganizationAccessesList = async () => {
        const organizationData = await getUserOrganizations('');

        if (organizationData.results.length === 0) {
            navigate(AppRoute.BillingCreateOrganization);
            return;
        }
        setIsLoading(false);
        setOrganizationAccesses([...organizationData.results]);
    };

    const handleNextClick = async () => {
        if (selectedOrganizationId === null) {
            return;
        }

        setIsLoading(true);
        const [walletData] = await getOrganizationWallet(String(selectedOrganizationId));

        if (walletData) {
            setWalletInfo(walletData);
            selectOrganization(organizationAccesses.find((org) => org.organization.id === selectedOrganizationId) as OrganizationAccess);
            navigate(AppRoute.BillingReviewPlan);

            return;
        }

        setIsLoading(false);
    };

    /**
    * JSX for the "Next" button used in the existing bottom bar of the layout.
    *
    */
    const nextBtn = (
        <Button
            id="actionNext"
            variant={ButtonVariant.Curved}
            extraClasses="primary slim"
            onClick={handleNextClick}
            disabled={selectedOrganizationId === null}
            testId="actionNext"
        >
            <span className="btn-content">
                {t('general.nextStep')}
            </span>
        </Button>

    );

    /**
        * JSX for the "Go Back" button used in the existing bottom bar of the layout.
        *
        */
    const goBackBtn = (
        <Button
            id="actionBack"
            variant={ButtonVariant.Curved}
            extraClasses="secondary slim"
            onClick={() => navigate(AppRoute.BillingPlans)}
            testId="actionBack"
        >
            <span className="btn-content">
                {t('general.goBack')}
            </span>
        </Button>
    );

    return (
        <IsolateFlowLayout
            title={t('subscribeFlow.title')}
        >
            {isLoading && <LoadingCircles size="s" variant="primary" />}
            {organizationOptions.length > 0 && (
                <>
                    <div className="select-organization-screen step-content" data-testid="select-organization-screen">
                        <HorizontalStepper steps={OnboardingSteps} activeStep={0} />
                        <div className="step-content__screen">
                            <h2>{t('subscribeFlow.organizationStep.title')}</h2>
                            <div className="select-organization-screen__list">
                                <RadioSelect
                                    name="organization"
                                    options={organizationOptions}
                                    value={selectedOrganizationId}
                                    onChange={(_, v) => setSelectedOrganizationId(v as number)}
                                />
                            </div>
                            <Link to={AppRoute.BillingCreateOrganization} className="create-organization-btn">
                                {t('subscribeFlow.organizationStep.createOrganizationBtn')}
                            </Link>
                        </div>
                    </div>
                    <footer className="footer" data-testid="footer">
                        <div className="footer__btns-wrap">
                            {nextBtn}
                            {goBackBtn}
                        </div>
                    </footer>
                </>
            )}
        </IsolateFlowLayout>
    );
};

export const SelectOrganizationScreen = withBillingContext(withTranslationContext(withOrganizationsContext(SelectOrganizationScreenBase)));

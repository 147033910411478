/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { ComponentType, FC, createContext } from 'react';
import {
    Basket,
    Order,
    OrderRequestPayload,
    Subscription,
    SubscriptionPlan,
    UpdateBasketPayload,
    Wallet,
    WalletUpdatePayload,
} from '../../types/billing';
import { DataOrError, ErrorResponse } from '../../types/errors';

import { BillingController } from './BillingController';
import { BillingState } from '../../slicers/billingSlice';
import { FormValidationError } from '../../utils/validations';

export interface BillingContext {
    organizationWallet: Wallet | null;
    userWallet: Wallet | null;
    billingInfo: BillingState;
    getAvailablePlans: () => Promise<DataOrError<SubscriptionPlan[], ErrorResponse>>;
    selectPlanToPurchase(plan: SubscriptionPlan): void;
    changeCreditsToPurchase(credits: number): void;
    clearCheckoutCart(): void;
    updateBillingInformation: (walletId: string, payload: WalletUpdatePayload) => Promise<DataOrError<Wallet, ErrorResponse>>;
    setWalletInfo: (wallet: Wallet | null) => void;
    createNewBasket: (walletId: string) => Promise<DataOrError<Basket, ErrorResponse>>;
    updateBasket: (walletId: string, updateBasketPayload: UpdateBasketPayload) => Promise<DataOrError<Basket, ErrorResponse>>;
    getSubscriptionDetails: (walletId: string) => Promise<DataOrError<Subscription, ErrorResponse>>;
    getOrganizationWallet: (organizationId: string) => Promise<DataOrError<Wallet, ErrorResponse>>;
    simulateOrder: (payload: OrderRequestPayload) => Promise<DataOrError<Order, ErrorResponse>>;
    purchaseOrder: (payload: OrderRequestPayload) => Promise<DataOrError<Order, ErrorResponse>>;
    loadUserWallet: () => Promise<DataOrError<Wallet, ErrorResponse>>;
    validateCreditsInput(credits: number): FormValidationError | null;
}

export const billingContextDefaultValue: BillingContext = {
    userWallet: null,
    organizationWallet: null,
    billingInfo: {
        planDetails: null,
        credits: 0,
        wallet: null,
        basket: null,
    },
    getAvailablePlans: async () => [[Object.create({})], null],
    selectPlanToPurchase: () => { },
    changeCreditsToPurchase: () => { },
    clearCheckoutCart: () => { },
    getOrganizationWallet: () => Promise.resolve([Object.create({}), null]),
    updateBillingInformation: async () => [Object.create({}), null],
    setWalletInfo: () => { },
    createNewBasket: async () => [Object.create({}), null],
    updateBasket: async () => [Object.create({}), null],
    getSubscriptionDetails: async () => [Object.create({}), null],
    simulateOrder: async () => [Object.create({}), null],
    purchaseOrder: async () => [Object.create({}), null],
    loadUserWallet: () => Promise.resolve([Object.create({}), null]),
    validateCreditsInput: () => null,
};

const billingContextInstance = createContext<BillingContext | null>(billingContextDefaultValue);

export const BillingContextProvider = billingContextInstance.Provider;
export const BillingContextConsumer = billingContextInstance.Consumer;

export const withBillingContext = <P extends object>(Component: ComponentType<P>): FC<Omit<P, keyof BillingContext>> => (props) => (
    <BillingController>
        <BillingContextConsumer>
            {(ctx) => <Component {...props as P} {...ctx} />}
        </BillingContextConsumer>
    </BillingController>
);

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ContractsContext, withContractsContext } from '../../controllers/ContractsContext';
import { OrganizationsContext, withOrganizationsContext } from '../../controllers/OrganizationsContext';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';

import { AppRoute } from '../../../constants/routes';
import Button from '../../elements/Button';
import { ButtonVariant } from '../../../types/general';
import { Contract } from '../../../types/contracts';
import ContractCard from '../../elements/contracts/ContractCard';
import { DefaultLayout } from '../../elements/layouts/DefaultLayout';
import { FloatingAddAction } from '../../elements/FloatingAddAction';
import InfiniteScrollWrapper from '../../elements/InfiniteScrollWrapper';
import { LoadingCircles } from '../../elements/LoadingCircles';
import { Permissions } from '../../../types/permissions';
import { preparePageTitle } from '../../../utils/route';
import { useUserHasPermission } from '../../../hooks/useUserHasPermission';

interface OwnProps extends TranslationContext, OrganizationsContext, ContractsContext { }

const OrganizationContractsScreenComponent: FunctionComponent<OwnProps> = ({
    t, getOrganizationContracts, organizationSelected,
}) => {
    const [contracts, setContracts] = useState<Contract[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [cursor, setCursor] = useState('');

    const canManage = useUserHasPermission([Permissions.MANAGE_CONTRACT, Permissions.MANAGE_ALL_ORGANIZATION_CONTRACTS]);

    useEffect(() => {
        document.title = preparePageTitle(t('contracts.title'));
        getContractsList();
    }, [organizationSelected]);

    const getContractsList = () => {
        setIsLoading(true);

        return getOrganizationContracts(Number(organizationSelected?.organization?.id), cursor).then((data) => {
            setIsLoading(false);
            setContracts([...contracts, ...data.results]);
            setCursor(data.cursor);
        });
    };

    return (
        <DefaultLayout>
            <div className="contract-screen" data-testid="contracts-screen">
                <div className="contract-screen__header">
                    <h1>{t('contracts.title')}</h1>
                    {canManage && (
                        <Link to={AppRoute.CreateContract} state={{ from: AppRoute.Contracts }}>
                            <Button
                                variant={ButtonVariant.Curved}
                                extraClasses="large-add-btn shorter-btn"
                                testId="large-add-btn"
                            >
                                {t('contracts.createBtn')}
                            </Button>
                        </Link>
                    )}
                </div>
                <div className="contract-screen__list">
                    <p className="contract-screen__list__BETA-migration-warning">{t('contracts.BETA_migrationWarning')}</p>
                    {!isLoading && contracts.length === 0 && (
                        <p className="contract-screen__list__empty-list">{t('contracts.noResults')}</p>
                    )}
                    <InfiniteScrollWrapper
                        hasMore={!!cursor}
                        requestMore={getContractsList}
                    >
                        {contracts.map((contract) => (
                            <ContractCard key={contract.id} contract={contract} />
                        ))}
                    </InfiniteScrollWrapper>
                </div>
                {isLoading && <LoadingCircles size="m" variant="primary" />}

                <FloatingAddAction type="link" to={AppRoute.CreateContract} state={{ from: AppRoute.Contracts }} testId="add-btn" />
            </div>
        </DefaultLayout>
    );
};

export const OrganizationContractsScreen = withTranslationContext(withOrganizationsContext(withContractsContext(OrganizationContractsScreenComponent)));

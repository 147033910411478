/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { Elements, useStripe } from '@stripe/react-stripe-js';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';

import { Alert } from '@mui/material';
import { PaymentMethodContext, withPaymentMethodContext } from '../../../controllers/PaymentMethodContext';
import { ErrorResponse } from '../../../../types/errors';
import { StripeAppearance } from '../../../../constants/StripeAppearance';

interface Props extends PaymentMethodContext {}

export const PaymentStatusScreenBase = (props: Props) => {
    const {
        getStripeConfig,
    } = props;
    const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>>();
    const [error, setError] = useState<ErrorResponse>();
    const clientSecret = new URLSearchParams(window.location.search).get(
        'setup_intent_client_secret',
    ) as string;
    
    useEffect(() => {
        getStripeConfig().then((res) => {
            const [data, _error] = res;
            if (data) {
                setStripePromise(loadStripe(data.publishableKey));
            }
            if (_error) {
                setError(_error);
            }
        });
    }, []);

    return (
        <div>
            <h1>New Credit Card Result Page</h1>
            
            {stripePromise && clientSecret && (
                <Elements
                    stripe={stripePromise}
                    options={{
                        clientSecret,
                        appearance: StripeAppearance,
                    }}
                >
                    <PaymentStatus />
                </Elements>
            )}

            {error && (
                <Alert severity="error">{JSON.stringify(error)}</Alert>

            )}

        </div>
    );
};

const PaymentStatus = () => {
    const stripe = useStripe();
    const [message, setMessage] = useState<string>();

    useEffect(() => {
        if (!stripe) {
            return;
        }

        // Retrieve the "setup_intent_client_secret" query parameter appended to
        // your return_url by Stripe.js
        const clientSecret = new URLSearchParams(window.location.search).get(
            'setup_intent_client_secret',
        ) as string;

        // Retrieve the SetupIntent
        stripe
            .retrieveSetupIntent(clientSecret)
            .then(({ setupIntent }) => {
                // Inspect the SetupIntent `status` to indicate the status of the payment
                // to your customer.
                //
                // Some payment methods will [immediately succeed or fail][0] upon
                // confirmation, while others will first enter a `processing` state.
                //
                // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
                switch (setupIntent?.status) {
                    case 'succeeded':
                        setMessage('Success! Your payment method has been saved.');
                        break;

                    case 'processing':
                        setMessage("Processing payment details. We'll update you when processing is complete.");
                        break;

                    case 'requires_payment_method':
                        // Redirect your user back to your payment page to attempt collecting
                        // payment again
                        setMessage('Failed to process payment details. Please try another payment method.');
                        break;
                    default:
                    // NOOP
                        break;
                }
            });
    }, [stripe]);

    return (
        <div>
            <h1>Payment Status</h1>
            <Alert severity="info">{message}</Alert>
        </div>

    );
};

export const PaymentStatusScreen = withPaymentMethodContext(PaymentStatusScreenBase);

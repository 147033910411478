/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

/**
 * Endpoint to retrieve the list of available payment methods
 *
 * @returns
 */
export const paymentMethodsAvailableUrl = () => {
    return '/wallet/payment-methods/available';
};

/**
 * Endpoint to manage payment methods
 *
 * @returns
 */
export const paymentMethodsUrl = (walletId: string) => {
    return `/wallet/${walletId}/payment-methods`;
};

/**
 * Endpoint to manage payment methods
 *
 * @returns
 */
export const paymentMethodUrl = (paymentMethodId: string) => {
    return `/wallet/payment-methods/${paymentMethodId}`;
};

/**
 * Manages default payment method
 *
 * @returns
 */
export const paymentMethodDefaultUrl = (paymentMethodId: string) => {
    return `/wallet/payment-methods/${paymentMethodId}/default`;
};

/**
 * Stripe configuration resource
 *
 * @returns
 */
export const stripeConfigUrl = () => {
    return '/stripe/config';
};

/**
 * Available plans
 *
 * @returns
 */
export const availablePlansUrl = () => {
    return '/subscription-plan/list-available';
};

/**
 * Stripe create payment intent resource
 *
 * @returns
 */
export const createPaymentIntentUrl = (walletId: string) => {
    return `/wallet/${walletId}/checkout/payment-intent`;
};

/**
 * Organization wallet
 *
 * @returns
 */
export const organizationWalletUrl = (organizationId: string) => {
    return `/organizations/${organizationId}/wallet`;
};

/**
 * Wallet resource
 * @param walletId
 * @returns
 */
export const walletUrl = (walletId: string = '') => {
    return `/wallet/${walletId}`;
};

/**
 * Create basket resource
 * @param walletId
 * @returns
 */
export const createBasketUrl = (walletId: string) => {
    return `/wallet/${walletId}/checkout/create-basket`;
};

/**
 * Update basket resource
 * @param walletId
 * @returns
 */
export const updateBasketUrl = (walletId: string) => {
    return `/wallet/${walletId}/checkout/update-basket`;
};

/**
 * Current user subscription details
 *
 * @returns
 */
export const activeSubscriptionUrl = (walletId: string) => {
    return `/wallet/${walletId}/subscription/active`;
};

/**
 * Latest user subscription details (active or not)
 *
 * @returns
 */
export const latestSubscriptionUrl = (walletId: string) => {
    return `/wallet/${walletId}/subscription/latest`;
};

/**
 * Simulate order
 *
 * @returns
 */
export const simulateOrderUrl = () => {
    return '/orders/simulate';
};

/**
 /** Purchase order endpoint
 *
 * @returns
 */

export const purchaseOrderUrl = () => {
    return '/orders/purchase';
};

/**
 /** Get users wallet
 *
 * @returns
 */
export const usersWalletUrl = () => {
    return '/users/wallet';
};

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { ComponentType, createContext, FC } from 'react';
import { DataOrError, ErrorResponse } from '../../types/errors';
import { HelpAndSupportController } from './HelpAndSupportController';
import { NewTicketPayload, NewTicketResponse } from '../../types/help';

export interface HelpAndSupportContext {
    submitTicket: (payload: NewTicketPayload) => Promise<DataOrError<NewTicketResponse, ErrorResponse>>;
}

export const helpAndSupportDefaultValue: HelpAndSupportContext = {
    submitTicket: async () => [Object.create({}), null],
};

const helpAndSupportInstance = createContext<HelpAndSupportContext>(helpAndSupportDefaultValue);

export const HelpAndSupportContextProvider = helpAndSupportInstance.Provider;
export const HelpAndSupportContextConsumer = helpAndSupportInstance.Consumer;

export const withHelpAndSupportContext = <P extends object>(Component: ComponentType<P>): FC<Omit<P, keyof HelpAndSupportContext>> => (props) => (
    <HelpAndSupportController>
        <HelpAndSupportContextConsumer>
            {(ctx) => <Component {...props as P} {...ctx} />}
        </HelpAndSupportContextConsumer>
    </HelpAndSupportController>
);

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FunctionComponent,
} from 'react';
import { Link } from 'react-router-dom';
import { UsageChart } from './UsageChart';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';

interface BaseProps extends TranslationContext{
    name?: string;
    title: string;
    totalValue: number;
    showTotal?: boolean;
    availableValue: number;
    captiveValue?: number;
    linkText?: string;
    linkUrl?: string;
}
const UsageCardBase: FunctionComponent<BaseProps> = (props) => {
    const {
        t,
        name,
        title,
        totalValue,
        showTotal = false,
        availableValue,
        captiveValue = 0,
        linkText,
        linkUrl,
    } = props;

    return (
        <div className="usage-card" data-testid={`usage-card-${name}`}>
            <h2>{title}</h2>
            <div className="usage-card__info">
                <div className="usage-card__info__current-available" data-testid={`usage-card-${name}-current-available`}>
                    <span>{availableValue}</span>
                    {showTotal && (<>{` ${t('billingDashboard.of')} ${totalValue}`}</>)}
                </div>
                <div className="usage-card__info__details">
                    <div className="usage-card__info__details__available">
                        {`${t('billingDashboard.available')}: ${availableValue}`}
                    </div>
                    <div className="usage-card__info__details__captive">
                        {`${t('billingDashboard.captive')}: ${captiveValue}`}
                    </div>
                </div>
            </div>
            <UsageChart total={totalValue} available={availableValue} captive={captiveValue} />
            {linkText && linkUrl && (
                <Link to={linkUrl}>
                    {linkText}
                </Link>
            )}
        </div>
    
    );
};

export const UsageCard = withTranslationContext(UsageCardBase);

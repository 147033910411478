/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FieldValue,
    FormValidatorError,
    FormValidatorErrorType,
} from '../validations';

import { ValidatorStrategy } from './Validator';

export class NotEmptyValidator implements ValidatorStrategy {
    validate(fieldValue: FieldValue): FormValidatorError | null {
        if (Array.isArray(fieldValue) && fieldValue.length === 0) {
            return { typeOfViolation: FormValidatorErrorType.NotEmpty };
        }
        return null;
    }
}

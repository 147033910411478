/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { ComponentType, FC, createContext } from 'react';
import { QesSignatureController } from './QesSignatureController';
import { DataOrError, ErrorResponse } from '../../../types/errors';
import { QesVideoAuth, QesVideoWorkflow } from '../../../types/esignatures/qes';

export interface QesSignatureContext {
    startQesVideoWorkflow(): Promise<DataOrError<QesVideoWorkflow, ErrorResponse>>;
    getQesVideoTermsAndCondition(): Promise<DataOrError<Blob, ErrorResponse>>;
    getQesVideoPrivacyPolicy(): Promise<DataOrError<Blob, ErrorResponse>>;
    acceptQesVideoTermsAndCondition(): Promise<DataOrError<QesVideoAuth, ErrorResponse>>;
    acceptQesVideoPrivacyPolicy(): Promise<DataOrError<QesVideoAuth, ErrorResponse>>;
    requestQesVideoFlow(): Promise<DataOrError<QesVideoAuth, ErrorResponse>>;
    requestQesVideoVerification(): Promise<undefined | ErrorResponse>;
    completeSignature(otp: string): Promise<ErrorResponse | undefined>;
    navigateToContract(): void;
    navigateToPrivacyPolicy(): void;
    navigateToStartVideoValidation(): void;
    navigateToCheckingVideoSignature(): void;
    navigateToVideoValidationFailure(): void;
    navigateToCheckingVideoSignatureFailure(): void;
    navigateToQesOtp(): void;
    navigateToContractSignedPage(): void;
}

export const qesSignatureContextDefaultValue: QesSignatureContext = {
    startQesVideoWorkflow: async () => [{ workflowId: '', workflowAuthorization: '' }, null],
    getQesVideoTermsAndCondition: async () => [new Blob([]), null],
    getQesVideoPrivacyPolicy: async () => [new Blob([]), null],
    acceptQesVideoTermsAndCondition: async () => [{ videoAuthorization: '', videoId: '' }, null],
    acceptQesVideoPrivacyPolicy: async () => [{ videoAuthorization: '', videoId: '' }, null],
    requestQesVideoFlow: async () => [{ videoAuthorization: '', videoId: '' }, null],
    requestQesVideoVerification: async () => undefined,
    completeSignature: async () => undefined,
    navigateToContract: () => { },
    navigateToPrivacyPolicy: () => { },
    navigateToStartVideoValidation: () => { },
    navigateToCheckingVideoSignature: () => { },
    navigateToVideoValidationFailure: () => { },
    navigateToCheckingVideoSignatureFailure: () => { },
    navigateToQesOtp: () => { },
    navigateToContractSignedPage: () => { },
};

const qesSignatureContextInstance = createContext<QesSignatureContext | null>(qesSignatureContextDefaultValue);

export const QesSignatureContextProvider = qesSignatureContextInstance.Provider;
export const QesSignatureContextConsumer = qesSignatureContextInstance.Consumer;

export const withQesSignatureContext = <P extends object>(Component: ComponentType<P>): FC<Omit<P, keyof QesSignatureContext>> => (props) => (
    <QesSignatureController>
        <QesSignatureContextConsumer>
            {(ctx) => <Component {...props as P} {...ctx} />}
        </QesSignatureContextConsumer>
    </QesSignatureController>
);

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FormEventHandler,
    FunctionComponent,
    useEffect,
    useState,
} from 'react';
import { Backdrop } from '@mui/material';
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';
import { CmdSignatureContext, withCmdSignatureContext } from '../../../controllers/esignatures/CmdSignatureContext';
import { TranslationContext, withTranslationContext } from '../../../controllers/TranslationContext';

import { ReactComponent as ArrowLeftIcon } from '../../../../assets/images/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from '../../../../assets/images/arrow-right.svg';
import AutenticacaoGovLogo from '../../../../assets/images/logo-autenticacao.gov-branco.png';
import Button from '../../../elements/Button';
import { ButtonVariant } from '../../../../types/general';
import CmdIcon from '../../../../assets/images/cmd-icon-short-blue.svg';
import CmdIconWhite from '../../../../assets/images/cmd-logo-short.svg';
import { ErrorResponse } from '../../../../types/errors';
import { EsignatureLoadingModal } from '../../../elements/esignatures/common/EsignatureLoadingModal';
import { EsignaturesLayout } from '../../../elements/esignatures/layouts/EsignaturesLayout';
import { ProgressBar } from '../../../elements/ProgressBar';
import { RectangleStepper } from '../../../elements/RectangleStepper';
import { SmartCodeFormField } from '../../../elements/SmartCodeFormField';
import { withOrganizationsContext } from '../../../controllers/OrganizationsContext';

type OwnProps = TranslationContext & Pick<CmdSignatureContext, 'resendOTP'|'navigateToCredentialsPage'|'validateOTP'|'navigateToContractSignedPage'> & {
    otpValidationMinutes: number;
}

export const CmdSignatureOtpScreenBase: FunctionComponent<OwnProps> = (props) => {
    const {
        t,
        tWithJSX,
        otpValidationMinutes,
        navigateToCredentialsPage,
        resendOTP,
        validateOTP,
        navigateToContractSignedPage,
    } = props;

    const [secondsLeft, setSecondsLeft] = useState(otpValidationMinutes * 60);
    const [error, setError] = useState<ErrorResponse>();
    const [isSubmitting, setIsSubmitting] = useState(false);
    
    const [searchParams] = useSearchParams();
    const fileName = searchParams.get('fileName') ?? '';
    const clientId = searchParams.get('clientId') ?? '';
    
    useEffect(() => {
        const timer = setInterval(() => {
            setSecondsLeft(
                (prevSecondsLeft) => (prevSecondsLeft > 0 ? prevSecondsLeft - 1 : 0),
            );
        }, 1000);
        return () => {
            clearInterval(timer);
        };
    }, []);

    /**
     * Reset timer to initial value
     */
    function resetTimeLeft() {
        setSecondsLeft(otpValidationMinutes * 60);
    }

    /**
     * Renders time left using format mm:ss
     *
     * @returns
     */
    function renderTimeLeft() {
        return dayjs().startOf('day').add(secondsLeft, 'second').format('mm:ss');
    }

    /**
     * Calculate progress number for progress bar
     *
     * @returns
     */
    function calculateProgress() {
        return (secondsLeft / (otpValidationMinutes * 60)) * 100;
    }

    /**
     * Handles resend OTP logic
     */
    function handleResendOtp() {
        // to be done in a different PR
        resendOTP({ clientId }).then((res) => {
            if (res[0]) return resetTimeLeft();

            if (res[1]) {
                setError(res[1]);
            }
        }).catch((e) => setError(e));
    }

    const handleFormSubmit: FormEventHandler = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target as HTMLFormElement);
        setIsSubmitting(true);
        validateOTP({
            otp: String(formData.get('otp')),
        }).then((res) => {
            if (res[0]) {
                navigateToContractSignedPage();
                return;
            }
            setError(res[1]);
        }).catch(() => {
        }).finally(() => {
            setIsSubmitting(false);
        });
    };
    
    /**
    * JSX for the "Next" button used in the existing bottom bar of the layout.
    *
    */
    const nextBtn = (
        <Button
            id="actionNext"
            variant={ButtonVariant.Curved}
            extraClasses="primary"
            isSubmit
            testId="cmd-otp-actionGoNext"
            disabled={isSubmitting}
        >
            <span className="btn-content">
                {t('cmdSignatureOtpScreen.actionGoNext')}
                <ArrowRightIcon />
            </span>
        </Button>
    
    );
    
    /**
    * JSX for the "Go Back" button used in the existing bottom bar of the layout.
    *
    */
    const goBackBtn = (
        <Button
            id="actionBackToViewSigningContract"
            variant={ButtonVariant.Curved}
            extraClasses="secondary"
            onClick={() => navigateToCredentialsPage()}
            testId="cmd-otp-actionGoBack"
        >
            <span className="btn-content">
                <ArrowLeftIcon />
                {t('cmdSignatureOtpScreen.actionGoBack')}
            </span>
        </Button>
    );
    const progress = calculateProgress();
    
    return (
        <>
            <form
                onSubmit={handleFormSubmit}
                autoComplete="off" // Compliance with AMA guidelines
            >
                <EsignaturesLayout primaryBtn={nextBtn} secondaryBtn={goBackBtn}>
                    <div id="cmd-signature-banner" className="cmd-signature-banner">
                        <img src={AutenticacaoGovLogo} alt="Autenticação.Gov logo" height={25} width="auto" data-testid="cmd-signature-banner" />
                    </div>
                    <section id="cmd-otp-screen" className="cmd-otp" data-testid="cmd-otp-screen">
                        <div id="cmd-stepper" className="cmd-stepper">
                            <RectangleStepper list={['Personalizar', 'Assinar']} currrent={1} testId="cmd-otp-stepper" />
                        </div>
                        <h3>{t('cmdSignatureOtpScreen.confirmTitle')}</h3>
                        <p>{tWithJSX('cmdSignatureOtpScreen.confirmDescription', {
                            fileName,
                        })}
                        </p>

                        <p>{t('cmdSignatureOtpScreen.otpTitle')}</p>
                        <SmartCodeFormField id="cmd-otp-inputs" name="otp" size={6} isPassword />

                        <div className="cmd-otp__progress-bar" data-testid="progress-bar">
                            <ProgressBar progress={progress} size="xs" variant="primary" testId="time-left-progress-bar" />
                            <p data-testid="time-disclaimer">{tWithJSX('cmdSignatureOtpScreen.timeDisclaimer', {
                                time: renderTimeLeft(),
                            })}
                            </p>
                        </div>

                        <div className="cmd-otp__resend">
                            <h3>{t('cmdSignatureOtpScreen.didnReceiveMessageTitle')}</h3>
                            <button data-testid="cmd-otp-resend-btn" type="button" onClick={() => handleResendOtp()}>{t('cmdSignatureOtpScreen.didnReceiveMessageResend')}</button>
                        </div>

                        {/* TO BE REFACTORED WITH ERROR PRESENTATION */}
                        { error?.errors?.map((e) => (
                            <p key={e.message} style={{ color: 'red' }}>{e.message}</p>
                        ))}

                        <div className="cmd-otp__warning" data-testid="cmd-otp-warning">
                            <div className="cmd-otp__warning-header">
                                <img alt="cmd-logo" src={CmdIcon} height="25px" width="auto" />
                                <h3>{t('cmdSignatureOtpScreen.warningCmdTitle')}</h3>
                            </div>
                            <p>{t('cmdSignatureOtpScreen.warningCmdDescription')}</p>
                            <a data-testid="signature-policy-link" target="_blank" href="https://www.autenticacao.gov.pt/web/guest/politicas-e-informacao-sobre-chave-movel-digital" rel="noreferrer">{t('cmdSignatureOtpScreen.warningCmdLinkName')}</a>
                        </div>
                    </section>
                </EsignaturesLayout>
            </form>
            {isSubmitting && (
                <Backdrop open>
                    <EsignatureLoadingModal
                        open
                        providerIconSrc={CmdIconWhite}
                        message={t('cmdLoadingScreen.otpScreenText')}
                        disclaimer={t('cmdLoadingScreen.otpScreenDisclaimer')}
                    />
                </Backdrop>
            )}
        </>
    );
};

export const CmdSignatureOtpScreen = withTranslationContext(withOrganizationsContext(withCmdSignatureContext(CmdSignatureOtpScreenBase)));

/*
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import React, { FunctionComponent } from 'react';
import { useUserHasPermission } from '../../hooks/useUserHasPermission';
import AccessDenied from '../screens/AccessDenied';
import { PermissionList } from '../../types/permissions';

interface OwnProps {
    permissions: PermissionList;
    children: React.ReactNode;

}
export const HasPermission: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        permissions,
        children,
    } = props;

    const hasPermission = useUserHasPermission(permissions);
    return (
        <>
            {hasPermission
                ? children
                : <AccessDenied />
            }
        </>

    );
};

export default HasPermission;

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { Chip, Fab } from '@mui/material';
import {
    FunctionComponent, useEffect, useMemo, useState,
} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { isEqual } from 'lodash';
import { ReactComponent as PlusIcon } from '../../../assets/images/plus-icon.svg';
import { AppRoute } from '../../../constants/routes';
import { useUserHasPermission } from '../../../hooks/useUserHasPermission';
import { ButtonVariant, OrderOptions } from '../../../types/general';
import { Permissions } from '../../../types/permissions';
import { Workflow, WorkflowsFilterParams } from '../../../types/workflows';
import { preparePageTitle } from '../../../utils/route';
import { OrganizationsContext, withOrganizationsContext } from '../../controllers/OrganizationsContext';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import { WorkflowContext, withWorkflowContext } from '../../controllers/WorkflowContext';
import Button from '../../elements/Button';
import HasPermission from '../../elements/HasPermission';
import InfiniteScrollWrapper from '../../elements/InfiniteScrollWrapper';
import { LoadingCircles } from '../../elements/LoadingCircles';
import { DefaultLayout } from '../../elements/layouts/DefaultLayout';
import { WorkflowCard } from '../../elements/workflows/WorkflowCard';
import { FiltersForm } from '../../elements/workflows/FiltersForm';
import { Drawer } from '../../elements/Drawer';
import { ReactComponent as FiltersIcon } from '../../../assets/images/filters.svg';
import { formatToLocalDate } from '../../../utils/date';
import { ReactComponent as RemoveIcon } from '../../../assets/images/closeBtn.svg';

type OwnProps = TranslationContext & OrganizationsContext & WorkflowContext;

const defaultFilters: WorkflowsFilterParams = {
    _cursor: '',
    _limit: '',
    _sort: OrderOptions.ASCENDING,
    email: '',
    externalId: '',
    from: '',
    to: '',
};

const OrganizationWorkflowsScreenBase: FunctionComponent<OwnProps> = (props) => {
    const {
        t,
        getOrganizationWorkflows,
        organizationSelected,
    } = props;

    const canManage = useUserHasPermission([Permissions.MANAGE_ORGANIZATION_TRANSACTIONS, Permissions.MANAGE_ALL_ORGANIZATION_TRANSACTIONS]);
    const navigate = useNavigate();

    const [workflows, setWorkflows] = useState<Workflow[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isOpenFilters, setIsOpenFilters] = useState(false);
    const [filters, setFilters] = useState<WorkflowsFilterParams>(defaultFilters);

    useEffect(() => {
        document.title = preparePageTitle(t('workflows.list.title'));
        getWorkflowsList(filters);
    }, []);

    const appliedFilters: string[] = useMemo(() => {
        return Object.keys(filters)
            .filter((key) => !key.startsWith('_')
                && filters[key as keyof WorkflowsFilterParams] !== ''
                && filters[key as keyof WorkflowsFilterParams] !== undefined);
    }, [filters]);

    const getWorkflowsList = async (submittedFilters: WorkflowsFilterParams) => {
        if (organizationSelected?.organization?.id) {
            const appliedFiltersWithOrder = submittedFilters._sort !== filters._sort
                ? { ...submittedFilters, _cursor: '' }
                : submittedFilters;
            const [workflowsData] = await getOrganizationWorkflows(organizationSelected?.organization?.id, appliedFiltersWithOrder);
        
            if (workflowsData) {
                setWorkflows(isEqual(submittedFilters, filters) ? [...workflows, ...workflowsData.results] : [...workflowsData.results]);
                setFilters({
                    ...appliedFiltersWithOrder,
                    _cursor: workflowsData.cursor,
                });
            }
        }
        setIsLoading(false);
    };

    const applyFilters = (filtersToApply: WorkflowsFilterParams) => {
        setIsLoading(true);
        setIsOpenFilters(false);
        getWorkflowsList({ ...filtersToApply, _cursor: '' });
    };

    const getAppliedFilterDisplayValue = (key: keyof WorkflowsFilterParams) => {
        switch (key) {
            case 'transactionType':
            case 'transactionStatus':
                return t(`workflows.list.filters.${key}Options.${filters[key]}`);
            case 'from':
            case 'to':
                return formatToLocalDate(filters[key]);
            default:
                return filters[key];
        }
    };

    const removeFilter = (keyToRemove: keyof WorkflowsFilterParams) => {
        setFilters({
            ...filters,
            [keyToRemove]: defaultFilters[keyToRemove],
        });
    };
    const cleanAllFilters = () => {
        setIsOpenFilters(false);
        getWorkflowsList(defaultFilters);
    };

    return (
        <HasPermission permissions={[Permissions.VIEW_ORGANIZATION_TRANSACTIONS, Permissions.VIEW_ALL_ORGANIZATION_TRANSACTIONS]}>
            <DefaultLayout>
                <div className="organization-workflows-screen" data-testid="organization-workflows-screen">
                    <div className="organization-workflows-screen__header">
                        <h1>{t('workflows.list.title')}</h1>
                        {canManage && (
                            <Button
                                variant={ButtonVariant.Curved}
                                extraClasses="large-add-btn shorter-btn"
                                testId="large-add-btn"
                                onClick={() => navigate(AppRoute.CreateWorkflowSelectType)}
                            >
                                {t('workflows.list.createBtn')}
                            </Button>
                        )}
                        <Button
                            extraClasses="circle-btn filters-trigger-btn"
                            onClick={() => setIsOpenFilters(true)}
                            testId="mobile-filters-trigger-btn"
                        >
                            <FiltersIcon />
                        </Button>
                    </div>
                    <div className="organization-workflows-screen__applied-filters" data-testid="applied-filters">
                        <div>
                            {appliedFilters.length > 0 && (
                                <>
                                    <div className="organization-workflows-screen__applied-filters__header" data-testid="applied-filters-header">
                                        {`${appliedFilters.length} ${t(`workflows.list.filters.${appliedFilters.length === 1 ? 'filterApplied' : 'filtersApplied'}`)}:`}
                                        <Button
                                            onClick={cleanAllFilters}
                                            testId="large-clean-btn"
                                        >
                                            {t('workflows.list.filters.cleanAll')}
                                        </Button>
                                    </div>
                                    <div className="organization-workflows-screen__applied-filters__list" data-testid="applied-filters-list">
                                        {appliedFilters.map((appliedFilter) => {
                                            return (
                                                <Chip
                                                    key={appliedFilter}
                                                    className="organization-workflows-screen__applied-filters__list__item"
                                                    label={`${t(`workflows.list.filters.${appliedFilter}`)}: ${getAppliedFilterDisplayValue(appliedFilter as keyof WorkflowsFilterParams)}`}
                                                    onDelete={() => removeFilter(appliedFilter as keyof WorkflowsFilterParams)}
                                                    deleteIcon={<RemoveIcon />}
                                                />
                                            );
                                        })}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="organization-workflows-screen__content">
                        <div className="organization-workflows-screen__content__large-filters" data-testid="filters-sidebar">
                            <FiltersForm
                                currentFilters={filters}
                                onSubmit={applyFilters}
                                onClean={cleanAllFilters}
                            />
                        </div>
                        <div className="organization-workflows-screen__content__list">
                            <InfiniteScrollWrapper
                                hasMore={!!filters._cursor}
                                requestMore={() => getWorkflowsList(filters)}
                            >
                                {workflows.map((workflow) => (
                                    <WorkflowCard key={workflow.id} workflow={workflow} />
                                ))}
                            </InfiniteScrollWrapper>
                            {isLoading && <LoadingCircles size="m" variant="primary" />}
                        </div>
                        {canManage && (
                            <div className="add-btn-wrap">
                                <Link to={AppRoute.CreateWorkflowSelectType}>
                                    <Fab size="medium" color="primary" aria-label="add" data-testid="add-btn">
                                        <PlusIcon />
                                    </Fab>
                                </Link>
                            </div>
                        )}
                    </div>
                    <Drawer
                        open={isOpenFilters}
                        handleClose={() => setIsOpenFilters(false)}
                        title={t('general.filter')}
                        extraClasses="filters-drawer"
                        testId="filters-drawer"
                    >
                        <FiltersForm
                            currentFilters={filters}
                            onSubmit={applyFilters}
                            onClean={cleanAllFilters}
                        />
                    </Drawer>
                </div>
            </DefaultLayout>
        </HasPermission>
    );
};

export const OrganizationWorkflowsScreen = withTranslationContext(withOrganizationsContext(withWorkflowContext(OrganizationWorkflowsScreenBase)));

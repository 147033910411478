/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { DefaultLayout } from '../elements/layouts/DefaultLayout';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import { ReactComponent as BackIcon } from '../../assets/images/chevron-left.svg';
import Button from '../elements/Button';
import { SignatureAvailabilityList } from '../elements/SignatureAvailabilityList';

type BaseProps = TranslationContext;

const SignatureAvailabilityScreenBase: FunctionComponent<BaseProps> = ({ t }) => {
    const navigate = useNavigate();
    
    return (
        <DefaultLayout>
            <div className="signature-availability">
                <div className="signature-availability__header">
                    <Button
                        extraClasses="circular-ghost-btn"
                        onClick={() => navigate(-1)}
                        testId="back-btn"
                    >
                        <BackIcon />
                    </Button>
                    <h1>{t('signatureAvailability.title')}</h1>
                </div>
                <div className="signature-availability__content">
                    <SignatureAvailabilityList />
                </div>
            </div>
        </DefaultLayout>
    );
};

export const SignatureAvailabilityScreen = withTranslationContext(SignatureAvailabilityScreenBase);

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { Link, To } from 'react-router-dom';
import { Fab } from '@mui/material';
import { MouseEventHandler } from 'react';
import { ReactComponent as PlusIcon } from '../../assets/images/plus-icon.svg';

type Props = {
    type: 'link';
    to: To;
    state?: unknown;
    testId: string;
} | {
    type: 'button';
    onClick: MouseEventHandler;
    testId: string;
}

/**
 * A floating action meant to be present on mobile screens to create new resources
 *
 * @param props
 * @returns
 */
export const FloatingAddAction = (props: Props) => {
    const { type: action, testId } = props;
    if (action === 'link') {
        const {
            to,
            state,
        } = props;
        return (
            <div className="add-btn-wrap">
                <Link to={to} state={state}>
                    <Fab size="medium" color="primary" aria-label="add" data-testid={testId}>
                        <PlusIcon />
                    </Fab>
                </Link>
            </div>

        );
    }

    if (action === 'button') {
        const {
            onClick,
        } = props;

        return (
            <div className="add-btn-wrap">
                <Fab size="medium" color="primary" aria-label="add" data-testid={testId} onClick={onClick}>
                    <PlusIcon />
                </Fab>
            </div>
        );
    }

    return null;
};

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { Tooltip as MuiTooltip } from '@mui/material';

export const Tooltip = MuiTooltip;

/*
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent } from 'react';

interface OwnProps {
    size: 'xs'|'s'|'m'|'l'|'xl';
    variant: 'primary'|'secondary'|'neutral';
}

export const LoadingCircles: FunctionComponent<OwnProps> = (props) => {
    const {
        size,
        variant,
    } = props;
    return (
        <div className="loading-circles" data-testid="loading-circles">
            <div className={`${size} ${variant}`} data-testid="circle" />
            <div className={`${size} ${variant}`} data-testid="circle" />
            <div className={`${size} ${variant}`} data-testid="circle" />
        </div>
    );
};

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent, useMemo } from 'react';
import { ButtonVariant } from '../../../types/general';
import { getSubtypeOfSubscriptionPlan } from '../../../utils/billing';
import { formatNumberToLocale } from '../../../utils/number';
import { BillingContext, withBillingContext } from '../../controllers/BillingContext';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import Button from '../Button';
import { LoadingCircles } from '../LoadingCircles';

interface OwnProps extends TranslationContext, BillingContext {
    canChangeCheckoutCart?: boolean;
    isLoading?: boolean;
}

const CartSummaryComponent: FunctionComponent<OwnProps> = (props) => {
    const {
        t,
        language,
        billingInfo,
        changeCreditsToPurchase,
        canChangeCheckoutCart = false,
        isLoading = false,
    } = props;

    const planSubtype = getSubtypeOfSubscriptionPlan(billingInfo.basket?.subscriptionPlan || null);

    const totalCosts = useMemo(() => {
        const totalNoTax = Number(billingInfo.basket?.subscriptionPlan?.annualPrice?.value ?? 0) + Number(billingInfo.basket?.creditValue.value ?? 0);
        const taxValue = billingInfo.basket?.taxValue.value ?? 0;
        const total = billingInfo.basket?.totalValue.value ?? 0;

        return {
            totalNoTax,
            taxValue,
            total,
        };
    }, [billingInfo]);

    return (
        <div className="cart-summary">
            {isLoading && <div className="cart-summary__loading"><LoadingCircles size="m" variant="primary" /></div>}
            {planSubtype && (
                <>
                    <h2>{t('subscribeFlow.summary.title')}</h2>
                    <div className="cart-summary__item">
                        <h3>{t('subscribeFlow.summary.plan')} {t(`plans.${planSubtype}.title`)}</h3>
                    </div>
                </>
            )}
            <div className="cart-summary__item">
                {billingInfo.basket?.subscriptionPlan && (
                    <>
                        <div data-testid="plan-cost">
                            <h3>{t('subscribeFlow.summary.plan')}</h3>
                            <h3>{formatNumberToLocale(Number(billingInfo.basket?.subscriptionPlan.annualPrice?.value), language, billingInfo.basket?.subscriptionPlan.annualPrice?.currency)}</h3>
                        </div>
                        {billingInfo.basket?.subscriptionPlan.annualPrice?.value && (
                            <p>{t('subscribeFlow.summary.chargeFrequency')}</p>
                        )}
                    </>
                )}
                <div data-testid="credits-cost">
                    <h3>{t('subscribeFlow.summary.credits')} ({billingInfo.basket?.credits?.amount ?? 0})</h3>
                    <h3>{formatNumberToLocale(Number(billingInfo.basket?.creditValue.value), language, billingInfo.basket?.creditValue.currency)}</h3>
                </div>

                {canChangeCheckoutCart && billingInfo.credits !== 0 && (
                    <Button
                        extraClasses="remove-credits"
                        onClick={() => changeCreditsToPurchase(0)}
                        variant={ButtonVariant.Basic}
                        testId="clear-credits"
                    >
                        {t('subscribeFlow.summary.removeCredits')}
                    </Button>
                )}
            </div>
            <div className="cart-summary__item">
                <div data-testid="products-cost">
                    <h3>{t('subscribeFlow.summary.price')}</h3>
                    <h3>{formatNumberToLocale(totalCosts.totalNoTax, language, billingInfo.planDetails?.annualPrice?.currency)}</h3>
                </div>
                <div data-testid="tax-cost">
                    <p>{t('subscribeFlow.summary.tax')}</p>
                    <p>{formatNumberToLocale(totalCosts.taxValue, language, billingInfo.planDetails?.annualPrice?.currency)}</p>
                </div>
            </div>
            <div className="cart-summary__item">
                <div data-testid="checkout-total-cost">
                    <h3>{t('subscribeFlow.summary.total')}</h3>
                    <h3>{formatNumberToLocale(totalCosts.total, language, billingInfo.planDetails?.annualPrice?.currency)}</h3>
                </div>
            </div>
        </div>
    );
};

export const CartSummary = withBillingContext(withTranslationContext(CartSummaryComponent));

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import React, { FunctionComponent } from 'react';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import { ReactComponent as AccessDeniedIcon } from '../../assets/images/access_denied.svg';
import { DefaultLayout } from '../elements/layouts/DefaultLayout';

interface OwnProps extends TranslationContext { }

const AccessDeniedPage: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const { t } = props;

    return (
        <DefaultLayout>
            <div className="access-denied-page information-page primary-gradient-bg">
                <div className="information-page__content-wrap">
                    <div className="information-page__content">
                        <AccessDeniedIcon />
                        <h1>{t('accessDenied.title')}</h1>
                        <p>{t('accessDenied.description')}</p>
                    </div>
                </div>
            </div>
        </DefaultLayout>
    );
};

export default withTranslationContext(AccessDeniedPage);

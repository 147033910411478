/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { useNavigate, useParams } from 'react-router-dom';
import { FunctionComponent, useEffect, useState } from 'react';
import { WorkflowContext, withWorkflowContext } from '../../controllers/WorkflowContext';
import { LoadingScreen } from '../LoadingScreen';
import { DefaultLayout } from '../../elements/layouts/DefaultLayout';
import { AppRoute } from '../../../constants/routes';
import { ErrorResponse } from '../../../types/errors';
import { buildUrl } from '../../../utils/navigation';

type OwnProps = WorkflowContext;

const ParticipantAssignScreenBase: FunctionComponent<OwnProps> = (props) => {
    const {
        assignUserToParticipant,
    } = props;
    const { participantToken = '' } = useParams();
    const navigate = useNavigate();

    const [isVerifying, setIsVerifying] = useState(true);
    const [errorResponse, setErrorResponse] = useState<ErrorResponse | null>(null);

    useEffect(() => {
        prepareAssignUserToParticipant();
    }, []);

    useEffect(() => {
        if (errorResponse) throw errorResponse;
    }, [errorResponse]);

    const prepareAssignUserToParticipant = async () => {
        const [assignedParticipant, assignParticipantError] = await assignUserToParticipant(participantToken);
        setIsVerifying(false);

        if (assignParticipantError) {
            // user email is different and an pin will be send
            if (Number(assignParticipantError.errors[0].errorCode) === 81) {
                navigate(buildUrl(AppRoute.WorkflowParticipantAssignPinValidation, { participantToken }));
                return;
            }
         
            setErrorResponse(assignParticipantError);
            return;
        }

        navigate(buildUrl(AppRoute.ViewWorkflow, { workflowId: String(assignedParticipant.transactionId) }));
    };

    return (
        <DefaultLayout>
            {isVerifying && <LoadingScreen />}
        </DefaultLayout>
    );
};

export const ParticipantAssignScreen = withWorkflowContext(ParticipantAssignScreenBase);

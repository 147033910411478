/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { Alert, Avatar, Collapse } from '@mui/material';
import { FunctionComponent, useState } from 'react';
import { toast } from 'react-toastify';
import { DataOrError, ErrorResponse } from '../../../types/errors';
import { ListResponse, MenuListOptions } from '../../../types/general';
import { MembersContext, withMembersContext } from '../../controllers/MembersContext';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';

import { ButtonWithMenuList } from '../ButtonWithMenuList';
import { LoadingCircles } from '../LoadingCircles';
import { Member } from '../../../types/members';
import Modal from '../Modal';
import { ReactComponent as OwnerIcon } from '../../../assets/images/profile.svg';
import { Role } from '../../../types/roles';
import Switch from '../Switch';

interface OwnProps extends TranslationContext, MembersContext {
    member: Member;
    roles: ListResponse<Role>;
}

const MemberCollapseBase: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        member,
        t,
        deleteMember,
        roles,
        loadMemberRoles,
        assignRoleToMember,
    } = props;

    const [isExpanded, setIsExpanded] = useState(false);
    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [loadingRoles, setLoadingRoles] = useState(false);
    const [memberRolesResult, setMemberRolesResult] = useState<DataOrError<Array<Role>, ErrorResponse>>([[], null]);
    const [, roleError] = memberRolesResult;
    const [userRoleIds, setUserRoleIds] = useState<Set<number>>(new Set());

    const unAssignRole = (role: Role) => {
        const newRoles = userRoleIds;
        newRoles.delete(role.id);

        assignRoleToMember(member.id, {
            roleIds: Array.from(newRoles),
        }).then(() => {
            setUserRoleIds(newRoles);
            onUnassignSuccess();
        }).catch((e) => {
            onUnassingFailure(e.errors[0].getMessageTranslated(t));
        });
    };

    const assignRole = (role: Role) => {
        const newRoles = userRoleIds;
        newRoles.add(role.id);

        assignRoleToMember(member.id, {
            roleIds: Array.from(newRoles),
        }).then(() => {
            setUserRoleIds(newRoles);
            onAssignSuccess();
        }).catch((e) => {
            onAssingFailure(e.errors[0].getMessageTranslated(t));
        });
    };

    const handleRoleSwitch = async (role: Role) => {
        if (userRoleIds.has(role.id)) {
            unAssignRole(role);
            return;
        }

        assignRole(role);
    };

    const handleOpenModalConfirm = () => {
        setOpenModalConfirm(true);
    };

    const handleCloseModalConfirm = () => {
        setOpenModalConfirm(false);
    };

    const handleConfirm = async () => {
        const deleteError = await deleteMember(member.id);

        if (deleteError) {
            onDeleteFailure(deleteError.errors[0].getMessageTranslated(t));
        } else {
            onDeleteSuccess();
        }

        handleCloseModalConfirm();
    };

    const onDeleteSuccess = () => {
        toast.success(t('membersCollapse.deleteSuccess'));
    };

    const onDeleteFailure = (errorMessage: string) => {
        toast.error(errorMessage);
    };

    const onAssignSuccess = () => {
        toast.success(t('membersCollapse.assignSuccess'));
    };

    const onAssingFailure = (errorMessage: string) => {
        toast.error(errorMessage);
    };

    const onUnassignSuccess = () => {
        toast.success(t('membersCollapse.unassignSuccess'));
    };

    const onUnassingFailure = (errorMessage: string) => {
        toast.error(errorMessage);
    };

    const options: MenuListOptions[] = [
        {
            value: t('membersCollapse.deleteMenuItem'),
            action: handleOpenModalConfirm,
            testId: 'delete-btn',
        },
    ];

    const buildRoleSet = (data: DataOrError<Array<Role>, ErrorResponse>) => {
        const roleIdsUserHas = data[0]?.map((r) => r.id) ?? [];
        const auxRoleSet = userRoleIds;
        roleIdsUserHas?.forEach((r) => {
            auxRoleSet.add(r);
        });
        setUserRoleIds(auxRoleSet);
    };

    const handleLoadMemberRoles = () => {
        setLoadingRoles(true);
        loadMemberRoles(member.id).then(((data) => {
            setMemberRolesResult(data);
            setLoadingRoles(false);
            
            buildRoleSet(data);
        }));
    };

    const handleExpandRolesCollapser = () => {
        if (!isExpanded) {
            handleLoadMemberRoles();
        }

        setIsExpanded(!isExpanded);
    };

    return (
        <div className="member-collapse card" data-testid={`member-collapse-${member.id}`}>
            <button className="member-collapse__wrap" onClick={handleExpandRolesCollapser} type="button">
                <div className="member-collapse__wrap__profile">
                    <div className="member-collapse__wrap__profile__avatar">
                        <Avatar alt="member" sizes="20">
                            <OwnerIcon height={14} />
                        </Avatar>
                    </div>
                    <div className="member-collapse__wrap__profile__info">
                        <div className="member-collapse__wrap__profile__info__name">
                            <p>{member.fullName}</p>
                        </div>
                        <div className="member-collapse__wrap__profile__info__role">
                            {member.jobTitle}
                        </div>
                    </div>
                </div>
            </button>
            <div className="member-collapse__options">
                <ButtonWithMenuList
                    options={options}
                />
            </div>
            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                <div className="member-collapse__roles">
                    {!loadingRoles && roles.results.map((role) => {
                        const roleSelected = userRoleIds.has(role.id);
                        return (
                            <div className="member-collapse__roles__select" key={role.id}>
                                <p className={`member-collapse__roles__select ${roleSelected ? 'selected' : ''}`}>{role.name}</p>
                                <Switch value={roleSelected} name={role.description} onChange={() => handleRoleSwitch(role)} />
                            </div>
                        );
                    })}
                    {loadingRoles && <LoadingCircles size="l" variant="secondary" /> }
                    {roleError && (
                        <Alert severity="error">
                            {roleError?.errors?.[0]?.getMessageTranslated(t)}
                        </Alert>
                    )}
                </div>
            </Collapse>
            <Modal
                open={openModalConfirm}
                title={t('membersCollapse.deleteConfirmTitle')}
                handleClose={handleCloseModalConfirm}
                handleConfirm={handleConfirm}
            >
                <p>{t('membersCollapse.deleteConfirmText')}</p>
            </Modal>
        </div>
    );
};

export const MemberCollapse = withTranslationContext(withMembersContext(MemberCollapseBase));

/*
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    ChangeEvent,
    FunctionComponent,
    useRef,
    useState,
} from 'react';
import classNames from 'classnames';
import { FormValidationError, getErrorsForField } from '../../utils/validations';
import { formatPhone, parseCountryCodeFromPhone, parsePhoneNumberFromPhone } from '../../utils/phone';

import ArrowDown from '../../assets/images/arrow-down.svg';
import CountryList from '../../assets/data/country-dial-codes.json';
import ErrorMessageBase from './ErrorMessage';

interface OwnProps {
    id?: string;
    name: string;
    initialValue?: string;
    testId?: string;
    required?: boolean;
    onChange?: (countryCode: string, phoneNumber: string, phone: string) => void;
    errors?: FormValidationError | null;
}

/**
 * Renders an input field to insert phone numbers with country code and phone number as separate form fields.
 * When using in a form, there is a hidden input field with the full phone string value following the format <country_code><space><phone_number> which helps during form submit.
 * {@link https://www.figma.com/file/nvWLkKo3SS0kIDZ58NWkFe/Web-App?type=design&node-id=1710-22199&mode=design&t=zSanxlYFENpoxaQs-0 Mockups}
 *
 * @param props {OwnProps}
 * @returns
 */
export const PhoneInput: FunctionComponent<OwnProps> = (props) => {
    const {
        id,
        name,
        initialValue,
        testId,
        required,
        onChange,
        errors,
    } = props;
    
    const [countryCodeSelected, setCountryCodeSelected] = useState(parseCountryCodeFromPhone(initialValue ?? ''));
    const [phoneNumberSelected, setPhoneNumberSelected] = useState(parsePhoneNumberFromPhone(initialValue ?? ''));
    const [hideList, setHideList] = useState(true);
    const [countryList, setCountryList] = useState(CountryList);
    const [searchValue, setSearchValue] = useState('');
    const searchFieldRef = useRef<HTMLInputElement>(null);

    function propagateNewPhoneToParent(cCode: string, pNumber: string, phoneVal: string) {
        if (typeof onChange !== 'undefined') {
            onChange(cCode, pNumber, phoneVal);
        }
    }

    function handleCountryCodeSelection(newCode: string) {
        setCountryCodeSelected(newCode);
        handleCloseCountryCode();
        resetFilter();

        const newPhone = formatPhone(newCode, phoneNumberSelected);
        propagateNewPhoneToParent(newCode, phoneNumberSelected, newPhone);
    }

    function handlePhoneNumberChange(e: ChangeEvent<HTMLInputElement>) {
        // Accept only numbers
        const newPhoneNumber = e.target.value.replace(/\D/g, '');

        setPhoneNumberSelected(newPhoneNumber);
        const newPhone = formatPhone(countryCodeSelected, newPhoneNumber);
        propagateNewPhoneToParent(countryCodeSelected, newPhoneNumber, newPhone);
    }

    function handleClickButton() {
        setHideList(!hideList);
        focusSearchInput();
    }

    function handleCloseCountryCode() {
        setHideList(true);
    }

    function focusSearchInput() {
        searchFieldRef.current?.focus();
    }

    function handleSearchCountry(e: ChangeEvent<HTMLInputElement>) {
        const filterValue = e.target.value.toLowerCase();
        if (!filterValue) return resetFilter();
        
        setSearchValue(e.target.value);
        const filteredCountries = CountryList.filter((el) => {
            const compareVal = el.country.toLowerCase();
            return compareVal.includes(filterValue) || el.dial_format.includes(filterValue);
        });
        setCountryList(filteredCountries);
    }

    function resetFilter() {
        setSearchValue('');
        setCountryList(CountryList);
    }
    const fieldErrors = getErrorsForField(name, errors);
    return (
        <div id={id} className={classNames('phone-input__container', { 'has-error': fieldErrors })} data-testid="phone-component">
            <div>
                <div className="phone-input__country-wrapper">
                    <button type="button" className="phone-input__selector" onClick={handleClickButton} data-testid="selector">
                        <img src={ArrowDown} alt="arrow" height="8px" width="auto" />
                        <input
                            name="dial"
                            data-testid="country-selected"
                            required={required}
                            value={countryCodeSelected}
                            type="text"
                            readOnly
                            onChange={() => {
                                // NOOP this is a helper input that triggers the browser to force selecting a dial code when it is required
                            }}
                        />
                    </button>
                    <div className="phone-input__list-wrapper" hidden={hideList} aria-hidden={hideList}>
                        <input
                            placeholder="..."
                            ref={searchFieldRef}
                            type="search"
                            value={searchValue}
                            onChange={handleSearchCountry}
                            data-testid="search"
                            aria-autocomplete="none"
                        />
                        <hr />
                        <div data-testid="country-list">
                            {countryList.map((el) => (
                                <button type="button" key={`${el.country}_${el.dial_code}`} id={el.dial_format} onClick={() => handleCountryCodeSelection(el.dial_format)} data-testid={el.country}>
                                    <span className="flag">{el.flag}</span>
                                    <span className="country">{el.country}</span>
                                    <span className="dial">{el.dial_format}</span>
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
            
                <input
                    className="phone-input__phone-number"
                    name={`${name}-phone-number`}
                    type="numeric"
                    inputMode="numeric"
                    onChange={handlePhoneNumberChange}
                    value={phoneNumberSelected}
                    placeholder="000 000 000"
                    data-testid="phone-number"
                    aria-autocomplete="none" // Compliance with Chave Móvel Digital signature experience
                    required={required}
                />
                <input name={name} type="text" hidden value={formatPhone(countryCodeSelected, phoneNumberSelected)} onChange={() => {}} readOnly data-testid={testId} />

            </div>
            {fieldErrors.length ? <ErrorMessageBase errors={fieldErrors} field={name} /> : null}
        </div>
    );
};

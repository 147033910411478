/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FieldValue,
    FormValidatorError,
    FormValidatorErrorType,
} from '../validations';

import { ValidatorStrategy } from './Validator';

export class NotBlankValidator implements ValidatorStrategy {
    validate(fieldValue: FieldValue): FormValidatorError | null {
        if (fieldValue === null || fieldValue === undefined || fieldValue.toString().trim() === '') {
            return { typeOfViolation: FormValidatorErrorType.NotBlank };
        }
        return null;
    }
}

/*
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { ComponentType, FC, createContext } from 'react';
import { DataOrError, ErrorResponse } from '../../types/errors';
import {
    InvitationPayload,
    Invite,
    InviteParams,
    Member,
} from '../../types/members';
import { Role, RolesMemberPayload } from '../../types/roles';

import { FormValidationError } from '../../utils/validations';
import { ListResponse } from '../../types/general';
import MembersController from './MembersController';

export interface MembersContext {
    getMembers(organizationId: number, cursor?: string): Promise<ListResponse<Member>>;
    getInvites(organizationId: number, params?: InviteParams): Promise<ListResponse<Invite>>;
    getInvite(inviteToken: string): Promise<Invite | null>;
    deleteMember(memberId: number): Promise<undefined | ErrorResponse>;
    sendMemberInvite(payload: InvitationPayload): Promise<undefined | ErrorResponse>;
    validateInvitationForm(fields: InvitationPayload): FormValidationError | null;
    assignRoleToMember(memberId: number, payload: RolesMemberPayload): Promise<undefined | ErrorResponse>;
    acceptInvite(inviteToken: string): Promise<undefined | ErrorResponse>;
    rejectInvite(inviteToken: string): Promise<undefined | ErrorResponse>;
    loadMemberRoles(memberId: number): Promise<DataOrError<Array<Role>, ErrorResponse>>;
}

export const membersContextDefaultValue: MembersContext = {
    getMembers: async () => ({ cursor: '', results: [] }),
    getInvites: async () => ({ cursor: '', results: [] }),
    getInvite: async () => null,
    deleteMember: async () => undefined,
    sendMemberInvite: async () => undefined,
    validateInvitationForm: () => null,
    assignRoleToMember: async () => undefined,
    acceptInvite: async () => undefined,
    rejectInvite: async () => undefined,
    loadMemberRoles: async () => ([[], null]),
};

const membersContextInstance = createContext<MembersContext | null>(membersContextDefaultValue);

export const MembersContextProvider = membersContextInstance.Provider;
export const MembersContextConsumer = membersContextInstance.Consumer;

export const withMembersContext = <P extends object>(Component: ComponentType<P>): FC<Omit<P, keyof MembersContext>> => (props) => (
    <MembersController>
        <MembersContextConsumer>
            {(ctx) => <Component {...props as P} {...ctx} />}
        </MembersContextConsumer>
    </MembersController>
);

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

export const submitTicketUrl = () => {
    return '/help/tickets';
};

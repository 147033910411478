/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FunctionComponent, useEffect, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { AppRoute } from '../../../constants/routes';
import { OnboardingFlowStep, SubscriptionPlan } from '../../../types/billing';
import { ButtonVariant } from '../../../types/general';
import { BillingContext, withBillingContext } from '../../controllers/BillingContext';
import { PaymentMethodContext, withPaymentMethodContext } from '../../controllers/PaymentMethodContext';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import Button from '../../elements/Button';
import { HorizontalStepper } from '../../elements/HorizontalStepper';
import { LoadingCircles } from '../../elements/LoadingCircles';
import { PlansList } from '../../elements/billing/PlansList';
import { IsolateFlowLayout } from '../../elements/layouts/IsolateFlowLayout';
import { preparePageTitle } from '../../../utils/route';

type OwnProps = TranslationContext & BillingContext & PaymentMethodContext;

export const ReviewPlanScreenBase: FunctionComponent<OwnProps> = (props) => {
    const {
        t,
        getAvailablePlans,
        billingInfo,
        selectPlanToPurchase,
        createNewBasket,
        changeCreditsToPurchase,
        updateBasket,
    } = props;
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);
    const [plans, setPlans] = useState<SubscriptionPlan[]>([]);

    const OnboardingSteps = [
        t(`subscribeFlow.stepper.${OnboardingFlowStep.ORGANIZATION}`),
        t(`subscribeFlow.stepper.${OnboardingFlowStep.PLAN_REVIEW}`),
        t(`subscribeFlow.stepper.${OnboardingFlowStep.ADD_CREDITS}`),
        t(`subscribeFlow.stepper.${OnboardingFlowStep.BILLING}`),
        t(`subscribeFlow.stepper.${OnboardingFlowStep.PAYMENT}`),
    ];

    useEffect(() => {
        document.title = preparePageTitle(t('subscribeFlow.title'));
        if (!billingInfo.wallet || !billingInfo.planDetails) {
            navigate(AppRoute.BillingPlans);
            return;
        }
        getPlansList();
    }, []);

    const getPlansList = async () => {
        const [plansData] = await getAvailablePlans();

        setIsLoading(false);

        if (plansData) {
            setPlans([...plansData]);
        }
    };

    const handleClickOnPlan = (plan: SubscriptionPlan) => {
        selectPlanToPurchase(plan);
    };

    const handleNextClick = async () => {
        if (!billingInfo.wallet || !billingInfo.planDetails) {
            return;
        }

        setIsLoading(true);
        const [basket] = await createNewBasket(String(billingInfo.wallet.id));
        if (basket) {
            const [updatedBasket] = await updateBasket(String(billingInfo.wallet.id), { subscriptionPlanId: billingInfo.planDetails.id, creditAmount: 0 });
            if (updatedBasket) {
                selectPlanToPurchase(updatedBasket.subscriptionPlan);
                changeCreditsToPurchase(0);

                navigate(AppRoute.BillingAddCredits);
                return;
            }
        }

        setIsLoading(false);
    };

    /**
    * JSX for the "Next" button used in the existing bottom bar of the layout.
    *
    */
    const nextBtn = (
        <Button
            id="actionNext"
            variant={ButtonVariant.Curved}
            extraClasses="primary slim"
            onClick={handleNextClick}
            testId="actionNext"
            disabled={isLoading}
        >
            <span className="btn-content">
                {t('general.nextStep')}
            </span>
        </Button>

    );

    /**
        * JSX for the "Go Back" button used in the existing bottom bar of the layout.
        *
        */
    const goBackBtn = (
        <Button
            id="actionBack"
            variant={ButtonVariant.Curved}
            extraClasses="secondary slim"
            onClick={() => navigate(AppRoute.BillingSelectOrganization)}
            testId="actionBack"
        >
            <span className="btn-content">
                {t('general.goBack')}
            </span>
        </Button>
    );

    return (
        <IsolateFlowLayout title={t('subscribeFlow.title')}>
            {isLoading && <LoadingCircles size="s" variant="primary" />}
            <div className="review-plan-screen step-content" data-testid="review-plan-screen">
                <HorizontalStepper steps={OnboardingSteps} activeStep={1} />
                <div className="step-content__screen">
                    <h2>{t('subscribeFlow.title')}</h2>
                    {billingInfo.planDetails && (
                        <PlansList availablePlans={plans} activePlan={billingInfo.planDetails} onCardClick={handleClickOnPlan} />
                    )}
                </div>
            </div>
            <footer className="footer" data-testid="footer">
                <div className="footer__btns-wrap">
                    {nextBtn}
                    {goBackBtn}
                </div>
            </footer>
        </IsolateFlowLayout>
    );
};

export const ReviewPlanScreen = withPaymentMethodContext(withTranslationContext(withBillingContext(ReviewPlanScreenBase)));

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FormEvent,
    FunctionComponent, useEffect, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AppRoute } from '../../../constants/routes';
import { validations } from '../../../constants/validations';
import { OnboardingFlowStep, WalletUpdatePayload } from '../../../types/billing';
import { ButtonVariant, KeyedObject } from '../../../types/general';
import {
    FormValidationError, FormValidatorError, validateForm,
} from '../../../utils/validations';
import { BillingContext, withBillingContext } from '../../controllers/BillingContext';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import { BillingInformationForm } from '../../elements/billing/BillingInformationForm';
import { CartSummary } from '../../elements/billing/CartSummary';
import Button from '../../elements/Button';
import { HorizontalStepper } from '../../elements/HorizontalStepper';
import { IsolateFlowLayout } from '../../elements/layouts/IsolateFlowLayout';
import { preparePageTitle } from '../../../utils/route';

type OwnProps = BillingContext & TranslationContext;

const BillingInformationScreenComponent: FunctionComponent<OwnProps> = (props) => {
    const {
        t,
        updateBillingInformation,
        billingInfo,
    } = props;

    const navigate = useNavigate();

    const [personErrorsForm, setPersonErrorsForm] = useState<FormValidationError | null>(null);
    const [fiscalErrorsForm, setFiscalErrorsForm] = useState<FormValidationError | null>(null);
    const [addressErrorsForm, setAddressErrorsForm] = useState<FormValidationError | null>(null);

    useEffect(() => {
        document.title = preparePageTitle(t('subscribeFlow.title'));
        if (!billingInfo.wallet || !billingInfo.planDetails) {
            navigate(AppRoute.BillingPlans);
        }
    }, []);

    const OnboardingSteps = [
        t(`subscribeFlow.stepper.${OnboardingFlowStep.ORGANIZATION}`),
        t(`subscribeFlow.stepper.${OnboardingFlowStep.PLAN_REVIEW}`),
        t(`subscribeFlow.stepper.${OnboardingFlowStep.ADD_CREDITS}`),
        t(`subscribeFlow.stepper.${OnboardingFlowStep.BILLING}`),
        t(`subscribeFlow.stepper.${OnboardingFlowStep.PAYMENT}`),
    ];

    const onFormSubmit = async (e: FormEvent) => {
        e.preventDefault();

        if (billingInfo.wallet === null) return;

        const formData = new FormData(e.target as HTMLFormElement);
        const contactablePersonPayload = {
            firstName: String(formData.get('firstName')),
            lastName: String(formData.get('lastName')),
            email: String(formData.get('email')),
            phoneNumber: String(formData.get('phoneNumber')),
        };
        const fiscalInfoPayload = {
            fiscalNumber: String(formData.get('fiscalNumber')),
            fiscalName: String(formData.get('fiscalName')),
        };
        const addressPayload = {
            addressLine1: String(formData.get('addressLine1')),
            addressLine2: String(formData.get('addressLine2')),
            postalCode: String(formData.get('postalCode')),
            city: String(formData.get('city')),
            state: String(formData.get('state')),
            country: String(formData.get('country')),
        };

        const errorsPerson: KeyedObject<FormValidatorError[]> | null = validateForm(contactablePersonPayload, validations.contactablePersonValidationsUpdate);
        const errorsFiscal: KeyedObject<FormValidatorError[]> | null = validateForm(fiscalInfoPayload, validations.fiscalInfoValidationsUpdate);
        const errorsAddress: KeyedObject<FormValidatorError[]> | null = validateForm(addressPayload, validations.addressValidationsUpdate);
        let hasErrors = false;

        if (errorsPerson && Object.keys(errorsPerson).length > 0) {
            setPersonErrorsForm({ fields: errorsPerson });
            hasErrors = true;
        }

        if (errorsFiscal && Object.keys(errorsFiscal).length > 0) {
            setFiscalErrorsForm({ fields: errorsFiscal });
            hasErrors = true;
        }

        if (errorsAddress && Object.keys(errorsAddress).length > 0) {
            setAddressErrorsForm({ fields: errorsAddress });
            hasErrors = true;
        }

        if (hasErrors) return;

        const payload: WalletUpdatePayload = {
            contactablePerson: contactablePersonPayload,
            fiscalInfo: fiscalInfoPayload,
            address: addressPayload,
        };

        const [, submittingError] = await updateBillingInformation(String(billingInfo.wallet?.id), payload);
        if (submittingError) {
            toast.error(submittingError.errors[0].getMessageTranslated(t));
            return;
        }
        navigate(AppRoute.PaymentCheckout);
    };
    /**
    * JSX for the "Next" button used in the existing bottom bar of the layout.
    *
    */
    const nextBtn = (
        <Button
            id="actionNext"
            isSubmit
            variant={ButtonVariant.Curved}
            extraClasses="primary slim"
            testId="actionNext"
        >
            <span className="btn-content">
                {t('general.nextStep')}
            </span>
        </Button>
    );

    /**
    * JSX for the "Go Back" button used in the existing bottom bar of the layout.
    *
    */
    const goBackBtn = (
        <Button
            id="actionBack"
            variant={ButtonVariant.Curved}
            extraClasses="secondary slim"
            onClick={() => navigate(AppRoute.BillingAddCredits)}
            testId="actionBack"
        >
            <span className="btn-content">
                {t('general.goBack')}
            </span>
        </Button>
    );

    return (
        <form
            className="form"
            onSubmit={onFormSubmit}
            data-testid="billing-information-form"
        >
            <IsolateFlowLayout
                title={t('subscribeFlow.title')}
            >
                <div className="billing-information-screen step-content" data-testid="billing-information-screen">
                    <HorizontalStepper steps={OnboardingSteps} activeStep={3} />
                    <div className="step-content__screen">
                        {billingInfo.wallet && (
                            <BillingInformationForm
                                title={t('subscribeFlow.billingStep.title')}
                                personErrorsForm={personErrorsForm}
                                fiscalErrorsForm={fiscalErrorsForm}
                                addressErrorsForm={addressErrorsForm}
                                wallet={billingInfo.wallet}
                            />
                        )}
                        <CartSummary />
                    </div>
                </div>
                <footer className="footer" data-testid="footer">
                    <div className="footer__btns-wrap">
                        {nextBtn}
                        {goBackBtn}
                    </div>
                </footer>
            </IsolateFlowLayout>
        </form>
    );
};

export const BillingInformationScreen = withTranslationContext(withBillingContext(BillingInformationScreenComponent));

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import React, { FunctionComponent, useRef, useState } from 'react';
import { CircularProgress } from '@mui/material';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { ContractsContext, withContractsContext } from '../../../controllers/ContractsContext';
import {
    TranslationContext,
    withTranslationContext,
} from '../../../controllers/TranslationContext';

import Button from '../../Button';
import { ButtonVariant } from '../../../../types/general';
import { ContractConfigOption } from '../../../../types/contracts';
import Modal from '../../Modal';
import { ReactComponent as SwapIcon } from '../../../../assets/images/swap.svg';
import { useContractContext } from '../ContractContextProvider';
import { Tooltip } from '../../Tooltip';

interface OwnProps extends TranslationContext, ContractsContext {
}

const SwapContractOptionComponent: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t,
    } = props;

    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isUploading, setIsUploading] = useState(false);

    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const { prepareReplaceContract, contract, prepareContractPdf } = useContractContext();
  
    const onSwapButtonClick = () => {
        setIsOpenModal(true);
    };

    /**
     * Start contract swap/replacement flow
     *
     * @remarks
     * First function of contract replacement flow
     */
    const handleContractSwap = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileReplaced = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            onReplaceContract(e.target.files[0]);
        }
    };

    const onReplaceContract = (fileToUpload: File) => {
        setIsUploading(true);
        prepareReplaceContract(fileToUpload, () => onSuccess(), onFailure);
    };

    const onSuccess = () => {
        setIsUploading(false);
        setIsOpenModal(false);
        prepareContractPdf(String(contract?.id));
    };

    const onFailure = (errorMessage: string) => {
        setIsUploading(false);
        toast.error(errorMessage);
    };

    return (
        <>
            <Button
                variant={ButtonVariant.IconBtn}
                data-step={ContractConfigOption.SwapContract}
                extraClasses={classNames('primary',
                    { active: isOpenModal })
                }
                onClick={onSwapButtonClick}
                testId="swap-btn"
            >
                <Tooltip title={t('contractCreate.swapConfirmTitle')} placement="left">
                    <SwapIcon className="stroked" />
                </Tooltip>
            </Button>
            <Modal
                open={isOpenModal}
                title={t('contractCreate.swapConfirmTitle')}
                handleClose={() => setIsOpenModal(false)}
            >
                <div className="content-wrap">
                    <p>{t('contractCreate.swapConfirmText')}</p>
                    <p className="warning-msg">{t('contractCreate.swapConfirmWarning')}</p>
                    {!isUploading && (
                        <Button
                            onClick={handleContractSwap}
                            variant={ButtonVariant.Curved}
                            extraClasses="primary"
                            testId="confirm-btn"
                        >
                            {t('modalConfirm.confirm')}
                        </Button>
                    )}
                    {isUploading
                        && <CircularProgress color="primary" />}
                    <input hidden type="file" onChange={handleFileReplaced} ref={fileInputRef} data-testid="upload-file" />
                </div>
            </Modal>
        </>
    );
};

const SwapContractOption = withTranslationContext(withContractsContext(SwapContractOptionComponent));
export { SwapContractOption };

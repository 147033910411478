/*
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import React, { FunctionComponent } from 'react';
import { useAppDispatch, useAppSelector } from '../../utils/storeHooks';

import { AppLanguage } from '../../types/preferences';
import { RootState } from '../../store';
import { TranslationContextProvider } from './TranslationContext';
import en from '../../locale/en.json';
import { getTranslation } from '../../utils/translation';
import {
    matchAvailableLanguagesOrDefault,
} from '../../services/language';
import pt from '../../locale/pt.json';
import { setLanguage } from '../../slicers/preferencesSlice';
import { useBrowserLanguageChanged } from '../../hooks/useBrowserLanguageChanged';

interface OwnProps {
    children: React.ReactNode;
}

type Props = OwnProps;

const TranslationController: FunctionComponent<Props> = (props: Props) => {
    const { children } = props;

    const translations = { en, pt };

    const language = useAppSelector((state: RootState) => state.preferences.language);
    const languages = useAppSelector((state: RootState) => state.preferences.languages);
    const dispatch = useAppDispatch();
    
    const handleBrowserLanguageChanges = (newLang: string) => {
        changeLanguageSelected(matchAvailableLanguagesOrDefault(newLang));
    };
    useBrowserLanguageChanged(handleBrowserLanguageChanges);

    const changeLanguageSelected = (newLanguage: AppLanguage) => {
        dispatch(setLanguage(newLanguage));
    };

    const parseToJSX = (text: string): React.ReactNode[] => {
        const node = [];
        if (text.includes('%b')) {
            const fragments = text.split('%b');

            for (let index = 0; index < fragments.length; index++) {
                const element = fragments[index];

                if (index % 2 === 1) {
                    node.push(React.createElement('strong', null, element));
                } else {
                    node.push(element);
                }
            }
        }

        return node.filter((n) => n !== '');
    };

    const tWithJSX = (key: string, params?: Record<string, unknown>): React.ReactNode => {
        const translationText = getTranslation(translations, language, key, params);

        return React.createElement('span', null, ...parseToJSX(translationText));
    };

    const t = (literal: string, params?: Record<string, string | number | undefined>): string => {
        return getTranslation(translations, language, literal, params);
    };

    return (
        <TranslationContextProvider
            value={{
                language,
                languages,
                t,
                tWithJSX,
                changeLanguageSelected,
            }}
        >
            {children}
        </TranslationContextProvider>
    );
};

export default TranslationController;

/*
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import React, { FunctionComponent } from 'react';
import { InviteStatus, Invite } from '../../types/members';
import { ReactComponent as Avatar } from '../../assets/images/avatar.svg';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import { MembersContext, withMembersContext } from '../controllers/MembersContext';

interface OwnProps extends TranslationContext, MembersContext {
    invite: Invite;
}

const MemberCollapse: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        invite,
        t,
    } = props;

    return (
        <div className="invite-item">
            <div className="invite-item__wrap">
                <div className="invite-item__wrap__profile">
                    <div className="invite-item__wrap__profile__avatar">
                        <Avatar />
                    </div>
                    <div className="invite-item__wrap__profile__info">
                        <div className={`invite-item__wrap__profile__info__status ${InviteStatus[invite.status].toLowerCase()}`}>
                            {t(`invite.${InviteStatus[invite.status]}`)}
                        </div>
                        <div className="invite-item__wrap__profile__info__email">
                            <p>{invite.email}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withTranslationContext(withMembersContext(MemberCollapse));

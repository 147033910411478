/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */
import React, { FunctionComponent } from 'react';
import { HelpAndSupportContext, HelpAndSupportContextProvider } from './HelpAndSupportContext';
import { useCornerstoneApi } from '../../api';
import { submitTicketUrl } from '../../services/help';
import { NewTicketResponse } from '../../types/help';
import { ErrorResponse } from '../../types/errors';

interface OwnProps {
    children: React.ReactNode;
}

type Props = OwnProps;

export const HelpAndSupportController: FunctionComponent<Props> = (props: Props) => {
    const {
        children,
    } = props;

    const CornerstoneApiInstance = useCornerstoneApi();

    const submitTicket: HelpAndSupportContext['submitTicket'] = async (payload) => {
        try {
            const { data } = await CornerstoneApiInstance.post<NewTicketResponse>(
                submitTicketUrl(),
                payload,
            );
            return [data, null];
        } catch (error) {
            return [null, error as ErrorResponse];
        }
    };

    return (
        <HelpAndSupportContextProvider value={{
            submitTicket,
        }}
        >
            {children}
        </HelpAndSupportContextProvider>
    );
};

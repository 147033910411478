/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { useEffect, useState } from 'react';
import { PUBLIC_EID_ENDPOINT } from '../settings';
import { VideoIDValidationChecks } from '../types/videoId';

type VideoIdHook = {
    tryVideoIdAgain: () => void;
    isVideoIdLoading: boolean;
    hasVideoIdFailed: boolean;
    videoIdValidationResult: VideoIDValidationChecks | null;
}

/**
 * Checks all requirements for a successful identity validation.
 * Calls onSuccess method if every requirement is met.
 */
export function useVideoIdRequirements(
    onSuccess: () => void,
): VideoIdHook {
    const [requirementsCheck, setRequirementsCheck] = useState<{
      validationResult: VideoIDValidationChecks | null;
      isLoading: boolean;
      hasFailed: boolean;
      error: Error | null;
      hasStarted: boolean;
      hasMinimumRequirements: boolean;
    }>({
        hasStarted: false,
        validationResult: null,
        error: null,
        hasFailed: false,
        isLoading: false,
        hasMinimumRequirements: false,
    });

    useEffect(() => {
        loadEidScript(startVerification);
    }, []);
  
    const handleOnSuccess = () => {
        onSuccess();
    };
  
    const tryAgain = () => {
        startVerification();
    };
  
    const startVerification = async () => {
        setRequirementsCheck({
            isLoading: true,
            validationResult: null,
            error: null,
            hasFailed: false,
            hasStarted: true,
            hasMinimumRequirements: false,
        });
        
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const requirements: Promise<any> = window.EID?.videoId?.checkRequirements();
        
        return requirements
            .then((checks: VideoIDValidationChecks) => {
                setRequirementsCheck((s) => ({
                    ...s,
                    isLoading: false,
                    validationResult: checks,
                    hasMinimumRequirements: checks.passed,
                }));
  
                if (checks.passed) {
                    handleOnSuccess();
                }
            })
            .catch((err: Error) => {
                // Unexpected errors
                setRequirementsCheck(() => ({
                    error: err,
                    hasFailed: true,
                    hasStarted: requirementsCheck.hasStarted,
                    isLoading: false,
                    validationResult: null,
                    hasMinimumRequirements: false,
                }));
            });
    };

    return {
        tryVideoIdAgain: tryAgain,
        hasVideoIdFailed: requirementsCheck.hasFailed,
        isVideoIdLoading: requirementsCheck.isLoading,
        videoIdValidationResult: requirementsCheck.validationResult,
    };
}

const loadEidScript = (callback: () => void) => {
    const existingScript = document.getElementById('eid-video-id');

    if (!existingScript) {
        const script = document.createElement('script');
        
        script.src = `${PUBLIC_EID_ENDPOINT}` ?? '';
        script.id = 'eid-video-id';
        document.body.appendChild(script);

        script.onload = () => {
            if (callback) callback();
        };
    }
    
    if (existingScript && callback) callback();
};

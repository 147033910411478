/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FormEventHandler, FunctionComponent, useEffect, useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import Button from '../../elements/Button';
import { ButtonVariant } from '../../../types/general';
import { FormField } from '../../elements/FormField';
import { AppRoute } from '../../../constants/routes';
import { HelpAndSupportContext, withHelpAndSupportContext } from '../../controllers/HelpAndSupportContext';
import { FormValidationError } from '../../../utils/validations';
import { preparePageTitle } from '../../../utils/route';

interface OwnProps extends TranslationContext, HelpAndSupportContext { }

type RouterState = {
    referrer?: string;
    returnTo?: string;
}

const NewSupportTicketScreenBase: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const { t, submitTicket } = props;
    const location = useLocation();
    const navigate = useNavigate();
    const referrer = (location.state as RouterState)?.referrer ?? location.pathname;
    const returnTo = (location.state as RouterState)?.returnTo ?? AppRoute.Index;
    const [fieldErrors, setFieldErrors] = useState<FormValidationError | null>();

    useEffect(() => {
        document.title = preparePageTitle(t('newSupportTicketScreen.title'));
    }, []);
    
    const onSubmit: FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        submitTicket({
            problem: formData.get('problem')?.toString() ?? '',
            contextUrl: referrer,
        }).then((res) => {
            const [, error] = res;

            if (error?.errors.length) {
                toast.error(error.errors?.[0]?.getMessageTranslated(t));
                return;
            }
            if (error?.fields) {
                setFieldErrors({
                    fields: error.fields,
                });
                return;
            }

            navigate(AppRoute.SupportTicketSubmitted, {
                state: location.state,
            });
        });
    };

    const onCancel = () => {
        navigate(returnTo);
    };

    return (
        <div className="new-support-ticket-screen">
            <h1>{t('newSupportTicketScreen.title')}</h1>
            <p>{t('newSupportTicketScreen.description')}</p>

            <form onSubmit={onSubmit}>
                <FormField name="problem" minLength={10} maxLength={1024} isTextArea rows={5} label={t('newSupportTicketScreen.problemLabel')} placeholder={t('newSupportTicketScreen.problemPlaceholder')} errors={fieldErrors} />
                
                <div className="new-support-ticket-screen__actions">
                    <Button onClick={onCancel} variant={ButtonVariant.Curved} extraClasses="secondary" title={t('newSupportTicketScreen.cancel')}>{t('newSupportTicketScreen.cancel')}</Button>
                    <Button variant={ButtonVariant.Curved} isSubmit extraClasses="primary" title={t('newSupportTicketScreen.cancel')}>{t('newSupportTicketScreen.submit')}</Button>
                </div>
            </form>
        </div>
    );
};

export const NewSupportTicketScreen = withHelpAndSupportContext(withTranslationContext(NewSupportTicketScreenBase));

/**
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

/* eslint-disable no-underscore-dangle */

import 'pdfjs-dist/web/pdf_viewer.css';

import { PDFPageProxy, renderTextLayer } from 'pdfjs-dist';
import React, {
    CSSProperties,
    FunctionComponent,
    useCallback,
    useEffect,
    useMemo,
    useRef,
} from 'react';
import { ElementPosition, isOfType } from '../../../types/general';
import { PendingPlaceholder, Placeholder } from '../../../types/contracts';

import { PdfPlaceholderAnnotation } from './PdfPlaceholderAnnotation';

interface OwnProps {
    page: PDFPageProxy | undefined;
    scale: number;
    style?: CSSProperties;
    placeholderList?: Placeholder[];
    pendingPlaceholder?: PendingPlaceholder;
    isPlaceholderDraggable?: boolean;
    onDragPlaceholder?: (position: ElementPosition, placeholder: Placeholder | PendingPlaceholder) => void;
    dragPlaceholderIntoNewPage?: (offsetAxisYOfParentElement: number, placeholder: Placeholder | PendingPlaceholder, newPosition: Partial<ElementPosition>) => void;
}

/**
 * Component that renders a PDF page
 */
const PdfPage: FunctionComponent<OwnProps> = (props) => {
    const {
        page,
        style,
        scale,
        dragPlaceholderIntoNewPage,
        onDragPlaceholder,
        pendingPlaceholder,
        placeholderList = [],
        isPlaceholderDraggable,
    } = props;

    const textLayerRef = useRef<HTMLDivElement | null>(null);
    const pdfPageRef = useRef<HTMLDivElement | null>(null);

    const viewport = useMemo(() => (page?.getViewport({ scale })), [page, scale]);

    useEffect(() => {
        renderPage();

        return () => {
            unMount();
        };
    }, [page, scale]);

    /**
     * Function that renders a PDF page
     *
     * @remarks
     *
     * This function will create a canvas element and render
     * a pdf page on it. If the pdf page contains any text, that is
     * extractable, it will render it too on top of the canvas so
     * that the user can interact with it
     */
    const renderPage = useCallback(() => {
        if (!pdfPageRef.current || !page || !viewport) return;

        // Create canvas element
        const canvas = document.createElement('canvas');

        // Get context from canvas element
        const context: CanvasRenderingContext2D | null = canvas.getContext('2d');
        if (!context) return;

        const { width, height } = viewport;

        // Prepare canvas using PDF page dimensions
        canvas.height = height;
        canvas.width = width;

        // Append canvas to our page div
        pdfPageRef.current.append(canvas);

        // Render PDF page into canvas context
        const renderTask = page.render({
            canvasContext: context,
            viewport,
        });

        renderTask.promise.then(() => {
            // Wait for rendering to finish
            return page.getTextContent();
        }).then((textContent) => {
            if (!textLayerRef.current) {
                return;
            }

            // Pass the text data to the method for rendering of text over the pdf canvas.
            renderTextLayer({
                textContentSource: textContent,
                container: textLayerRef.current,
                viewport,
                textDivs: [],
            });
        });
    }, [page]);

    /**
     * Function that removes any leftover canvas from the DOM
     */
    const unMount = () => {
        if (!pdfPageRef.current) return;

        const canvasElements = pdfPageRef.current.getElementsByTagName('canvas');

        if (canvasElements.length === 0) return;

        // Remove any existing canvas from the DOM
        for (let i = 0; i < canvasElements.length; i++) {
            pdfPageRef.current.removeChild(canvasElements[i]);
        }
    };

    return (
        <div
            className="page"
            ref={pdfPageRef}
            style={{ ...style, left: 'unset', width: viewport?.width }}
            data-testid={`pdf-page-${page?._pageIndex}`}
        >
            <div ref={textLayerRef} className="textLayer" />
            {placeholderList.length > 0 && placeholderList.map((placeholder) => (
                <PdfPlaceholderAnnotation
                    placeholder={placeholder}
                    scale={scale}
                    onDragPlaceholder={onDragPlaceholder}
                    onDragIntoNewPage={dragPlaceholderIntoNewPage}
                    isDraggable={isPlaceholderDraggable && !pendingPlaceholder}
                    disabled={!!pendingPlaceholder}
                    key={`${placeholder.signer.id}-${isOfType<Placeholder>(placeholder, 'id') ? placeholder.id : '_'}`}
                />
            ))}
            {pendingPlaceholder && (
                <PdfPlaceholderAnnotation
                    placeholder={pendingPlaceholder}
                    scale={scale}
                    isDraggable={isPlaceholderDraggable}
                    onDragPlaceholder={onDragPlaceholder}
                    onDragIntoNewPage={dragPlaceholderIntoNewPage}
                />
            )}
        </div>
    );
};

export default React.memo(PdfPage);

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent } from 'react';
import { Slider, Stack } from '@mui/material';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import Button from '../Button';
import { ReactComponent as ScaleBackIcon } from '../../../assets/images/scale-back.svg';
import { ReactComponent as PlusIcon } from '../../../assets/images/plus-icon.svg';
import { ReactComponent as MinusIcon } from '../../../assets/images/minus-icon.svg';
import { ReactComponent as ArrowLeftIcon } from '../../../assets/images/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from '../../../assets/images/arrow-right.svg';
import { ButtonVariant } from '../../../types/general';

interface OwnProps extends TranslationContext {
    currentPage: number;
    totalPages: number;
    scale: number;
    minScale: number;
    onPageChange: (pageNumber: number) => void;
    onScaleChange: (newScale: number) => void;
}

const PdfNavigationBar: FunctionComponent<OwnProps> = (props) => {
    const {
        scale,
        minScale,
        currentPage,
        totalPages,
        onPageChange,
        onScaleChange,
        t,
    } = props;

    const renderZoomSlider = () => {
        return (
            <Stack direction="row" spacing={1}>
                <MinusIcon />
                <div className="slider-container">
                    <Slider
                        aria-label="zoom"
                        min={Number((Math.round(minScale * 100) / 100).toFixed(2))}
                        max={3}
                        step={0.05}
                        value={scale}
                        onChange={(_, value) => onScaleChange(Number(value) || minScale)}
                    />
                </div>
                <PlusIcon />
            </Stack>
        );
    };

    return (
        <div className="pdf-navigation">
            <Button
                variant={ButtonVariant.IconBtn}
                extraClasses="primary"
                onClick={() => onScaleChange(minScale)}
                disabled={scale === minScale}
                testId="scale-back-button"
            >
                <ScaleBackIcon />
            </Button>
            {renderZoomSlider()}
            <Button
                variant={ButtonVariant.IconBtn}
                extraClasses="primary"
                onClick={() => onPageChange(currentPage - 1)}
                disabled={currentPage === 1}
            >
                <ArrowLeftIcon className="arrow" />
            </Button>
            <p>{t('pdfNavigation.pages')} {currentPage}/{totalPages}</p>
            <Button
                variant={ButtonVariant.IconBtn}
                extraClasses="primary"
                onClick={() => onPageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
            >
                <ArrowRightIcon className="arrow" />
            </Button>
        </div>
    );
};

export default withTranslationContext(PdfNavigationBar);

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { ComissionType } from '../types/workflows';

/**
 * Functions that calculates commission
 *
 * @param commissionType
 * @param commissionValue
 * @param totalPrice
 * @returns
 */
export const calculateCommission = (commissionType: ComissionType, commissionValue: number = 0, totalPrice: number = 0): number => {
    if (commissionValue < 0) {
        // eslint-disable-next-line no-console
        console.warn('Commission percentage must be larger than 0');
        return 0;
    }
    
    switch (commissionType) {
        case ComissionType.FIXED:
            return commissionValue;
        case ComissionType.PERCENTAGE:
            // round to 2 decimal places
            return Math.round(((totalPrice * commissionValue / 100) + Number.EPSILON) * 100) / 100;
        case ComissionType.RENT:
            return Math.round(((totalPrice * commissionValue / 100) + Number.EPSILON) * 100);
        case ComissionType.NONE:
        default:
            return 0;
    }
};

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

export const addMonthsToDate = (months: number): Date => {
    const date = new Date();
    date.setMonth(date.getMonth() + months);

    date.setUTCHours(23, 59, 59, 0);

    return date;
};

export const formatToLocalDate = (date?: string, locale?: string) => {
    if (!date) return '';
    const event = new Date(date);

    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    };
    
    const formattedDate = event.toLocaleDateString(locale ?? navigator.language, options);
  
    return formattedDate;
};

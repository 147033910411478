/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FormEvent, FunctionComponent, useEffect, useState,
} from 'react';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import Modal from '../Modal';
import { FormField } from '../FormField';
import { FormSelectField } from '../FormSelectField';
import Button from '../Button';
import { ButtonVariant } from '../../../types/general';
import { ParticipantPayload, WorkflowParticipantRole } from '../../../types/workflows';

interface OwnProps extends TranslationContext {
    open: boolean;
    onModalClose: () => void;
    onSubmit: (p: Partial<ParticipantPayload>) => void;
}

const AddParticipantModalComponent: FunctionComponent<OwnProps> = (props) => {
    const {
        t,
        open,
        onModalClose,
        onSubmit,
    } = props;

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState<WorkflowParticipantRole>(WorkflowParticipantRole.ADMIN);

    useEffect(() => {
        if (!open) {
            setEmail('');
            setName('');
            setRole(WorkflowParticipantRole.ADMIN);
        }
    }, [open]);

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        onSubmit({
            name,
            email,
            participantRole: role,
        });
    };

    const roleOptions = () => {
        return Object.keys(WorkflowParticipantRole).filter((r) => r !== WorkflowParticipantRole.TRANSACTION_OWNER).map((r) => {
            return {
                value: r,
                label: t(`participantRole.${r}`),
            };
        });
    };

    return (
        <Modal
            open={open}
            title={t('workflows.participants.addParticipant')}
            handleClose={onModalClose}
        >
            <form
                className="form"
                onSubmit={handleSubmit}
            >
                <FormField
                    name="name"
                    maxLength={250}
                    label={t('workflows.participants.labelName')}
                    value={name}
                    onChange={(_, value) => setName(value)}
                />
                <FormField
                    name="email"
                    label={t('workflows.participants.labelEmail')}
                    value={email}
                    onChange={(_, value) => setEmail(value)}
                />
                <FormSelectField
                    name="role"
                    label={t('workflows.participants.labelRole')}
                    options={roleOptions()}
                    onChange={(_, value) => setRole(value as WorkflowParticipantRole)}
                />
                <Button
                    isSubmit
                    variant={ButtonVariant.Curved}
                    extraClasses="primary"
                    testId="submit-btn"
                    disabled={!name || !role || !email}
                >
                    {t('workflows.participants.addParticipant')}
                </Button>
            </form>
        </Modal>
    );
};

export const AddParticipantModal = withTranslationContext(AddParticipantModalComponent);

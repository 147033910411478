/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FunctionComponent,
    useEffect,
    useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { useContractContext } from '../../elements/contracts/ContractContextProvider';
import { ErrorResponse } from '../../../types/errors';
import { UploadContractScreen } from './UploadContractScreen';
import { preparePageTitle } from '../../../utils/route';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';

interface OwnProps extends TranslationContext {}

const CreateWorkflowContractScreenBase: FunctionComponent<OwnProps> = ({ t }) => {
    const [uploadError, setUploadError] = useState<null | ErrorResponse>(null);

    useEffect(() => {
        document.title = preparePageTitle(t('contractCreate.title'));
        if (uploadError) throw uploadError;
    }, [uploadError]);

    const { workflowId = '' } = useParams();
    const { uploadWorkflowContract, navigateToDraftContractPage } = useContractContext();
    const [isSubmitting, setIsSubmitting] = useState(false);
    
    /**
     * Callback for File Selection
     *
     * @remarks
     * Update file and contractFields name states
     */
    const handleFileSelected = async (documentFile: File | undefined, selectedContractTypeId: number) => {
        if (!documentFile) return;

        setIsSubmitting(true);

        const [contract, contractError] = await uploadWorkflowContract(workflowId, documentFile, selectedContractTypeId);

        if (contract) {
            setIsSubmitting(false);
            navigateToDraftContractPage(String(contract.id));
            return;
        }

        if (contractError) {
            setUploadError(contractError);
        }
    };
    
    return (
        <UploadContractScreen
            isSubmitting={isSubmitting}
            onFileSelected={handleFileSelected}
        />
    );
};

export const CreateWorkflowContractScreen = withTranslationContext(CreateWorkflowContractScreenBase);

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import availableCurrency from '../assets/data/available-currencies.json';

export type AvailableCurrencyType = keyof typeof availableCurrency;

export const formatNumberToLocale = (value: number, locale: string = 'en', currencyType?: AvailableCurrencyType | string): string => {
    const options = currencyType ? { style: 'currency', currency: currencyType } : {};

    const formattedValue = new Intl.NumberFormat(locale ?? 'en', options).format(
        value,
    ).replace(/\s+/g, ' ');

    return formattedValue;
};

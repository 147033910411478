/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    ParticipantPermissionsList, ResourcePermission, ResourcePermissionsType, ResourceType,
} from '../types/workflows';

/**
 * Verify if participant is allowed to access resource
 */
export const verifyParticipantPermission = (
    participantPermissions: ParticipantPermissionsList,
    allowedPermissions: ResourcePermissionsType[],
    resourceType: ResourceType,
    resourceId?: number,
) => {
    const {
        transactionPermissions,
        contracts: contractsPermissions,
        documents: documentsPermissions,
    } = participantPermissions;

    switch (resourceType) {
        case ResourceType.CONTRACT:
            if (resourceId === undefined) return false;
            return verifyParticipantAssetPermission(allowedPermissions, resourceId, contractsPermissions);
        case ResourceType.DOCUMENT:
            if (resourceId === undefined) return false;
            return verifyParticipantAssetPermission(allowedPermissions, resourceId, documentsPermissions);
        case ResourceType.TRANSACTION:
            if (transactionPermissions.some((ap) => allowedPermissions.includes(ap))) {
                return true;
            }
        
            return false;
        default:
            break;
    }

    return false;
};

/**
 * Verify if participant can access Contract or Document
 */
export const verifyParticipantAssetPermission = (
    allowedPermissions: ResourcePermissionsType[],
    assetId: number,
    assetsPermissionsForParticipant: ResourcePermission[],
) => {
    const assetPermissions = assetsPermissionsForParticipant.find((el) => el.id === assetId);

    if (!assetPermissions) return false;

    if (assetPermissions.permissions.some((ap) => allowedPermissions.includes(ap))) {
        return true;
    }

    return false;
};

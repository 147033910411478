/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent, useEffect, useState } from 'react';
import { WorkflowStateEnum, WorkflowType, WorkflowsFilterParams } from '../../../types/workflows';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import { FormField } from '../FormField';
import { DatePicker } from '../DatePicker';
import { RadioSelect } from '../RadioSelect';
import Button from '../Button';
import { ButtonVariant, OrderOptions } from '../../../types/general';
import { FormSelectField } from '../FormSelectField';

type OwnProps = TranslationContext & {
    currentFilters: WorkflowsFilterParams;
    onSubmit: (filters: WorkflowsFilterParams) => void;
    onClean: () => void;
};

const FiltersFormBase: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {
        t,
        currentFilters,
        onSubmit,
        onClean,
    } = props;
    const [filters, setFilters] = useState<WorkflowsFilterParams>(currentFilters);

    const transactionTypeOptions = [
        { value: WorkflowType.SELL, label: t('workflows.list.filters.transactionTypeOptions.SELL') },
        { value: WorkflowType.RENTAL, label: t('workflows.list.filters.transactionTypeOptions.RENTAL') },
    ];

    const sellStatusOptions = [
        { value: WorkflowStateEnum.SELL_RAISING, label: t('workflows.view.SELL.steps.SELL_RAISING') },
        { value: WorkflowStateEnum.SELL_PENDING_APPROVAL, label: t('workflows.view.SELL.steps.SELL_PENDING_APPROVAL') },
        { value: WorkflowStateEnum.SELL_NEGOTIATION, label: t('workflows.view.SELL.steps.SELL_NEGOTIATION') },
        { value: WorkflowStateEnum.SELL_BUYING_PROMISSORY_AGREEMENT, label: t('workflows.view.SELL.steps.SELL_BUYING_PROMISSORY_AGREEMENT') },
        { value: WorkflowStateEnum.SELL_DEED, label: t('workflows.view.SELL.steps.SELL_DEED') },
        { value: WorkflowStateEnum.SELL_CANCELED, label: t('workflows.view.SELL.steps.SELL_CANCELED') },
        { value: WorkflowStateEnum.SELL_CLOSED, label: t('workflows.view.SELL.steps.SELL_CLOSED') },
    ];
    const rentalStatusOptions = [
        { value: WorkflowStateEnum.RENTAL_RAISING, label: t('workflows.view.RENTAL.steps.RENTAL_RAISING') },
        { value: WorkflowStateEnum.RENTAL_PENDING_APPROVAL, label: t('workflows.view.RENTAL.steps.RENTAL_PENDING_APPROVAL') },
        { value: WorkflowStateEnum.RENTAL_LISTED, label: t('workflows.view.RENTAL.steps.RENTAL_LISTED') },
        { value: WorkflowStateEnum.RENTAL_NEGOTIATION, label: t('workflows.view.RENTAL.steps.RENTAL_NEGOTIATION') },
        { value: WorkflowStateEnum.RENTAL_PROPOSAL_APPROVED, label: t('workflows.view.RENTAL.steps.RENTAL_PROPOSAL_APPROVED') },
        { value: WorkflowStateEnum.RENTAL_CANCELED, label: t('workflows.view.RENTAL.steps.RENTAL_CANCELED') },
        { value: WorkflowStateEnum.RENTAL_RENTED, label: t('workflows.view.RENTAL.steps.RENTAL_RENTED') },
    ];

    const orderOptions = [
        { value: OrderOptions.ASCENDING, label: t('workflows.list.filters.orderAscending') },
        { value: OrderOptions.DESCENDING, label: t('workflows.list.filters.orderDescending') },
    ];

    useEffect(() => {
        setFilters(currentFilters);
    }, [currentFilters]);

    /**
     * Update the filters field change
     *
     * @remarks
     * Verify the field changed and update in filters state
     */
    const onFieldsChange = (name: string, value: string | number | unknown) => {
        setFilters((prevFields) => ({
            ...prevFields,
            [name]: value,
        }));
    };

    /**
     * Update the filters workflowType change
     *
     * @remarks
     * Verify the field changed and update in filters state
     */
    const onWorkflowTypeChange = (name: string, value: string | number | unknown) => {
        setFilters((prevFields) => ({
            ...prevFields,
            [name]: value,
            transactionStatus: undefined,
        }));
    };

    /**
    * Handle Date Change Function
    *
    * @remarks
    * Handle Date Picker component date value change
    */
    const handleDateChange = (date: Date | null, field: string) => {
        if (!date || isNaN(date.getTime())) return;
        setFilters({
            ...filters,
            [field]: date.toISOString(),
        });
    };

    return (
        <form className="filters-form" data-testid="filters-form">
            <div className="filters-form__filters">
                <div className="filters-form__filters__order" data-testid="form-order">
                    <FormSelectField
                        name="_sort"
                        label={t('workflows.list.filters.order')}
                        options={orderOptions}
                        value={filters._sort}
                        onChange={onFieldsChange}
                    />
                    <div className="separator" />
                </div>
                <FormField
                    name="externalId"
                    label={t('workflows.list.filters.externalId')}
                    placeholder={t('workflows.list.filters.externalIdPlaceholder')}
                    value={filters.externalId}
                    onChange={onFieldsChange}
                    extraClasses="bg-gray-filled filter-externalId"
                />
                <div className="separator" />
                <RadioSelect
                    name="transactionType"
                    label={t('workflows.list.filters.transactionType')}
                    value={filters.transactionType}
                    options={transactionTypeOptions}
                    onChange={onWorkflowTypeChange}
                />
                {filters.transactionType && (
                    <>
                        <div className="separator" />
                        <RadioSelect
                            name="transactionStatus"
                            label={t('workflows.list.filters.transactionStatus')}
                            value={filters.transactionStatus}
                            options={filters.transactionType === WorkflowType.RENTAL ? rentalStatusOptions : sellStatusOptions}
                            onChange={onFieldsChange}
                            isCollapsible
                        />
                        <div className="separator" />
                    </>
                )}
                <DatePicker
                    name="from"
                    label={t('workflows.list.filters.from')}
                    format="DD/MM/YYYY"
                    value={filters.from ? String(filters.from) : undefined}
                    onChange={handleDateChange}
                    extraClasses="bg-gray-filled full-width workflow-from"
                />
                <DatePicker
                    name="to"
                    label={t('workflows.list.filters.to')}
                    format="DD/MM/YYYY"
                    value={filters.to ? String(filters.to) : undefined}
                    onChange={handleDateChange}
                    extraClasses="bg-gray-filled full-width workflow-to"
                />
                <div className="separator" />
                <FormField
                    name="email"
                    label={t('workflows.list.filters.email')}
                    placeholder={t('workflows.list.filters.emailPlaceholder')}
                    value={filters.email}
                    onChange={onFieldsChange}
                    extraClasses="bg-gray-filled filter-email"
                />
            </div>
            <div className="filters-form__buttons">
                <Button
                    variant={ButtonVariant.Curved}
                    extraClasses="secondary clean-btn"
                    onClick={onClean}
                    testId="clean-btn"
                >
                    {t('workflows.list.filters.cleanAll')}
                </Button>
                <Button
                    variant={ButtonVariant.Curved}
                    onClick={() => onSubmit(filters)}
                    testId="submit-btn"
                >
                    {t('general.applyFilters')}
                </Button>
            </div>
        </form>
    );
};

export const FiltersForm = withTranslationContext(FiltersFormBase);

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

/**
 * AES Signature Request Signature URL
 *
 * @returns { string } url
 */
export const requestSignatureUrl = (signerId: string, otpType: string) => {
    return `/signature/aes/${signerId}/request/${otpType}`;
};

/**
 * AES validate Signature URL
 *
 * @returns { string } url
 */
export const validateAesUrl = (signerId: string, otpType: string) => {
    return `/signature/aes/${signerId}/validate/${otpType}`;
};

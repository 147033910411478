/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FunctionComponent, ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { WS } from '../../../utils/websocket';
import { withWebsocketContext, WebsocketContext as WebsocketHocContext } from '../../controllers/WebsocketContext';
import { ErrorResponse } from '../../../types/errors';
import { websocketSignatureUrl } from '../../../services/websocket';

export interface WebsocketContextProviderInterface {
    isWsConnected: boolean;
    isWsConnecting: boolean;
    wsError: ErrorResponse | null;
    initConnection: (signerId: string) => void;
    addHandler: typeof WS.addHandler;
    removeHandler: typeof WS.removeHandler;
    sendMessage: (messageType: unknown, payload?: unknown) => void;
    close: typeof WS.close;
}

export const websocketContextDefaultValue: WebsocketContextProviderInterface = {
    isWsConnected: false,
    isWsConnecting: false,
    wsError: null,
    initConnection: () => { },
    addHandler: () => { },
    removeHandler: () => { },
    sendMessage: () => { },
    close: () => { },
};

export const WebsocketContext = createContext(websocketContextDefaultValue);

type OwnProps = WebsocketHocContext & {
    children: ReactNode;
}

const WebsocketContextProviderComponent: FunctionComponent<OwnProps> = (props) => {
    const { children, getWebsocketToken } = props;

    const [isWsConnected, setIsWsConnected] = useState<boolean>(websocketContextDefaultValue.isWsConnected);
    const [isWsConnecting, setIsWsConnecting] = useState<boolean>(websocketContextDefaultValue.isWsConnecting);
    const [wsError, setWsError] = useState<ErrorResponse | null>(websocketContextDefaultValue.wsError);

    useEffect(() => {
        WS.addHandler('open', connectionOpened);
        WS.addHandler('close', connectionClosed);

        return () => {
            WS.removeHandler('open', connectionOpened);
            WS.removeHandler('close', connectionClosed);
            WS.close();
        };
    }, []);

    const connectionOpened = useCallback(() => {
        setIsWsConnected(true);
        setIsWsConnecting(false);
    }, []);

    const connectionClosed = useCallback(() => {
        setIsWsConnected(false);
        setIsWsConnecting(false);
    }, []);

    const initConnection: WebsocketContextProviderInterface['initConnection'] = async (signerId: string) => {
        setIsWsConnecting(true);
        setIsWsConnected(false);
        setWsError(null);

        const websocketTokenResponse = await getWebsocketToken();

        if (websocketTokenResponse[1]) {
            setWsError(websocketTokenResponse[1]);
            setIsWsConnecting(false);
            return;
        }

        const websocketToken = websocketTokenResponse[0].webSocketToken;

        WS.initConnection('type', { websocketURL: websocketSignatureUrl(websocketToken, signerId) });
    };

    const addHandler: WebsocketContextProviderInterface['addHandler'] = (key, fn) => {
        WS.addHandler(key, fn);
    };

    const removeHandler: WebsocketContextProviderInterface['removeHandler'] = (key, fn) => {
        WS.removeHandler(key, fn);
    };

    const close: WebsocketContextProviderInterface['close'] = () => {
        WS.close();
    };

    const sendMessage: WebsocketContextProviderInterface['sendMessage'] = (type: unknown, payload?: unknown) => {
        const data = { type, payload };

        WS.sendMessage(data);
    };

    const providerValue = useMemo(() => ({
        isWsConnected,
        isWsConnecting,
        wsError,
        initConnection,
        addHandler,
        removeHandler,
        sendMessage,
        close,
    }), [
        isWsConnected,
        isWsConnecting,
        wsError,
    ]);

    return (
        <WebsocketContext.Provider
            value={providerValue}
        >
            {children}
        </WebsocketContext.Provider>
    );
};

export const WebsocketContextProvider = withWebsocketContext(WebsocketContextProviderComponent);

export const useWebsocketContext = () => {
    return useContext(WebsocketContext);
};

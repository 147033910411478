/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    ChangeEvent, FunctionComponent,
    useRef,
    useState,
} from 'react';
import Button from './Button';
import { ReactComponent as DocumentIcon } from '../../assets/images/document-icon.svg';
import { ReactComponent as UploadIcon } from '../../assets/images/upload-icon.svg';
import { TranslationContext, withTranslationContext } from '../controllers/TranslationContext';
import { WorkflowContext, withWorkflowContext } from '../controllers/WorkflowContext';
import { CardObject } from '../../types/general';
import { Tooltip } from './Tooltip';

interface OwnProps extends TranslationContext, WorkflowContext {
    document: CardObject;
    onUploadFileCallback: (file: File | undefined, id?: string | number) => Promise<void>;
}

const UploadDocumentCardComponent: FunctionComponent<OwnProps> = (props) => {
    const {
        document,
        t,
        onUploadFileCallback,
    } = props;

    const [isUploading, setIsUploading] = useState(false);

    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const handleFileSelected = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const uploadedFile = e.target.files[0];

            setIsUploading(true);

            onUploadFileCallback(uploadedFile, document?.id).finally(() => {
                setIsUploading(false);
            });
        }
    };

    const handleCardClick = () => {
        if (isUploading) return;
        
        fileInputRef.current?.click();
    };

    return (
        <div className="upload-document-card" data-testid={`upload-document-card-${document.id}`} onClick={handleCardClick}>
            <div className="upload-document-card__info">
                <span className="upload-document-card__info__icon-wrap">
                    {document.icon ?? <DocumentIcon />}
                </span>
                <div className="upload-document-card__info__details">
                    <h1 title={document.title}>{document.title}</h1>
                    {isUploading
                        ? (
                            <span className="upload-document-card__info__details__loading" data-testid="uploading-loader" />
                        )
                        : (
                            <p className="upload-document-card__info__details__status">
                                {document.description || t('general.waitingUpload')}
                            </p>
                        )}
                    
                </div>
            </div>
            <Button>
                <Tooltip title={t('workflows.documents.uploadIconTooltip')} placement="bottom">
                    <UploadIcon />
                </Tooltip>
            </Button>
            <input ref={fileInputRef} type="file" onChange={handleFileSelected} data-testid="file-input" />
        </div>
    );
};

export const UploadDocumentCard = withWorkflowContext(withTranslationContext(UploadDocumentCardComponent));

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { useEffect } from 'react';

/**
 * Detects when the user changes preferred browser language
 *
 * @param onChange
 */
export const useBrowserLanguageChanged = (onChange: (newLang: string) => void) => {
    useEffect(() => {
        const handleNewLanguage = () => {
            if (navigator.language) {
                onChange(navigator.language);
            }
        };
        window.addEventListener('languagechange', () => handleNewLanguage());
        
        return () => {
            window.removeEventListener('languagechange', () => handleNewLanguage());
        };
    }, []);
};

/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import { FunctionComponent, ReactNode } from 'react';
import { ParticipantPermissionsList, ResourcePermissionsType, ResourceType } from '../../../types/workflows';
import { verifyParticipantPermission } from '../../../utils/participantPermission';

interface OwnProps {
    participantPermissions?: ParticipantPermissionsList;
    allowedPermissions: ResourcePermissionsType[];
    resourceType: ResourceType;
    resourceId?: number;
    children: ReactNode;
}

const ParticipantPermissionGuard: FunctionComponent<OwnProps> = (props) => {
    const {
        participantPermissions,
        allowedPermissions,
        resourceType,
        resourceId,
        children,
    } = props;

    if (!participantPermissions) return null;

    if (verifyParticipantPermission(participantPermissions, allowedPermissions, resourceType, resourceId)) {
        return <>{children}</>;
    }

    return null;
};

export { ParticipantPermissionGuard };

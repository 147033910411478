/**
 *
 * @Copyright 2023 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import React, {
    FunctionComponent,
    useCallback, useEffect, useState,
} from 'react';

import { PDFDocumentProxy, getDocument } from 'pdfjs-dist';
import * as PDFJS from 'pdfjs-dist/build/pdf';
import * as PDFJSWorker from 'pdfjs-dist/build/pdf.worker';
import { VariableSizeList } from 'react-window';
import PdfViewer from './PdfViewer';
import { PendingPlaceholder, Placeholder } from '../../../types/contracts';

PDFJS.GlobalWorkerOptions.workerSrc = PDFJSWorker;

interface OwnProps {
    fileUrl: string;
    pdfPagesListRef: React.MutableRefObject<VariableSizeList | undefined>;
    placeholderList?: Placeholder[];
    pendingPlaceholder?: PendingPlaceholder;
    updatePlaceholderList?: (newPlaceholder: Placeholder) => void;
    updatePendingPlaceholder?: (newPendingPlaceholder: PendingPlaceholder) => void;
    updateCurrentPage?: (newPage: number) => void;
    isPlaceholderDraggable?: boolean;
}

export interface PdfViewerRef {
    currentPage: number;
}

/**
 * Component that reads and renders pdf file
 */
const PdfViewerWrapper: FunctionComponent<OwnProps> = (props) => {
    const {
        fileUrl,
        pdfPagesListRef,
        placeholderList = [],
        pendingPlaceholder,
        updatePlaceholderList = () => {},
        updatePendingPlaceholder = () => {},
        updateCurrentPage,
        isPlaceholderDraggable,
    } = props;

    const [totalPages, setTotalPages] = useState(0);
    const [pdfDoc, setPdfDoc] = useState<PDFDocumentProxy | null>(null);
    const [currentPage, setCurrentPage] = useState(0);
    
    useEffect(() => {
        if (updateCurrentPage) updateCurrentPage(currentPage);
    }, [currentPage]);

    useEffect(() => {
        if (!fileUrl) return;

        // Load PDF file
        const loadingTask = getDocument(fileUrl);
        loadingTask.promise.then((pdf) => {
            setPdfDoc(pdf);

            // eslint-disable-next-line no-underscore-dangle
            setTotalPages(pdf._pdfInfo.numPages);
        }, () => {
            // PDF loading error
            // Improvements: show some error (catchable) when uploading non-PDF files
        });
    }, [fileUrl]);

    /**
     * Functions that fetches single plages from document
     *
     * @returns { PDFPageProxy | undefined } pdfPage
     */
    const handleGetPdfPage = useCallback((index: number) => {
        return pdfDoc?.getPage(index + 1);
    }, [pdfDoc]);

    return (
        <div className="pdf-wrapper" data-testid="pdf-wrapper">
            <PdfViewer
                pdfPagesListRef={pdfPagesListRef}
                totalPages={totalPages}
                getPdfPage={handleGetPdfPage}
                placeholderList={placeholderList}
                pendingPlaceholder={pendingPlaceholder}
                updatePlaceholderList={updatePlaceholderList}
                updatePendingPlaceholder={updatePendingPlaceholder}
                currentPage={currentPage}
                updateCurrentPage={setCurrentPage}
                isPlaceholderDraggable={isPlaceholderDraggable}
            />
        </div>
    );
};

export default PdfViewerWrapper;

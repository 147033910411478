/**
 *
 * @Copyright 2024 UNLOCKIT DECENTRALIZATION, LDA
 * Development by VOID Software, SA
 *
 */

import {
    FunctionComponent, useEffect, useMemo, useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TranslationContext, withTranslationContext } from '../../controllers/TranslationContext';
import { Button } from '../../elements/Button';
import { ReactComponent as PlusIcon } from '../../../assets/images/plus-icon.svg';
import { ButtonVariant } from '../../../types/general';
import ContractCard from '../../elements/contracts/ContractCard';
import { LoadingCircles } from '../../elements/LoadingCircles';
import { WorkflowContext, withWorkflowContext } from '../../controllers/WorkflowContext';
import { Contract } from '../../../types/contracts';
import { DefaultLayout } from '../../elements/layouts/DefaultLayout';
import { ReactComponent as BackIcon } from '../../../assets/images/chevron-left.svg';
import { buildUrl } from '../../../utils/navigation';
import { AppRoute } from '../../../constants/routes';
import { useUserHasPermission } from '../../../hooks/useUserHasPermission';
import { Permissions } from '../../../types/permissions';
import { ParticipantPermissionsList, ResourcePermissionsType } from '../../../types/workflows';

type BaseProps = TranslationContext & WorkflowContext;

const WorkflowContractsScreenBase: FunctionComponent<BaseProps> = (props: BaseProps) => {
    const navigate = useNavigate();

    const {
        t,
        getWorkflowContracts,
        getWorkflowParticipantPermissions,
    } = props;
    const { workflowId = '' } = useParams();

    const [contracts, setContracts] = useState<Contract[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [contractsCount, setContractsCount] = useState(0);
    const [participantPermissions, setParticipantPermissions] = useState<ParticipantPermissionsList | null>(null);

    const canManage = useUserHasPermission([Permissions.MANAGE_ORGANIZATION_TRANSACTIONS, Permissions.MANAGE_ALL_ORGANIZATION_TRANSACTIONS]);

    const participantCanManage = useMemo(() => {
        if (canManage) return true;

        if (participantPermissions?.transactionPermissions.some((e) => e === ResourcePermissionsType.MANAGE)) {
            return true;
        }

        return false;
    }, [canManage, participantPermissions]);

    useEffect(() => {
        fetchContractsList();
    }, []);

    /**
     * Fetch Contracts
     *
     * @remarks
     * Fetch contracts to render on contracts list
     * This request response does not come paginated.
     * It return all the contracts for the workflow.
     */
    const fetchContractsList = async () => {
        const [contractsData] = await getWorkflowContracts(workflowId);
        if (contractsData) {
            setContractsCount(contractsData.length);
            setContracts(contractsData);
        }

        getWorkflowParticipantPermissions(workflowId).then((permissionsResponse) => {
            const [permissions] = permissionsResponse;

            if (!permissions) {
                return;
            }

            setParticipantPermissions(permissions);
        });
        setIsLoading(false);
    };

    return (
        <DefaultLayout>
            <div className="workflow-contracts-screen">
                <div className="workflow-contracts-screen__header">
                    <div className="workflow-contracts-screen__header__back-and-title">
                        <Button
                            extraClasses="circular-ghost-btn"
                            onClick={() => navigate(buildUrl(AppRoute.ViewWorkflow, { workflowId }))}
                            testId="back-btn"
                        >
                            <BackIcon />
                        </Button>
                        <h1 className="workflow-contracts-screen__back-and-title__title">
                            {t('workflows.view.contracts')}
                            <span className="count" data-testid="contracts-count">
                                ({contractsCount})
                            </span>
                        </h1>
                    </div>
                    {participantCanManage && (
                        <Button
                            variant={ButtonVariant.Circle}
                            extraClasses="primary"
                            testId="add-contract-btn"
                            onClick={() => navigate(buildUrl(AppRoute.CreateWorkflowContract, { workflowId }))}
                        >
                            <PlusIcon />
                        </Button>
                    )}
                </div>
                {isLoading && <LoadingCircles size="s" variant="primary" />}
                {!isLoading && contracts.length === 0 && (<p>{t('workflows.view.noContractsFound')}</p>)}
                {!isLoading && contracts.length > 0 && (
                    <>
                        <div className="document-card-list" data-testid="contracts-list">
                            {contracts.map((contract) => (
                                <ContractCard key={contract.id} contract={contract} />
                            ))}
                        </div>
                    </>
                )}
            </div>
        </DefaultLayout>
    );
};

export const WorkflowContractsScreen = withTranslationContext(withWorkflowContext(WorkflowContractsScreenBase));
